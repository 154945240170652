import { Col, Row } from "antd";
import moment from "moment";
import React, { Fragment } from "react";
import { contactNameFormat } from "../../../../shared/Utils/utilities";
type Props = {
  appointment?: any;
};

const PatientDetails: React.FunctionComponent<Props> = ({
  appointment,
}: Props) => {
  return (
    <Fragment>
      <Col span={24}>
        <Row>
          <Col span={6}>
            <p className=" mb-5 ">Doctor:</p>
          </Col>
          <Col span={18}>
            <p className=" mb-5 bold capitalize">{appointment?.doctor?.name}</p>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <p className=" mb-5 ">Location:</p>
          </Col>
          <Col span={18}>
            <p className=" mb-5 bold capitalize">
              {appointment?.branch === null
                ? "Video Consultation"
                : appointment?.branch?.name}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <p className="mb-5 ">Date and Time:</p>
          </Col>
          <Col span={18}>
            <p className="mb-5 bold">
              {moment(appointment?.appointment_date).format("Do MMM YYYY")},{" "}
              {appointment?.start_time}{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <p className="mb-5 ">Patient Name:</p>
          </Col>
          <Col span={18}>
            <p className="mb-5 bold capitalize">
              {contactNameFormat(
                appointment?.patient?.pii?.first_name,
                appointment?.patient?.pii?.last_name,
                appointment?.patient?.genderMaster?.name,
                appointment?.patient?.dob
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <p className="mb-5 ">Amount:</p>
          </Col>
          <Col span={18}>
            <p className="mb-5 bold">₹ {appointment?.amount_after_discount} </p>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};

export default PatientDetails;
