import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";

function AdminDashboardPage() {
  const [url, setUrl] = useState("" as any);
  const [proxyUrl, setProxyUrl] = useState("" as any);
  const [iframe, loadIframe] = useState(false);

  const changeUrl = () => {
    // if(url === ""){
    //   message.error("Provide Details")
    //   return
    // }
    loadIframe(true);
  };

  const changeProxyUrl = (url: any) => {
    setProxyUrl(url);
  };

  return (
    <Row>
      <Col span={8} />
      <Col span={16}>
        <Row gutter={20}>
          <Col span={24}>
            <label>Load Iframe</label>
          </Col>
          <Col span={12}>
            <Input
              placeholder="enter proxy url"
              onChange={(evt: any) => changeProxyUrl(evt.target.value)}
            />
          </Col>
          <Col span={12}>
            <Input
              placeholder="enter redirect url"
              onChange={(evt) => (setUrl(evt.target.value), loadIframe(false))}
            />
          </Col>
          <Col span={24} className="mt10">
            <Button type={"primary"} onClick={() => changeUrl()}>
              Get Frame
            </Button>
          </Col>
          <Col span={24} className="mt20">
            {iframe === true ? (
              <iframe
                sandbox={"allow-scripts"}
                src={proxyUrl + url}
                style={{
                  border: "none",
                  height: "100vh",
                  width: "100%",
                }}
              ></iframe>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default AdminDashboardPage;
