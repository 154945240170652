import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import Password from "antd/es/input/Password";
import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  fetchSelf,
  fetchSelfNotificationPreference,
  setPassword,
  setSelfNotificationPreference,
} from "../../../redux/reducers/auth.slice";
import { fetchMessageTemplateTypes } from "../../../redux/reducers/messge-template.slice";
import { RootState } from "../../../shared/constants";
import { getFullName } from "../../../shared/Utils/utilities";

const { Text } = Typography;
type Props = {};

const CreatePassword = () => {
  const dispatch = useDispatch();
  return (
    <Form
      name="Set Password"
      className="login-form"
      layout={"vertical"}
      initialValues={{
        password: "123456",
        confirmPassword: "123456",
      }}
      autoComplete="off"
      onFinish={async (values) => {
        if (values.password !== values.confirmPassword) {
          notification.error({ message: "Password does not match" });
          return;
        }
        try {
          const response = await dispatch(
            setPassword({ password: values.password, type: "pin" })
          );
          notification.success({ message: "Password set successfully" });
          dispatch(fetchSelf());
        } catch (e) {
          notification.error({ message: JSON.stringify(e) });
        }
      }}
    >
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: false, message: "Password required" }]}
      >
        <Password />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        rules={[{ required: false, message: "Confirm Password required" }]}
      >
        <Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Set password
        </Button>
      </Form.Item>
    </Form>
  );
};
const ChangePassword = () => {
  const dispatch = useDispatch();
  return (
    <Form
      name="Change Password"
      className="login-form"
      layout={"vertical"}
      initialValues={{
        password: "123456",
        confirmPassword: "123456",
        oldPassword: "123456",
      }}
      autoComplete="off"
      onFinish={async (values) => {
        if (values.password !== values.confirmPassword) {
          notification.error({ message: "Password does not match" });
          return;
        }
        try {
          const response = await dispatch(
            changePassword({
              oldPassword: values.oldPassword,
              password: values.password,
              type: "password",
            })
          );
          if (!response?.payload?.data?.error) {
            notification.success({ message: "Password set successfully" });
            dispatch(fetchSelf());
          }
        } catch (e) {
          notification.error({ message: JSON.stringify(e) });
        }
      }}
    >
      <Form.Item
        name="oldPassword"
        label="Old Password"
        rules={[{ required: false, message: "Old Password required" }]}
      >
        <Password />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: false, message: "Password required" }]}
      >
        <Password />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        rules={[{ required: false, message: "Confirm Password required" }]}
      >
        <Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
const ProfilePage: React.FunctionComponent<Props> = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { templateTypes } = useSelector(
    (state: RootState) => state.messageTemplate
  );
  const { userNotificationPreferences, settingUserNotificationPreference } =
    useSelector((state: RootState) => state.auth);
  const refresh = async () => {
    dispatch(fetchSelfNotificationPreference());
  };
  useEffect(() => {
    dispatch(fetchMessageTemplateTypes());
    refresh();
  }, []);
  console.log(user);
  if (!user) {
    return <Skeleton active={true} />;
  }
  return (
    <>
      {/*<PermissionComponent permission={PATIENT_MODULE_ACTIONS_READ}>*/}
      <div className={"col-md-12 main-container"}>
        <Title>{getFullName(user)}</Title>
        <Row>
          <Col xs={24} lg={8}>
            {user.logins?.pin ? (
              <ChangePassword />
            ) : (
              <>
                <CreatePassword />
              </>
            )}
          </Col>
        </Row>
        <Divider />
        <Card>
          <Title level={5}>Notification Preferences</Title>
          <Space direction={"vertical"} style={{ width: "100%" }}>
            {templateTypes.map((templateType: any) => {
              return (
                <Row>
                  <Col flex={"auto"}>
                    <label>{templateType.value}</label>
                  </Col>
                  <Col>
                    {templateType.configurable ? (
                      <Select
                        style={{ minWidth: "200px" }}
                        value={
                          userNotificationPreferences.find(
                            (a: any) => a.type === templateType.key
                          )?.notification_status
                        }
                        loading={settingUserNotificationPreference}
                        onSelect={async (e: any) => {
                          try {
                            const response = await dispatch(
                              setSelfNotificationPreference({
                                type: templateType.key,
                                notification_status: e,
                              })
                            );
                            if (!response?.payload?.data?.error) {
                              refresh();
                            }
                          } catch (e) {
                            notification.error({ message: JSON.stringify(e) });
                          }
                        }}
                      >
                        {["active", "silent", "disabled"].map((a: string) => {
                          return <Select.Option value={a}>{a}</Select.Option>;
                        })}
                      </Select>
                    ) : (
                      "Active"
                    )}
                  </Col>
                </Row>
              );
            })}
          </Space>
        </Card>
      </div>
      {/*</PermissionComponent>*/}
    </>
  );
};

export default ProfilePage;
