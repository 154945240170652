import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Client } from "../../shared/Utils/api-client";
import {  TICKETS, TICKET_DETAIL_BY_ID, TICKET_DOCTOR_SUPPORT, TICKET_SERVICE, ACTIVITY_HISTORY, UPDATE_TICKET_TYPE, PAYMENT_HISTORY,VIDEO_LOGS, TICKET_DOWNLOAD } from '../../shared/routes/crm.routes.constants';


const config = {
    name: 'tickets',
};
export const fetchTicketsList: any = createAsyncThunk(`${config.name}/fetchTicketsList`, async (params: any) => {
    return await Client.get(TICKETS, {params})
})
export const createNewTicket: any = createAsyncThunk(`${config.name}/createNewTicket`, async (body: any) => {
    return await Client.post(TICKETS, body)
})
export const fetchTicketsDetails: any = createAsyncThunk(`${config.name}/fetchTicketsDetails`, async (id: any) => {
    return await Client.get(TICKET_DETAIL_BY_ID(id), {})
})
export const updateTicket: any = createAsyncThunk(`${config.name}/updateTicket`, async (body: any) => {
    return await Client.patch(TICKET_DETAIL_BY_ID(body?.id), body.data)
})

export const addTicketService: any = createAsyncThunk(`${config.name}/addTicketService`, async (body: any) => {
    return await Client.post(TICKET_SERVICE, body)
})

export const createSupportTicket: any = createAsyncThunk(`${config.name}/createSupportTicket`, async (body: any) => {
    return await Client.post(TICKET_DOCTOR_SUPPORT, body)
})
export const fetchActivityHistory: any = createAsyncThunk(`${config.name}/fetchActivityHistory`, async (params: any) => {
    return await Client.get(ACTIVITY_HISTORY(params.phone),{params})
})
export const fetchVideoLogs: any = createAsyncThunk(`${config.name}/fetchVideoLogs`, async ({id,dates}: { id: string, dates: string }) => {
    return await Client.get(VIDEO_LOGS(id, dates), {})
})
export const updateTicketType: any = createAsyncThunk(`${config.name}/updateTicketType`, async (body: any) => {
    return await Client.patch(UPDATE_TICKET_TYPE(body.id), body.data)
})
export const fetchPaymentHistory: any = createAsyncThunk(`${config.name}/fetchPaymentHistory`, async (id: any) => {
    return await Client.get(PAYMENT_HISTORY(id))
})
export const downloadTicketData: any = createAsyncThunk(`${config.name}/downloadLeadData`, async (params: any) => {
    return await Client.get(TICKET_DOWNLOAD, {params})
})
export const ticketsSlice = createSlice({
    name: config.name,
    initialState: {
        loading: false,
        tickets: [] as any,
        pagination: {offset: 0, limit: 10, total: 0},
        error: '' as string,
        ticket: {} as any,
        filters: null as any
    },
    reducers: {
        reset: (state) => {
            state.tickets = []
            state.loading = false
            state.pagination = {offset: 0, limit: 10, total: 0}
            state.error = ''
            state.ticket = {} as any
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        clearTicketDetails: (state) => {
            state.ticket = {} as any
        }
    }, extraReducers(builder) {
        builder
            //  Fetch Tickets
            .addCase(fetchTicketsList.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(fetchTicketsList.fulfilled, (state, action) => {
                state.loading = false
                state.tickets = action?.payload?.data?.data?.items;
                state.pagination = {
                    offset: action?.payload?.data?.data?.offset, 
                    limit: action?.payload?.data?.data?.limit, 
                    total: action?.payload?.data?.data?.total
                };
            })
            .addCase(fetchTicketsList.rejected, (state) => {
                state.loading = false
                state.error = ''
            })

            // Create Ticket
            .addCase(createNewTicket.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(createNewTicket.fulfilled, (state, action) => {
                state.loading = false
                
            })
            .addCase(createNewTicket.rejected, (state) => {
                state.loading = false
                state.error = ''
            })
           


            //   Ticket Details
            .addCase(fetchTicketsDetails.pending, (state) => {
                state.loading = true
                state.error = ''
                state.ticket = {} as any
            })
            .addCase(fetchTicketsDetails.fulfilled, (state, action) => {
                state.loading = false
                state.ticket = action?.payload?.data?.data;
            })
            .addCase(fetchTicketsDetails.rejected, (state) => {
                state.loading = false
                state.error = ''
                state.ticket = {} as any
            })
    }
})

export const {setFilters, clearTicketDetails} = ticketsSlice.actions

export default ticketsSlice.reducer