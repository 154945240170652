import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { deleteDoctorProcedure, fetchDoctorProcedureConfig } from "../../../../redux/reducers/doctor.slice";
import { RootState } from "../../../../shared/constants";
import TableLayout from "../../../../shared/layouts/table.layout";
import { Master } from "../../../../shared/types/master.type";
import { SlotProcedure } from "../../../../shared/types/slot.type";

const { Option } = Select;

type Props = {
  procedure?: SlotProcedure;
  onSubmitProperty: (values: SlotProcedure) => void;
};

const SlotProcedurePage: React.FunctionComponent<Props> = ({
  procedure,
  onSubmitProperty,
}) => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [form] = useForm();
  const { procedures } = useSelector( (state: RootState) => state.master );
  const [selectedProcedure, setSelectedProcedure] = React.useState(false);
  const { procedureList, loading } = useSelector((state: RootState) => state.doctor);
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [deleteModalText, setDeleteModalText] = React.useState("" as string);
  const [deletePrcId, setDeletePrcId] = React.useState("" as string);
  const [procedureArr, setProcedureArr] = React.useState([] as any);

  const columns = [
    {
      title: "Procedure",
      render: (key: any) => key?.procedure_info?.name,
    },
    {
      title: "Fees",
      dataIndex: "fee",
    },
    {
      title: "Duration (in mins)",
      dataIndex: "interval",
    },
    {
      title: "Follow up days",
      dataIndex: "interval_capacity",
    },
    // {
    //   title: "Follow up Fees",
    //   dataIndex: "interval",
    // },
    {
      fixed: "right" as "right",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
              <Button
                onClick={() => openDeleteModal(a?.id) }
                shape="circle"
                icon={<CloseOutlined />}
                size={"middle"}
              />
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if(location?.state && location?.state?.mode === 'edit'){
      getProcedure(location?.state?.id);
    }
    const allSelected = procedureList.map((val:any)=>{
      return val?.procedure_id
    })
    setProcedureArr(procedures?.filter((val:any)=>{
        return allSelected.indexOf(val?.id) === -1
    }));
  }, [dispatch]);

  const getProcedure = (id:string) => {
    dispatch(fetchDoctorProcedureConfig({ doctor_slot_config_id: id }));
  }

  const handleChange = (value: string) => {
    setSelectedProcedure(true)
  };

  const openDeleteModal = async (id: string) => {
    setDeleteModalVisible(true);
    setDeleteModalText("Are you sure, you want to delete this procedure.");
    setDeletePrcId(id);
  };

  const handleDeleteModal = async () => {
    await dispatch(deleteDoctorProcedure(deletePrcId));
    await closeDeleteModal();
    await getProcedure(location?.state?.id);
  };

  const closeDeleteModal = () => {
    setDeleteModalText("");
    setDeleteModalVisible(false);
  };
  
  return (
    <Form
      name="basic"
      form={form}
      layout={"vertical"}
      wrapperCol={{ span: 20 }}

      className="mt20"
      // initialValues={regionDetail}
      onFinish={(values: SlotProcedure) => {
        onSubmitProperty(values);
      }}
      onFinishFailed={({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
      }}
      autoComplete="off"
    >
      <Row>
        <TableLayout
          loading={loading}
          dataSource={procedureList}
          columns={columns}
          type="blankFilter"
          pagination={false}
        />
        <Col span={12} className='mt20'>
          <Form.Item
            label="Select Consultation Type"
            name="procedure_id"
            rules={[{ required: true, message: "Please choose Consultation Type" }]}
          >
            <Select
            placeholder={"Choose Consultation Type"}
            onChange={(evt) => handleChange(evt)}
          >
            {procedureArr.map((procedure: Master) => {
              return (
                <Option value={procedure.id}>
                  {procedure.name}
                </Option>
              );
            })}
          </Select>
          </Form.Item>
        </Col>
      </Row>
      { selectedProcedure && <Row>
        <Row className="mt10">
          <Col span={24}>
            <label>Enter Fees & Other Information</label>
          </Col>
        </Row>
        <Row className="mt10">
          <Col span={6}>
            <Form.Item
              label="Fees"
              name="fee"
              rules={[{ required: true, message: "Please input Fees" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Duration (in mins)" name="interval">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Follow up days" name="interval_capacity">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Follow up Fees" name="follow_fees">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </Row>  }
      <Modal
        title="Delete Procedure"
        visible={deleteModalVisible}
        onOk={handleDeleteModal}
        onCancel={closeDeleteModal}
      >
        {deleteModalText}
      </Modal>

      
    </Form>
  );
};

export default SlotProcedurePage;
