import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ACCESS_GROUP,
  ACCESS_GROUP_BY_ID,
  ALL_SCOPE_VALUES,
  MODULES,
  PERMISSIONS,
  PERMISSIONS_BY_ROLE_ID,
  ROLES,
  ROLE_BY_ID,
} from "../../shared/routes/route.constants";
import { Role } from "../../shared/types/role.type";
import { Client } from "../../shared/Utils/api-client";

// Change the Roles API Accordingly and remove this one done

const config = {
  name: "roles",
};
export const fetchRoles: any = createAsyncThunk(
  `${config.name}/fetchRoles`,
  async (params: any) => {
    return await Client.get(ROLES, { params });
  }
);
export const fetchAccessGroups: any = createAsyncThunk(
  `${config.name}/fetchAccessGroups`,
  async (params: any) => {
    return await Client.get(ACCESS_GROUP, { params });
  }
);
export const fetchAllScopeValues: any = createAsyncThunk(
  `${config.name}/fetchAllScopeValues`,
  async (params: any) => {
    return await Client.get(ALL_SCOPE_VALUES, { params });
  }
);

export const fetchAllPermissions: any = createAsyncThunk(
  `${config.name}/fetchAllPermissions`,
  async (params: any) => {
    return await Client.get(PERMISSIONS, { params });
  }
);
export const fetchAllModules: any = createAsyncThunk(
  `${config.name}/fetchAllModules`,
  async (params: any) => {
    return await Client.get(MODULES, { params });
  }
);

export const fetchRoleById: any = createAsyncThunk(
  `${config.name}/fetchRoleById`,
  async (id: string) => {
    return await Client.get(ROLE_BY_ID(id), {});
  }
);
export const fetchAccessGroupById: any = createAsyncThunk(
  `${config.name}/fetchAccessGroupById`,
  async ({ id, withDetails }: any) => {
    return await Client.get(ACCESS_GROUP_BY_ID(id), { withDetails });
  }
);
export const addNewRole: any = createAsyncThunk(
  `${config.name}/addNewRole`,
  async (data: Role) => {
    return await Client.post(ROLES, data);
  }
);
export const addNewAccessGroup: any = createAsyncThunk(
  `${config.name}/addNewAccessGroup`,
  async (data: Role) => {
    return await Client.post(ACCESS_GROUP, data);
  }
);
export const updateRoleById: any = createAsyncThunk(
  `${config.name}/updateRoleById`,
  async ({ id, data }: { id: string; data: Role }) => {
    return await Client.put(ROLE_BY_ID(id), data);
  }
);
export const updateAccessGroupById: any = createAsyncThunk(
  `${config.name}/updateAccessGroupById`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.put(ACCESS_GROUP_BY_ID(id), data);
  }
);
export const updateRolePermissions: any = createAsyncThunk(
  `${config.name}/updateRolePermissions`,
  async ({ id, data }: { id: string; data: { permissions: string[] } }) => {
    return await Client.post(PERMISSIONS_BY_ROLE_ID(id), data);
  }
);
export const roleSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingRole: false,
    savingAccessGroup: false,
    role: null as Role | null,
    accessGroup: null as any | null,
    error: "" as string,
    permissions: [] as any[],
    modules: [] as any[],
    roles: [] as Role[],
    allScopeValues: [] as any[],
    accessGroups: [] as any[],
    pagination: { offset: 0, limit: 10, total: 0 },
    loadingAccessGroups: false,
  },
  reducers: {
    reset: (state) => {
      state.role = null;
      state.savingRole = false;
      state.loading = false;
      state.error = "";
      state.roles = [];
      state.allScopeValues = [] as any[];
      state.permissions = [];
      state.pagination = { offset: 0, limit: 10, total: 0 };
    },
  },
  extraReducers(builder) {
    builder
      // Permissions List
      .addCase(fetchAllScopeValues.fulfilled, (state, action) => {
        // state.allScopeValues = ''
        //console.log(action.payload)
        state.allScopeValues = action.payload.data.data;
      })
      .addCase(fetchAllPermissions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload?.data?.data;
      })
      .addCase(fetchAllPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Modules List
      .addCase(fetchAllModules.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllModules.fulfilled, (state, action) => {
        state.loading = false;
        state.modules = action.payload?.data?.data;
      })
      .addCase(fetchAllModules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Role List
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload.data.data.items;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Access Groups
      .addCase(fetchAccessGroups.pending, (state) => {
        state.loadingAccessGroups = true;
        state.error = "";
      })
      .addCase(fetchAccessGroups.fulfilled, (state, action) => {
        state.loadingAccessGroups = false;
        state.accessGroups = action.payload?.data?.data?.items;
        state.pagination = {
          offset: action.payload.data.data.offset,
          limit: action.payload.data.data.limit,
          total: action.payload.data.data.total,
        };
      })
      .addCase(fetchAccessGroups.rejected, (state, action) => {
        state.loadingAccessGroups = false;
        state.error = action.error.message;
      })

      // Role By Id
      .addCase(fetchRoleById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.role = null;
      })
      .addCase(fetchRoleById.fulfilled, (state, action) => {
        state.loading = false;
        state.role = action.payload?.data?.data;
      })
      .addCase(fetchRoleById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Role By Id
      .addCase(fetchAccessGroupById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.accessGroup = null;
      })
      .addCase(fetchAccessGroupById.fulfilled, (state, action) => {
        state.loading = false;
        state.accessGroup = action.payload?.data?.data;
      })
      .addCase(fetchAccessGroupById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Role create
      .addCase(addNewRole.pending, (state) => {
        state.savingRole = true;
        state.error = "";
      })
      .addCase(addNewRole.fulfilled, (state, action) => {
        state.savingRole = false;
      })
      .addCase(addNewRole.rejected, (state, action) => {
        state.savingRole = false;
        state.error = action.error.message;
      })

      // Role create
      .addCase(addNewAccessGroup.pending, (state) => {
        state.savingAccessGroup = true;
        state.error = "";
      })
      .addCase(addNewAccessGroup.fulfilled, (state, action) => {
        state.savingAccessGroup = false;
      })
      .addCase(addNewAccessGroup.rejected, (state, action) => {
        state.savingAccessGroup = false;
        state.error = action.error.message;
      })

      // Role create
      .addCase(updateRoleById.pending, (state) => {
        state.savingRole = true;
        state.error = "";
      })
      .addCase(updateRoleById.fulfilled, (state, action) => {
        state.savingRole = false;
        //console.log('here2')
      })
      .addCase(updateRoleById.rejected, (state, action) => {
        state.savingRole = false;
        state.error = action.error.message;
      })
      // Role create
      .addCase(updateAccessGroupById.pending, (state) => {
        state.savingAccessGroup = true;
        state.error = "";
      })
      .addCase(updateAccessGroupById.fulfilled, (state, action) => {
        state.savingAccessGroup = false;
      })
      .addCase(updateAccessGroupById.rejected, (state, action) => {
        state.savingAccessGroup = false;
        state.error = action.error.message;
      })
      // Role create
      .addCase(updateRolePermissions.pending, (state) => {
        state.savingRole = true;
        state.error = "";
      })
      .addCase(updateRolePermissions.fulfilled, (state, action) => {
        state.savingRole = false;
      })
      .addCase(updateRolePermissions.rejected, (state, action) => {
        state.savingRole = false;
        state.error = action.error.message;
      });
  },
});

//export const {} = roleSlice.actions

export default roleSlice.reducer;
