import { Button, Col, message, Modal, Row, Select, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchAppointmentById,
  fetchAppointmentPaymentRecipet,
  updateAppointmentById,
} from "../../../redux/reducers/appointment.slice";
import { fetchDoctorAvailSlots } from "../../../redux/reducers/doctor.slice";
import { getSettings } from "../../../redux/reducers/settings.slice";
import { CLIENT_NAME, RootState } from "../../../shared/constants";
import ForwardIcon from "../../../shared/icons/ForwardIcon";
import GoBacklayout from "../../../shared/layouts/go-back.layout";
import ResultModalLayout from "../../../shared/layouts/resultmodal.layout";
import DoctorSlots from "../../admin/Doctors/components/doctor-slots";
const { Option } = Select;
const { Text } = Typography;

type Props = {
  type?: string;
};

const AppointmentSupportPage: React.FunctionComponent<Props> = ({ type }) => {
  const dispatch = useDispatch();
  const { appointment } = useSelector((state: RootState) => state.appointment);
  const { doctor_avail_slots, slots_loader } = useSelector(
    (state: RootState) => state.doctor || {}
  );
  const [resheduleAppointment, setReSheduleAppointment] = useState(false);
  const [oldSlot, setNewSlot] = useState(null as any);
  const RouteParams: any = useParams();
  const [cancelAppointment, setCancelAppointment] = useState(false as any);
  const [cancelReason, setCancelReason] = useState("" as any);
  const [callExecutive, setCallExecutive] = useState(false as any);
  const { loading, settings } = useSelector(
    (state: RootState) => state.settings
  );
  const [resultModal, setResultModal] = useState(false);
  const [title, setTitle] = useState("" as any);
  const [subTitle, setSubTitle] = useState("" as any);
  useEffect(() => {
    dispatch(fetchAppointmentById(RouteParams["id"]));
    dispatch(getSettings({ type: "general" }));
  }, []);

  const onSubmit = (slot: any, procedure: any, branch: any, current: any) => {
    let data: any = {
      source: "web",
      procedure_id: procedure?.procedure_id,
      slot_id: slot?.slot_id,
      slot_time: slot?.s_time,
      procedure_type: procedure?.procedure_name,
      branch_id: branch?.branch_id === undefined ? null : branch?.branch_id,
      fee: branch?.fee,
      slot_date: current,
      external_procedure_id: procedure?.external_procedure_id,
      doctor_id: appointment?.doctor?.id,
      patient_id: appointment?.patient?.id,
    };
    setNewSlot(data);
  };

  console.log(oldSlot);
  const reSheduleAppointment = () => {
    (async function () {
      try {
        const slots = await dispatch(
          fetchDoctorAvailSlots({
            doctor_id: appointment.doctor_id,
            branch_id: appointment.branch_id,
            procedure_id: appointment.procedure_id,
            old_appointment_id: appointment.id,
            fee: appointment.amount,
          })
        );
        if (slots.payload.status === 200) {
          setReSheduleAppointment(true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const rescheduleAppointment = () => {
    if (oldSlot === null) {
      message.error("Choose Time Slot");
      return;
    }
    (async function () {
      try {
        oldSlot.status = "re-scheduled";
        const response = await dispatch(
          updateAppointmentById({ id: RouteParams["id"], data: oldSlot })
        );
        if (response.payload.status === 200) {
          if (response.payload.data.data.is_ticket_created === true) {
            setTitle(response.payload.data.data.message);
            setSubTitle(response.payload.data.data.sub_title);
          } else {
            setTitle(response.payload.data.data.message);
            setSubTitle("");
          }
          setReSheduleAppointment(false);
          setResultModal(true);
        }
      } catch (e) {}
    })();
  };

  const getSlots = (date: any) => {
    (async function () {
      try {
        const slots = await dispatch(
          fetchDoctorAvailSlots({
            doctor_id: appointment.doctor_id,
            branch_id: appointment.branch_id,
            procedure_id: appointment.procedure_id,
            slot_date: moment(date).format("YYYY-MM-DD"),
          })
        );
        if (slots.payload.status === 200) {
          setReSheduleAppointment(true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const cancelAppointmentFinal = () => {
    (async function () {
      if (cancelReason === "") {
        message.error("Choose reason");
        return;
      }
      let _appointmentFinal = {
        status: "cancelled",
        remark: cancelReason,
      };
      try {
        const _appointment = await dispatch(
          updateAppointmentById({ id: appointment.id, data: _appointmentFinal })
        );
        if (_appointment.payload.status === 200) {
          if (_appointment.payload.data.data.is_ticket_created === true) {
            setTitle(_appointment.payload.data.data.message);
            setSubTitle(_appointment.payload.data.data.sub_title);
          } else {
            setTitle(_appointment.payload.data.data.message);
            setSubTitle("");
          }
          setCancelAppointment(false);
          setResultModal(true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const viewReceipt = () => {
    (async function () {
      try {
        let response: any;
        if (appointment?.type === "historical") {
          response = await dispatch(
            fetchAppointmentPaymentRecipet({
              external_appointment_id: RouteParams["id"],
            })
          );
        } else {
          response = await dispatch(
            fetchAppointmentPaymentRecipet({
              appointment_id: appointment.id,
              patient_id: appointment?.patient_id,
            })
          );
        }
        if (response.payload.status === 200) {
          window.open(response?.payload?.data?.data?.signed_url);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <Row className="padding20 doctor-app" justify={"center"}>
      <Col span={1} md={1} xs={1}>
        <GoBacklayout
          route={"/patient/appointment/" + appointment?.id}
        ></GoBacklayout>
      </Col>
      <Col span={23} xs={23} md={23} className="text-center">
        <h2 className="doctor-secondary-color">
          <b>How Can We Help?</b>
        </h2>
      </Col>

      <Col
        span={12}
        md={12}
        xs={24}
        className="doctor-box-shadow doctor-top-border white-background  mt10"
      >
        <Row>
          <Col span={24}>
            <ul className="supportMenu">
              {appointment?.bill_ready && (
                <>
                  <li>
                    <Text
                      underline
                      className="doctor-secondary-color  text-center mt10"
                      onClick={() => viewReceipt()}
                    >
                      Download Invoice
                    </Text>
                    <span
                      style={{ float: "right", marginTop: "2px" }}
                      className={"cursor"}
                    >
                      <ForwardIcon height="14" />
                    </span>
                  </li>
                </>
              )}

              <li className="mb0">
                <Text
                  underline
                  className="doctor-secondary-color  text-center mt10 cursor"
                  onClick={() => setCallExecutive(true)}
                >
                  Call Support Executive
                </Text>
                <span
                  style={{ float: "right", marginTop: "2px" }}
                  className={"cursor"}
                  onClick={() => setCallExecutive(true)}
                >
                  <ForwardIcon height="14" />
                </span>
              </li>

              {appointment?.enable_cancellation && (
                <>
                  <li>
                    <Text
                      underline
                      className="doctor-secondary-color  text-center mt10 cursor"
                      onClick={() => setCancelAppointment(true)}
                    >
                      Cancel the Appointment
                    </Text>

                    <span
                      style={{ float: "right", marginTop: "2px" }}
                      className={"cursor"}
                      onClick={() => setCancelAppointment(true)}
                    >
                      <ForwardIcon height="14" />
                    </span>
                  </li>
                </>
              )}

              {appointment?.enable_reschedule && (
                <>
                  <li className="">
                    <Text
                      underline
                      className="doctor-secondary-color  text-center mt10 cursor"
                      onClick={() => reSheduleAppointment()}
                    >
                      Reschedule the Appointment
                    </Text>

                    <span
                      style={{ float: "right", marginTop: "2px" }}
                      className={"cursor"}
                      onClick={() => reSheduleAppointment()}
                    >
                      <ForwardIcon height="14" />
                    </span>
                  </li>
                </>
              )}
            </ul>
          </Col>
        </Row>
      </Col>

      {/* Reschedule Appointment */}
      <Modal
        width={741}
        title={
          <p className={"capitalize mb0"}>
            <b>
              Choose Slot for New Appointment <br></br>(
              {appointment?.doctor?.name} | {appointment?.branch?.name})
            </b>{" "}
            <br />
            <span
              className={" light-text"}
              style={{
                marginTop: "10px",
                display: "block",
                color: "#003878",
                opacity: ".5",
              }}
            >
              Previous Appointment :{" "}
              {moment(appointment?.appointment_date).format("Do, MMM YYYY")},{" "}
              {appointment?.start_time} | ₹{appointment?.amount}
            </span>
          </p>
        }
        centered
        className="nopadding border-radius doctor-app-dark-modal "
        onCancel={() => setReSheduleAppointment(false)}
        visible={resheduleAppointment}
        footer={
          <>
            {oldSlot !== null && oldSlot?.slot_time && (
              <p className="primary-color-text mt10  text-center fw6">
                New Appointment will be scheduled on{" "}
                {moment(oldSlot.slot_date).format("DD MMM' YYYY")},{" "}
                {oldSlot.slot_time} with Rs. {oldSlot.fee} Fee. <br />
                Are you sure you want to continue?
              </p>
            )}

            <Button
              size={"large"}
              className={"doctor-secondary-button"}
              block
              onClick={() => rescheduleAppointment()}
            >
              Reschedule
            </Button>

            <p className={"text-center mt10"}>
              <i className="sub-text mt10">
                Invoice already generated. Request will be sent to {CLIENT_NAME}{" "}
                front office
              </i>
            </p>
          </>
        }
      >
        <Row>
          <Col span={24}>
            <DoctorSlots
              mode={"view"}
              onDateChange={(date: any) => getSlots(date)}
              slots={doctor_avail_slots}
              slots_loader={slots_loader}
              onSubmit={(
                slot: any,
                procedure: any,
                branch: any,
                current: any
              ) => onSubmit(slot, procedure, branch, current)}
            ></DoctorSlots>
          </Col>
        </Row>
      </Modal>

      {/* Cancel Appointment */}
      <Modal
        visible={cancelAppointment}
        footer={false}
        closable={false}
        className="nopadding border-radius doctor-app-dark-modal"
        title={"Cancel Appointment"}
        centered
        maskClosable={false}
        onCancel={() => setCancelAppointment(false)}
      >
        <Row gutter={20} className={"padding20"}>
          <Col span={24}>
            <p className={"doctor-primary-color"}>
              Please choose a reason for cancellation
            </p>
          </Col>

          <Col span={24}>
            <Select
              className={"full-width"}
              size={"large"}
              placeholder={"Choose reason"}
              onChange={(evt) => setCancelReason(evt)}
            >
              <Option value={"Booked by mistake"}>Booked by mistake</Option>
              <Option value={"Doctor Not available"}>
                Doctor Not available
              </Option>
              <Option value={"Met with same Doctor"}>
                Met with same Doctor
              </Option>
              <Option value={"Met with some other Doctor"}>
                Met with some other Doctor
              </Option>
              <Option value={"High Doctor Wait time"}>
                High Doctor Wait time
              </Option>
              <Option value={"I (Patient) running late for the appointment"}>
                I (Patient) running late for the appointment
              </Option>
              <Option value={"Appointment not required anymore"}>
                Appointment not required anymore
              </Option>
            </Select>
          </Col>

          <Col span={12} className={"mt20"}>
            <Button
              size={"large"}
              onClick={() => setCancelAppointment(false)}
              block
              className={"doctor-border-button"}
            >
              Go Back
            </Button>
          </Col>
          <Col span={12} className={"mt20"}>
            <Button
              size={"large"}
              disabled={cancelReason === "" ? true : false}
              block
              className={"doctor-secondary-button"}
              onClick={() => cancelAppointmentFinal()}
            >
              Cancel Appointment
            </Button>
          </Col>
          <Col span={24} className={"text-center mt10"}>
            <i className="sub-title">
              Invoice already generated. Request will be <br />
              sent to Max front office
            </i>
          </Col>
        </Row>
      </Modal>
      <Modal
        centered
        visible={callExecutive}
        onCancel={() => setCallExecutive(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            {!loading &&
              settings.length !== 0 &&
              settings?.contacts.map((item: any) => {
                return (
                  <p className={"capitalize"}>
                    <b>{item.type.replace("_", " ")}</b> <br></br>{" "}
                    <span className="doctor-secondary-color">{item.phone}</span>
                  </p>
                );
              })}
          </Col>
        </Row>
      </Modal>

      {resultModal && (
        <ResultModalLayout
          type="success"
          modalVisible={resultModal}
          setModalVisible={(data) => setResultModal(data)}
          title={title}
          subtitle={subTitle}
        ></ResultModalLayout>
      )}
    </Row>
  );
};

export default AppointmentSupportPage;
