import { CheckOutlined, EditFilled, InfoOutlined } from "@ant-design/icons";
import { Button, Collapse, Row, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchPermissions } from "../../../redux/reducers/permission.slice";
import { fetchRoles } from "../../../redux/reducers/role.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import {
  ROLE_MODULE_ACTIONS_READ,
  ROLE_MODULE_ACTIONS_UPDATE
} from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import DesktopListLayout from "../../../shared/layouts/desktop-list.layout";
import {
  ROUTE_ROLE_ACTION,
  ROUTE_ROLE_CREATE
} from "../../../shared/routes/admin.routes.constants";
import { Role } from "../../../shared/types/role.type";

const { Panel } = Collapse;

const { Text } = Typography;
type Props = {};

const RoleList: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const RouteParams: any = useParams();
  const [selectedPermissions, setSelectedPermissions] = useState(
    [] as string[]
  );
  const [rowId, setrowId] = useState();
  const { roles, loading } = useSelector((state: RootState) => state.role);
  const role: Role = useSelector((state: RootState) => state.role.role);
  let [count, setcount] = useState(1);
  const {
    permission: { permissions, loading: loadingPermissions },
  } = useSelector((state: RootState) => state);

  const callPermission = (data: any, e: any) => {
    setrowId(data.id);
    setcount(++count);
    console.log("data.id", data.id);
  };

  useEffect(() => {
    dispatch(fetchPermissions({}));
  }, [count]);

  useEffect(() => {
    // console.log("roles?.actions", roles?.actions);
    if (roles) {
      roles.map((role: any) => {
        if (role.id === rowId) {
          setSelectedPermissions(role?.actions);
        }
      });
    }
  }, [count]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);


  const columns = [
    {
      title: "S.No",
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "40%",
    },
    {
      title: "Module Permissions/Actions",
      width: "40%",
      render: (a: any) => (
        <>
          <Tooltip
            overlayStyle={{
              maxHeight: "300px",
              maxWidth: "500px",
              minWidth: "350px",
              overflowY: "scroll",
            }}
            placement="right"
            title={
              permissions?.moduleSpecific &&
              Object.keys(permissions?.moduleSpecific)?.map((key: any) => {
                return (
                  <>
                    <Collapse>
                      <Panel header={key} key={key}>
                        <ul>
                          {Object.keys(
                            permissions.moduleSpecific[key].actions
                          ).map((k) => {
                            return (
                              <li>
                                {k}:{" "}
                                {selectedPermissions?.map((selected, i) => {
                                  const newValues = selected.includes(".");
                                  if (!newValues) {
                                    if (
                                      key.toLowerCase() ===
                                      selected.toLowerCase()
                                    ) {
                                      return <CheckOutlined />;
                                    } else {
                                      return "";
                                    }
                                  } else {
                                    const arrs = selected.split(".");
                                    if (
                                      key.toLowerCase() ===
                                        arrs[0].toLowerCase() &&
                                      k.toLowerCase() ===
                                        arrs[2].replace(/_/gi, "")
                                    ) {
                                      return <CheckOutlined />;
                                    } else {
                                      return "";
                                    }
                                  }
                                })}
                              </li>
                            );
                          })}
                          {Object.keys(permissions.global).map((k) => {
                            return (
                              <>
                                <li>
                                  {k}:{" "}
                                  {selectedPermissions?.map((selected, i) => {
                                    const newValues = selected.includes(".");
                                    if (!newValues) {
                                      if (
                                        key.toLowerCase() ===
                                        selected.toLowerCase()
                                      ) {
                                        return <CheckOutlined />;
                                      } else {
                                        return "";
                                      }
                                    } else {
                                      const arrs = selected.split(".");
                                      if (
                                        key.toLowerCase() ===
                                          arrs[0].toLowerCase() &&
                                        k.toLowerCase() ===
                                          arrs[2].replace("_", "")
                                      ) {
                                        return <CheckOutlined />;
                                      } else {
                                        return "";
                                      }
                                    }
                                  })}
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </Panel>
                    </Collapse>
                  </>
                );
              })
            }
          >
            <Button
              onMouseEnter={(e) => {
                callPermission(a, e);
              }}
            >
              <InfoOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },

    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <PermissionComponent permission={ROLE_MODULE_ACTIONS_UPDATE}>
              <Button
                onClick={() => history.push(ROUTE_ROLE_ACTION(a.id, "edit"))}
                shape="circle"
                icon={<EditFilled />}
                size={"middle"}
              />
            </PermissionComponent>
          </Row>
        );
      },
    },
  ];

  return (
    <div className={"col-md-12 main-container"}>
      <PermissionComponent permission={ROLE_MODULE_ACTIONS_READ}>
        <DesktopListLayout
          columns={columns}
          dataSource={roles}
          enableAddRoute={ROUTE_ROLE_CREATE}
          loading={loading}
          title={"Users Role"}
        />
      </PermissionComponent>
    </div>
  );
};

export default RoleList;
