import { Button, Form, Input, Select, Skeleton } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { RootState } from "../../../../shared/constants";
import { Branch } from "../../../../shared/types/branch.type";
import { Department } from "../../../../shared/types/department.type";

const { Option } = Select;
type Props = {
  onSubmit: (values: Department) => void;
  department?: Department;
};
const DepartmentForm: React.FunctionComponent<Props> = ({
  department,
  onSubmit,
}) => {
  const { savingDepartment, loading } = useSelector(
    (state: RootState) => state.department
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBranches());
  }, []);
  const { branches } = useSelector((state: RootState) => state.branch);
  if (loading) {
    return <Skeleton />;
  }
  return (
    <Form
      name="basic"
      layout={"horizontal"}
      labelAlign={"left"}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 8 }}
      className="mt20"
      initialValues={department}
      onFinish={(values: Department) => {
        onSubmit(values);
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input Name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: "Please input code" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Branch"
        name="branch_id"
        rules={[{ required: true, message: "Choose branch" }]}
      >
        <Select>
          {branches.map((branch: Branch) => {
            return <Option value={branch.id}>{branch.name}</Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button loading={savingDepartment} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DepartmentForm;
