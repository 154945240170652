import { Button, Form, Input, Skeleton } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../shared/constants";
import { Role } from "../../../../shared/types/role.type";

type Props = {
  onSubmit: (values: Role) => void;
  role?: Role;
};
const RoleForm: React.FunctionComponent<Props> = ({ role, onSubmit }) => {
  const [form] = useForm();
  const { savingRole, loading } = useSelector((state: RootState) => state.role);
  useEffect(() => {
    if (role) {
      form.setFieldsValue(role);
    }
  }, [role]);
  if (loading) {
    return <Skeleton />;
  }
  return (
    <Form
      name="basic"
      form={form}
      layout={"horizontal"}
      labelAlign={"left"}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 8 }}
      initialValues={role || {}}
      onFinish={(values: Role) => {
        onSubmit(values);
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
      className="mt20"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input role name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Please input description" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
        <Button loading={savingRole} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RoleForm;
