import { RightOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../../redux/reducers/settings.slice";
import { createSupportTicket } from "../../redux/reducers/tickets.slice";
import { RootState } from "../constants";
import ResultModalLayout from "./resultmodal.layout";
const { TextArea } = Input;
const { Text } = Typography;

function ReportLayout() {
  const [raiseIssue, setRaiseIssue] = useState(false as any);
  const [callExecutive, setCallExecutive] = useState(false as any);
  const [remarks, setRemarks] = useState("" as any);
  const { loading, settings } = useSelector(
    (state: RootState) => state.settings
  );
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.profile);
  const [resultModal, setResultModal] = useState(false);
  const [title] = useState("Your Issue Submitted Successfully" as any);
  const [subTitle] = useState("" as any);
  useEffect(() => {
    dispatch(getSettings({ type: "general" }));
  }, [dispatch]);

  const createTicket = () => {
    if (remarks === "") {
      message.error("Add Remarks");
      return;
    }

    let _data = {
      titile: "Issue",
      remarks: remarks,
      ticket_description: remarks,
      service_type: "appointment",
      doctor_id: profile.id,
    };
    const _createTicket = async () => {
      await dispatch(createSupportTicket(_data));
      setResultModal(true);
      setRaiseIssue(false);
    };
    _createTicket();
  };

  return (
    <Col
      span={24}
      className="doctor-box-shadow doctor-top-border white-background padding20 supportPopup "
    >
      <Row>
        <Col span={24}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: 600,
            }}
            className="cursor"
            onClick={() => setRaiseIssue(true)}
          >
            {" "}
            <Text underline className="doctor-secondary-color  text-center  ">
              Raise an issue
            </Text>{" "}
            <RightOutlined
              style={{ float: "right" }}
              className={"cursor"}
              onClick={() => setRaiseIssue(true)}
            />{" "}
          </span>
          <Divider style={{ margin: "10px 0" }}></Divider>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: 600,
            }}
            className="cursor"
            onClick={() => setCallExecutive(true)}
          >
            {" "}
            <Text
              underline
              className="doctor-secondary-color  cursor text-center  cursor"
            >
              Call Support Executive
            </Text>{" "}
            <RightOutlined
              style={{ float: "right" }}
              onClick={() => setCallExecutive(true)}
              className={"cursor"}
            />{" "}
          </span>
          <Divider style={{ margin: "10px 0" }}></Divider>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: 600,
            }}
            className="cursor"
          >
            {" "}
            <a
              target={"_blank"}
              rel="noreferrer"
              href={
                "https://cdn.maxhospitals.in/application/video/appointment.mp4"
              }
            >
              {" "}
              <Text
                underline
                className="doctor-secondary-color  text-center  cursor"
              >
                How to use App
              </Text>{" "}
            </a>{" "}
            <RightOutlined style={{ float: "right" }} className={"cursor"} />{" "}
          </span>
        </Col>
      </Row>
      <Modal
        centered
        visible={raiseIssue}
        onCancel={() => setRaiseIssue(false)}
        className="doctor-app-dark-modal  border-radius"
        footer={false}
        title={"Raise an Issue"}
      >
        <Row className={"padding20"}>
          <Col span={24}>
            <h4 className={"doctor-primary-color"}>
              Tell us about your concerns
            </h4>
            <TextArea
              rows={10}
              value={remarks}
              onChange={(evt) => setRemarks(evt.target.value)}
            ></TextArea>
            <Button
              block
              className={"doctor-secondary-button mt20"}
              size={"large"}
              onClick={() => createTicket()}
            >
              Submit{" "}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        centered
        visible={callExecutive}
        onCancel={() => setCallExecutive(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            {!loading &&
              settings.length !== 0 &&
              settings?.contacts &&
              settings?.contacts.map((item: any) => {
                return (
                  <p className={"capitalize"}>
                    <b>{item.type.replace("_", " ")}</b> <br></br>{" "}
                    <span className="doctor-secondary-color">{item.phone}</span>
                  </p>
                );
              })}
          </Col>
        </Row>
      </Modal>
      {resultModal && (
        <ResultModalLayout
          type="success"
          modalVisible={resultModal}
          setModalVisible={(data) => setResultModal(data)}
          title={title}
          subtitle={subTitle}
        ></ResultModalLayout>
      )}
    </Col>
  );
}

export default ReportLayout;
