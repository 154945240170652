
import { Modal,Row,Col } from 'antd';
import {useHistory} from "react-router-dom";

type Props = {
    message?: string,
    sub_heading?: string,
    type?:string,
    route?:any,
    visible?:any
  }

function MessageModal({message,sub_heading,route,type,visible}: Props) {
    const history = useHistory();
  return (
     <Modal centered visible={true} footer={visible} maskClosable={false} onCancel={()=>history.push(route)}>
         <Row>
             <Col span={24} className="text-center"> 
             {
                 type === "Success" && <img alt="" src={require("../../assets/icons/success tick.png").default} className="mt50"/>
             }
             <h3 className="primary-color-text mt50 bold">{message} </h3>
             <p className="sub-text mt10">{sub_heading} </p> 
             </Col>
         </Row>
     </Modal>
  );
}

export default MessageModal;
