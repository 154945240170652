import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Input,
  message,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAppointmentById,
  updateAppointmentTicketStatusDetails,
} from "../../../redux/reducers/appointment.slice";
import { fetchDoctorAvailSlots } from "../../../redux/reducers/doctor.slice";
import { fetchTeams } from "../../../redux/reducers/team.slice";
import { updateTicketType } from "../../../redux/reducers/tickets.slice";
import { fetchAgents } from "../../../redux/reducers/user.slice";
import { RootState } from "../../../shared/constants";
import {
  formatDate,
  formatDropDownValue,
} from "../../../shared/Utils/utilities";
import PatientDetails from "../../admin/Appointment/components/patient-details";
import PatientJourney from "../../admin/Appointment/components/patient-journey";
import PatientMessageHistory from "../../admin/Appointment/components/patient-message-history";
import PatientPaymentHistory from "../../admin/Appointment/components/patient-payment-history";
import PatientVideoConsultLogs from "../../admin/Appointment/components/video-logs";
import DoctorSlots from "../../admin/Doctors/components/doctor-slots";
const { Search } = Input;
const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
type Props = {
  ticket?: any;
  appointment: any;
  action: string;
  hideMe: (ret?: any) => void;
  appointmentRequestStatus?: any;
};

function GeneralTicketActions({
  appointment,
  action,
  hideMe,
  appointmentRequestStatus,
  ticket,
}: Props) {
  const dispatch = useDispatch();
  const [actionData] = useState(action as any);
  const [reschedule, setReschedule] = useState(false);
  const [oldSlot, setNewSlot] = useState(null as any);
  const [cancel, setCancelAppointment] = useState(false);
  const [audit, setAuditTrail] = useState(false);
  const [actionReason, setActionReason] = useState(
    "Doctor Not Available" as any
  );
  const { doctor_avail_slots, slots_loader } = useSelector(
    (state: RootState) => state.doctor || {}
  );
  /****** Agent/Team ******/
  const { agents } = useSelector((state: RootState) => state.user);
  const { teams } = useSelector((state: RootState) => state.team);

  const [userList, setUserList] = useState([] as any);
  const [teamList, setTeamList] = useState(null as any);
  const [assigneeList, setAssigneeList] = useState([] as any);
  const [assignTo, setAssignTo] = useState(null as any);
  const [assignee, setAssignee] = useState(null as any);
  const [totalSlots, setTotalSlots] = useState(null as any);

  useEffect(() => {
    doctor_avail_slots?.availableSlots && setTotalSlots(doctor_avail_slots);
  }, [doctor_avail_slots]);
  const onDateChange = (date: any) => {
    dispatch(
      fetchDoctorAvailSlots({
        doctor_id: appointment.doctor_id,
        branch_id: appointment.branch_id,
        procedure_id: appointment.procedure_id,
        old_appointment_id: appointment.id,
        fee: appointment.amount,
        slot_date: moment(date).format("YYYY-MM-DD"),
      })
    );
  };
  useEffect(() => {
    //if(appointment.bill_ready === true){
    dispatch(fetchAgents({ limit: 500 }));
    dispatch(fetchTeams({ limit: 500 }));
    //}
  }, [appointment]);
  useEffect(() => {
    if (agents.length > 0) {
      let vals = agents.map((val: any) => {
        return {
          name: formatDropDownValue(
            `${val?.user?.first_name ? val?.user?.first_name : ""} ${
              val?.user?.last_name ? val?.user?.last_name : ""
            }`
          ),
          value: val?.user?.id,
        };
      });
      setUserList(vals);
      if (ticket?.assigned_user_id) {
        setAssigneeList(vals);
      }
    }
  }, [agents]);
  useEffect(() => {
    if (teams.length > 0) {
      let vals = teams.map((val: any) => {
        return { name: formatDropDownValue(val.name), value: val.id };
      });
      setTeamList(vals);
      if (!ticket?.assigned_user_id && ticket?.assigned_team_id) {
        setAssigneeList(vals);
      }
    }
  }, [teams]);

  function changeSelectedAssignType(e: any) {
    setAssignTo(e);
    setAssignee(null);
    if (e == "agent") {
      setAssigneeList(userList);
    }
    if (e == "team") {
      setAssigneeList(teamList);
    }
  }
  function changeSelectedAssignee(e: any) {
    setAssignee(e);
  }
  async function ticketChangeTypeCancel() {
    let data = {
      event_name: "cancellation_post_invoice",
      remarks: actionReason,
    } as any;
    if (assignTo === "agent" && assignee) {
      data = { ...data, assigned_user_id: assignee };
    }
    if (assignTo === "team" && assignee) {
      data = { ...data, assigned_team_id: assignee };
    }
    const response = await dispatch(
      updateTicketType({ id: ticket?.id, data: data })
    );
    if (response?.payload?.status === 200) {
      message.success(
        "Ticket Type Updated to “Invoice Generated Cancellation Request” and Assigned to Unit Team"
      );
      setCancelAppointment(false);
      hideMe(true);
    }
  }
  async function ticketChangeTypeRes() {
    if (oldSlot === null) {
      message.error("Choose Time Slot");
      return;
    }
    let data = {
      event_name: "reschedule_post_invoice",
      remarks: actionReason,
      meta: oldSlot,
    } as any;
    if (assignTo === "agent" && assignee) {
      data = { ...data, assigned_user_id: assignee };
    }
    if (assignTo === "team" && assignee) {
      data = { ...data, assigned_team_id: assignee };
    }
    const response = await dispatch(
      updateTicketType({ id: ticket?.id, data: data })
    );
    if (response.payload.status === 200) {
      message.success(
        "Ticket Type Updated to “Invoice Generated Reschedule Request” and Assigned to Unit Team"
      );
      setReschedule(false);
      setNewSlot(null);
      hideMe(true);
    }
  }
  /*********************/
  const getDoctorSlots = async (_appointment: any) => {
    try {
      dispatch(fetchDoctorAvailSlots({ doctor_id: _appointment.doctor_id }));
      setReschedule(true);
    } catch (error) {}
  };

  const cancelAppointment = (_appointment: any) => {
    setCancelAppointment(true);
  };
  const auditTrail = (_appointment: any) => {
    setAuditTrail(true);
  };

  const onSubmit = (slot: any, procedure: any, branch: any, current: any) => {
    let data: any = {
      doctor_id: appointment?.doctor?.id,
      branch_id: branch?.branch_id === undefined ? null : branch?.branch_id,
      patient_id: appointment?.patient?.id,
      source: "web",
      procedure_id: procedure?.procedure_id,
      external_procedure_id: procedure?.external_procedure_id,
      slot_id: slot?.slot_id,
      slot_time: slot?.s_time,
      slot_date: current,
      old_appointment_id: appointment.id,
      remarks: actionReason,
      status: "re-scheduled",
    };
    setNewSlot(data);
  };
  const rescheduleAppointment = () => {
    if (oldSlot === null) {
      message.error("Choose Time Slot");
      return;
    }
    (async function () {
      if (appointmentRequestStatus && ticket?.id) {
        try {
          oldSlot.status = "re-scheduled";
          oldSlot.remarks = actionReason;
          const response = await dispatch(
            updateAppointmentTicketStatusDetails({
              ticket_id: ticket?.id,
              remarks: actionReason,
              request_status: "approved",
              appointment: oldSlot,
            })
          );
          if (response.payload.status === 200) {
            message.success("Rescheduled Successfully");
            setReschedule(false);
            setNewSlot(null);
            hideMe(true);
          }
        } catch (error) {}
      } else {
        try {
          oldSlot.status = "re-scheduled";
          oldSlot.remarks = actionReason;
          const response = await dispatch(
            updateAppointmentById({ id: appointment?.id, data: oldSlot })
          );
          if (response.payload.status === 200) {
            message.success("Rescheduled Successfully");
            setReschedule(false);
            setNewSlot(null);
            hideMe(true);
          }
        } catch (error) {}
      }
    })();
  };

  const appointmentCancel = () => {
    (async function () {
      let data = {
        status: "cancelled",
      };
      if (appointmentRequestStatus && ticket?.id) {
        try {
          const response = await dispatch(
            updateAppointmentTicketStatusDetails({
              ticket_id: ticket?.id,
              remarks: actionReason,
              request_status: "approved",
              appointment: {
                status: "cancelled",
                old_appointment_id: appointment.id,
              },
            })
          );
          if (response.payload.status === 200) {
            message.success("Cancelled Successfully");
            setCancelAppointment(false);
            hideMe(true);
          }
        } catch (error) {}
      } else {
        try {
          const response = await dispatch(
            updateAppointmentById({ id: appointment?.id, data: data })
          );
          if (response.payload.status === 200) {
            message.success("Cancelled Successfully");
            setCancelAppointment(false);
            hideMe(true);
          }
        } catch (error) {}
      }
    })();
  };
  const cancelReschedule = () => {
    setReschedule(false);
    setNewSlot(null);
    hideMe();
  };

  useEffect(() => {
    switch (actionData) {
      case "cancel_appointment":
        cancelAppointment(appointment);
        break;
      case "audit_trail":
        auditTrail(appointment);
        break;
      case "reschedule_appointment":
        getDoctorSlots(appointment);
        break;
    }
  }, actionData);
  console.log("TotalSlots:", totalSlots);
  return (
    <>
      {/*  Reschedule Drawer */}
      <Drawer
        title={`Reschedule Appointment`}
        className="crm-app-drawer"
        placement="right"
        width={"50%"}
        footer={
          <>
            {oldSlot !== null && (
              <p className="primary-color-text mt10 bold">
                New Appointment will be scheduled on{" "}
                {formatDate(oldSlot.slot_date, "DD MMM'YY")},{" "}
                {oldSlot.slot_time} with Rs. {appointment?.amount} Fee. Are you
                sure you want to continue?
              </p>
            )}
            {appointment.bill_ready && (
              <>
                <p className="mt10 bold">
                  <span className="text-danger">
                    Invoice is already generated.
                  </span>
                  <br />
                  Please assign ticket to Unit Team for cancellation request
                  approval. Refund will be initiated once cancellation is
                  approved
                </p>
                <p className="mb-5">Select Reason*</p>
                <Select
                  style={{ width: 250 }}
                  defaultValue={"Doctor Not Available"}
                  onChange={(e) => setActionReason(e)}
                  className="mb-20"
                >
                  <Option value={"Doctor Not Available"}>
                    Doctor Not Available
                  </Option>
                  <Option value={"Met with same Doctor"}>
                    Met with same Doctor
                  </Option>
                  <Option value={"Met with some other Doctor"}>
                    Met with some other Doctor
                  </Option>
                  <Option value={"High Doctor Wait time"}>
                    High Doctor Wait time
                  </Option>
                  <Option value={"Patient running late for the appointment"}>
                    Patient running late for the appointment
                  </Option>
                  <Option value={"Appointment not required anymore"}>
                    Appointment not required anymore
                  </Option>
                </Select>
                <Row gutter={16} className="mb-20">
                  <Col span={24}>
                    <p className="mb-5">Assign To</p>
                  </Col>
                  <Col span={9}>
                    <Select
                      value={assignTo}
                      onChange={changeSelectedAssignType}
                      style={{ width: "100%" }}
                    >
                      {[
                        { value: "agent", name: "Agent" },
                        { value: "team", name: "Team" },
                      ].map((option: any, index: number) => {
                        return (
                          <Option key={index} value={option.value}>
                            {option.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={9}>
                    <Select
                      value={assignee}
                      onChange={changeSelectedAssignee}
                      style={{ width: "100%" }}
                    >
                      {assigneeList.map((option: any, index: number) => {
                        return (
                          <Option key={index} value={option.value}>
                            {option.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
                <Space>
                  <Button type="primary" onClick={() => ticketChangeTypeRes()}>
                    Update Ticket
                  </Button>
                </Space>
              </>
            )}
            {!appointment.bill_ready && (
              <>
                <p className="mb-5">Select Reason</p>
                <Select
                  style={{ width: 250 }}
                  defaultValue={"Doctor Not Available"}
                  onChange={(e) => setActionReason(e)}
                >
                  <Option value={"Doctor Not Available"}>
                    Doctor Not Available
                  </Option>
                  <Option value={"Met with same Doctor"}>
                    Met with same Doctor
                  </Option>
                  <Option value={"Met with some other Doctor"}>
                    Met with some other Doctor
                  </Option>
                  <Option value={"High Doctor Wait time"}>
                    High Doctor Wait time
                  </Option>
                  <Option value={"Patient running late for the appointment"}>
                    Patient running late for the appointment
                  </Option>
                  <Option value={"Appointment not required anymore"}>
                    Appointment not required anymore
                  </Option>
                </Select>
                <p>&nbsp;</p>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => rescheduleAppointment()}
                  >
                    Reschedule
                  </Button>
                  <Button onClick={() => cancelReschedule()}>Cancel</Button>
                </Space>
              </>
            )}
          </>
        }
        onClose={() => {
          setReschedule(false);
          hideMe();
        }}
        visible={reschedule}
      >
        <Row>
          <Col span={24} className="grey-background padding20">
            <Space align="center">
              <CalendarOutlined style={{ fontSize: "20px" }} />{" "}
              <p className="primary-color-text mt10 bold">
                Appointment Details
              </p>
            </Space>
          </Col>
        </Row>
        <Row
          style={{ marginBottom: "50px" }}
          className="white-background nomargin padding24"
        >
          <PatientDetails appointment={appointment}></PatientDetails>
        </Row>
        <Row className="nomargin">
          <Col
            span={24}
            className="grey-background padding20"
            style={{ height: "60px", marginTop: "30px" }}
          >
            <Space align="center">
              <UserOutlined style={{ fontSize: "20px" }} />{" "}
              <p className="primary-color-text mt10 bold">Select New Slot</p>
            </Space>
          </Col>
        </Row>
        <Row
          style={{ marginBottom: "50px" }}
          className="white-background nomargin padding24"
        >
          <Col span={24} className="mt20">
            {/* <DoctorSlots mode={"view"} slots={doctor_avail_slots} slots_loader={slots_loader} onSubmit={(slot:any,procedure:any,branch:any,current:any) => onSubmit(slot,procedure,branch,current)}></DoctorSlots> */}
            {totalSlots && (
              <DoctorSlots
                onDateChange={(date: any) => onDateChange(date)}
                mode={"view"}
                slots={totalSlots}
                slots_loader={slots_loader}
                onSubmit={(
                  slot: any,
                  procedure: any,
                  branch: any,
                  current: any
                ) => onSubmit(slot, procedure, branch, current)}
              ></DoctorSlots>
            )}
          </Col>
        </Row>
      </Drawer>

      {/*  Cancel Drawer */}
      <Drawer
        title={`Cancel Appointment Request`}
        className="crm-app-drawer"
        placement="right"
        width={"50%"}
        onClose={() => {
          setCancelAppointment(false);
          hideMe();
        }}
        visible={cancel}
      >
        <Row className="nomargin" align={"middle"}>
          <Col
            span={24}
            className="bg-app padding20"
            style={{ height: "90px" }}
          >
            <Space align="center">
              <CalendarOutlined style={{ fontSize: "20px" }} />{" "}
              <p className="mb-0 bold">Appointment Details</p>
            </Space>
          </Col>
        </Row>
        <Row
          style={{ marginBottom: "50px" }}
          className="white-background nomargin padding24"
        >
          <PatientDetails appointment={appointment}></PatientDetails>
        </Row>

        <Row className="bg-app nomargin padding24">
          <Col span={24}>
            {appointment.bill_ready && (
              <>
                <p className="mt10 bold">
                  <span className="text-danger">
                    Invoice is already generated.
                  </span>
                  <br />
                  Please assign ticket to Unit Team for cancellation request
                  approval. Refund will be initiated once cancellation is
                  approved
                </p>
                <p className="mb-5">Select Reason*</p>
                <Select
                  style={{ width: 250 }}
                  defaultValue={"Doctor Not Available"}
                  onChange={(e) => setActionReason(e)}
                  className="mb-20"
                >
                  <Option value={"Doctor Not Available"}>
                    Doctor Not Available
                  </Option>
                  <Option value={"Met with same Doctor"}>
                    Met with same Doctor
                  </Option>
                  <Option value={"Met with some other Doctor"}>
                    Met with some other Doctor
                  </Option>
                  <Option value={"High Doctor Wait time"}>
                    High Doctor Wait time
                  </Option>
                  <Option value={"Patient running late for the appointment"}>
                    Patient running late for the appointment
                  </Option>
                  <Option value={"Appointment not required anymore"}>
                    Appointment not required anymore
                  </Option>
                </Select>
                <Row gutter={16} className="mb-20">
                  <Col span={24}>
                    <p className="mb-5">Assign To</p>
                  </Col>
                  <Col span={9}>
                    <Select
                      value={assignTo}
                      onChange={changeSelectedAssignType}
                      style={{ width: "100%" }}
                    >
                      {[
                        { value: "agent", name: "Agent" },
                        { value: "team", name: "Team" },
                      ].map((option: any, index: number) => {
                        return (
                          <Option key={index} value={option.value}>
                            {option.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={9}>
                    <Select
                      value={assignee}
                      onChange={changeSelectedAssignee}
                      style={{ width: "100%" }}
                    >
                      {assigneeList.map((option: any, index: number) => {
                        console.log("333333333", option);
                        return (
                          <Option key={index} value={option.value}>
                            {option.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
                <Space>
                  <Button type="primary" onClick={() => appointmentCancel()}>
                    Update Ticket
                  </Button>
                </Space>
              </>
            )}
            {!appointment.bill_ready && (
              <>
                <p className="mt10 bold">
                  Are you sure you want to cancel this appointment? Refund of
                  Rs. {appointment.amount} will be initiated
                </p>
                <p className="mb-5">Select Reason</p>
                <Select
                  style={{ width: 250 }}
                  defaultValue={"Doctor Not Available"}
                  onChange={(e) => setActionReason(e)}
                >
                  <Option value={"Doctor Not Available"}>
                    Doctor Not Available
                  </Option>
                  <Option value={"Met with same Doctor"}>
                    Met with same Doctor
                  </Option>
                  <Option value={"Met with some other Doctor"}>
                    Met with some other Doctor
                  </Option>
                  <Option value={"High Doctor Wait time"}>
                    High Doctor Wait time
                  </Option>
                  <Option value={"Patient running late for the appointment"}>
                    Patient running late for the appointment
                  </Option>
                  <Option value={"Appointment not required anymore"}>
                    Appointment not required anymore
                  </Option>
                </Select>
                <p>&nbsp;</p>
                <Space>
                  <Button type="primary" onClick={() => appointmentCancel()}>
                    Yes, Cancel Appointment
                  </Button>
                  <Button
                    onClick={() => {
                      setCancelAppointment(false);
                      hideMe();
                    }}
                  >
                    No
                  </Button>
                </Space>
              </>
            )}
          </Col>
        </Row>
      </Drawer>

      {/*  Audit Trail Drawer */}
      <Drawer
        title={`Audit Trail`}
        className="crm-app-drawer"
        placement="right"
        width={"80%"}
        onClose={() => {
          setAuditTrail(false);
          hideMe();
        }}
        visible={audit}
      >
        <Row className="nomargin">
          <Col
            span={24}
            className="grey-background padding20"
            style={{ height: "60px" }}
          >
            <Space align="center">
              <CalendarOutlined style={{ fontSize: "20px" }} />{" "}
              <p className="primary-color-text mt10 bold">
                Appointment Details
              </p>
            </Space>
          </Col>
        </Row>
        <Row
          style={{ marginBottom: "50px" }}
          className="white-background nomargin padding24"
        >
          <Col span={14}>
            <PatientDetails appointment={appointment}></PatientDetails>
          </Col>
          {/* <Col span={10} className="text-right mt30">
                        <p><Space><Button className="bordered-secondary-color-btn" type="default">Resend Confirmation Link </Button>  <Button className="bordered-mid-color-btn" type="default">Resend Prescription Link</Button></Space></p>
                        <p><Button className="mt10 bordered-secondary-color-btn" type="default">Send Invoice/Receipt </Button> </p>
                    </Col> */}
        </Row>
        <Row className="mt30">
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Patient Journey" key="1">
                <PatientJourney
                  appointmentId={appointment?.id}
                ></PatientJourney>
                {/* <PatientJourney appointmentId={appointment && appointment?.ticket_map?.length>0 && appointment?.ticket_map[0]?.appointment_id}></PatientJourney> */}
              </TabPane>
              <TabPane tab="Message History" key="2">
                <PatientMessageHistory
                  patient_id={appointment?.patient_id}
                  appointment_id={appointment?.id}
                ></PatientMessageHistory>
              </TabPane>
              <TabPane tab="Payments History" key="3">
                <PatientPaymentHistory
                  orderId={appointment?.order_id}
                ></PatientPaymentHistory>
              </TabPane>
              <TabPane tab="Video Consultation Logs" key="4">
                <PatientVideoConsultLogs
                  appointment={appointment}
                  room_id={appointment?.room_id}
                  appointment_date={appointment?.appointment_date}
                ></PatientVideoConsultLogs>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}

export default GeneralTicketActions;
