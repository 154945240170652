import { SendIcon } from "@100mslive/react-icons";
import { useHMSActions } from "@100mslive/react-sdk";
import { Flex, IconButton, styled } from "@100mslive/react-ui";
import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useChatDraftMessage } from "../AppData/useChatState";
import { ToastManager } from "../Toast/ToastManager";

const TextArea = styled("textarea", {
  width: "100%",
  bg: "transparent",
  color: "$textPrimary",
  resize: "none",
  lineHeight: "1rem",
  height: "50px",
  "&:focus": {
    boxShadow: "none",
    outline: "none",
  },
});

export const ChatFooter = ({ role, peerId, onSend, children }) => {
  const hmsActions = useHMSActions();
  const inputRef = useRef(null);
  const [draftMessage, setDraftMessage] = useChatDraftMessage();
  const { connection } = useSelector((state) => state.chat);
  const { currentApplication, account } = useSelector((state) => state.auth);
  const { appointment } = useSelector((state) => state.appointment);
  const { profile } = useSelector((state) => state.profile);

  const sendMessage = useCallback(async () => {
    const message = inputRef.current.value;
    let data = {
      action: "sendMessage",
      message: message,
      type: "new_msg",
      user_id: profile?.id,
      account_id: account?.id,
      source: "100MS",
      user_name: profile?.name,
      message_type: "text",
      doctor_id: "",
      patient_id: "",
      role: currentApplication,
      appointment_id: "",
      token: localStorage.getItem("token"),
    };
    if (currentApplication === "doctor") {
      data.doctor_id = profile.id;
      data.patient_id = appointment?.patient_id;
    } else {
      data.doctor_id = appointment?.doctor_id;
      data.patient_id = profile.id;
      data.appointment_id = appointment?.id;
    }

    if (!message || !message.trim().length) {
      return;
    }
    try {
      if (role) {
        await hmsActions.sendGroupMessage(message, [currentApplication]);
        connection.send(JSON.stringify(data));
      } else if (peerId) {
        await hmsActions.sendDirectMessage(message, peerId);
        connection.send(JSON.stringify(data));
      } else {
        await hmsActions.sendBroadcastMessage(message);
        connection.send(JSON.stringify(data));
      }
      inputRef.current.value = "";
      setTimeout(() => {
        onSend();
      }, 0);
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  }, [role, peerId, hmsActions, onSend]);

  useEffect(() => {
    const messageElement = inputRef.current;
    if (messageElement) {
      messageElement.value = draftMessage;
    }
  }, [draftMessage]);

  useEffect(() => {
    const messageElement = inputRef.current;
    return () => {
      setDraftMessage(messageElement?.value || "");
    };
  }, [setDraftMessage]);

  return (
    <Flex
      align="center"
      css={{
        borderTop: "1px solid $borderDefault",
        bg: "$menuBg",
        minHeight: "$16",
        maxHeight: "$24",
        position: "relative",
        py: "$4",
        pl: "$8",
      }}
    >
      {children}
      <TextArea
        placeholder="Write something here"
        ref={inputRef}
        className={"ant-input"}
        rows={1}
        autoFocus
        onKeyPress={async (event) => {
          if (event.key === "Enter") {
            if (!event.shiftKey) {
              event.preventDefault();
              await sendMessage();
            }
          }
        }}
      />
      <IconButton
        onClick={sendMessage}
        css={{ ml: "auto", height: "max-content", mr: "$4" }}
        data-testid="send_msg_btn"
      >
        <SendIcon />
      </IconButton>
    </Flex>
  );
};
