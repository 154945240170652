import React from 'react';
import { Typography, Row, Col, Collapse } from 'antd';
import StartQCHeader from './../orderQc/components/start-qc-header.component';
import MedicineComponent from '../../../../shared/layouts/pharmacy-medicine.layout';

const { Title } = Typography;
const { Panel } = Collapse;
type Props = {}

const PharmacyOrderRxGeneration: React.FunctionComponent<Props> = () => {
    function callback() {

    }
      const medicineTtlPrice = (childData: any) =>{
      }

    return (
      <div className={'col-md-12 main-container pd20'}>
        <Title level={2}>Rx Generation</Title>
        <StartQCHeader />
        <Row className='bordered-grey-color'>
          <Col className='pd20 grey-background' span={9}>
            <Collapse bordered={false} defaultActiveKey={['1']} onChange={callback}>
                <Panel header="2 Medicines in Prescription" key="1">
                    <MedicineComponent medicineTotalPrice = {medicineTtlPrice}/>
                </Panel>
                <Panel header="1 Medicines Not in Prescription" key="2">
                    <MedicineComponent medicineTotalPrice = {medicineTtlPrice}/>
                </Panel>
            </Collapse>
          </Col>
          <Col span={15}>
           
          </Col>
        </Row>
      </div>
    );
};

export default PharmacyOrderRxGeneration;

