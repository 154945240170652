import { EditFilled, EyeFilled } from "@ant-design/icons";
import { Button, Popover, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchBranches } from "../../../redux/reducers/branch.slice";
import { fetchTeams } from "../../../redux/reducers/team.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import {
  TEAM_MODULE_ACTIONS_READ,
  TEAM_MODULE_ACTIONS_UPDATE,
} from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import DesktopListLayout from "../../../shared/layouts/desktop-list.layout";
import SearchLayout from "../../../shared/layouts/search";
import {
  ROUTE_TEAM_ACTION,
  ROUTE_TEAM_CREATE,
} from "../../../shared/routes/admin.routes.constants";
import { getFullName } from "../../../shared/Utils/utilities";

const { Text } = Typography;
type Props = {};

const Teams: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const { teams, loading, pagination } = useSelector(
    (state: RootState) => state.team
  );
  const { branches } = useSelector((state: RootState) => state.branch);
  const [filters, setFilters] = useState(null as any);
  const columns = [
    {
      title: "S.No",
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "capitalize",
    },
    {
      title: "Branch",
      render: (a: any) => (
        <>
          {a.branches?.map((val: any) => {
            return <p className="capitalize">{val.branch.name}</p>;
          })}
        </>
      ),
      className: "capitalize",
    },
    {
      title: "Owner",
      dataIndex: ["owner"],
      className: "capitalize",
      render: (a: any, value: any, index: number) => (
        <Text>{getFullName(a)}</Text>
      ),
    },
    {
      title: "Users",
      className: "capitalize",
      render: (a: any) => (
        <>
          <span>{a.users.length} Users</span>
          <Popover
            content={
              <>
                <div>
                  {a.users?.map((val: any) => {
                    return (
                      <p className="capitalize">
                        {val.user.first_name} {val.user.last_name} (
                        {val.user.phone})
                      </p>
                    );
                  })}
                </div>
              </>
            }
            title="Users"
          >
            <Button type="link" icon={<EyeFilled />}></Button>
          </Popover>
        </>
      ),
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <PermissionComponent permission={TEAM_MODULE_ACTIONS_UPDATE}>
              <Button
                onClick={() => history.push(ROUTE_TEAM_ACTION(a.id, "edit"))}
                shape="circle"
                icon={<EditFilled />}
                size={"middle"}
              />
            </PermissionComponent>
          </Row>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBranches());
  }, []);
  useEffect(() => {
    getTeamList();
  }, [filters]);
  const onPageChange = (e: number) => {
    getTeamList(e - 1);
  };
  const onSearch = (name: string) => {
    setFilters({ ...filters, name: name.toLowerCase() });
  };
  const getTeamList = (page: any = null) => {
    let filter = filters
      ? Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => v != null && v != "")
        )
      : {};
    dispatch(
      fetchTeams({
        limit: 10,
        offset: page !== null ? 10 * page : pagination.offset,
        ...filter,
      })
    );
  };
  const onBranchChange = (branch_id: string) => {
    setFilters({ ...filters, branch_id: branch_id });
  };
  return (
    <div className={"col-md-12 main-container"}>
      <PermissionComponent permission={TEAM_MODULE_ACTIONS_READ}>
        <DesktopListLayout
          columns={columns}
          dataSource={teams}
          enableAddRoute={ROUTE_TEAM_CREATE}
          loading={loading}
          total={pagination.total}
          onPageChange={onPageChange}
          take={10}
          currentPage={pagination.offset / 10 + 1}
          actions={[
            <SearchLayout
              placeholder="Search Teams"
              onSearch={(values: any) => onSearch(values)}
            />,
            <Select
              placeholder="Select Branch"
              onChange={(e: any) => onBranchChange(e)}
            >
              {branches?.map((val: any) => {
                return (
                  <>
                    <Select.Option value={val.id}>
                      <span className="capitalize">{val.name}</span>
                    </Select.Option>
                  </>
                );
              })}
            </Select>,
          ]}
          title={"Teams"}
        />
      </PermissionComponent>
    </div>
  );
};

export default Teams;
