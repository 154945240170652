import { EditFilled } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchDepartments } from "../../../redux/reducers/department.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import {
  DEPARTMENT_MODULE_ACTIONS_READ,
  DEPARTMENT_MODULE_ACTIONS_UPDATE,
} from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import DesktopListLayout from "../../../shared/layouts/desktop-list.layout";
import {
  ROUTE_DEPARTMENT_ACTION,
  ROUTE_DEPARTMENT_CREATE,
} from "../../../shared/routes/admin.routes.constants";

const { Text } = Typography;
type Props = {};

const DepartmentsList: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const { departments, loading } = useSelector(
    (state: RootState) => state.department
  );
  const columns = [
    {
      title: "S.No",
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Branch",
      dataIndex: ["branch", "name"],
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <PermissionComponent permission={DEPARTMENT_MODULE_ACTIONS_UPDATE}>
              <Button
                onClick={() =>
                  history.push(ROUTE_DEPARTMENT_ACTION(a.id, "edit"))
                }
                shape="circle"
                icon={<EditFilled />}
                size={"middle"}
              />
            </PermissionComponent>
          </Row>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDepartments());
  }, []);
  return (
    <div className={"col-md-12 main-container"}>
      <PermissionComponent permission={DEPARTMENT_MODULE_ACTIONS_READ}>
        <DesktopListLayout
          columns={columns}
          dataSource={departments}
          enableAddRoute={ROUTE_DEPARTMENT_CREATE}
          loading={loading}
          title={"Departments"}
        />
      </PermissionComponent>
    </div>
  );
};

export default DepartmentsList;
