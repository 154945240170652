import { Col, Modal, Result, Row } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import { ResultStatusType } from 'antd/lib/result';

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Props = {
    type:ResultStatusType,
    modalVisible:boolean,
    setModalVisible: (status:boolean)=>void,
    message?: string,
    title?: string,
    subtitle?: string
}
function ResultModalLayout({type = 'success', modalVisible, message = 'Loading...', setModalVisible, title='', subtitle=''}: Props){
    return (
        <Modal
            centered
            visible={modalVisible}
            onCancel={()=>setModalVisible(false)}
            footer={false} style={{paddingBottom:'0px'}}
        >
            <Row>
                <Col className="text-center" span={24}>
                <Result
                className={"doctor-success-message"}
                    status={type}
                    title={title}
                    subTitle={subtitle}
                />
                </Col>
            </Row>
        </Modal>
    )
}
export default ResultModalLayout;