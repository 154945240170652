import {
  Button, Form,
  Input,
  Skeleton
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchRegions } from "../../../../redux/reducers/region.slice";
import { RootState } from "../../../../shared/constants";

type Props = {
  onSubmit: (values: any) => void;
};

const RegionForm: React.FunctionComponent<Props> = ({
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [regionDetail, setRegionDetail] = useState({} as any);
  const { loading } = useSelector( (state: RootState) => state.region );
  const RouteParams: any = useParams();

  useEffect(() => {
    dispatch(fetchRegions()).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        let rgArr = val?.payload?.data.data;
        if(rgArr.length > 0){
          rgArr.forEach( (e:any) => {
            if(e.id === RouteParams?.id){
              setRegionDetail(e);
              form.setFieldsValue({
                name: e.name,
                code: e.code,
                latitude: e.latitude,
                longitude: e.longitude,
                image_url: e.image_url
              });
            }
          }); 
        }
        
      }
    });
  }, []);

  if (loading) {
    return <Skeleton />;
  }
  
  return (
    <Form
      name="basic"
      form={form}
      layout={"horizontal"}
      labelAlign={"left"}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 8 }}
      className="mt20"
      initialValues={regionDetail}
      onFinish={(values: any) => {
        onSubmit(values);
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input Name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: "Please input Code" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Latitude" name="latitude">
        <Input />
      </Form.Item>
      <Form.Item label="Longitude" name="longitude">
        <Input />
      </Form.Item>
      <Form.Item label="Image URL" name="image_url">
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button loading={loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegionForm;
