import { DeleteFilled } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Select, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  addNewTeamUser,
  fetchTeamById,
  removeTeamUser,
  updateTeamById,
} from "../../../../redux/reducers/team.slice";
import { fetchUsers } from "../../../../redux/reducers/user.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Team } from "../../../../shared/types/team.type";
import { User } from "../../../../shared/types/user.type";
import { getFullName } from "../../../../shared/Utils/utilities";
import TeamForm from "./team-form.page";

const TeamEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const team: Team = useSelector((state: RootState) => state.team.team);
  useEffect(() => {
    if (RouteParams["id"]) {
      dispatch(fetchTeamById(RouteParams["id"]));
    }
  }, []);
  return (
    <DesktopFormLayout title={"Team Edit"} subtitle={"Edit new user for .."}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Basic Details" key="1">
          <Row>
            <Col span={12}>
              <TeamForm
                team={team}
                onSubmit={async (values: Team) => {
                  try {
                    await dispatch(
                      updateTeamById({ id: team.id, data: values })
                    );
                    history.goBack();
                  } catch (e) {}
                }}
              />
            </Col>
          </Row>
        </Tabs.TabPane>
        {/*<Tabs.TabPane tab="Cities" key="2">*/}
        {/*    <Row>*/}
        {/*        <Col span={12}>*/}

        {/*            <TeamForm team={team} onSubmit={async (values: Team) => {*/}
        {/*                try {*/}
        {/*                    await dispatch(updateTeamById({id: team.id, data: values}));*/}
        {/*                    history.goBack();*/}
        {/*                } catch (e) {*/}

        {/*                }*/}
        {/*            }}/>*/}

        {/*        </Col>*/}

        {/*    </Row>*/}
        {/*</Tabs.TabPane>*/}
        {/*<Tabs.TabPane tab="Locations/Branches" key="3">*/}
        {/*    <Row>*/}
        {/*        <Col span={12}>*/}

        {/*            <TeamForm team={team} onSubmit={async (values: Team) => {*/}
        {/*                try {*/}
        {/*                    await dispatch(updateTeamById({id: team.id, data: values}));*/}
        {/*                    history.goBack();*/}
        {/*                } catch (e) {*/}

        {/*                }*/}
        {/*            }}/>*/}

        {/*        </Col>*/}

        {/*    </Row>*/}
        {/*</Tabs.TabPane>*/}
        <Tabs.TabPane tab="Users" key="4">
          {team && <TeamUsers team={team} />}
        </Tabs.TabPane>
        {/*<Tabs.TabPane tab="Specialities" key="5">*/}
        {/*    <Row>*/}
        {/*        <Col span={12}>*/}

        {/*            <TeamForm team={team} onSubmit={async (values: Team) => {*/}
        {/*                try {*/}
        {/*                    await dispatch(updateTeamById({id: team.id, data: values}));*/}
        {/*                    history.goBack();*/}
        {/*                } catch (e) {*/}

        {/*                }*/}
        {/*            }}/>*/}

        {/*        </Col>*/}

        {/*    </Row>*/}
        {/*</Tabs.TabPane>*/}
      </Tabs>
    </DesktopFormLayout>
  );
};

const TeamUsers = ({ team }: any) => {
  const { users: teamUsers } = team;
  const dispatch = useDispatch();
  const { users } = useSelector((state: RootState) => state.user);
  const [selectedUser, setSelectedUser] = useState("");
  useEffect(() => {
    dispatch(fetchUsers({ limit: 1000 }));
  }, []);
  const addSelectedTeamUser = async () => {
    await dispatch(addNewTeamUser({ team_id: team.id, user_id: selectedUser }));
    dispatch(fetchTeamById(team["id"]));
  };
  useEffect(() => {
    if (selectedUser) {
      addSelectedTeamUser();
    }
  }, [selectedUser]);
  return (
    <Card>
      <Select
        value={selectedUser}
        onChange={setSelectedUser}
        style={{ minWidth: "250px" }}
      >
        <Select.Option value={""} disabled={true}>
          Select User
        </Select.Option>
        {users.map((user: User) => {
          return (
            <Select.Option value={user.id}>
              <div>{getFullName(user)}</div>
              <div>{user?.phone}</div>
            </Select.Option>
          );
        })}
      </Select>
      <Divider />
      {teamUsers.map((teamUser: any) => {
        return (
          <Row>
            <Col>
              <Tag>
                {getFullName(teamUser.user)} / {teamUser.user?.phone}
              </Tag>
            </Col>
            <Col>
              <Button
                onClick={async () => {
                  await dispatch(
                    removeTeamUser({
                      team_id: team.id,
                      user_id: teamUser.user_id,
                    })
                  );
                  dispatch(fetchTeamById(team["id"]));
                }}
                shape="circle"
                icon={<DeleteFilled />}
                size={"small"}
              />
            </Col>
          </Row>
        );
      })}
    </Card>
  );
};

export default TeamEdit;
