import React, { useState } from 'react';
import { Col, Layout, Row } from "antd";
import Sidebar from "./sidebar";
import AdminHeader from './header-admin.layout';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import { useDispatch } from "react-redux";
import {setSearchValue} from "../../redux/reducers/master.slice"

const { Header, Sider, Content } = Layout;

function OrderDesktopLayout({ children }: any) {
    const [collapsed, setCollapsed] = useState(true)
    const dispatch = useDispatch();
    const searchValue = (values: any) => {
        dispatch(setSearchValue(values));
    }
    return (
        <Col span={24}>
            <Row>
                <Layout>
                    <Sider trigger={null}  collapsible collapsed={collapsed} className={"doctor-app-sidebar noborder"}>
                        <Row>
                            <Col span={24}>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: () => setCollapsed(!collapsed),
                                })}
                            </Col>
                        </Row>
                        <div className="mt20">
                            <Sidebar type={"order"}></Sidebar>
                        </div>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-header-background" style={{padding: 15}}>
                        <Row>

                            <Col span={24}>
                                <AdminHeader onSearch={(values: any) => searchValue(values)}/>
                            </Col>

                        </Row>
                    </Header>
                        <Content
                            className="doctor-app nopadding"
                            style={{
                                minHeight: "100vh",
                            }}>
                            <Col span={24}>
                                {
                                    children
                                }
                            </Col>
                        </Content>
                    </Layout>
                </Layout>
            </Row>
        </Col>

    );
}


export default OrderDesktopLayout;
