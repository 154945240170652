import { Button, Form, Input, message, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { forgetPasswordWithOTP } from "../../../redux/reducers/auth.slice";
import { RootState } from "../../../shared/constants";
import { LoginWithOTP } from "../../../shared/types/login.types";
import { countryCodes } from "../../../shared/Utils/dial-codes";
import { MobileNumberRegex } from "../../../shared/Utils/regex";

interface Props {
  phone: string;
  kind: string;
  onUsernameChange: (string: string) => void;
}

export const ForgetPasswordForm = ({
  kind,
  phone,
  onUsernameChange,
}: Props) => {
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const { otpSessionId, loggingIn } = useSelector(
    (state: RootState) => state.auth
  );
  const RouteParams: any = useParams();
  const [countryCode, setCountryCode] = useState("+91" as string);

  const [applicationId, setApplicationId] = useState("");
  const [form] = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedApplication = applications?.find(
      (a: any) => a.slug === RouteParams?.source
    );
    setApplicationId(selectedApplication?.id);
  }, [applications, account]);
  const prefixSelector = (
    <Form.Item name="country_code" noStyle initialValue={countryCode}>
      <Select
        showSearch
        style={{ width: 100 }}
        value={countryCode}
        onChange={(evt) => setCountryCode(evt)}
        className={"noborder"}
      >
        {countryCodes.map((item) => {
          return (
            <Select.Option value={item.dial_code}>
              <Space>
                <span>{item.flag}</span>
                <span>{item.dial_code}</span>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  return (
    <div>
      <div>
        <Title className="mt100  bold" level={3}>
          Forgot Pin
        </Title>
      </div>
      <Form
        name="number-login"
        className="login-form"
        form={form}
        initialValues={{ phone }}
        onFinish={async (values: LoginWithOTP) => {
          const response = await dispatch(
            forgetPasswordWithOTP({
              ...values,
              account_id: account.id,
              kind: kind,
            })
          );
          if (!response.payload?.data?.data?.session_id) {
            message.error(
              response.payload?.data?.data?.message || "Something went wrong"
            );
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: "Please input your phone!" },
            { pattern: MobileNumberRegex, message: "Please valid phone!" },
          ]}
        >
          <Input
            size={"large"}
            onChange={(e) => onUsernameChange(e.target.value)}
            addonBefore={prefixSelector}
            className="noborderWrapper doctor-app-box-shadow height60px"
          />
        </Form.Item>
        <Button
          size="large"
          className="doctor-secondary-button doctor-padding-15"
          loading={loggingIn}
          htmlType={"submit"}
        >
          {" "}
          Get OTP
        </Button>
      </Form>
    </div>
  );
};
