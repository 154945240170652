import {
  Button,
  Checkbox,
  Col,
  message,
  Radio,
  Row,
  Skeleton,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPermissions,
  updateAllPermissions,
} from "../../../redux/reducers/settings.slice";
import { RootState } from "../../../shared/constants";
var _ = require("lodash");

function DoctorPrescriptionSetting() {
  const dispatch = useDispatch();
  const { loading, permissions, settingId } = useSelector(
    (state: RootState) => state.settings
  );
  const { profile } = useSelector((state: RootState) => state.profile);
  const [allSetting, setSettings] = useState([] as any);

  useEffect(() => {
    dispatch(
      fetchAllPermissions({ type: "user_support", user_id: profile.id })
    );
  }, []);

  useEffect(() => {
    setSettings(permissions);
  }, [permissions]);

  function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const getchangesSettings = (value: any, key: any) => {
    let data = _.cloneDeep(allSetting);
    data[key] = value;
    setSettings(data);
  };

  const submitChanges = () => {
    (async function () {
      try {
        let response = await dispatch(
          updateAllPermissions({
            id: settingId,
            data: {
              settings: allSetting,
            },
          })
        );
        if (response.payload.status === 200) {
          message.success("Updated Successfully");
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <div className="fullHeightDiv">
      <Row>
        <Col span={24} className={"white-background padding20"}>
          <h1 className={"doctor-primary-color bold"}>
            Video Consultation Support Services
          </h1>
        </Col>

        <Col span={24} className={"padding20"}>
          <Row>
            <Col
              span={24}
              className={
                "white-background padding20  doctor-box-shadow border-radius5"
              }
              style={{ paddingBottom: "20px" }}
            >
              {loading ? (
                <Skeleton></Skeleton>
              ) : (
                <Row gutter={40}>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <label className="doctor-primary-color bold">
                          1. Who will Prepare Case summary for patients?
                        </label>{" "}
                        <br />
                        <Space direction="vertical" className="mt20">
                          <Radio.Group
                            value={allSetting?.case_summary_by}
                            onChange={(evt: any) =>
                              getchangesSettings(
                                evt.target.value,
                                "case_summary_by"
                              )
                            }
                          >
                            <Radio
                              value={"My Team"}
                              className="doctor-primary-color"
                            >
                              My Team
                            </Radio>{" "}
                            <br />
                            <br />
                            <Radio
                              value={"VC Back Office Team"}
                              className="doctor-primary-color mt10"
                            >
                              VC Back Office Team
                            </Radio>
                            <br />
                            <br />
                            <Radio
                              value={"None"}
                              className="doctor-primary-color mt10"
                            >
                              None
                            </Radio>
                          </Radio.Group>
                        </Space>
                      </Col>
                      {/* <Divider></Divider> */}

                      <Col span={24} className="mt20">
                        <label className="doctor-primary-color bold">
                          2. Do you want to avail following Support Services?
                        </label>{" "}
                        <br />
                        <Space direction="vertical" className="mt20">
                          <Checkbox
                            value={1}
                            className="doctor-primary-color"
                            onChange={(evt: any) =>
                              getchangesSettings(
                                evt.target.checked,
                                "allow_document_qc"
                              )
                            }
                            checked={allSetting?.allow_document_qc}
                          >
                            {" "}
                            Document quality check <br />
                            <span className={"sub-text"}>
                              {" "}
                              All the health documents uploaded by patients are
                              checked and arranged in order (if more than 4
                              hours are left for consultation)
                            </span>
                          </Checkbox>
                          {/* <Checkbox value={2} className="doctor-primary-color" onChange={(evt:any)=>getchangesSettings(evt.target.checked,getKeyByValue(allSetting,allSetting?.allow_case_summary))} checked={allSetting?.allow_case_summary}>Case Summary Preparation</Checkbox> */}
                          <Checkbox
                            value={3}
                            className="doctor-primary-color"
                            onChange={(evt: any) =>
                              getchangesSettings(
                                evt.target.checked,
                                "patient_manual_reminder_call"
                              )
                            }
                            checked={allSetting?.patient_manual_reminder_call}
                          >
                            Manual Reminder calling <br />
                            <span className={"sub-text"}>
                              {" "}
                              If enabled, instead of an automated reminder call
                              to patient, a manual call is made by the support
                              team, 5 minutes before the video consultation
                            </span>
                          </Checkbox>
                          {/* <Checkbox value={4} className="doctor-primary-color" onChange={(evt:any)=>getchangesSettings(evt.target.checked,getKeyByValue(allSetting,allSetting?.patient_mobile_issues_handle))} checked={allSetting?.patient_mobile_issues_handle}>Missed Call Handling</Checkbox> */}
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24} className={"padding20 bottomFixedButton"}>
          <Button
            className={"doctor-secondary-button"}
            block
            size={"large"}
            onClick={() => submitChanges()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default DoctorPrescriptionSetting;
