import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  message,
  Radio,
  Row,
  Skeleton,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import {
  disabledPrevoiusDates,
  getDayFormat,
} from "../../../../shared/Utils/utilities";
var _ = require("lodash");
const { Panel } = Collapse;

type Props = {
  mode?: string;
  onSubmit?: (slot: any, procedure: any, branch: any, current: any) => void;
  slots?: any;
  slots_loader?: any;
  onDateChange?: (date: any) => void;
};

const DoctorSlots: React.FunctionComponent<Props> = ({
  mode,
  slots,
  slots_loader,
  onSubmit,
  onDateChange,
}) => {
  const [current, setCurrent] = useState("");
  const [_slot, setSlot] = useState("" as any);
  const [procedure, setProcedure] = useState("" as any);
  const [branch, setBranch] = useState("" as any);
  const [slotloader, setSlotloader] = useState(true);
  // const genExtra = () => (
  //     <Tag>Cash</Tag>
  // )

  const setSlotProcedure = (evt: any, slot: any, branch: any) => {
    setSlot(JSON.parse(evt.target.value));
    setProcedure(slot);
    setBranch(branch);
    if (mode === "view" && onSubmit !== undefined) {
      onSubmit(JSON.parse(evt.target.value), slot, branch, current);
    }
  };

  const changeSlotProcedure = (slot: any) => {
    setSlot(null);
    setProcedure("");
    setBranch("");
    setCurrent(slot?.appointment_date);

    if (mode === "view" && onSubmit !== undefined) {
      onSubmit && onSubmit("", "", "", slot?.appointment_date);
    }
  };

  const getAppointmentFinalView = () => {
    if (_slot === "") {
      message.error("Choose Slot");
      return;
    }
    let data: any = {
      source: "web",
      procedure_id: procedure?.procedure_id,
      slot_id: _slot?.slot_id,
      slot_time: _slot?.s_time,
      current: current,
      procedure_type: procedure?.procedure_name,
      branch_id: branch?.branch_id === undefined ? null : branch?.branch_id,
      fee: branch?.fee,
      date: current,
      external_procedure_id: procedure?.external_procedure_id,
    };
    sessionStorage.setItem("appointment_details", JSON.stringify(data));
    if (onSubmit) onSubmit(_slot, procedure, branch, current);
  };

  useEffect(() => {
    if (slots?.availableSlots) {
      if (slots?.availableSlots.length === 0) {
        setCurrent("");
      } else {
        setCurrent(slots?.availableSlots[0].appointment_date);
      }

      setSlotloader(false);
    }
  }, [slots]);

  const firstAndLast = (slots: any) => {
    return (
      slots &&
      slots.length !== 0 &&
      slots[0] &&
      [slots[0]?.s_time] + " to " + slots[slots.length - 1].s_time
    );
  };
  if (slots_loader) {
    return <Skeleton active></Skeleton>;
  }

  if (slots?.availableSlots?.length === 0) {
    return (
      <Row>
        <Col
          span={24}
          className=" border-radius border-radius-bottom border text-center"
        >
          <img
            src={
              require("../../../../assets/icons/no result found.png").default
            }
            className="mt100"
          ></img>
          <p className="sub-text mt10 text-center">No Slots Available </p>
          <p className="sub-text text-center">
            {/* @ts-ignore */}
            <DatePicker
              onChange={(evt) => onDateChange && onDateChange(evt)}
              allowClear={false}
              disabledDate={disabledPrevoiusDates}
            >
              Custom Date
            </DatePicker>
          </p>
        </Col>
      </Row>
    );
  } else {
    return (
      <>
        {/* <Col span={24} className="white-background bottom-box-shadow"> */}
        <div className="select-slot-wrapper">
          <Row>
            <Col span={24}>
              <Row wrap className="bg-app date-slot-bar">
                {slots?.availableSlots.map((slot: any, index: any) => {
                  return (
                    <Fragment>
                      <Col
                        span={5}
                        className={
                          slot.appointment_date === current
                            ? "secondary-color-background-tab text-center slot-date-card"
                            : "text-center slot-date-card"
                        }
                      >
                        <div
                          className="cursor"
                          onClick={() => changeSlotProcedure(slot)}
                        >
                          <p
                            className={
                              slot.appointment_date === current
                                ? "mt10"
                                : "doctor-primary-color mt10"
                            }
                          >
                            {getDayFormat(slot.appointment_date, true)}{" "}
                            <br></br>
                            <span
                              className={
                                slot.appointment_date === current
                                  ? ""
                                  : "doctor-primary-color"
                              }
                            >
                              {slot.appointment_count} Slots
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col className="mt20">
                        <Divider
                          type="vertical"
                          style={{ margin: 0 }}
                        ></Divider>
                      </Col>
                    </Fragment>
                  );
                })}

                <Col span={8} className="text-center slot-date-card">
                  <div>
                    <DatePicker
                      className={"doctor-secondary-border border-radius5 mt15"}
                      onChange={(evt) => {
                        onDateChange && onDateChange(evt);
                        setSlot(null);
                      }}
                      disabledDate={disabledPrevoiusDates}
                      allowClear={false}
                    >
                      Custom Date
                    </DatePicker>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} className={"padding20"}>
              {slotloader ? (
                <Skeleton active></Skeleton>
              ) : (
                <Collapse
                  accordion
                  defaultActiveKey={["1"]}
                  className="slot-time-picker noborder border-radius mt20"
                  expandIconPosition="right"
                >
                  {current !== undefined &&
                    _.filter(slots?.availableSlots, {
                      appointment_date: current,
                    }).map((slot: any) => {
                      return slot.procedures.map((procedure: any) => {
                        return procedure.branches.map(
                          (branch: any, index: any) => {
                            return (
                              <Panel
                                className="white-background"
                                header={
                                  <Row>
                                    <Col span={20}>
                                      <p className="doctor-primary-color-text mb-0 bold  capitalize">
                                        {" "}
                                        {firstAndLast(branch.slots)} |{" "}
                                        {branch.slots.length} Slots
                                      </p>
                                    </Col>
                                  </Row>
                                }
                                key={index + 1}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Radio.Group
                                      buttonStyle="solid"
                                      value={JSON.stringify(_slot)}
                                      onChange={(evt: any) =>
                                        setSlotProcedure(evt, procedure, branch)
                                      }
                                    >
                                      {/* <Space size="middle"> */}
                                      {branch.slots.map((slot: any) => {
                                        return (
                                          <Radio.Button
                                            style={{
                                              marginRight: "15px",
                                              minWidth: "100px",
                                              textAlign: "center",
                                              marginBottom: "10px",
                                              height: "50px",
                                              paddingTop: "10px",
                                            }}
                                            value={JSON.stringify(slot)}
                                            className="secondary-radio border-radius bold text-primary"
                                          >
                                            {slot.s_time}
                                          </Radio.Button>
                                        );
                                      })}
                                      {/* </Space> */}
                                    </Radio.Group>
                                  </Col>
                                </Row>
                              </Panel>
                            );
                          }
                        );
                      });
                    })}
                </Collapse>
              )}
            </Col>
          </Row>
        </div>

        {mode !== "view" && (
          <Button
            block
            className="mt20"
            size="large"
            type="primary"
            onClick={() => getAppointmentFinalView()}
          >
            Next{" "}
          </Button>
        )}
      </>
    );
  }
};

export default DoctorSlots;
