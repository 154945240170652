import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMasterCities,
  fetchMasterGender,
  fetchMasterNationality,
} from "../../../../redux/reducers/master.slice";
import { RootState } from "../../../../shared/constants";
import { Master } from "../../../../shared/types/master.type";
import { Patient } from "../../../../shared/types/patient.type";
import { disabledFutureDates } from "../../../../shared/Utils/utilities";

const { Option } = Select;

type Props = {
  onSubmit: (values: Patient) => void;
  patient?: Patient;
  mode?: string;
  onCancel?: (values: any) => void;
  showCancel?: any;
};
const PatientForm: React.FunctionComponent<Props> = ({
  patient,
  onSubmit,
  mode,
  onCancel,
  showCancel,
}) => {
  const { gender, cities, nationality, loading } = useSelector(
    (state: RootState) => state.master
  );
  const [citiesData, setCitiesData] = useState([] as any);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMasterGender());
    dispatch(fetchMasterNationality());
    dispatch(fetchMasterCities());
  }, []);

  useEffect(() => {
    let _citiesData = [] as any;
    if (cities.length !== 0) {
      cities.map((item: any) => {
        return _citiesData.push({
          value: item?.state_name,
          label: item?.state_name,
          children:
            item?.data.length === 0
              ? []
              : item?.data.map((_item: any) => {
                  return {
                    value: _item?.id,
                    label: _item?.city,
                  };
                }),
        });
      });
    }
    setCitiesData(_citiesData);
  }, [cities]);
  return (
    <Col span={24} className="doctor-light-background paddingLR20">
      <Form
        name="basic"
        onChange={(evt: any) => console.log(evt)}
        initialValues={{
          mobile: patient?.mobile,
          nationality_id:
            nationality &&
            nationality.find((a: any) => a.name === "indian")?.id,
        }}
        layout={"vertical"}
        onFinish={(values: Patient) => {
          values.dob = moment(values.dob).format("YYYY-MM-DD");
          onSubmit(values);
        }}
        onFinishFailed={() => console.log("")}
        autoComplete="off"
        className="mt20 "
      >
        <Row gutter={20}>
          <Col span={mode === "view" ? 6 : 0}>
            <label>User Profile Score</label>
            <Row gutter={20}>
              <Col span={18}>
                <Tag
                  color="#E0E9F4"
                  className="padding20 primary-color-text bold"
                >
                  20% Conversion Probability
                </Tag>
              </Col>
              <Col span={6}>
                <Tag color="#DCF2F1" className="padding20 sub-color-text bold">
                  NPS
                </Tag>
              </Col>
            </Row>
          </Col>
          <Col span={mode === "view" ? 3 : 8}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: "Please input First Name" }]}
            >
              <Input
                disabled={mode === "view" ? true : false}
                size={"large"}
                placeholder={"Enter First Name"}
              />
            </Form.Item>
            {/* {
                                mode === "edit" && <p> <Space size="small"> <Text className="sub-color-text bold cursor" underline>+ Add VIP Tag</Text>  <Text className="sub-color-text bold cursor" underline>+ Add HWC Tag</Text></Space> </p>
                            }  */}
          </Col>
          <Col span={mode === "view" ? 3 : 8}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: "Please input Last Name" }]}
            >
              <Input
                disabled={mode === "view" ? true : false}
                size={"large"}
                placeholder={"Enter Last Name"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Mobile Number"
              name="mobile"
              rules={[
                { required: true, message: "Please input Mobile Number" },
              ]}
            >
              <Input
                type={"tel"}
                disabled={true}
                size={"large"}
                placeholder={"Enter Patient Contact Number"}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Email Id"
              name="email"
              rules={[{ required: true, message: "Enter Email-ID" }]}
            >
              <Input
                disabled={mode === "view" ? true : false}
                size={"large"}
                placeholder={"Enter Email-ID"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="D.O.B"
              name="dob"
              rules={[{ required: true, message: "Enter date of birth" }]}
            >
              <DatePicker
                disabledDate={disabledFutureDates}
                className={"full-width"}
                size={"large"}
                placeholder={"Enter date of birth"}
              ></DatePicker>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Gender"
              name="gender_id"
              rules={[{ required: true, message: "Choose Gender" }]}
            >
              <Select
                disabled={mode === "view" ? true : false}
                size={"large"}
                placeholder={"Choose Gender"}
              >
                {gender.map((gender: Master) => {
                  return (
                    <Option value={gender.id} className={"capitalize"}>
                      {gender.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          {/* <Col span={8}>
                        <Form.Item
                            label="Patient Type"
                            name="patient_type"
                            rules={[{ required: false, message: 'Please input Name' }]}
                        >
                            <Input disabled={mode === "view" ? true : false} />
                        </Form.Item>
                    </Col> */}

          <Col span={24}>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  label="Choose Nationality"
                  name={"nationality_id"}
                  required
                  rules={[{ required: true, message: "Choose Nationality" }]}
                >
                  <Select
                    disabled={mode === "view" ? true : false}
                    size={"large"}
                    className={"capitalize"}
                    placeholder={"Choose Nationality"}
                  >
                    {nationality
                      .filter((a: any) => a.name === "indian")
                      .map((nationality: Master) => {
                        return (
                          <Option
                            value={nationality.id}
                            className={"capitalize"}
                          >
                            {nationality.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Choose State/City Name"
                  name={["addresses", "city_id"]}
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Choose City name",
                      type: "array",
                    },
                  ]}
                >
                  {loading ? (
                    <Skeleton.Input></Skeleton.Input>
                  ) : (
                    <Cascader
                      className={"capitalize"}
                      size={"large"}
                      options={citiesData && citiesData}
                      // changeOnSelect
                      placeholder="Please select"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Pin Code"
                  name={["addresses", "pincode"]}
                  rules={[{ required: true, message: "Enter Pincode" }]}
                >
                  <Input size={"large"} placeholder="Enter Pincode"></Input>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Address"
              name={["addresses", "address_line_1"]}
              required
              rules={[{ required: true, message: "Please input Address" }]}
            >
              <Input
                size={"large"}
                placeholder="Please Enter Complete Address"
              ></Input>
            </Form.Item>
          </Col>
        </Row>

        {mode !== "view" && (
          <Form.Item>
            <Button
              className="doctor-border-button btn-shadow"
              size={"large"}
              block
              htmlType="submit"
            >
              Submit
            </Button>
            {showCancel && (
              <Button type="link" onClick={() => onCancel && onCancel(false)}>
                Cancel
              </Button>
            )}
          </Form.Item>
        )}
      </Form>
    </Col>
  );
};

export default PatientForm;
