import React, { useEffect } from 'react';
import { Button, Row, Space, Select, Input } from 'antd';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../shared/constants";
import { fetchPharmacyOrderRequest } from '../../../../../redux/reducers/pharmacy.slice';
import moment from 'moment';
import { ROUTE_PHARMACY_CREATE_QC } from "../../../../../shared/routes/order.routes.constants";
import TableLayout from '../../../../../shared/layouts/table.layout';
const { Search } = Input
const { Option } = Select
type Props = {}


const OpenOrderQC: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const { pharmacyOrder, loading, pagination } = useSelector((state: RootState) => state.pharmacy);

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchPharmacyOrderRequest({offset: pagination.offset, limit: pagination.limit}));
    }, [])

    const columns = [
        {
            title: 'Patient Name',
            render : (a: any) =>{ return ( <><span>{a.patient ? a?.patient?.pii?.first_name + ' ' + a?.patient?.pii?.last_name : ''} (UHID: 98989)</span><br />{a?.patient?.pii?.mobile}</>) }
        },
        {
            title: 'Hospital/Doctor',
            render : (a: any) =>{ return ( <><span>Max Saket</span><br />Dr. Neeraj</>) }
        },
        {
            title: "Appt. Type",
            render : (a: any) =>{ return ( <><span>{a?.appointment?.appointment_type}</span></>) }
        },
        {
            title: "Order Placement Time",
            render : (a: any) =>{ return ( <><span>{moment(a.updated_at).format("DD MMM'YY,hh:mm A")}</span></>) }
        },
        {
            title: "Source",
            render : (a: any) =>{ return ( <><span>{a.source}</span></>) }
        },
        // {
        //     title: "No. of Meds",
        //     render : (a: any) =>{ return ( <><span>0 Meds</span><br />(Rs. {a.total_amount ? a.total_amount : '-'})</>) }
        // },
        {
            fixed: 'right' as 'right',
            width: '150px',
            title: 'Action',
            key: 'action',
            render: (a: any) => {
                return <Row gutter={10}>
                    {(a.status === 'pending' || a.status === 'open') ? 
                    <Space>
                        <Button onClick={() => history.push(ROUTE_PHARMACY_CREATE_QC(a.id))} type="primary" size={'middle'} >Start QC</Button>
                    </Space> : 
                    (a.status === 'qc_done') ? 
                    <Space>
                        <Button onClick={() => history.push(ROUTE_PHARMACY_CREATE_QC(a.id))} type="primary" size={'middle'} >QC Done</Button>
                    </Space>
                    : <span>{ a.status}</span>
                    }
                </Row>;
            },
        },
    ];

    let pageNumber = 0
    const onPageChange = (e:any) => {
        pageNumber = e-1;
        dispatch(fetchPharmacyOrderRequest({offset: pageNumber*10, limit: pagination.limit}))
    }

    return (
      <div className='mt15'>
        <p>
          <Space>
            <Select placeholder="Search By" style={{ width: 150 }}>
                <Option value="patient_id">Patient ID</Option>
                <Option value="request_id">Request ID</Option>
                <Option value="appointment_id">Appointment ID</Option>
            </Select>
            <Search style={{ width: 400 }} placeholder="Search by Patient ID, Request ID or Appointment ID"></Search>
            <Select placeholder="Source" style={{ width: 150 }}>
                <Option value="Appointment">Appointment</Option>
                <Option value="Max_EP">Max_EP</Option>
                <Option value="Ecommerce">Ecommerce</Option>
            </Select>
          </Space>
        </p>
        {/* <Table
            loading={loading} 
            dataSource={pharmacyOrder} 
            columns={columns} 
            onChange={(e)=>onPageChange(e)} 
            pagination = {{pageSizeOptions: ['40'], showSizeChanger: true}}
        /> */}
         <TableLayout 
            loading={loading} 
            dataSource={pharmacyOrder} 
            columns={columns} 
            onChange={(e)=>onPageChange(e)} 
            type='emptyFilter' 
            total={pagination.total} 
            pagination={{ position: ['none', 'none'] }} 
        />
      </div>
    );
};

export default OpenOrderQC;

