import { Col, Divider, Row } from "antd";
import React from "react";
import UserNameComponent from "../../../../shared/components/user.name.components";
import { getAge } from "../../../../shared/Utils/utilities";

type Props = {
  profile?: any;
};

const ProfileDetails: React.FunctionComponent<Props> = ({ profile }) => {
  return (
    <Row>
      <Col span={24} className={"padding20"}>
        <Row className="mt10" gutter={20}>
          <Col span={24}>
            <h1 className={"doctor-primary-color bold capitalize"}>
              <UserNameComponent />
            </h1>
            <h4 className={"capitalize "}>{profile?.qualification}</h4>
            <h4 style={{ fontWeight: 400 }} className={"capitalize"}>
              {profile?.designation && <>{profile?.designation} |</>}{" "}
              {profile.practicing_since && (
                <> {getAge(profile.practicing_since)}+ Experience</>
              )}
            </h4>
          </Col>
          <Divider></Divider>
        </Row>
        <Row className="mt20 text-center" gutter={20}>
          <Col span={8}>
            <label className=" max-subHead-color">Speciality</label>
            <h1
              style={{ fontWeight: 400 }}
              className={"doctor-primary-color  capitalize"}
            >
              {profile?.specialities}
            </h1>
          </Col>
          <Col span={8}>
            <label className="max-subHead-color">Mobile No. (Official)</label>
            <h1 style={{ fontWeight: 400 }} className={"doctor-primary-color "}>
              +91 {profile?.mobile}
            </h1>
          </Col>
          <Col span={8}>
            <label className="max-subHead-color">Email</label>
            <h1
              style={{ fontWeight: 400 }}
              className={"doctor-primary-color  capitalize"}
            >
              {profile?.email}
            </h1>
          </Col>
          {/* <Divider></Divider> */}
        </Row>
      </Col>
    </Row>
  );
};

export default ProfileDetails;
