import { Card, Col, Typography } from "antd";
import PaymentTable from "./components/paymentSummary.table";
const { Title } = Typography;
type Props = {};

function PaymentSummary(props: Props) {
  return (
    <div className={"col-md-12 main-container padding15"}>
      <Title className="paddingcollapse" level={4}>
        Payment Summary
      </Title>
      <Col span={24}>
        <Card className="nopaddingcard crm-tabs">
          <PaymentTable />
        </Card>
      </Col>
    </div>
  );
}

export default PaymentSummary;
