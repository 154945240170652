import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Form,
    Input,
    Col,
    Row,
    Button, Space, Skeleton, Select, Checkbox
} from 'antd';
import { useEffect, useState } from 'react';
//import useLocations from 'shared/hooks/useLocations';
import TextArea from 'antd/es/input/TextArea';
import { fetchAllTemplateGroups } from '../../../../../redux/reducers/prescription.slice';
import { RootState } from '../../../../../shared/constants';
import { useDispatch,useSelector } from 'react-redux';
//import useGroups from 'shared/hooks/Groups/useGroups';
//import useDebounce from 'shared/hooks/useDebounce';

type Props = {
    questionnaire?: any
    onSubmit: any
    saving?: boolean
    onCancel?: any
    refresh?: any
}

const TemplateForm: React.FunctionComponent<Props> = ({ onSubmit, saving, questionnaire, onCancel, refresh }) => {
    const { t } = useTranslation();
    const history = useHistory();
    //const location = useLocations();
    const RouteParams: { id: string, mode: 'view' | 'edit' } = useParams();
    const [fields]: any[] = useState([]);
    const [search, setSearch] = useState('' as string);
    const debouncedSearch: never[] = []//useDebounce(search, 500);
    //const groups: any[]=[];
    //const {prescriptionTemplateGroup, loading} = useSelector((state: RootState) => state.prescription);
    const prescriptionTemplateGroup:any=[{name:'Doctor',id:"doctor"},{name:'Pharmacist',id:"pharmacist"}];
   
    //const { groups:[], loading: false, refetch: false } = []//useGroups();
    useEffect(() => {
        const p: any = { search };
        //refetchGroups(p);
    }, [debouncedSearch, questionnaire]);
    return (
        <div className={'user-group-create-or-edit-page form-wrapper'}>
            <Form
                size='large'
                fields={fields}
                onFinish={async (formValues) => {
                    if (questionnaire) {
                        if (questionnaire.group_id) {
                            delete formValues['group_id'];
                        }
                        // formValues['id']=questionnaire.id
                        formValues['template_data_id'] = questionnaire['template_data_id'];
                    }
                    //console.log("onFinish",formValues);
                    onSubmit({ ...formValues, content: JSON.stringify(JSON.parse(formValues.content)) });
                }}
                initialValues={questionnaire ? {
                    ...questionnaire.content,
                    content: questionnaire && questionnaire['content'] ? questionnaire && questionnaire['content']['data'] : '',


                } : {}}
                requiredMark={true}
                layout='vertical'

            >
                <Row gutter={[20, 20]} align={'bottom'}>
                    <Col md={8} sm={12} xs={24}>
                        <Form.Item
                            label={t('Prescription.Name')}
                            name={'name'}
                            
                            rules={[
                                { required: true, message: t("Prescription.NameRequired") },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                        <Form.Item
                            label={t('Prescription.Code')}
                            name={'code'}
                            rules={[
                                { required: true, message: t("Prescription.CodeRequired") },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                        <Form.Item
                            label={t('Prescription.Group')}
                            name={'type'}
                            rules={[
                                { required: true, message: t('Prescription.GroupRequired') },
                            ]}>
                            <Select
                                // disabled={questionnaire}
                                onSearch={
                                    (v) => {
                                        setSearch(v);
                                    }
                                }
                                showSearch={true}
                                optionFilterProp='children'
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {prescriptionTemplateGroup.map((group: any) => {
                                    return <Select.Option value={group.id}>{group.name}</Select.Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={t('Prescription.Content')}
                            name={'content'}
                            rules={[
                                { required: true, message: t('Prescription.ContentRequired') },
                            ]}>
                            <TextArea rows={5} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Form.Item>
                                <Button type='default'
                                    onClick={onCancel}
                                    disabled={saving}
                                    size={'large'}>
                                    {t('Prescription.Cancel')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary'
                                    htmlType='submit'
                                    loading={saving}
                                    size={'large'}>
                                    {t('Prescription.Submit')}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


export default TemplateForm;


