import React, { Fragment, useEffect } from 'react';
import { Button, Col, Divider, Row, Skeleton } from 'antd';
import GoBacklayout from '../../../shared/layouts/go-back.layout';
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointment } from '../../../redux/reducers/appointment.slice';
import { RootState } from "../../../shared/constants";
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from "moment"
import { useHistory } from "react-router-dom";
import NoDataLayout from '../../../shared/layouts/no-data.layout';
import { ROUTE_PATIENT_APPOINTMENT_DETAILS } from '../../../shared/routes/patient.routes.constants';
import VideoCircleIcon from '../../../shared/icons/VideoCircleIcon';


type Props = {
  type?: string
}

const PatientAppointmentPage: React.FunctionComponent<Props> = ({ type }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.profile);
  const { loading, appointments } = useSelector((state: RootState) => state.appointment);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchAppointment({ patient_id: profile.id, end_date: moment().subtract(1, 'days').format("YYYY-MM-DD"), historical: "1",limit:1000 }))
  }, [])



  const uploadHealthData = (appointment: any) => {
    if (appointment.type === "historical") {
      history.push({
        pathname: ROUTE_PATIENT_APPOINTMENT_DETAILS(appointment.external_booking_no),
        state: {
          type: "historical"
        }
      })
    }
    else {
      history.push(ROUTE_PATIENT_APPOINTMENT_DETAILS(appointment.id))
    }
  }


  return (
   
    <Fragment>
       
      <Row className="padding20 doctor-app">
        <Col span={10} xs={2}>
          <GoBacklayout></GoBacklayout>
        </Col>
        <Col span={4} xs={20} className="text-center">
          <h2 className="doctor-secondary-color mb0" ><b>Past Appointments</b></h2>
          <h2 className="doctor-primary-color capitalize lh18" ><b>for {profile.name}</b></h2>
        </Col>
      </Row>
      <Row justify={"center"} className={"padding20"}>
        {
          loading ?  <Col span={12} md={12} xs={24}><Skeleton active></Skeleton><Skeleton active></Skeleton><Skeleton active></Skeleton></Col> : appointments.filter((appointment:any)=>(appointment?.procedure_info?.name).toLowerCase() !== "consultation").length === 0 ? <NoDataLayout placeholder="No Record Found" className="mt100"></NoDataLayout> :
            <Col span={12} md={12} xs={24} className="doctor-box-shadow doctor-top-border ">
              <Row>
                <Col span={24} className="white-background" style={{ padding: "10px 20px" }}>
                  <Row>
                    <Col span={24}>
                      <p className="doctor-primary-color bold" style={{ marginBottom: "0px",paddingTop:'5px',paddingBottom:'5px',fontSize:'18px',fontWeight:'600' }}>{appointments.filter((appointment:any)=>(appointment?.procedure_info?.name).toLowerCase() !== "consultation").length} Past Appointments</p>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="dark-background padding20">

                  {appointments.map((appointment: any,aIndex:Number) => {
                    return  (appointment?.procedure_info?.name).toLowerCase() !== "consultation" && <Row style={{ marginBottom: "20px" }}>
                      <Col span={20}>
                        <h3 className={"capitalize mb0"}><b>{appointment?.doctor?.name}</b></h3>
                        <p className="doctor-primary-color capitalize">
                          {
                            appointment?.doctor?.specialities.map((item: any) => {
                              return <span>{item?.specialityMaster?.name}</span>
                            })
                          }
                        </p>
                        <p className="doctor-primary-color mt-10"><ClockCircleOutlined style={{ backgroundColor: "white", borderRadius: "100px" }} />  <span>{moment(appointment?.appointment_date).format("dddd, MMM  D")} at {appointment?.start_time} </span></p>
                      </Col>
                      <Col span={4} className="text-right" style={{paddingTop:"10px"}}>
                        {
                          (appointment?.procedure_info?.name).toLowerCase() === "consultation" ? <img alt="" src={require("../../../assets/icons/inperson green.png").default} style={{ width: "20%", marginRight: "5px", marginTop: "-3px" }}></img> : <VideoCircleIcon height='30' width='30' />
                        }
                      </Col>
                      <Col span={24}>
                        <Button className="doctor-border-button border-radius5 noborder fw6" block size="large" onClick={() => uploadHealthData(appointment)}> 
                        
                        {
                              appointment?.cta_name
                        }
                        
                        </Button>
                      </Col>
                      {aIndex!==appointments.length-1&&
                      <Col span={24}>
                        <Divider className={" recentAppointmentDivider"} ></Divider>
                      </Col>}
                    </Row>
                  })
                  }
                </Col>
              </Row>
            </Col>
        }

      </Row>
    </Fragment>
  );
}

export default PatientAppointmentPage;
