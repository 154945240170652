import { EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  notification,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchMessageData,
  reshootMessage,
} from "../../../../redux/reducers/appointment.slice";
import { formatDate } from "../../../../shared/Utils/utilities";
const { Text } = Typography;

const data = [
  {
    sender: "+91-8376929308",
    channel: "SMS",
    status: "Sent",
    text: "Dear Vinayak, appointment has been cancelled based upon your request a...",
    event: "Cancelled",
    sent_on: "30 Sep’21, 10:30 AM",
  },
];

const PatientMessageHistory: React.FunctionComponent<any> = (Props) => {
  const dispatch = useDispatch();
  const [messageData, setmessageData] = useState([] as any);
  const resendMessage = (message: any) => {
    const messageId = message?.id;
    messageId &&
      dispatch(reshootMessage(messageId)).then((val: any) => {
        notification["success"]({
          message: "Message resent successfully",
        });
      });
  };
  const columns = [
    {
      title: "Sent to ",
      render: (a: any) => {
        return (
          <>{a?.channel == "push" ? "Device" : a?.parameters?.recipients}</>
        );
      },
      width: "100px",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      className: "capitalize",
      width: "100px",
    },
    {
      title: "Text",
      dataIndex: ["parameters"],
      ellipsis: {
        showTitle: true,
      },
      width: "500px",
      render: (a: any) => (
        <>
          <Row>
            <Col style={{ maxWidth: "400px", overflow: "hidden" }}>
              {a?.message}
            </Col>
            <Col>...</Col>
            <Col>
              <Tooltip placement="topLeft" title={a?.message}>
                <EyeOutlined />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Sent on",
      // dataIndex: 'notification_datetime',
      render: (a: any) => {
        return <>{formatDate(a?.created_at)}</>;
      },
      width: "150px",
    },
    {
      title: "Actions",
      render: (a: any) => {
        return (
          <>
            {a.enable_reshoot && (
              <Button
                type="primary"
                size="small"
                onClick={() => resendMessage(a)}
              >
                Resend
              </Button>
            )}{" "}
          </>
        );
      },
      width: "100px",
    },
  ];

  useEffect(() => {
    dispatch(
      fetchMessageData({
        id: Props.patient_id,
        appointment_id: Props.appointment_id,
      })
    ).then((val: any) => {
      setmessageData(
        val?.payload?.data?.data?.items ? val?.payload?.data?.data?.items : []
      );
    });
  }, []);

  useEffect(() => {
    if (Props?.isReset && Props?.isReset !== "reset") {
      dispatch(fetchMessageData(Props.patient_id)).then((val: any) => {
        setmessageData(
          val?.payload?.data?.data?.items ? val?.payload?.data?.data?.items : []
        );
      });
    }
  }, [Props.isReset]);

  return (
    <Col span={24}>
      <Table
        scroll={{ x: "60vw", y: 400 }}
        loading={false}
        locale={{
          emptyText: (
            <>
              <Space direction={"vertical"} size={10}>
                <Text>No Records</Text>
              </Space>
            </>
          ),
        }}
        pagination={false}
        dataSource={messageData}
        columns={columns}
      />
    </Col>
  );
};

export default PatientMessageHistory;
