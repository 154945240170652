import { Button, Col, notification, Row } from "antd";
import Password from "antd/es/input/Password";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  fetchSelf,
  setPassword,
} from "../../../redux/reducers/auth.slice";
import { RootState } from "../../../shared/constants";

function DoctorResetPin() {
  const { profile } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  const [changeP, setchangeP] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [setPwd, setsetPwd] = useState({
    password: "",
    confirmPassword: "",
  });

  const onChangePassword = (e: any) => {
    const name = e?.target.name;
    const value = e?.target.value;
    setchangeP({ ...changeP, [name]: value });
  };

  const onSetPassword = (e: any) => {
    const name = e?.target.name;
    const value = e?.target.value;
    setsetPwd({ ...setPwd, [name]: value });
  };

  const setPasswords = () => {
    if (setPwd.password !== setPwd.confirmPassword) {
      notification.error({ message: "Pin and Confirm Pin should match " });
      return;
    }
    if (setPwd.password.length !== 6 || setPwd.confirmPassword.length !== 6) {
      notification.error({ message: "Please enter 6 digits for pin." });
      return;
    }
    try {
      const response = dispatch(
        setPassword({ password: setPwd.password, type: "pin" })
      );
      if (!response?.payload?.data?.error) {
        notification.success({ message: "Password set successfully" });
        dispatch(fetchSelf());
        window.location.reload();
      }
    } catch (e) {
      notification.error({ message: JSON.stringify(e) });
    }
  };

  const changePasswords = async () => {
    if (
      changeP.password === "" ||
      changeP.confirmPassword === "" ||
      changeP.oldPassword === ""
    ) {
      notification.error({ message: "Please fill required fields." });
      return;
    }
    if (
      changeP.password.length !== 6 ||
      changeP.confirmPassword.length !== 6 ||
      changeP.oldPassword.length !== 6
    ) {
      notification.error({ message: "Enter a 6-digit numeric pin" });
      return;
    }

    if (changeP.password !== changeP.confirmPassword) {
      notification.error({ message: "Pin and Confirm Pin should match" });
      return;
    }
    try {
      const response = await dispatch(
        changePassword({
          oldPassword: changeP?.oldPassword,
          password: changeP?.password,
          type: "pin",
        })
      );

      if (
        !response?.payload?.data?.error &&
        response?.payload?.status === 201
      ) {
        notification.success({ message: "Password set successfully" });
        dispatch(fetchSelf());
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        //
      }
    } catch (e) {
      notification.error({ message: JSON.stringify(e) });
    }
  };

  return (
    <>
      <div className="fullHeightDiv">
        <Row>
          <Col span={24} className={"white-background padding20"}>
            <h1 className={"doctor-primary-color bold"}>
              {" "}
              {profile.pin ? "Reset pin" : "Set Pin"}
            </h1>
            <p>Enter a 6-digit numeric pin</p>
          </Col>
          {profile.pin ? (
            <>
              <Col span={24} className={"padding20"}>
                <Row>
                  <Col
                    span={24}
                    className={
                      "white-background   doctor-box-shadow border-radius5"
                    }
                    style={{ padding: "20px 20px" }}
                  >
                    <Row gutter={50} className={"resetPin"}>
                      <Col span={12}>
                        <label>Current PIN</label>
                        <br />
                        <Password
                          name="oldPassword"
                          onChange={(e) => {
                            onChangePassword(e);
                          }}
                          maxLength={6}
                          min={6}
                          type={"number"}
                          className="noborderWrapper   border-radius overflow-hidden mt10"
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={50} className={"resetPin"}>
                      <Col className="resetPinHead" span={12}>
                        <label>New PIN</label>
                        <br />
                        <Password
                          name="password"
                          onChange={(e) => {
                            onChangePassword(e);
                          }}
                          maxLength={6}
                          min={6}
                          type={"number"}
                          className="noborderWrapper   border-radius overflow-hidden mt10"
                        />
                      </Col>
                      <Col span={12}>
                        <label>Confirm PIN</label>
                        <br />
                        <Password
                          name="confirmPassword"
                          onChange={(e) => {
                            onChangePassword(e);
                          }}
                          maxLength={6}
                          min={6}
                          type={"number"}
                          className="noborderWrapper   border-radius overflow-hidden mt10"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className={"padding20 bottomFixedButton mt10"}>
                <Button
                  onClick={() => {
                    changePasswords();
                  }}
                  className={"doctor-secondary-button"}
                  block
                  size={"large"}
                >
                  Submit
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col span={24} className={"padding20"}>
                <Row>
                  <Col
                    span={24}
                    className={
                      "white-background   doctor-box-shadow border-radius5"
                    }
                    style={{ padding: "20px 20px" }}
                  >
                    <Row gutter={50} className={"resetPin"}>
                      <Col className="resetPinHead" span={12}>
                        <label>PIN</label>
                        <br />
                        <Password
                          name="password"
                          onChange={(e) => {
                            onSetPassword(e);
                          }}
                          maxLength={6}
                          className="noborderWrapper   border-radius overflow-hidden mt10"
                        />
                      </Col>
                      <Col span={12}>
                        <label>Confirm PIN</label>
                        <br />
                        <Password
                          name="confirmPassword"
                          onChange={(e) => {
                            onSetPassword(e);
                          }}
                          maxLength={6}
                          className="noborderWrapper   border-radius overflow-hidden mt10"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className={"padding20 bottomFixedButton"}>
                <Button
                  onClick={() => {
                    setPasswords();
                  }}
                  className={"doctor-secondary-button"}
                  block
                  size={"large"}
                >
                  Save
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
}

export default DoctorResetPin;
