import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  TreeSelect
} from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchDoctorSlotConfig } from "../../../../redux/reducers/doctor.slice";
import { RootState } from "../../../../shared/constants";
import { Branch } from "../../../../shared/types/branch.type";
import { Department } from "../../../../shared/types/department.type";
import { Doctor } from "../../../../shared/types/doctor.type";
import { Master } from "../../../../shared/types/master.type";
import { SlotConfigration } from "../../../../shared/types/slot.type";

const { Option } = Select;
const { TreeNode, SHOW_PARENT } = TreeSelect;

type Props = {
  department?: Department;
  doctors?: Doctor;
  branch?: Branch;
  workingDays?: Master;
  slot?: SlotConfigration;
  procedures?: Master;
  onSubmitSlot: (values: SlotConfigration) => void;
};

const SlotConfig: React.FunctionComponent<Props> = ({ slot, onSubmitSlot }) => {
  const { doctors } = useSelector((state: RootState) => state.doctor);
  const { branches } = useSelector((state: RootState) => state.branch);
  const { departments } = useSelector((state: RootState) => state.department);
  const { workingDays } = useSelector((state: RootState) => state.master);
  const location: any = useLocation();
  const [form] = useForm();
  const dispatch = useDispatch();

  const AddCustomDay = (evt: any) => {
    console.log(evt);
  };
  const RemoveCustomDay = (evt: any) => {
    console.log(evt);
  };

  const filterDays = (day: any) => {
    return day.name.split(" ").length === 1;
  };

  useEffect(() => {
    // console.log('eeee 22222', location)
    if(location?.state && location?.state?.mode === 'add'){
      form.setFieldsValue({
        doctor_id: location?.state?.doc_id
      });
    }
    // if (slot) {
    //   var editSot = JSON.parse(JSON.stringify(slot));
    //   let availability_days :any = [];
    //   if (
    //     editSot?.session_end_date &&
    //     editSot?.session_start_date &&
    //     editSot?.start_time &&
    //     editSot?.end_time
    //   ) {
    //     console.log('eeeee 444555', editSot)
    //     editSot.session_end_date = moment(editSot?.session_end_date);
    //     editSot.session_start_date = moment(editSot?.session_start_date);
    //     editSot.start_time = moment(editSot?.session_end_date);
    //     editSot.end_time = moment(editSot?.session_start_date);
    //     editSot.availability_days.map((day: any) => {
    //       return availability_days.push(day.day.id);
    //     });
    //     editSot.availability_days = availability_days;
    //   }
    // }
    if(location?.state && location?.state?.mode === 'edit'){
      getDoctorSlot(location?.state?.id);
    }
    
  }, []);

  const getDoctorSlot = (id:string) => {
    dispatch(fetchDoctorSlotConfig(id)).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        let data = val?.payload?.data.data;
        // console.log('eeeee 44443333', data)
        form.setFieldsValue({
          branch_id: data?.branch_id,
          department_id: data?.department_id,
          doctor_id: data?.doctor_id,
          session_start_date: data?.session_start_date ? moment(data?.session_start_date) : null,
          session_end_date: data?.session_end_date ? moment(data?.session_end_date) : null,
          availability_days: data?.availability_days,
          is_tatkal: data?.is_tatkal,
          tatkal_days: data?.tatkal_days,
          // start_time: data?.start_time ? moment(data?.start_time): null,
          // end_time: data?.end_time ? moment(data?.end_time): null,
          protocol: data?.protocol,
          shift_type: data?.shift_type,
        });
      }
    });
  }

  return (
    <Form
      name="basic"
      form={form}
      layout={"vertical"}
      wrapperCol={{ span: 20 }}
      // initialValues={editSot || {}}
      onFinish={(values: SlotConfigration) => {
        onSubmitSlot(values);
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
    >
      <Row>
        <Col span={5}>
          <Form.Item
            label="Branch"
            name="branch_id"
            rules={[{ required: true, message: "Please choose branch" }]}
          >
            <Select placeholder="Choose Branch">
              {branches.map((branch: Branch) => {
                return <Option value={branch.id}>{branch.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Department"
            name="department_id"
            rules={[{ required: false, message: "Please choose department" }]}
          >
            <Select placeholder="Choose Department">
              {departments.map((department: Department) => {
                return <Option value={department.id}>{department.code}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Doctor"
            name="doctor_id"
            rules={[{ required: true, message: "Please choose doctor" }]}
          >
            <Select showSearch placeholder="Choose Docter">
              {doctors.map((doctor: Doctor) => {
                return <Option value={doctor.id}>{doctor.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Session Start Time"
            name="session_start_date"
            rules={[
              { required: true, message: "Please choose session start date" },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Session Start Date"
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Session End Time"
            name="session_end_date"
            rules={[{ required: true, message: "Please session end date" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Session End Date"
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={16}>
          <label>Session applicable on Days:</label>
          <Form.Item
            label="Doctor Availability"
            name="availability_days"
            rules={[
              { required: false, message: "Please check Availability days" },
            ]}
          >
            <Checkbox.Group className="mt10">
              <Row>
                {workingDays.map((workingDays: Master) => {
                  return (
                    <Col span={6}>
                      <Checkbox value={workingDays.id}>
                        {workingDays.name}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Row>
            <Col span={12}>
              <label>Add Custom Availability</label>
              <Form.Item
                label=""
                name="branch"
                rules={[
                  { required: false, message: "Please session start date" },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  treeCheckable
                  showCheckedStrategy={SHOW_PARENT}
                  onSelect={(evt: any) => AddCustomDay(evt)}
                  onDeselect={(evt: any) => RemoveCustomDay(evt)}
                  treeDefaultExpandAll
                >
                  {workingDays
                    .filter((day: any) => filterDays(day))
                    .map((workingDays: any) => {
                      return (
                        <TreeNode
                          value={workingDays.name}
                          title={workingDays.name}
                        >
                          <TreeNode
                            value={
                              workingDays.id + "/" + workingDays.name + "/1"
                            }
                            title={"1st " + workingDays.name}
                          />
                          <TreeNode
                            value={
                              workingDays.id + "/" + workingDays.name + "/2"
                            }
                            title={"2nd " + workingDays.name}
                          />
                          <TreeNode
                            value={
                              workingDays.id + "/" + workingDays.name + "/3"
                            }
                            title={"3rd " + workingDays.name}
                          />
                          <TreeNode
                            value={
                              workingDays.id + "/" + workingDays.name + "/4"
                            }
                            title={"4th " + workingDays.name}
                          />
                        </TreeNode>
                      );
                    })}
                </TreeSelect>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={8} className="mt10">
          <Form.Item label="" name="is_tatkal">
            <Checkbox>Enable Tatkal & Open before (in days)</Checkbox>
          </Form.Item>
          <Form.Item label="Tatkal Days" name="tatkal_days">
            <Input className="mt10"></Input>
            <small>Half of slots will be reserved as Tatkal</small>
          </Form.Item>
        </Col>

        <Col span={12}>
          <label>Session Time</label>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Start Time"
                name="start_time"
                rules={[
                  { required: true, message: "Please session start time" },
                ]}
              >
                <TimePicker
                  format={"hh:mm a"}
                  style={{ width: "100%" }}
                  placeholder="Session Start Time"
                ></TimePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Time"
                name="end_time"
                rules={[{ required: true, message: "Please session end time" }]}
              >
                <TimePicker
                  format={"hh:mm a"}
                  style={{ width: "100%" }}
                  placeholder="Session End Time"
                ></TimePicker>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <label>Slot Protocol</label>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Enter some instructions"
                name="protocol"
                rules={[
                  { required: false, message: "Please Enter some instructions" },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Shift Type"
                name="shift_type"
                rules={[{ required: true, message: "Please choose shift type" }]}
              >
                <Select showSearch placeholder="Choose Docter">
                  <Option value="instant">Instant</Option>
                  <Option value="no_slot">No Slot</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        
        <Col span={12}>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SlotConfig;
