import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DEPARTMENTS,
  DEPARTMENT_BY_ID,
} from "../../shared/routes/route.constants";
import { Department } from "../../shared/types/department.type";
import { Client } from "../../shared/Utils/api-client";

// Change the Departments API Accordingly and remove this one done

const config = {
  name: "departments",
};
export const fetchDepartments: any = createAsyncThunk(
  `${config.name}/fetchDepartments`,
  async (params: any) => {
    return await Client.get(DEPARTMENTS, { params });
  }
);

export const fetchDepartmentById: any = createAsyncThunk(
  `${config.name}/fetchDepartmentById`,
  async (id: string) => {
    return await Client.get(DEPARTMENT_BY_ID(id), {});
  }
);
export const addNewDepartment: any = createAsyncThunk(
  `${config.name}/addNewDepartment`,
  async (data: Department) => {
    return await Client.post(DEPARTMENTS, data);
  }
);
export const updateDepartmentById: any = createAsyncThunk(
  `${config.name}/updateDepartmentById`,
  async ({ id, data }: { id: string; data: Department }) => {
    return await Client.put(DEPARTMENT_BY_ID(id), data);
  }
);
export const departmentSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingDepartment: false,
    department: null as Department | null,
    error: "" as string,
    departments: [] as Department[],
  },
  reducers: {
    reset: (state) => {
      state.department = null;
      state.savingDepartment = false;
      state.loading = false;
      state.error = "";
      state.departments = [];
    },
  },
  extraReducers(builder) {
    builder
      // Department List
      .addCase(fetchDepartments.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.loading = false;
        state.departments = action.payload?.data?.data?.items;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Department By Id
      .addCase(fetchDepartmentById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.department = null;
      })
      .addCase(fetchDepartmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.department = action.payload?.data?.data;
      })
      .addCase(fetchDepartmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Department create
      .addCase(addNewDepartment.pending, (state) => {
        state.savingDepartment = true;
        state.error = "";
      })
      .addCase(addNewDepartment.fulfilled, (state) => {
        state.savingDepartment = false;
      })
      .addCase(addNewDepartment.rejected, (state, action) => {
        state.savingDepartment = false;
        state.error = action.error.message;
      })

      // Department create
      .addCase(updateDepartmentById.pending, (state) => {
        state.savingDepartment = true;
        state.error = "";
      })
      .addCase(updateDepartmentById.fulfilled, (state, action) => {
        state.savingDepartment = false;
      })
      .addCase(updateDepartmentById.rejected, (state, action) => {
        state.savingDepartment = false;
        state.error = action.error?.message;
      });
  },
});

//export const {} = departmentSlice.actions

export default departmentSlice.reducer;
