import {
  CalendarOutlined,
  MoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Input,
  Menu,
  message,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchAppointment,
  updateAppointmentById,
} from "../../../redux/reducers/appointment.slice";
import { fetchDoctorAvailSlots } from "../../../redux/reducers/doctor.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import { APPOINTMENT_MODULE_ACTIONS_READ } from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import DoctorSlots from "../Doctors/components/doctor-slots";
import PatientDetails from "./components/patient-details";
import PatientForm from "./components/patient-form";
import PatientJourney from "./components/patient-journey";
import PatientMessageHistory from "./components/patient-message-history";
import PatientPaymentHistory from "./components/patient-payment-history";
import PatientVideoConsultLogs from "./components/video-logs";
const { Search } = Input;
const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
type Props = {};

const AppointmentHistory: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [edit, setEditAppointment] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [oldSlot, setNewSlot] = useState(null as any);
  const [cancel, setCancelAppointment] = useState(false);
  const [audit, setAuditTrail] = useState(false);
  const { appointments } = useSelector((state: RootState) => state.appointment);
  const [appointment, setSelectedAppointment] = useState("" as any);
  const { doctor_avail_slots, slots_loader } = useSelector(
    (state: RootState) => state.doctor || {}
  );

  const getDoctorSlots = async (_appointment: any) => {
    setSelectedAppointment(_appointment);
    try {
      dispatch(fetchDoctorAvailSlots({ doctor_id: _appointment.doctor_id }));
      setReschedule(true);
    } catch (error) {}
  };

  const setAppointment = (_appointment: any) => {
    setSelectedAppointment(_appointment);
    setEditAppointment(true);
  };
  const cancelAppointment = (_appointment: any) => {
    setSelectedAppointment(_appointment);
    setCancelAppointment(true);
  };
  const auditTrail = (_appointment: any) => {
    setSelectedAppointment(_appointment);
    setAuditTrail(true);
  };

  const onSubmit = (slot: any, procedure: any, branch: any, current: any) => {
    let data: any = {
      source: "web",
      procedure_id: procedure?.procedure_id,
      slot_id: slot?.slot_id,
      slot_time: slot?.s_time,
      procedure_type: procedure?.procedure_name,
      branch_id: branch?.branch_id === undefined ? null : branch?.branch_id,
      fee: branch?.fee,
      date: current,
    };
    setNewSlot(data);
  };

  const rescheduleAppointment = () => {
    if (oldSlot === null) {
      message.error("Choose Time Slot");
      return;
    }
    oldSlot.status = "re-scheduled";
    dispatch(updateAppointmentById({ id: appointment.id, data: oldSlot }));
  };

  const appointmentCancel = () => {
    let data = {
      status: "cancelled",
    };
    try {
      dispatch(updateAppointmentById({ id: appointment.id, data: data }));
      setCancelAppointment(false);
    } catch (error) {}
  };

  const cancelReschedule = () => {
    setReschedule(false);
    setNewSlot(null);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAppointment());
  }, []);
  const menu = (a: any) => (
    <Menu>
      <Menu.Item key="0" onClick={() => setAppointment(a)}>
        Edit Details
      </Menu.Item>
      <Menu.Item key="1" onClick={() => getDoctorSlots(a)}>
        Reschedule Appt.
      </Menu.Item>
      {a.latest_status !== "cancelled" && (
        <Menu.Item key="3" onClick={() => cancelAppointment(a)}>
          Cancel Appt.
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: "Patient Details",
      key: "patient_name",
      dataIndex: ["patient", "pii", "name"],
    },
    {
      title: "Phone Number",
      dataIndex: ["patient", "pii", "mobile"],
    },
    {
      title: "Doctor Name",
      dataIndex: ["doctor", "name"],
    },
    {
      title: "Hospital",
      dataIndex: ["branch", "name"],
    },
    {
      title: "Appt. Type",
      dataIndex: ["procedure_info", "name"],
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      render: (key: any) => moment(key).format("Do MMM YYYY"),
    },
    // {
    //     title: "Appointment Status",
    //     dataIndex: 'latest_status',
    // },
    {
      title: "Payment Status",
      dataIndex: "latest_status",
    },
    {
      title: "Booked",
      dataIndex: "booked",
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <Space>
              <Button
                onClick={() => auditTrail(a)}
                type="primary"
                size={"middle"}
              >
                Audit Trail
              </Button>
              <Dropdown overlay={menu(a)} trigger={["click"]}>
                <MoreOutlined></MoreOutlined>
              </Dropdown>
            </Space>
          </Row>
        );
      },
    },
  ];

  console.log(oldSlot);
  return (
    <PermissionComponent permission={APPOINTMENT_MODULE_ACTIONS_READ}>
      <div className={"col-md-12 main-container"}>
        <Title level={2}>Booking History</Title>
        <p>
          <Space>
            <Search placeholder="Search Patient"></Search>
            <Select placeholder="Payment Status">
              <Option value="">Done</Option>
            </Select>
            <Select placeholder="Created Date">
              <Option value="">Done</Option>
            </Select>
          </Space>
        </p>
        <Table
          className="mt20"
          columns={columns}
          dataSource={appointments}
          pagination={false}
        />

        {/* Edit Appointment Drawer */}
        <Drawer
          title={`Edit Appointment Details`}
          placement="right"
          width={"50%"}
          footer={
            <Space>
              <Button type="primary">Save</Button>
              <Button onClick={() => setEditAppointment(false)}>Cancel</Button>
            </Space>
          }
          onClose={() => setEditAppointment(false)}
          visible={edit}
        >
          <Row className="nomargin">
            <Col
              span={24}
              className="grey-background padding20"
              style={{ height: "60px" }}
            >
              <Space align="center">
                <CalendarOutlined style={{ fontSize: "20px" }} />{" "}
                <p className="primary-color-text mt10 bold">
                  Appointment Details
                </p>
              </Space>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: "50px" }}
            className="white-background nomargin padding24"
          >
            <PatientDetails appointment={appointment}></PatientDetails>
          </Row>
          <Row className="nomargin">
            <Col
              span={24}
              className="grey-background padding20"
              style={{ height: "60px", marginTop: "30px" }}
            >
              <Space align="center">
                <UserOutlined style={{ fontSize: "20px" }} />{" "}
                <p className="primary-color-text mt10 bold">Patient Details</p>
              </Space>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: "50px" }}
            className="white-background nomargin padding24"
          >
            <PatientForm appointment={appointment}></PatientForm>
          </Row>
        </Drawer>

        {/*  Reschedule Drawer */}
        <Drawer
          title={`Reschedule Appointment`}
          placement="right"
          width={"50%"}
          footer={
            <>
              {oldSlot !== null && (
                <p className="primary-color-text mt10 bold">
                  New Appointment will be scheduled on{" "}
                  {moment(oldSlot.date).format("Do MMM' YYYY")},{" "}
                  {oldSlot.slot_time} with Rs. {oldSlot.fee} Fee. Are you sure
                  you want to continue?
                </p>
              )}

              <Space>
                <Button type="primary" onClick={() => rescheduleAppointment()}>
                  Reschedule
                </Button>
                <Button onClick={() => cancelReschedule()}>Cancel</Button>
              </Space>
            </>
          }
          onClose={() => setReschedule(false)}
          visible={reschedule}
        >
          <Row className="nomargin">
            <Col
              span={24}
              className="grey-background padding20"
              style={{ height: "60px" }}
            >
              <Space align="center">
                <CalendarOutlined style={{ fontSize: "20px" }} />{" "}
                <p className="primary-color-text mt10 bold">
                  Appointment Details
                </p>
              </Space>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: "50px" }}
            className="white-background nomargin padding24"
          >
            <PatientDetails appointment={appointment}></PatientDetails>
          </Row>
          <Row className="nomargin">
            <Col
              span={24}
              className="grey-background padding20"
              style={{ height: "60px", marginTop: "30px" }}
            >
              <Space align="center">
                <UserOutlined style={{ fontSize: "20px" }} />{" "}
                <p className="primary-color-text mt10 bold">Select New Slot</p>
              </Space>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: "50px" }}
            className="white-background nomargin padding24"
          >
            <Col span={24} className="mt20">
              <DoctorSlots
                mode={"view"}
                slots={doctor_avail_slots}
                slots_loader={slots_loader}
                onSubmit={(
                  slot: any,
                  procedure: any,
                  branch: any,
                  current: any
                ) => onSubmit(slot, procedure, branch, current)}
              ></DoctorSlots>
            </Col>
          </Row>
        </Drawer>

        {/*  Cancel Drawer */}
        <Drawer
          title={`Cancel Appointment Request`}
          placement="right"
          width={"50%"}
          onClose={() => setCancelAppointment(false)}
          visible={cancel}
        >
          <Row className="nomargin">
            <Col
              span={24}
              className="grey-background padding20"
              style={{ height: "60px" }}
            >
              <Space align="center">
                <CalendarOutlined style={{ fontSize: "20px" }} />{" "}
                <p className="primary-color-text mt10 bold">
                  Appointment Details
                </p>
              </Space>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: "50px" }}
            className="white-background nomargin padding24"
          >
            <PatientDetails appointment={appointment}></PatientDetails>
          </Row>

          <Row className="grey-background nomargin padding24">
            <Col span={24}>
              <p className="primary-color-text mt10 bold">
                Are you sure you want to cancel this appointment? Refund of Rs.{" "}
                {appointment.amount} will be initiated
              </p>
              <Space>
                <Button type="primary" onClick={() => appointmentCancel()}>
                  Yes, Cancel Appointment
                </Button>
                <Button onClick={() => setCancelAppointment(false)}>No</Button>
              </Space>
            </Col>
          </Row>
        </Drawer>

        {/*  Audit Trail Drawer */}
        <Drawer
          title={`Audit Trail`}
          placement="right"
          width={"80%"}
          onClose={() => setAuditTrail(false)}
          visible={audit}
        >
          <Row className="nomargin">
            <Col
              span={24}
              className="grey-background padding20"
              style={{ height: "60px" }}
            >
              <Space align="center">
                <CalendarOutlined style={{ fontSize: "20px" }} />{" "}
                <p className="primary-color-text mt10 bold">
                  Appointment Details
                </p>
              </Space>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: "50px" }}
            className="white-background nomargin padding24"
          >
            <Col span={14}>
              <PatientDetails appointment={appointment}></PatientDetails>
            </Col>
            <Col span={10} className="text-right mt30">
              <p>
                <Space>
                  <Button
                    className="bordered-secondary-color-btn"
                    type="default"
                  >
                    Resend Confirmation Link{" "}
                  </Button>{" "}
                  <Button className="bordered-mid-color-btn" type="default">
                    Resend Prescription Link
                  </Button>
                </Space>
              </p>
              <p>
                <Button
                  className="mt10 bordered-secondary-color-btn"
                  type="default"
                >
                  Send Invoice/Receipt{" "}
                </Button>{" "}
              </p>
            </Col>
          </Row>
          <Row className="mt30">
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Patient Journey" key="1">
                  <PatientJourney></PatientJourney>
                </TabPane>
                <TabPane tab="Message History" key="2">
                  <PatientMessageHistory></PatientMessageHistory>
                </TabPane>
                <TabPane tab="Payments History" key="3">
                  <PatientPaymentHistory></PatientPaymentHistory>
                </TabPane>
                <TabPane tab="Video Consultation Logs" key="4">
                  <PatientVideoConsultLogs></PatientVideoConsultLogs>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Drawer>
      </div>
    </PermissionComponent>
  );
};

export default AppointmentHistory;
