import React from "react";
// component imports
import CompactTableColumnViewComponent from "../Column/CompactTableColumnViewComponent";

class CompactTableChildrenViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colErrorArray: [],
    };
    if (
      props.indRowData &&
      props.indRowData.config &&
      props.indRowData.config.displaycondition &&
      props.indRowData.config.displaycondition.length !== 0
    ) {
      props.setConditionalHide({
        class: props.indRowData.class,
        displaycondition: props.indRowData.config.displaycondition,
        childIndex: props.childIndex,
      });
    }
  }

  getEmitedTextboxValue = (index, event) => {
    this.props.emitTextboxValue(index, event);
  };
  getEmitedChildTextboxValue = (index, event) => {
    this.props.emitChildTextboxValue(index, event);
  };

  getEmitedChildAddNewRowEvent = (index, event) => {
    this.props.emitChildAddNewRowEvent(index, event);
  };

  getEmitedChildDeleteRowObjectData = (index, event) => {
    this.props.emitChildDeleteRowObjectData(index, event);
  };

  getEmitedChildSuggestionEvent = (index, event) => {
    this.props.emitChildSuggestionEvent(index, event);
  };

  setConfigForFormula = (e) => {
    this.props.setConfigForFormula({ ...e, childIndex: this.props.childIndex });
  };

  setErrorForColumn = (colIndex, errorStatus) => {
    let { colErrorArray } = this.state;
    let index = colErrorArray.indexOf(colIndex);
    if (index !== -1 && !errorStatus) {
      colErrorArray.splice(index, 1);
    } else if (index === -1 && errorStatus) {
      colErrorArray.push(colIndex);
    }
    this.setState({ colErrorArray }, () =>
      this.props.emitErrorStatus(colErrorArray.length ? true : false)
    );
  };

  render() {
    // console.log();
    const { indChildrenData } = this.props;
    let show =
      !indChildrenData.class || indChildrenData.class !== "deferRendering hide";
    return (
      show && (
        <React.Fragment>
          <>
            <tr>
              {indChildrenData.columns.map((indColumnData, index) => {
                return (
                  <React.Fragment key={index}>
                    <CompactTableColumnViewComponent
                      checkForm={this.props.checkForm}
                      emitErrorStatus={this.setErrorForColumn.bind(this, index)}
                      colIndex={index}
                      setConfigForFormula={this.setConfigForFormula.bind(this)}
                      rowType={indChildrenData.type}
                      rowId={indChildrenData.rowId}
                      rowIndex={indChildrenData.index}
                      indColumnData={indColumnData}
                      emitTextboxValue={this.getEmitedChildTextboxValue.bind(
                        this,
                        index
                      )}
                      emitAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(
                        this,
                        index
                      )}
                      emitDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(
                        this,
                        index
                      )}
                      emitSuggestionEvent={this.getEmitedChildSuggestionEvent.bind(
                        this,
                        index
                      )}
                    ></CompactTableColumnViewComponent>
                  </React.Fragment>
                );
              })}
              <td
                style={{ padding: "0.1rem", width: "10%", marginRight: "10px" }}
              >
                {this.props.showDelete && (
                  <span className="table-remove">
                    <span
                      className="link-icon mdi mdi-delete-forever  text-dark table-remove"
                      onClick={this.getEmitedChildDeleteRowObjectData.bind(
                        this,
                        this.props.rowIndex
                      )}
                    >
                      {" "}
                    </span>
                  </span>
                )}
                <span
                  className="link-icon mdi mdi-plus  text-dark"
                  onClick={this.getEmitedChildAddNewRowEvent.bind(
                    this,
                    this.props.rowIndex
                  )}
                >
                  {" "}
                </span>
              </td>
            </tr>
          </>
        </React.Fragment>
      )
    );
  }
}

export default CompactTableChildrenViewComponent;
