import { Col, Row } from "antd";

function ResourceNotFoundComponent({ props }: any) {
  return (
    <>
      <Row>
        <Col span={24} className={"text-center"}>
          <img
            alt="not found"
            src="https://img.etimg.com/thumb/msid-79592510,width-1015,height-761,imgsize-261105,resizemode-8,quality-100/prime/pharma-and-healthcare/2021-is-all-about-vaccine-transportation-piramal-schott-kaisha-are-ready-with-sturdy-vials.jpg"
            style={{ width: "20%" }}
          />
          <h2>Page not found</h2>
          <span> You are not authorised to access this resourse </span>
        </Col>
      </Row>
    </>
  );
}

export default ResourceNotFoundComponent;
