import {Button, Checkbox, Col, Divider, Input, notification, Row} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCognitoUserPasswordById,
    updateUserLoginById
} from "../../../../redux/reducers/user.slice";
import {User} from "../../../../shared/types/user.type";
import {getFullName} from "../../../../shared/Utils/utilities";
import Title from "antd/es/typography/Title";

const UserLogins: React.FunctionComponent<{ user: User; refresh: any }> = ({
                                                                               user,
                                                                               refresh,
                                                                           }) => {
    const dispatch = useDispatch();

    const [allowedLogins, setAllowedLogins] = useState([] as string[]);
    const [password, setPassword] = useState('' as string);

    const changeLogins = async (values: string[]) => {
        await dispatch(updateUserLoginById({data: values, id: user.id}));
        refresh();
    };
    const updateCognitoPasswordById = async () => {
        if (!password) {
            notification.error({message: "Password required"});
            return;
        }
        await dispatch(updateCognitoUserPasswordById({data: {newPassword: password}, id: user.id}));
        setPassword('')
    };
    useEffect(() => {
        if (user) {
            const _d = [];
            if (user.enabledLogins?.allow_login_ad) {
                _d.push("allow_login_ad");
            }
            if (user.enabledLogins?.allow_login_otp) {
                _d.push("allow_login_otp");
            }
            if (user.enabledLogins?.allow_login_pin) _d.push("allow_login_pin");
            if (user.enabledLogins?.allow_login_cognito)
                _d.push("allow_login_cognito");

            setAllowedLogins(_d);
        }
    }, [user]);
    useEffect(() => {
        setPassword('');
    }, [allowedLogins])
    return (
        <div className={"side-section-wrapper"}>
            <Row style={{marginTop: "20px"}}>
                <Col flex={"auto"}>
                    <Paragraph>
                        Manage Logins for <strong>{getFullName(user)}</strong>
                    </Paragraph>
                </Col>
            </Row>
            <Checkbox.Group
                style={{width: "100%"}}
                value={allowedLogins}
                onChange={(e: any[]) => {
                    setAllowedLogins(e);
                    changeLogins(e);
                }}
            >
                <Row>
                    <Col span={24}>
                        <Checkbox value="allow_login_otp">OTP Login</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value="allow_login_ad">AD Login</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value="allow_login_pin">PIN Login</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value="allow_login_cognito">Cognito Login</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>


            <Divider/>

            {
                allowedLogins?.indexOf('allow_login_cognito') > -1 && <div style={{paddingBottom: '100px'}}>

                    <Title level={5}>Change Cognito Password</Title>
                    <Input style={{maxWidth: '300px'}} value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <br/>
                    <br/>
                    <Button onClick={updateCognitoPasswordById}>
                        Change Password
                    </Button>
                </div>
            }
        </div>
    );
};
export default UserLogins;
