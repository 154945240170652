import React from 'react';
import { Tabs, Typography } from 'antd';
import OpenOrderQC from './components/open-order-qc.component';
const { Title } = Typography
type Props = {}


const PharmacyOrderQC: React.FunctionComponent<Props> = () => {
    return (
      <div className={'col-md-12 main-container pd20'}>
        <Title level={2}>Pharmacy Order Quality Check</Title>
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Open" key="1">
                <OpenOrderQC />
            </Tabs.TabPane>
            <Tabs.TabPane tab="History" key="2">
                <OpenOrderQC />
            </Tabs.TabPane>
        </Tabs>
      </div>
    );
};

export default PharmacyOrderQC;

