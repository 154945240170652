
import { Input } from 'antd';
const { Search } = Input;
type Props = {
    placeholder: string,
    onSearch: (values: string) => void,
  }

function SearchLayout({placeholder,onSearch}: Props) {
  return (
    <div>
      <Search placeholder={placeholder}  onSearch={(evt:any)=>onSearch(evt)} enterButton />
    </div>
  );
}

export default SearchLayout;
