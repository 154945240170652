import { EditFilled } from "@ant-design/icons";
import { Button, Row, Space, Table, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";

const { Text } = Typography;
type Props = {};

const PermissionsList: React.FunctionComponent<Props> = () => {
  const [tablePermissions, settablePermissions] = useState([]);
  const columns = [
    {
      title: "#",
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
    },
    {
      title: "Table Name",
      dataIndex: "table_name",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <Button shape="circle" icon={<EditFilled />} size={"middle"} />
          </Row>
        );
      },
    },
  ];

  return (
    <div className={"col-md-12 main-container"}>
      {/*<PermissionComponent permission={PATIENT_MODULE_ACTIONS_READ}>*/}
      <Table
        locale={{
          emptyText: (
            <>
              <Space
                direction={"vertical"}
                size={10}
                style={{ padding: "10px" }}
              >
                <Title level={3} className={"text-primary"}>
                  {""}
                </Title>
                <Text className={"text-primary"}>No Permission added</Text>
                <Button type={"primary"}>{"Add New Permission"}</Button>
              </Space>
            </>
          ),
        }}
        scroll={{ x: true }}
        dataSource={tablePermissions}
        columns={columns}
      />
      {/*</PermissionComponent>*/}
    </div>
  );
};

export default PermissionsList;
