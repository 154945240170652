import { Pie } from "@ant-design/plots";

const PieChartLayout = ({ status }) => {
  const data = status;
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    color: ["#2DBDB6", "#003E72", "#3C89BB"],
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) =>
        `${percent !== 0 ? (percent * 100).toFixed(1) + "%" : ""}`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return <Pie {...config} />;
};

export default PieChartLayout;
