import React, {useEffect} from "react";
import {Button, Form, Input, Skeleton,} from "antd";

import {useForm} from "antd/es/form/Form";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../shared/constants";

import PrescriptionTemplateBuilder from '../Builder/template-builder.page'
import { fetchAllTemplateGroups } from "../../../../redux/reducers/prescription.slice";
require('../../../../styles/scss/form-builder.scss');
type Props = {
    onSubmit: (values: any) => void,
    template?: any
}
const PrescriptionTemplateForm: React.FunctionComponent<Props> = ({template, onSubmit}) => {
    const [form] = useForm();
    const {savingRole, loading} = useSelector((state: RootState) => state.prescription);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(template){
            form.setFieldsValue(template);
        }
    }, [template]);
    useEffect(() => {
        dispatch(fetchAllTemplateGroups());
    }, [])
    if (loading) {
        return <Skeleton/>
    }
    return (
        
            <div className="form-builder">
                <PrescriptionTemplateBuilder template={template} onSubmit={(values: any) => {
                    onSubmit(values);
                }}/>
            </div>
            
           

        
    );
};

export default PrescriptionTemplateForm;

