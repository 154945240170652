import { Avatar, Col, message, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDocterMobile } from "../../../redux/reducers/doctor.slice";
import { fetchProfile } from "../../../redux/reducers/profile.slice";
import { RootState } from "../../../shared/constants";
import { getInitials } from "../../../shared/Utils/utilities";
import ProfileDetails from "./components/profile-details";

function ProfilePage() {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector((state: RootState) => state.profile);
  const [number, SetNumber] = useState(null as any);

  const updateMobile = () => {
    if (number === null) {
      message.error("Provide number");
      return;
    }

    (async function () {
      try {
        const response = await dispatch(
          updateDocterMobile({ coordinator_mobile: number })
        );
        if (response.payload.status === 201) {
          message.success("Updated Successfully");
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    dispatch(fetchProfile("users"));
  }, []);

  useEffect(() => {
    SetNumber(profile?.coordinator_mobile);
  }, [profile]);
  return (
    <Row justify={"center"}>
      <Col span={24} className={"white-background padding20"}>
        <h1 className={"doctor-primary-color bold"}>Profile</h1>
      </Col>

      <Col
        span={16}
        className={
          "padding20 white-background border-radius5 text-center mt50 doctor-box-shadow"
        }
      >
        <Avatar
          shape={"square"}
          style={{
            width: "100px",
            height: "100px",
            paddingTop: "30px",
            fontSize: "40px",
          }}
          className={"user-frame"}
          size="large"
        >
          {profile && getInitials(profile?.name)}{" "}
        </Avatar>

        {loading ? (
          <Skeleton></Skeleton>
        ) : (
          <Col span={24}>
            <ProfileDetails profile={profile}></ProfileDetails>

            {/* <Row>
              <Col span={24} className={"text-left"}>
                <h3 className={"doctor-primary-color"}>
                  <b>Support Contact Number</b>
                </h3>
                <p>
                  Who should {CLIENT_NAME} front office or call center connect
                  for any issues (yourself or your team member){" "}
                </p>
                <Input
                  value={number}
                  type={"number"}
                  prefix={"+91"}
                  size={"large"}
                  onChange={(evt) => SetNumber(evt.target.value)}
                ></Input>
              </Col>
            </Row> */}
          </Col>
        )}
      </Col>

      {/* <Col span={16} className={"mt20"} style={{ marginBottom: "20px" }}>
        <Button
          className={"doctor-secondary-button"}
          block
          size={"large"}
          onClick={() => updateMobile()}
        >
          Save
        </Button>
      </Col> */}
    </Row>
  );
}

export default ProfilePage;
