import { CheckOutlined, CloseOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchCMSData, updateCMSData } from "../../../redux/reducers/settings.slice";
import TableLayout from "../../../shared/layouts/table.layout";
type Props = {};

function CmsData(props: Props) {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(null as any);
  const [dataList, setDataList] = useState(null as any);
  const [loading, setLoading] = useState(null as any);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalText, setDeleteModalText] = useState("" as string);
  const [deleteSpcId, setDeleteSpcId] = useState("" as string);
  const [action, setAction] = useState("" as string);
  
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [filters]);

  const fetchData = () => {
    let filter = filters
      ? Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => v != null)
        )
      : {};
      dispatch(fetchCMSData({ ...filter })).then((data: any) => {
        if (data?.payload?.data?.statusCode == 200) {
          setDataList(data?.payload?.data?.data);
          setLoading(false);
        }
      });
  }

  const columns: any = [
    {
      title: "Doctor Type",
      render: (key: any) => (key?.data_type ? key?.data_type : "-"),
    },
    {
      title: "Date",
      render: (key: any) => key?.date ? moment(key?.date).format("YYYY-MM-DD") : "-",
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <Col>
              <Button
                shape="circle"
                href= {a?.url}
                // onClick={() => {downloadData(a?.url)}}
                title={"Click to download file"}
                target="_blank"
                download
                icon={<FileTextOutlined />}
                size={"middle"}
              />
            </Col>
            {a?.is_show_approve && 
            <Col>
              <Button
                shape="circle"
                title={"Approve"}
                onClick={() => {openDeleteModal(a?.id, 'true')}}
                icon={<CheckOutlined />}
                size={"middle"}
              />
            </Col>}
            {a?.is_show_reject && 
            <Col>
              <Button
                shape="circle"
                title={"Reject"}
                onClick={() => {openDeleteModal(a?.id, 'false')}}
                icon={<CloseOutlined />}
                size={"middle"}
              />
            </Col>}
          </Row>
        );
      },
    },
  ];

  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {
      ...filters,
      doctor_data_date: filters?.doctor_data_date ? moment(filters?.doctor_data_date) : null,
    },
    formElements: [
      {
        label: "",
        type: "DatePicker",
        name: "doctor_data_date",
        colSpan: 5,
      },
      {
        label: "",
        type: "Select",
        name: "data_type",
        colSpan: 5,
        allowClear: true,
        placeholder: "Select Doctor Type",
        options: [
          { name: "Doctor Video Link", value: "doctor_video_link" },
          { name: "Doctor Speciality", value: "doctor_speciality" },
          { name: "Doctor Props", value: "doctor_props" },
        ],
      },
      {
        label: "",
        type: "Select",
        name: "is_approved",
        colSpan: 5,
        allowClear: true,
        placeholder: "Is Approved",
        options: [
          { name: "True", value: "true" },
          { name: "False", value: "false" },
        ],
      },
    ],
  };

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] == "" || all[key] == null || all[key] == undefined) {
      } else {
        if (key == "doctor_data_date") {
          filtersObj.doctor_data_date = moment(all.doctor_data_date).format("YYYY-MM-DD");
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    console.log('eeeee 444444',filtersObj )
    setFilters(filtersObj);
  }

  const openDeleteModal = (id: string, action: string) => {
    let text = action === 'true' ? 'approve' : 'reject'
    setDeleteModalVisible(true);
    setDeleteModalText(`Are you sure, you want to ${text} it.`);
    setDeleteSpcId(id);
    setAction(action);
  }

  const handleDeleteModal = async () => {
    let data = {
      id: deleteSpcId,
      is_verified: action
    }
    await dispatch(updateCMSData(data));
    await closeDeleteModal();
    await fetchData()
  };

  const closeDeleteModal = () => {
    setDeleteModalText("");
    setAction('');
    setDeleteModalVisible(false);
  };

  const downloadData = async (data: any) => {
    const {myData} = data;
    const fileName = "file";
    const json = JSON.stringify(myData);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  

  return (
    <div className={"col-md-12 main-container"}>
      {/* <PermissionComponent permission={REQUEST_LOGS_MODULE_ACTIONS_READ}> */}
      <TableLayout
        loading={loading}
        dataSource={dataList}
        columns={columns}
        type="only_filter"
        formData={formElements}
      />

      <Modal
        title="Confirmation"
        visible={deleteModalVisible}
        onOk={handleDeleteModal}
        onCancel={closeDeleteModal}
      >
        {deleteModalText}
      </Modal>
      {/* </PermissionComponent> */}
    </div>
  );
}

export default CmsData;
