import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CALL_INITIATE,
  CALL_LOGS,
  CALL_LOG_BY_ID,
  CALL_LOG_DELINK,
  CALL_LOG_LINK,
} from "../../shared/routes/crm.routes.constants";
import { Client } from "../../shared/Utils/api-client";
const config = {
  name: "calllogs",
};
export const fetchCalllogsList: any = createAsyncThunk(
  `${config.name}/fetchCalllogsList`,
  async (params: any) => {
    return await Client.get(CALL_LOGS, { params });
  }
);
export const fetchCalllogDetails: any = createAsyncThunk(
  `${config.name}/fetchCalllogDetails`,
  async (id: string) => {
    return await Client.get(CALL_LOG_BY_ID(id), {});
  }
);
export const patchCalllogDetails: any = createAsyncThunk(
  `${config.name}/patchCalllogDetails`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(CALL_LOG_BY_ID(id), data);
  }
);
export const createCalllog: any = createAsyncThunk(
  `${config.name}/createCalllog`,
  async (body: any) => {
    return await Client.post(CALL_LOGS, body);
  }
);
export const linkCallLog: any = createAsyncThunk(
  `${config.name}/linkCallLog`,
  async (params: any) => {
    return await Client.post(CALL_LOG_LINK, params);
  }
);
export const delinkCallLog: any = createAsyncThunk(
  `${config.name}/delinkCallLog`,
  async (id: string) => {
    return await Client.delete(CALL_LOG_DELINK(id));
  }
);
export const initiateCall: any = createAsyncThunk(
  `${config.name}/initiateCall`,
  async (params: any) => {
    return await Client.post(CALL_INITIATE, params);
  }
);

export const calllogsSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    calllogs: [] as any,
    pagination: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
    calllog: {} as any,
  },
  reducers: {
    reset: (state) => {
      state.calllogs = [];
      state.loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
      state.calllog = {};
    },
    clearCalllogDetails: (state) => {
      state.calllog = {};
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch calllogs
      .addCase(fetchCalllogsList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchCalllogsList.fulfilled, (state, action) => {
        state.loading = false;
        state.calllogs = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchCalllogsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //  Create Call log
      .addCase(createCalllog.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createCalllog.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createCalllog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Details
      .addCase(fetchCalllogDetails.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchCalllogDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.calllog = action?.payload?.data?.data;
      })
      .addCase(fetchCalllogDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Patch call log
      .addCase(patchCalllogDetails.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(patchCalllogDetails.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action);
        // state.calllog = action?.payload?.data?.data;
      })
      .addCase(patchCalllogDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Link call log
    // .addCase(linkCallLog.pending, (state) => {
    //   state.loading = true;
    //   state.error = "";
    // })
    // .addCase(linkCallLog.fulfilled, (state, action) => {
    //   state.loading = false;
    // })
    // .addCase(linkCallLog.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message;
    // })

    // Delink Call log
    // .addCase(delinkCallLog.pending, (state) => {
    //   state.loading = true;
    //   state.error = "";
    // })
    // .addCase(delinkCallLog.fulfilled, (state, action) => {
    //   state.loading = false;
    // })
    // .addCase(delinkCallLog.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message;
    // });
  },
});

export const { clearCalllogDetails } = calllogsSlice.actions;

export default calllogsSlice.reducer;
