import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAdminStats } from "../../../redux/reducers/settings.slice";
import TableLayout from "../../../shared/layouts/table.layout";
type Props = {};

function AdminStats(props: Props) {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    date: moment().format("YYYY-MM-DD"),
    type: "sync_practo_appointments",
  } as any);
  const [logsList, setlogsList] = useState(null as any);
  const [loading, setLoading] = useState(null as any);
  useEffect(() => {
    setLoading(true);
    let filter = filters
      ? Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => v != null)
        )
      : {};
    dispatch(fetchAdminStats({ ...filter })).then((data: any) => {
      if (data?.payload?.data?.statusCode == 200) {
        setlogsList(data?.payload?.data?.data);
        setLoading(false);
      }
    });
  }, [filters]);

  const columns: any = [
    {
      title: "Stats Date",
      render: (key: any) =>
        key?.stats_date ? moment(key?.stats_date).format("YYYY-MM-DD") : "-",
    },
    {
      title: "Stats Datetime",
      render: (key: any) => (key?.stats_datetime ? key?.stats_datetime : "-"),
    },
    {
      title: "Total Received Count",
      render: (key: any) => (key?.received_count ? key?.received_count : "-"),
    },
    {
      title: "Practo Sync Failed Count",
      render: (key: any) =>
        key?.tp_sync_failed_count ? key?.tp_sync_failed_count : "-",
    },
    {
      title: "THB Sync Failed Count",
      render: (key: any) =>
        key?.thb_sync_failed_count ? key?.thb_sync_failed_count : "-",
    },
  ];

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = { ...filters } as any;
    for (let key of Object.keys(all)) {
      if (all[key] === "" || all[key] === undefined) {
      } else {
        if (key === "date") {
          filtersObj.date = moment(all.date).format("YYYY-MM-DD");
        } else {
          filtersObj[key] = all[key];
        }
      }
    }
    setFilters({ ...filtersObj });
  }

  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {
      ...filters,
      date: moment(filters.date),
    },
    formElements: [
      {
        label: "",
        type: "DatePicker",
        name: "date",
        colSpan: 4,
        placeholder: "",
      },
    ],
  };

  return (
    <div className={"col-md-12 main-container"}>
      {/* <PermissionComponent permission={REQUEST_LOGS_MODULE_ACTIONS_READ}> */}
      <TableLayout
        loading={loading}
        dataSource={logsList}
        columns={columns}
        type="only_filter"
        formData={formElements}
      />
      {/* </PermissionComponent> */}
    </div>
  );
}

export default AdminStats;
