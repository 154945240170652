import { Col, Row, Select, Typography } from "antd";
import Title from "antd/es/typography/Title";
import TableLayout from "../../../../shared/layouts/table.layout";

const { Text } = Typography;
const { Option } = Select;
type Props = {
  title?: string;
};

function PatientTransactions({ title }: Props) {
  const columns = [
    {
      title: "Doctor/Lab/Pharmacy",
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
    },
    {
      title: "Transaction Type",
      render: (a: any) => <Text>{a}</Text>,
    },
    {
      title: "Status",
      dataIndex: "email",
    },
    {
      title: "Payment Status",
      render: (a: any) => <Text>{a}</Text>,
    },
    {
      title: "Created On",
      render: (a: any) => <Text>{a}</Text>,
    },
    {
      title: "Appt/Service Data",
      dataIndex: "email",
    },
    {
      title: "Source",
      render: (a: any) => <Text>{a}</Text>,
    },
    {
      title: "Action",
      render: (a: any) => <Text>{a}</Text>,
    },
  ];

  const onChange = (value: any) => {
    console.log(value);
  };

  return (
    <div className={"desktop-list-layout"}>
      <div className="title">
        <Row>
          <Col flex={"auto"}>
            <Title level={4}>{title}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TableLayout
              columns={columns}
              dataSource={[]}
              loading={false}
              total={50}
              onChange={(value) => onChange(value)}
              title={"Transactions"}
            ></TableLayout>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PatientTransactions;
