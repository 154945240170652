import { Col } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { fetchDepartments } from "../../../../redux/reducers/department.slice";
import {
  fetchDoctors,
  updateDoctorSlotConfig,
  updateDoctorSlotProcedure
} from "../../../../redux/reducers/doctor.slice";
import {
  fetchMasterDays,
  fetchMasterProcedures,
  fetchMasterProperty
} from "../../../../redux/reducers/master.slice";
import {
  fetchSlotById,
  fetchSlotProcedureById
} from "../../../../redux/reducers/slot.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { ROUTE_DOCTOR } from "../../../../shared/routes/admin.routes.constants";
import {
  SlotConfigration
} from "../../../../shared/types/slot.type";
import DocterSlotForm from "./slot-form.page";


const SlotEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  var url_string = window.location.href;
  var url = new URL(url_string);
  var id = url.pathname.split("/");

  const [current, setCurrent] = React.useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  useEffect(() => {
    dispatch(fetchDoctors());
    dispatch(fetchDepartments());
    dispatch(fetchBranches());
    dispatch(fetchMasterDays());
    dispatch(fetchMasterProcedures());
    dispatch(fetchMasterProperty());
    dispatch(fetchSlotById(id[3]));
    dispatch(fetchSlotProcedureById(id[3]));
  }, []);

  return (
    <DesktopFormLayout title={"Edit Slot"} subtitle={"Edit Slot informations"}>
      <Col span={24}>
        <DocterSlotForm
          onSubmitSlot={async (values: SlotConfigration) => {
            //  let _available_days = new Array
            //  values.availability_days.map((day: String) => {
            //      _available_days.push({
            //          day_master_id: day
            //      })
            //  })
            //  values.availability_days = _available_days
            delete values.availability_days;
            if (
              values?.end_time &&
              values?.start_time &&
              values?.session_end_date &&
              values?.session_start_date
            ) {
              values = {
                ...values,
                end_time: moment(values?.end_time.toString()).format("HH:mm a"),
                start_time: moment(values?.start_time.toString()).format(
                  "HH:mm a"
                ),
                session_start_date: moment(
                  values?.session_start_date.toString()
                ).format("YYYY-MM-DD"),
                session_end_date: moment(
                  values?.session_end_date.toString()
                ).format("YYYY-MM-DD"),
              };
            }
            try {
              await dispatch(updateDoctorSlotConfig({ id: id[3], data: values })).then((val: any) => {
                if (val?.payload?.data?.statusCode == 200) {
                  next();
                }
              });
            } catch (e) {}
          }}
          onSubmitProperty={async (values: any) => {
            values.doctor_slot_config_id = id[3];
            values = {...values, fee: parseInt(values.fee), interval: parseInt(values.interval), interval_capacity: parseInt(values.interval_capacity)}
            await dispatch( updateDoctorSlotProcedure(values)).then((val: any) => {
              if (val?.payload?.data?.statusCode == 200) {
                history.push(ROUTE_DOCTOR);
              }
            });
            
          }}
          current={current}
        />
      </Col>
    </DesktopFormLayout>
  );
};

export default SlotEdit;
