import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  TEAMS,
  TEAM_BY_ID,
  TEAM_TYPES,
  TEAM_USERS,
  TEAM_USERS_BY_ID,
} from "../../shared/routes/route.constants";
import { Team } from "../../shared/types/team.type";
import { Client } from "../../shared/Utils/api-client";

// Change the Teams API Accordingly and remove this one done

const config = {
  name: "teams",
};
export const fetchTeams: any = createAsyncThunk(
  `${config.name}/fetchTeams`,
  async (params: any) => {
    return await Client.get(TEAMS, { params });
  }
);
export const fetchTeamTypes: any = createAsyncThunk(
  `${config.name}/fetchTeamTypes`,
  async (params: any) => {
    return await Client.get(TEAM_TYPES, { params });
  }
);

export const fetchTeamById: any = createAsyncThunk(
  `${config.name}/fetchTeamById`,
  async (id: string) => {
    return await Client.get(TEAM_BY_ID(id), {});
  }
);
export const addNewTeam: any = createAsyncThunk(
  `${config.name}/addNewTeam`,
  async (data: Team) => {
    return await Client.post(TEAMS, data);
  }
);
export const addNewTeamUser: any = createAsyncThunk(
  `${config.name}/addNewTeamUser`,
  async (data: any) => {
    return await Client.post(TEAM_USERS(data.team_id), data);
  }
);
export const removeTeamUser: any = createAsyncThunk(
  `${config.name}/removeTeamUser`,
  async (data: any) => {
    return await Client.delete(TEAM_USERS_BY_ID(data.team_id, data.user_id));
  }
);
export const updateTeamById: any = createAsyncThunk(
  `${config.name}/updateTeamById`,
  async ({ id, data }: { id: string; data: Team }) => {
    return await Client.put(TEAM_BY_ID(id), data);
  }
);
export const teamSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingTeam: false,
    team: null as Team | null,
    error: "" as string,
    teams: [] as Team[],
    teamTypes: [] as any[],
    pagination: { offset: 0, limit: 10, total: 0 },
  },
  reducers: {
    reset: (state) => {
      state.team = null;
      state.savingTeam = false;
      state.loading = false;
      state.error = "";
      state.teams = [];
      state.teamTypes = [];
      state.pagination = { offset: 0, limit: 10, total: 0 };
    },
  },
  extraReducers(builder) {
    builder

      .addCase(fetchTeamTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.teamTypes = action.payload.data.data.items;
      })
      // Team List
      .addCase(fetchTeams.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.teams = action.payload.data.data.items;
        state.pagination = {
          offset: action.payload.data.data.offset,
          limit: action.payload.data.data.limit,
          total: action.payload.data.data.total,
        };
      })
      .addCase(fetchTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Team By Id
      .addCase(fetchTeamById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.team = null;
      })
      .addCase(fetchTeamById.fulfilled, (state, action) => {
        state.loading = false;
        state.team = action?.payload?.data?.data;
      })
      .addCase(fetchTeamById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Team create
      .addCase(addNewTeam.pending, (state) => {
        state.savingTeam = true;
        state.error = "";
      })
      .addCase(addNewTeam.fulfilled, (state, action) => {
        state.savingTeam = false;
      })
      .addCase(addNewTeam.rejected, (state, action) => {
        state.savingTeam = false;
        state.error = action.error.message;
      })

      // Team create
      .addCase(updateTeamById.pending, (state) => {
        state.savingTeam = true;
        state.error = "";
      })
      .addCase(updateTeamById.fulfilled, (state, action) => {
        state.savingTeam = false;
        //console.log(action.payload);
      })
      .addCase(updateTeamById.rejected, (state, action) => {
        state.savingTeam = false;
        state.error = action.error.message;
      });
  },
});

//export const {} = teamSlice.actions

export default teamSlice.reducer;
