import firebase from "firebase/app";
import "firebase/messaging";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDgQevnrzvWVJTtvDa6UjOE1Z9j2_zAFgM",
  authDomain: "max-health-1baf9.firebaseapp.com",
  projectId: "max-health-1baf9",
  storageBucket: "max-health-1baf9.appspot.com",
  messagingSenderId: "1033341243627",
  appId: "1:1033341243627:web:a804f01e3f886d3ef26eae",
  measurementId: "G-EBK7E61VTV",
};

firebase.initializeApp(FIREBASE_CONFIG);

export class BrowserFcmProvider {
  static async webGetToken() {
    try {
      if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        return messaging.getToken({
          vapidKey:
            "BEnC2z4lrwdJUhWiz-A5V-D_lVbtDEQhJLZ5whRZZv33otOQI6ML__v2rAuG_fIb4PCPmRD9fusCO7GKmnjr6i8",
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  static showMessage() {
    try {
      if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        messaging.onMessage((payload) => {
          console.log(payload);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
}
