import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ADD_APPOINTMENT_ATTENDANT,
  ADD_APPOINTMENT_NOTE,
  APPOINTMENT,
  APPOINTMENTS_BY_PATIENT,
  APPOINTMENT_ANALYTIS,
  APPOINTMENT_BY_ID,
  APPOINTMENT_CHECKIN,
  APPOINTMENT_CHECKOUT,
  APPOINTMENT_IPD_BY_ID,
  APPOINTMENT_PATIENTS,
  APPOINTMENT_PATIENT_JOURNEY,
  APPOINTMENT_PATIENT_MESSAGE_JOURNEY,
  APPOINTMENT_PAYMENT_LINK,
  APPOINTMENT_PAYMENT_RECEIPT,
  APPOINTMENT_ROOM_DETAILS,
  APPOINTMENT_STATE_BY_ID,
  APPOINTMENT_STATUS_BY_ID,
  APPOINTMENT_THB_SYNC_FAILED,
  APPOINTMENT_TICKET_STATUS,
  APPOINTMENT_TICKET_STATUS_BY_ID,
  APPOINTMENT_TP_SYNC_FAILED,
  APPOINTMENT_TRANSACTIONS,
  APPOINTMENT_VALIDATE_ATTENDANT_ROOM_TOKEN,
  APPOINTMENT_VALIDATE_DEEP_LINK,
  APPOINTMENT_VALIDATE_ROOM,
  APPOINTMENT_VALIDATE_ROOM_TOKEN,
  CREATE_APPOINTMENT,
  HISTORICAL_APPOINTMENT_BY_ID,
  IPD_APPOINTMENTS,
  RESHOOT_MESSAGE,
  ROOM_LOGS,
  UPDATE_APPOINTMENT_NOTE_BY_ID,
} from "../../shared/routes/route.constants";
import { Appointment } from "../../shared/types/appointment.type";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "appointment",
};
export const fetchAppointment: any = createAsyncThunk(
  `${config.name}/fetchAppointment`,
  async (params: any) => {
    return await Client.get(APPOINTMENT, { params });
  }
);

export const fetchPatientAppointments: any = createAsyncThunk(
  `${config.name}/fetchPatientAppointments`,
  async (params: any) => {
    return await Client.get(APPOINTMENTS_BY_PATIENT, { params });
  }
);

export const addNewAppointemnt: any = createAsyncThunk(
  `${config.name}/addNewAppointemnt`,
  async (data: any) => {
    return await Client.post(CREATE_APPOINTMENT, data);
  }
);

export const addAppointemntNote: any = createAsyncThunk(
  `${config.name}/addAppointemntNote`,
  async (data: any) => {
    return await Client.post(ADD_APPOINTMENT_NOTE, data);
  }
);

export const updateAppointemntNote: any = createAsyncThunk(
  `${config.name}/updateAppointemntNote`,
  async (data: any) => {
    return await Client.patch(
      UPDATE_APPOINTMENT_NOTE_BY_ID(data.id),
      data.data
    );
  }
);

export const addNewAppointemntAttendant: any = createAsyncThunk(
  `${config.name}/addNewAppointemntAttendant`,
  async (params: any) => {
    return await Client.post(ADD_APPOINTMENT_ATTENDANT, { params });
  }
);

export const fetchAppointmentByDoctor: any = createAsyncThunk(
  `${config.name}/fetchAppointmentByDoctor`,
  async (params: any) => {
    return await Client.post(APPOINTMENT, { params });
  }
);

export const fetchAppointmentById: any = createAsyncThunk(
  `${config.name}/fetchAppointmentById`,
  async (id: string) => {
    return await Client.get(APPOINTMENT_BY_ID(id), {});
  }
);


export const fetchIPDAppointmentById: any = createAsyncThunk(
  `${config.name}/fetchIPDAppointmentById`,
  async (id: string) => {
    return await Client.get(APPOINTMENT_IPD_BY_ID(id), {});
  }
);

export const fetchHistoricalAppointmentById: any = createAsyncThunk(
  `${config.name}/fetchHistoricalAppointmentById`,
  async (params: string) => {
    return await Client.get(HISTORICAL_APPOINTMENT_BY_ID, { params });
  }
);

export const fetchAppointmentAuditTrail: any = createAsyncThunk(
  `${config.name}/fetchAppointmentAuditTrail`,
  async (id: string) => {
    return await Client.get(APPOINTMENT_PATIENT_JOURNEY(id), {});
  }
);

export const fetchMessageData: any = createAsyncThunk(
  `${config.name}/fetchMessageData`,
  async (data: any) => {
    return await Client.get(
      APPOINTMENT_PATIENT_MESSAGE_JOURNEY(data?.id, data?.appointment_id),
      {}
    );
  }
);

export const reshootMessage: any = createAsyncThunk(
  `${config.name}/reshootMessage`,
  async (id: string) => {
    return await Client.post(RESHOOT_MESSAGE(id), {});
  }
);

export const updateAppointmentState: any = createAsyncThunk(
  `${config.name}/updateAppointmentState`,
  async (data: string) => {
    return await Client.patch(APPOINTMENT_STATE_BY_ID, data);
  }
);

export const fetchIPDAppointment: any = createAsyncThunk(
  `${config.name}/fetchIPDAppointment`,
  async (params: string) => {
    return await Client.get(IPD_APPOINTMENTS, { params });
  }
);
export const updateAppointmentTicketStatusDetails: any = createAsyncThunk(
  `${config.name}/fetchAppointmentTicketStatusDetails`,
  async (body: string) => {
    return await Client.patch(APPOINTMENT_TICKET_STATUS, body);
  }
);
export const fetchAppointmentTicketStatusDetailsByID: any = createAsyncThunk(
  `${config.name}/fetchAppointmentTicketStatusDetailsByID`,
  async (id: string) => {
    return await Client.get(APPOINTMENT_TICKET_STATUS_BY_ID(id), {});
  }
);

export const updateAppointmentById: any = createAsyncThunk(
  `${config.name}/updateAppointmentById`,
  async ({ id, data }: { id: string; data: Appointment }) => {
    return await Client.patch(APPOINTMENT_STATUS_BY_ID(id), data);
  }
);

export const deleteAppointmentById: any = createAsyncThunk(
  `${config.name}/deleteAppointmentById`,
  async (id: string) => {
    return await Client.delete(APPOINTMENT_BY_ID(id), {});
  }
);

// Appointment Video Call
export const validateAppointmentCall: any = createAsyncThunk(
  `${config.name}/validateAppointmentCall`,
  async (data: string) => {
    return await Client.post(APPOINTMENT_VALIDATE_ROOM, data);
  }
);

export const validateAppointmentCallToken: any = createAsyncThunk(
  `${config.name}/validateAppointmentCallToken`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_VALIDATE_ROOM_TOKEN, { params });
  }
);

export const validateAppointmentCallDetails: any = createAsyncThunk(
  `${config.name}/validateAppointmentCallDetails`,
  async (data: any) => {
    return await Client.post(APPOINTMENT_ROOM_DETAILS, data, {
      shouldHide: true,
    });
  }
);

export const validateAppointmentCallAttendantToken: any = createAsyncThunk(
  `${config.name}/validateAppointmentCallAttendantToken`,
  async (data: any) => {
    return await Client.get(
      APPOINTMENT_VALIDATE_ATTENDANT_ROOM_TOKEN(
        data.room_id,
        data.participant_id
      ),
      { shouldHide: true }
    );
  }
);

export const validateAppointmentDeepLink: any = createAsyncThunk(
  `${config.name}/validateAppointmentDeepLink`,
  async (data: any) => {
    return await Client.get(APPOINTMENT_VALIDATE_DEEP_LINK(data), {
      shouldHide: true,
    });
  }
);

export const fetchAppointmentPaymentLink: any = createAsyncThunk(
  `${config.name}/fetchAppointmentPaymentLink`,
  async (id: any) => {
    return await Client.get(APPOINTMENT_PAYMENT_LINK(id), {});
  }
);

export const fetchAppointmentPaymentRecipet: any = createAsyncThunk(
  `${config.name}/fetchAppointmentPaymentRecipet`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_PAYMENT_RECEIPT, { params });
  }
);

export const checkoutAppointment: any = createAsyncThunk(
  `${config.name}/checkoutAppointment`,
  async (id: any) => {
    return await Client.post(APPOINTMENT_CHECKOUT(id), {});
  }
);

export const checkinAppointment: any = createAsyncThunk(
  `${config.name}/checkinAppointment`,
  async (id: any) => {
    return await Client.post(APPOINTMENT_CHECKIN(id), {});
  }
);

export const findAppointmentPatients: any = createAsyncThunk(
  `${config.name}/findAppointmentPatients`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_PATIENTS, { params });
  }
);

export const findLastVistedAppointmentPatients: any = createAsyncThunk(
  `${config.name}/findLastVistedAppointmentPatients`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_PATIENTS, { params });
  }
);

// Call Logs
export const submitCallLogs: any = createAsyncThunk(
  `${config.name}/submitCallLogs`,
  async (data: any) => {
    return await Client.post(ROOM_LOGS, data, { shouldHide: true });
  }
);

export const getCallLogs: any = createAsyncThunk(
  `${config.name}/getCallLogs`,
  async (params: any) => {
    return await Client.get(ROOM_LOGS, { params });
  }
);

export const fetchDoctorAnalytics: any = createAsyncThunk(
  `${config.name}/fetchDoctorAnalytics`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_ANALYTIS, { params });
  }
);
export const fetchAppointmentTransactions: any = createAsyncThunk(
  `${config.name}/fetchAppointmentTransactions`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_TRANSACTIONS, { params });
  }
);
export const fetchAppointmentTpSyncFailed: any = createAsyncThunk(
  `${config.name}/fetchAppointmentTpSyncFailed`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_TP_SYNC_FAILED, { params });
  }
);
export const fetchAppointmentThbSyncFailed: any = createAsyncThunk(
  `${config.name}/fetchAppointmentThbSyncFailed`,
  async (params: any) => {
    return await Client.get(APPOINTMENT_THB_SYNC_FAILED, { params });
  }
);

export const appointmentSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingAppointment: false,
    department: null as Appointment | null,
    pipEnabled: false,
    error: "" as string,
    appointments: [] as Appointment[],
    appointment: [] as any,
    call_validation: false as any,
    call_details: [] as any,
    appointment_verification: [] as any,
    videoChatProps: null as any,
    payment_link: null as any,
    analytics: null as any,
    appointment_patients: [] as any,
    patient_loading: false,
    pagination: { offset: 0, limit: 10, total: 0 },
    last_visit: [],
  },
  reducers: {
    resetAppointments: (state) => {
      state.department = null;
      state.savingAppointment = false;
      state.loading = false;
      state.error = "";
      state.appointments = [];
      state.appointment = [];
      state.call_details = [];
      state.appointment_verification = [];
      state.payment_link = null;
      state.appointment_patients = [];
      state.patient_loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.last_visit = [];
    },
    setVideoRoom: (state, action) => {
      state.videoChatProps = action.payload;
      state.pipEnabled = false;
    },
    togglePIP: (state, action) => {
      state.pipEnabled = !!action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // Appointment List
      .addCase(fetchAppointment.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action?.payload?.data?.data?.items;
        state.pagination = {
          offset: action?.payload?.data?.data?.offset,
          limit: action?.payload?.data?.data?.limit,
          total: action?.payload?.data?.data?.total,
        };
      })
      .addCase(fetchAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Appointment List
      .addCase(fetchPatientAppointments.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPatientAppointments.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action?.payload?.data?.data;
      })
      .addCase(fetchPatientAppointments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Appointment List
      .addCase(addNewAppointemnt.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addNewAppointemnt.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewAppointemnt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // IPD Appointment List
      .addCase(fetchIPDAppointment.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchIPDAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action?.payload?.data?.data?.items;
      })
      .addCase(fetchIPDAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Appointment  By Appointment ID
      .addCase(fetchAppointmentById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAppointmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.appointment = action?.payload?.data?.data;
      })
      .addCase(fetchAppointmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchIPDAppointmentById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchIPDAppointmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.appointment = action?.payload?.data?.data;
      })
      .addCase(fetchIPDAppointmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      

      // Appointment  By Historical Appointment ID
      .addCase(fetchHistoricalAppointmentById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchHistoricalAppointmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.appointment = action?.payload?.data?.data;
      })
      .addCase(fetchHistoricalAppointmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Validate Appointment call

      .addCase(validateAppointmentCall.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(validateAppointmentCall.fulfilled, (state, action) => {
        state.loading = false;
        state.call_validation = action?.payload?.data?.data?.validationStatus;
      })
      .addCase(validateAppointmentCall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // validate Appointment Call Token
      .addCase(validateAppointmentCallToken.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(validateAppointmentCallToken.fulfilled, (state, action) => {
        state.loading = false;
        state.call_details = action?.payload?.data?.data;
      })
      .addCase(validateAppointmentCallToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Validate Appointment Call Attendant Token
      .addCase(validateAppointmentCallAttendantToken.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(
        validateAppointmentCallAttendantToken.fulfilled,
        (state, action) => {
          state.loading = false;
          state.appointment_verification = action?.payload?.data?.data;
        }
      )
      .addCase(
        validateAppointmentCallAttendantToken.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      // Validate Appointment Deep Link
      .addCase(validateAppointmentDeepLink.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(validateAppointmentDeepLink.fulfilled, (state, action) => {
        state.loading = false;
        state.appointment_verification = action?.payload?.data?.data;
      })
      .addCase(validateAppointmentDeepLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Validate Appointment Deep Link
      .addCase(findLastVistedAppointmentPatients.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(findLastVistedAppointmentPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.last_visit = action?.payload?.data?.data;
      })
      .addCase(findLastVistedAppointmentPatients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //  Appointment Call Payment Link
      .addCase(fetchAppointmentPaymentLink.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAppointmentPaymentLink.fulfilled, (state, action) => {
        state.loading = false;
        state.payment_link = action?.payload?.data?.data;
      })
      .addCase(fetchAppointmentPaymentLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //  Appointment Analytics
      .addCase(fetchDoctorAnalytics.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchDoctorAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.analytics = action?.payload?.data?.data;
      })
      .addCase(fetchDoctorAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(findAppointmentPatients.pending, (state) => {
        state.patient_loading = true;
        state.error = "";
      })
      .addCase(findAppointmentPatients.fulfilled, (state, action) => {
        state.patient_loading = false;
        state.appointment_patients = action?.payload?.data?.data;
      })
      .addCase(findAppointmentPatients.rejected, (state, action) => {
        state.patient_loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetAppointments, togglePIP, setVideoRoom } =
  appointmentSlice.actions;

export default appointmentSlice.reducer;
