const DownIcon =({height="8" , width="15",color="#003878",onClick}:any) =>(
    <>
       
       
        <svg  width={width} height={height} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.26422 0.581383C1.65129 0.18743 2.28443 0.181847 2.67838 0.568912L7.64359 5.44731L12.4228 0.581496C12.8098 0.187482 13.443 0.181798 13.837 0.568801C14.231 0.955804 14.2367 1.58894 13.8497 1.98296L8.3696 7.56231C8.18374 7.75154 7.93031 7.85918 7.66507 7.86154C7.39984 7.8639 7.14453 7.76079 6.95533 7.57489L1.2767 1.99554C0.882743 1.60848 0.877159 0.975336 1.26422 0.581383Z" fill={color}/>
        </svg>

    </> 
);

export default DownIcon;