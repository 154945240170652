import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import StartQCHeader from './components/start-qc-header.component';
import PrescriptionComponent from '../../../../shared/layouts/pharmacy-prescription.layout';
import MedicineOrderComponent from '../../../../shared/layouts/pharmacy-medicine-order.layout';
import PlaceOrderComponent from '../../../../shared/layouts/pharmacy-place-order.layout';
import SelectedMedicine from './components/selected-medicine.component';
import { RootState } from "../../../../shared/constants";
import { fetchMedicinesById, getPrescriptionSignedUrl } from '../../../../redux/reducers/pharmacy.slice';

const PharmacyStartQC: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  const [ prescriptionData,  setPrescriptionData] = useState([{}]);
  const [ isEditMedicine,  setIsEditMedicine] = useState(null as any);
  const { pharmacyDetails } = useSelector((state: RootState) => state.pharmacy);
  let requestId: string = '';
  requestId = pharmacyDetails?.id;
  
  useEffect( () => {
    fetchAPI();
  }, [requestId])

  const fetchAPI = async () => {
    let phDetails = await dispatch(fetchMedicinesById(requestId));
    await getPrescription(phDetails?.payload?.data?.data?.pharmacy?.document_details);
  }

  const getPrescription = async (doc:any) => {
    try {
      let imageUrl = [];
      for (let i = 0; i < doc.length; i++) {
        const doc_id = doc[i]?.document_data_id;
        let data = await dispatch(getPrescriptionSignedUrl(doc_id))
        imageUrl.push({index:i+1, src: data?.payload?.data?.data?.result, selected: true })
      }
      setPrescriptionData(imageUrl)
    } catch (error) {
      
    }
  }

  const handleEditMedicine = (childData: any) =>{
    setIsEditMedicine(childData);
  }

  return (
    <div className={'col-md-12 main-container pd20'}>
      <StartQCHeader />
      <Row className='bordered-grey-color'>
        {(pharmacyDetails?.status === 'qc_done' && !isEditMedicine?.action) ? 
        <>
          <Col className='pd20 grey-background' span={14}>
            <SelectedMedicine handleEditMedicine = {handleEditMedicine}/>
          </Col>
          <Col span={10}>
          <PlaceOrderComponent />
          </Col>
        </> : 
        <>
          <Col className='pd20 grey-background' 
          // span= {10}>
          span={(pharmacyDetails?.document_details && pharmacyDetails?.document_details.length > 0 ) ?  10 : 0}>
          <PrescriptionComponent 
          prescriptionData = {prescriptionData}
          />
          </Col>
          <Col 
          // span= {14}>
          span={(pharmacyDetails?.document_details && pharmacyDetails?.document_details.length > 0 ) ?  14 : 24}>
          <MedicineOrderComponent 
            makeMedicineComponentEditable = {isEditMedicine?.action} 
            request_id={isEditMedicine?.pharmacyDetails?.id} 
            handleEditMedicine={handleEditMedicine}
          />
          </Col>
        </>
      }
      </Row>
    </div>
  );
};

export default PharmacyStartQC;

