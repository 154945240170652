import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MASTER_MESSAGE_TEMPLATE,
  MASTER_MESSAGE_TEMPLATE_BY_ID,
  MASTER_MESSAGE_TEMPLATE_TYPES,
  MESSAGE_TEMPLATES,
  TEAM_BY_ID,
} from "../../shared/routes/route.constants";
import { Team } from "../../shared/types/team.type";
import { Client } from "../../shared/Utils/api-client";

// Change the Teams API Accordingly and remove this one done

const config = {
  name: "message-templates",
};
export const fetchMessageTemplates: any = createAsyncThunk(
  `${config.name}/fetchMessageTemplates`,
  async (params: any) => {
    return await Client.get(MASTER_MESSAGE_TEMPLATE, { params });
  }
);
export const fetchMessageTemplateTypes: any = createAsyncThunk(
  `${config.name}/fetchMessageTemplateTypes`,
  async (params: any) => {
    return await Client.get(MASTER_MESSAGE_TEMPLATE_TYPES, { params });
  }
);

export const fetchMessageTemplateById: any = createAsyncThunk(
  `${config.name}/fetchMessageTemplateById`,
  async (id: string) => {
    return await Client.get(MASTER_MESSAGE_TEMPLATE_BY_ID(id), {});
  }
);
export const fetchMessageTemplateByType: any = createAsyncThunk(
  `${config.name}/fetchMessageTemplateById`,
  async (id: string) => {
    return await Client.get(MASTER_MESSAGE_TEMPLATE_BY_ID(id), {});
  }
);
export const updateTemplateContent: any = createAsyncThunk(
  `${config.name}/updateTemplateContent`,
  async (data: Team) => {
    return await Client.post(MESSAGE_TEMPLATES, data);
  }
);
export const updateTeamById: any = createAsyncThunk(
  `${config.name}/updateTeamById`,
  async ({ id, data }: { id: string; data: Team }) => {
    return await Client.put(TEAM_BY_ID(id), data);
  }
);
export const messageTemplateSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingTeam: false,
    messageTemplate: null as any | null,
    templateConfigs: null as any | null,
    error: "" as string,
    messageTemplates: [] as any[],
    templateTypes: [] as any[],
  },
  reducers: {
    reset: (state) => {
      state.messageTemplate = null;
      state.savingTeam = false;
      state.templateConfigs = null;
      state.loading = false;
      state.error = "";
      state.messageTemplates = [];
      state.templateTypes = [];
    },
  },
  extraReducers(builder) {
    builder
      // Team List
      .addCase(fetchMessageTemplates.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMessageTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.messageTemplates = action.payload?.data?.data;
      })
      .addCase(fetchMessageTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchMessageTemplateTypes.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMessageTemplateTypes.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload?.data?.data?.templateTypes){
          state.templateTypes = JSON.parse(JSON.stringify(action.payload?.data?.data?.templateTypes?.map((t: any)=>{
            return {
              ...t,
              key: t.key?.split('_').splice(1).join('_')
            }
          })));

          state.templateConfigs = {
            ...action.payload?.data?.data,
            templateTypes: state.templateTypes
          };

        }

      })
      .addCase(fetchMessageTemplateTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Team By Id
      .addCase(fetchMessageTemplateById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.messageTemplate = null;
      })
      .addCase(fetchMessageTemplateById.fulfilled, (state, action) => {
        state.loading = false;
        state.messageTemplate = action?.payload?.data?.data;
      })
      .addCase(fetchMessageTemplateById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Team create
      // .addCase(addNewTeam.pending, (state) => {
      //     state.savingTeam = true
      //     state.error = ''
      // })
      // .addCase(addNewTeam.fulfilled, (state, action) => {
      //     state.savingTeam = false
      // })
      // .addCase(addNewTeam.rejected, (state, action) => {
      //     state.savingTeam = false
      //     state.error = action.error.message
      // })

      // Team create
      .addCase(updateTeamById.pending, (state) => {
        state.savingTeam = true;
        state.error = "";
      })
      .addCase(updateTeamById.fulfilled, (state, action) => {
        state.savingTeam = false;
        console.log(action.payload);
      })
      .addCase(updateTeamById.rejected, (state, action) => {
        state.savingTeam = false;
        state.error = action.error.message;
      });
  },
});

//export const {} = messageTemplateSlice.actions;

export default messageTemplateSlice.reducer;
