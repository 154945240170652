import {
  AutoComplete,
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Skeleton
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBranchById } from "../../../../redux/reducers/branch.slice";
import { fetchMasterRegion } from "../../../../redux/reducers/master.slice";
import { fetchCity } from "../../../../redux/reducers/pharmacy.slice";
import { RootState } from "../../../../shared/constants";
import { Branch } from "../../../../shared/types/branch.type";
import { MobileNumberRegex } from "../../../../shared/Utils/regex";
const { Option } = Select;
type Props = {
  onSubmit: (values: Branch) => void;
  branch?: Branch;
};

const BranchForm: React.FunctionComponent<Props> = ({
  branch = {},
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [autoCompleteCityArr, setAutoCompleteCityArr] = useState([] as any);
  const [regions, setRegion] = useState([] as any);
  const [regionValue, setregionValue] = useState(null as any)
  const { savingBranch, loading } = useSelector(
    (state: RootState) => state.role
  );
  const RouteParams: any = useParams();

  // const [ checked, setChecked ] = useState(  )
  useEffect(() => {
    dispatch(fetchMasterRegion()).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        setRegion(val?.payload?.data.data);
      }
    });
    if (RouteParams["id"]) {
      dispatch(fetchBranchById(RouteParams["id"])).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          let e = val?.payload?.data?.data;
          form.setFieldsValue({
            name: e.name,
            code: e.code,
            city: e.city,
            region_id: e.region_id,
            phone: e.phone,
            external_id: e.external_id,
            region: e.region,
            latitude: e.latitude,
            longitude: e.longitude,
            is_active: e.is_active,
            image_url: e.image
          });
        }
      });
    }
  }, []);

  const onCitySearch = async (searchText: string) => {
    if (searchText.length > 2) {
      let param = {
        search_keyword: searchText,
      };
      let cityList: any = await dispatch(fetchCity(param));
      if (cityList?.payload?.data?.data?.length > 0)
        setAutoCompleteCityArr(cityList.payload.data.data);
    } else {
      setAutoCompleteCityArr([]);
    }
  };

  const onChangeRegion = (e: any) => {
    const regionName = regions.filter((val: any) => {
      return val.id === e
    })
    setregionValue(regionName && regionName[0]?.name)
  }



  const onCitySelect = async (data: string) => {
    // let selectedItem = autoCompleteCityArr.filter((item: any) => {
    //     return item.city === data;
    // });
    // let item = selectedItem[0];
    // setCityId(item);
    // setAutoCompleteCityArr([])
  };
  if (loading) {
    return <Skeleton />;
  }
  return (
    <Form
      name="basic"
      form={form}
      layout={"horizontal"}
      labelAlign={"left"}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 8 }}
      className="mt20"
      // initialValues={
      //   branch?.status !== undefined ? { ...branch } : { status: true }
      // }
      onFinish={(values: Branch) => {
        onSubmit({ ...values, region: regionValue });
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input Name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: "Please input Code" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="City"
        name="city"
        rules={[{ required: false, message: "Please select city" }]}
      >
        <AutoComplete
          onSearch={onCitySearch}
          onSelect={onCitySelect}
          dataSource={autoCompleteCityArr.map((items: any) => (
            <Option key={items.id} value={items.city}>
              {items.city}
            </Option>
          ))}
        />
      </Form.Item>

      <Form.Item
        label="Region"
        name="region_id"
        rules={[{ required: true, message: "Please input Region" }]}
      >
        <Select onChange={(e: any) => { onChangeRegion(e) }}>
          {regions?.map((val: any) => {
            return (
              <Option value={val.id}>
                <span className="capitalize">{val.name}</span>
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Phone"
        name="phone"
        rules={[{ required: true, pattern: MobileNumberRegex, message: "Please valid phone!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Latitude" name="latitude">
        <Input />
      </Form.Item>
      <Form.Item label="Longitude" name="longitude">
        <Input />
      </Form.Item>
      <Form.Item
        label="External ID"
        name="external_id"
        rules={[{ required: false, message: "Please input external ID!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Image URL" name="image_url">
        <Input />
      </Form.Item>
      <Form.Item label="Status" name="is_active" valuePropName="checked">
        <Checkbox>Active</Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button loading={savingBranch} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BranchForm;
