import { Skeleton, Steps } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Branch } from "../../../../shared/types/branch.type";
import { Department } from "../../../../shared/types/department.type";
import { Doctor } from "../../../../shared/types/doctor.type";
import { Master } from "../../../../shared/types/master.type";
import {
  SlotConfigration,
  SlotProcedure,
} from "../../../../shared/types/slot.type";
import SlotConfigPage from "./slot-config";
import SlotProcedurePage from "./slot-procedure";

type Props = {
  department?: Department;
  doctors?: Doctor;
  branch?: Branch;
  workingDays?: Master;
  procedures?: Master;
  onSubmitSlot: (values: SlotConfigration) => void;
  onSubmitProperty: (values: SlotProcedure) => void;
  current?: any;
};

const DocterSlotForm: React.FunctionComponent<Props> = ({
  onSubmitSlot,
  onSubmitProperty,
  current,
}) => {
  const { slot, loading, procedure } = useSelector(
    (state: RootState) => state.slot
  );
  const { Step } = Steps;
  console.log(slot, procedure);
  const steps = [
    {
      title: "First",
      content: (
        <SlotConfigPage
          onSubmitSlot={(values) => onSubmitSlot(values)}
          slot={slot}
        />
      ),
    },
    {
      title: "Second",
      content: (
        <SlotProcedurePage
          onSubmitProperty={(values) => onSubmitProperty(values)}
          procedure={procedure}
        />
      ),
    },
  ];

  if (loading) {
    return <Skeleton />;
  }

  return (
    <DesktopFormLayout title={""} subtitle={""}>
      <Steps current={current} size="small">
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      {/* <div className="steps-action">
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => message.success('Processing complete!')}>
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div> */}
    </DesktopFormLayout>
  );
};

export default DocterSlotForm;
