import React from "react";
class HeaderComponent extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { label } = this.props;
    return (
      <div className="col-display-block">
        <h4>{label}</h4>
      </div>
    );
  }
}

export default HeaderComponent;
