import { Button, Col, Row, Select, Table } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchLayout from "./search";

import { fetchUsers } from "../../redux/reducers/user.slice";
//import { Serializer } from 'v8';

const { Option } = Select;

//const { Text } = Typography;

type Props = {
  title: string;
  loading: boolean;
  enableAddRoute?: string;
  actions?: any[];
  dataSource: any[];
  columns: any[];
  skip?: string;
  take?: any;
  currentPage?: any;
  rowClassName?: any;
  onPageChange?: any;
  total?: any;
  pages?: string;
  application?: any;
};

function DesktopListLayout({
  application,
  pages,
  title,
  enableAddRoute,
  currentPage,
  loading,
  rowClassName,
  columns,
  dataSource,
  actions,
  onPageChange,
  total,
  take,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userDataSource, setuserDataSource] = useState([] as any);
  const [appValue, setAppValue] = useState('' as string);

  const onSearch = (values: any) => {
    if(values){
      dispatch(fetchUsers({ q: values }));
    } else{
      dispatch(fetchUsers());
    }
  };


  const handleChangeApp = (value: any) => {
    setAppValue(value)
    if(value === "all"){
      dispatch(fetchUsers({limit: 25}));
    } else{
      dispatch(fetchUsers({limit: 25, type: value}));
    }
    // if (value === "all") {
    //   setuserDataSource(dataSource);
    // } else {
    //   let output = dataSource.filter((eachVal: any) => {
    //     let opt = eachVal.user_accesses.some(
    //       (itemsss: any) => itemsss.application_id === value
    //     );
    //     return opt;
    //   });
    //   setuserDataSource(output);
    // }
  };

  console.log('eeee 4444', currentPage, appValue)

  useEffect(() => {
    if(appValue == 'all' || appValue == ''){
      dispatch(fetchUsers({limit: 25}));
    } else{
      dispatch(fetchUsers({limit: 25, type: appValue}));
    }
  }, [currentPage]);

  useEffect(() => {
    setuserDataSource(dataSource);
  }, [dataSource]);

  return (
    <div className={"desktop-list-layout"}>
      <div className="title">
        <Row gutter={8}>
          <Col flex={"auto"}>
            <Title level={4}>{title}</Title>
          </Col>
          {pages === "userPage" ? (
            <>
              <Col>
                <Select
                  defaultValue="Select App"
                  style={{ width: "150px" }}
                  onChange={handleChangeApp}
                >
                  <Option value="all"> All</Option>
                  {application &&
                    application?.map((item: any) => {
                      return <Option value={item.slug}> {item.name} </Option>;
                    })}
                </Select>
              </Col>
              <Col>
                <SearchLayout
                  placeholder="Search User"
                  onSearch={(values: any) => onSearch(values)}
                />
              </Col>
            </>
          ) : (
            ""
          )}
          {actions?.length &&
            actions.map((a) => {
              return <Col>{a}</Col>;
            })}
          {enableAddRoute && (
            <Col>
              <Button
                onClick={() => history.push(enableAddRoute)}
                type={"primary"}
              >
                Add New {title}
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <Table
        loading={loading}
        locale={
          {
            // emptyText: (
            //     <>
            //         <Space direction={'vertical'} size={10}>
            //             <Text>No {title} Added</Text>
            //         </Space>
            //     </>
            // ),
          }
        }
        scroll={{ x: true }}
        rowClassName={rowClassName}
        dataSource={userDataSource}
        pagination={ total ? {
          position: ["bottomRight"],
          total: total,
          pageSize: take,
          onChange: (e) => {
            if (onPageChange) {
              onPageChange(e);
            }
          },
          current: currentPage || take,
        } : false}
        columns={columns}
      />
    </div>
  );
}

export default DesktopListLayout;
