import { Avatar, Col, Divider, Row, Skeleton } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { fetchAppointmentById } from "../redux/reducers/appointment.slice";
import { fetchSlotById } from "../redux/reducers/slot.slice";
import {
  ROUTE_DOCTOR_APPOINTMENTS,
  ROUTE_DOCTOR_CHATS,
} from "../shared/routes/doctor.routes.constants";
import { DEVICE_NOTIFICATIONS } from "../shared/routes/route.constants";

export const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [notification, setNotifications] = useState([]);
  useEffect(() => {
    axios
      .request({
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // This is set to application/flac
        },
        method: "POST",
        data: { app_id: "max_app" },
        url: DEVICE_NOTIFICATIONS,
        timeout: 0,
      })
      .then((res) => {
        setNotifications(res?.data?.nf_list);
        setLoader(false);
      });
  }, []);

  const redirectToApp = async (item: any) => {
    if (item?.meta?.type === "new_chat_message") {
      history.push({
        pathname: ROUTE_DOCTOR_CHATS,
        state: {
          patient_id: item?.meta?.patient_id,
        },
      });
    } else if (item?.meta?.appointment_id) {
      const response = await dispatch(
        fetchAppointmentById(item?.meta?.appointment_id)
      );
      if (response.payload.status === 200) {
        let appointment = response?.payload?.data?.data;
        const slot = await dispatch(
          fetchSlotById(appointment?.doctor_slot_config_id)
        );
        history.push({
          pathname: ROUTE_DOCTOR_APPOINTMENTS("opd"),
          state: {
            branch: appointment.branch_id,
            date: appointment.appointment_date,
            branch_name: appointment.branch?.name,
            start: slot?.start_time,
            end: slot?.end_time,
            doctor_slot_config_id: appointment?.doctor_slot_config_id,
            practice_status: slot?.practice_status,
            is_appointment: true,
            appointment_id: item?.meta?.appointment_id,
          },
        });
      }
      return;
    } else {
      history.push("/dashboard");
      return;
    }
  };

  return (
    <div className="fullHeightDiv">
      <Row>
        <Col span={24} className={"white-background padding20"}>
          <h1 className={"doctor-primary-color bold"}>Notifications</h1>
        </Col>

        <Col span={24} style={{ padding: "20px 20px" }}>
          <Row>
            <Col
              span={24}
              className="doctor-box-shadow dark-background doctor-top-border "
              style={{ padding: "20px 20px" }}
            >
              {loader ? (
                <Skeleton></Skeleton>
              ) : (
                notification.map((item: any) => {
                  return (
                    <Row gutter={24}>
                      <Col span={1} xs={4} md={1}>
                        <Avatar
                          size={45}
                          className="white-background pastPrescriptionAvtar mt10"
                        ></Avatar>
                      </Col>
                      <Col span={22} xs={18} md={22}>
                        <h3
                          className={"doctor-primary-color capitalize cursor"}
                          onClick={() => redirectToApp(item)}
                        >
                          <b>{item?.title}</b>
                        </h3>
                        <p className={"doctor-primary-color mt-10"}>
                          {item?.body}
                        </p>
                        <p
                          className={"doctor-primary-color mt-10"}
                          style={{ opacity: "0.5" }}
                        >
                          {moment(item?.date).format("DD MMM'YY")}
                        </p>
                      </Col>
                      <Divider
                        className={" recentAppointmentDivider"}
                        style={{
                          marginTop: "0",
                          marginBottom: "15px",
                          opacity: ".2",
                          borderTop: "1px solid #163B6D",
                        }}
                      ></Divider>
                    </Row>
                  );
                })
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Notifications;
