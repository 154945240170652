import * as React from "react";
import { Row, Col, Slider } from "antd";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";

class NumericPainFaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  setValue(e) {
    let value = {
      value: e,
    };
    this.props.emitTextboxValue(value);
  }

  render() {
    const { value, isView, max, min, label, name } = this.props;

    return (
      <Col className="gutter-row" span={24}>
        <Row>
          <label> {name === "" ? label : name} </label>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col className="gutter-row" span={2}>
            <FrownOutlined style={{ fontSize: "20px" }} />
          </Col>
          <Col className="gutter-row" span={16}>
            <Slider
              disabled={isView}
              value={value}
              min={min}
              onChange={(e) => this.setValue(e)}
              max={max}
              style={{ marginTop: "5px" }}
            />
          </Col>

          <Col className="gutter-row" span={4}>
            <SmileOutlined style={{ fontSize: "20px" }} />
          </Col>
          {value !== null ? <p>Score : {value}</p> : ""}
        </Row>
      </Col>
    );
  }
}

export default NumericPainFaces;
