const CheckIcon =({height="150" , width="151",color="#23BCB5"}) =>(
    <>
        
        <svg width={width} height={height} viewBox="0 0 151 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M75.5716 0.101562C34.3075 0.101562 0.734375 33.6718 0.734375 74.9388C0.734375 116.206 34.3075 149.776 75.5716 149.776C116.836 149.776 150.409 116.206 150.409 74.9388C150.409 33.6718 116.836 0.101562 75.5716 0.101562ZM117.293 49.9806L71.2396 101.791C70.1056 103.066 68.5254 103.722 66.9336 103.722C65.67 103.722 64.4007 103.308 63.3386 102.462L34.555 79.4347C32.0739 77.4516 31.6709 73.8277 33.657 71.3437C35.6402 68.8597 39.2669 68.4567 41.748 70.4428L66.263 90.053L108.687 42.327C110.794 39.9495 114.438 39.7365 116.813 41.8492C119.19 43.9648 119.406 47.603 117.293 49.9806Z" fill={color}/>
        </svg>
    </>
);

export default CheckIcon;

