
import { ClockCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Skeleton, Space, Tag, Typography,Badge } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ScreenType } from "../../../../context/ScreenType";
import {
  fetchAppointmentById,
  submitCallLogs,
  validateAppointmentCall,
  validateAppointmentCallAttendantToken,
} from "../../../../redux/reducers/appointment.slice";
import { fetchMultipleRooms, markReadMessagePatient } from "../../../../redux/reducers/chat.slice";
import { fetchDoctorProperties } from "../../../../redux/reducers/doctor.slice";
import { RootState } from "../../../../shared/constants";
import BackIcon from "../../../../shared/icons/BackIcon";
import ChatLayout from "../../../../shared/layouts/chat.layout";
import GoBacklayout from "../../../../shared/layouts/go-back.layout";
import {
  ROUTE_DOCTOR_APPOINTMENTS_CALL,
  ROUTE_DOCTOR_APPOINTMENTS_CALL_ATTANDANT,
} from "../../../../shared/routes/doctor.routes.constants";
import { getYoutubeId } from "../../../../shared/Utils/utilities";

const { Text } = Typography;
var _ = require("lodash");

function AppointmentWaitingPage() {
  let screenType = useContext(ScreenType);
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  var url_string = window.location.href;
  var url = new URL(url_string);
  var is_patient = url.pathname.split("/");
  const { error, loading, appointment } = useSelector(
    (state: RootState) => state.appointment
  );
  const { doctor_props } = useSelector((state: RootState) => state.doctor);
  const [ip, setIP] = useState("");
  const [joined, setJoined] = useState(false);
  const { connection,rooms } = useSelector((state: RootState) => state.chat);
  const [allMessages, setAllMessages] = useState([] as any);
  const [chatCount, setChatCount] = useState(null as any);
  const [chat, setChat] = useState(false);


  useEffect(() => {
    async function appointmentDetails (){
      let _appointment = await dispatch(fetchAppointmentById(RouteParams["appointment_id"]));
      if (_appointment.payload.status === 200){
       let response = _.cloneDeep(_appointment?.payload?.data?.data);
       dispatch(
         fetchMultipleRooms({
           doctor_id: response?.doctor_id,
           patient_ids: [response?.patient_id],
         })
       );
      }
    }
    appointmentDetails()
    
    if (is_patient[4] === "patient") {
      dispatch(
        validateAppointmentCall({
          room_id: RouteParams["room_id"],
          participant_id: RouteParams["patient_id"],
        })
      );
    } else {
      dispatch(
        validateAppointmentCallAttendantToken({
          room_id: RouteParams["room_id"],
          participant_id: RouteParams["participant_id"],
        })
      );
    }
    getData();
    // setTimeout(() => {
    //   dispatch(fetchAppointmentById(RouteParams["appointment_id"]))
    // }, 1000);
  }, []);

  useEffect(() => {
    if (rooms.length !== 0) {
      setChatCount(rooms[0]?.is_new_unread_msg_for_patient ? 1 : 0);
    }
  }, [rooms]);



  useEffect(() => {
    const wsCurrent = connection;
    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      if (_message.user_id === appointment?.doctor_id) {
        setChatCount(1);
      }
      if (_message.type === "doctor_joined") {
        
        if (RouteParams["appointment_id"] === _message?.data?.appointment_id) {
          goToCall()
        }
        if (RouteParams["appointment_id"] !== _message?.data?.appointment_id) {
          setJoined(false)
        }
      }
      setAllMessages((prevState: any) => [...prevState, _message]);
      var element = document.getElementById("box") as any;
      element &&
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    };
  });

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    if (appointment.length === undefined) {
      dispatch(fetchDoctorProperties({ id: appointment?.doctor_id }));
    }
  }, [appointment]);

  const goToCall = () => {
    if (RouteParams["source"] === "guest") {
      history.push(
        ROUTE_DOCTOR_APPOINTMENTS_CALL_ATTANDANT(
          appointment.room_id,
          "guest",
          appointment.id,
          RouteParams["participant_id"]
        )
      );
    } else {
      // dispatch(
      //   submitCallLogs({
      //     event: "JOIN",
      //     user_type: RouteParams["source"],
      //     room: appointment.room_id,
      //     user_id: appointment?.patient?.id,
      //     device_type: "Browser",
      //     device_name: window.navigator.appCodeName,
      //     device_ip: ip,
      //     device_os: window.navigator.appVersion,
      //     reason: RouteParams["source"] + "Joined on Web"
      //   })
      // );
      history.push(
        ROUTE_DOCTOR_APPOINTMENTS_CALL(
          appointment.room_id,
          "patient",
          appointment.id
        )
      );
    }
  };


  const markReadMessage = () => {
    if (rooms.length !== 0) {
      dispatch(markReadMessagePatient(rooms[0]?.chat_room_id));
    }
  };

  if (loading) {
    return (
      <Row className={"full-height padding20 doctor-app"}>
        {" "}
        <Col span={4} style={{ paddingLeft: "2px", paddingTop: "20px" }}>
          <GoBacklayout></GoBacklayout>
        </Col>
        <Col span={16} className={"md20"}>
          <Skeleton active></Skeleton>
          <Skeleton active></Skeleton>
        </Col>
      </Row>
    );
  }
  if (error) {
    return <p>Error</p>;
  } else {
    return (
      <Row className={"padding20 doctor-app full-height"} >
        <Col span={4} style={{ paddingLeft: "2px", paddingTop: "20px" }}>
          <GoBacklayout></GoBacklayout>
        </Col>
        <Col span={18}>
          <Row>
            {screenType.mobile && (
              <Col xs={0} md={4} className="mt10">
                {RouteParams["source"] !== "guest" ? (
                  <span
                    className={"cursor"}
                    onClick={() =>
                    (window.location.href =
                      "/patient/appointment/" + RouteParams["appointment_id"])
                    }
                  >
                    {" "}
                    <Space className="cursor">
                      {" "}
                      <BackIcon />{" "}
                    </Space>
                  </span>
                ) : (
                  ""
                )}
              </Col>
            )}
            <Col xs={24} md={20} className="text-center">
              <Space size="middle">
                <div>
                  <ClockCircleOutlined
                    style={{ fontSize: "50px" }}
                    className="doctor-secondary-color"
                  />
                </div>
                <div className="text-left">
                  <h2 className="doctor-secondary-color mt10 capitalize fw6">
                    {" "}
                    {appointment?.doctor?.name}{" "}
                  </h2>
                  <h2 className="doctor-primary-color mt-20 fw6">
                    Waiting Room
                  </h2>
                </div>
              </Space>
            </Col>

            <Col xs={24} md={20}>
              <Row justify={"center"}>
                {/* <span className="doctor-secondary-button border-radius5 cursor" style={{ padding: "10px" }} onClick={() => goToCall()}>Join Now</span> */}

                <Col span={24} className="text-center mt20">

                  {
                     joined ? <Tag className="doctor-primary-color-tag-dark">
                     Doctor has joined the video call Please click on <b>'Join  Video Call'</b>
                   </Tag> : appointment?.is_doctor_joined  && !joined ? <>
                      <Tag className="doctor-primary-color-tag-dark">
                        Doctor has joined the video call Please click on <b>'Join Video Call'</b>
                      </Tag>
                    </> : appointment?.patient_appt_wait_time !== 0 ? <>
                      <Tag className="doctor-primary-color-tag-light">
                      The doctor is attending to another patient and will be with you shortly.
                      </Tag>
                    </> :  <>
                      <Tag className="doctor-primary-color-tag-light">
                      Waiting for Doctor to join the video call 
                      </Tag>
                    </>
                  }


                  {(appointment?.enable_video || appointment?.enable_check_in) && (
                    <p className={"mt20"}>
                      <span
                        className="doctor-secondary-button border-radius5 cursor"
                        style={{ padding: "10px" }}
                        onClick={() => goToCall()}
                      >
                        Join Video Call
                      </span>
                    </p>
                  )}
                  <>
                    {" "}

                  </>
                </Col>
              </Row>
              <Row justify={"center"}>
                <Col
                  md={14}
                  xs={24}
                  className="white-background doctor-box-shadow  plr20 plud10 border-radius5 full-width mt20"
                >
                  <p
                    className="doctor-primary-color font16"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Health Records</b>
                  </p>
                  {RouteParams["source"] === "guest" ? (
                    ""
                  ) : (
                    <Text
                      underline
                      className="doctor-secondary-color font14 cursor mt-20"
                      onClick={() =>
                      (window.location.href =
                        "/patient/appointment/" + RouteParams["appointment_id"])
                      }
                    >
                      Add or View Health Records
                    </Text>
                  )}

                  <Divider
                    style={{
                      margin: "10px 0px",
                      borderTop: "1px solid #163B6D",
                      opacity: ".2",
                    }}
                  ></Divider>
                  <div>
                    <div className="left">
                      <p
                        className="doctor-primary-color font14 opacity05 fw6"
                        style={{ marginBottom: "0" }}
                      >
                        Symptoms :{" "}
                        {appointment && appointment?.notes?.length !== 0
                          ? appointment?.notes?.[0].note
                          : "No Symptoms Added"}{" "}
                      </p>
                    </div>
                    <div className="right">
                      <p
                        className="doctor-primary-color font14  fw6"
                        style={{ marginBottom: "0" }}
                      >
                        {" "}
                        {RouteParams["source"] !== "guest" && (
                          <Text
                            underline
                            className="doctor-secondary-color font14 cursor "
                            style={{ float: "right" }}
                            onClick={() =>
                            (window.location.href =
                              "/patient/appointment/" +
                              RouteParams["appointment_id"])
                            }
                          >
                            Change
                          </Text>
                        )}{" "}
                      </p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </Col>
              </Row>
              <Row justify={"center"}>
                <Col
                  md={14}
                  xs={24}
                  className="doctor-box-shadow doctor-top-border mt20"
                >
                  <Row>
                    <Col span={24} className="doctor-color-heading">
                      <Row>
                        <Col span={24}>
                          <p className="doctor-primary-color bold mb0 p10 font15">
                          Watch Health Videos/Article by Max Healthcare
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className="white-background pb0">
                      <Row gutter={24} className="margin0 wVideosContainer">
                        {doctor_props.map((item: any) => {
                          if (item.value_type === "video_link") {
                            return (
                              item?.value?.video_link !== null &&
                              item?.value?.video_link.map((_item: any) => {
                                let yTID = getYoutubeId(_item?.url);
                                return (
                                  <Col span={12} className={"mt15 paddingl10"}>
                                    {
                                      <iframe
                                        width="100%"
                                        height="180"
                                        src={
                                          yTID
                                            ? "//www.youtube.com/embed/" + yTID
                                            : _item?.url
                                        }
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    }
                                    
                             
                              <p className="doctor-primary-color" style={{ marginLeft: "10px" }}><b>{_item?.title}</b></p>
                           
                                  </Col>
                                );
                              })
                            );
                          }
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={2} xs={2} className="text-right">
            {appointment?.latest_status !== "pending" &&
            appointment?.latest_status !== "cancelled" &&
            appointment?.type === "thb" ? (
              <Badge count={chatCount}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className=" cursor"
                  onClick={() => {
                    setChat(true);
                    setChatCount(0);
                  }}
                  style={{ marginTop: "7px" }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1318 0.615234C10.7112 0.615234 10.2906 0.615234 9.87001 0.615234C9.81675 0.62707 9.76391 0.641864 9.70981 0.650741C9.20511 0.732322 8.69153 0.776705 8.19697 0.899288C3.10302 2.16316 -0.00382186 7.34757 1.28752 12.4314C1.88226 14.7731 3.18967 16.6351 5.18777 17.9882C7.18164 19.3383 9.39447 19.8328 11.7789 19.5285C12.946 19.3797 14.0488 19.012 15.0777 18.4354C15.1821 18.3771 15.2734 18.369 15.3892 18.4066C16.5668 18.79 17.7474 19.165 18.9259 19.5471C19.1724 19.627 19.4234 19.6646 19.6335 19.4997C19.781 19.3844 19.8795 19.2068 20 19.0568C20 18.9456 20 18.834 20 18.7228C19.9924 18.7131 19.9818 18.7047 19.978 18.6937C19.5858 17.4691 19.1964 16.2437 18.8 15.0208C18.7585 14.8927 18.7653 14.7938 18.8304 14.6763C19.3736 13.691 19.732 12.6423 19.8909 11.5268C19.9265 11.2787 19.9636 11.0305 20 10.7824C20 10.3496 20 9.91673 20 9.48389C19.9708 9.25225 19.9459 9.01976 19.9121 8.78897C19.6809 7.20934 19.1115 5.76371 18.1528 4.48505C16.588 2.39818 14.5138 1.14023 11.9349 0.724714C11.6686 0.681175 11.3998 0.651164 11.1318 0.615234ZM18.0979 17.7029C17.9901 17.6745 17.9254 17.6602 17.8628 17.6403C17.1705 17.418 16.4667 17.2248 15.7895 16.9623C15.2734 16.7623 14.8583 16.8363 14.3806 17.1064C12.6645 18.0757 10.8185 18.3542 8.88724 17.9565C6.85025 17.5367 5.22032 16.4627 4.00675 14.7799C2.64481 12.8908 2.20351 10.7833 2.66003 8.50661C3.06878 6.46793 4.14962 4.83758 5.83196 3.62359C7.72608 2.257 9.83831 1.81528 12.1222 2.27729C14.1608 2.68942 15.7937 3.76899 16.9988 5.45768C18.521 7.59062 18.8748 9.93618 18.16 12.4483C17.9748 13.0988 17.6921 13.7083 17.3391 14.2845C17.1836 14.5381 17.1493 14.7947 17.2516 15.0787C17.3683 15.4034 17.4646 15.7352 17.5703 16.064C17.7411 16.5958 17.9131 17.1288 18.0979 17.7029Z"
                    fill="#163B6D"
                  />
                  <path
                    d="M13.2855 10.1141C13.2855 10.6289 13.7006 11.0423 14.2167 11.0406C14.7312 11.0389 15.1416 10.6221 15.1387 10.1048C15.1357 9.59879 14.7236 9.1892 14.2167 9.18751C13.7006 9.18581 13.2855 9.59879 13.2855 10.1141Z"
                    fill="#163B6D"
                  />
                  <path
                    d="M10.5062 9.18751C9.9897 9.18539 9.57418 9.59795 9.57418 10.1132C9.57376 10.6285 9.98843 11.0419 10.5045 11.0406C11.0118 11.0394 11.4239 10.6298 11.4277 10.1242C11.4307 9.60683 11.0207 9.18962 10.5062 9.18751Z"
                    fill="#163B6D"
                  />
                  <path
                    d="M5.86362 10.1225C5.86658 10.6361 6.28505 11.0448 6.80328 11.0406C7.31559 11.0364 7.72265 10.6154 7.71673 10.0963C7.71082 9.59245 7.29488 9.18623 6.78637 9.1875C6.27153 9.18877 5.86066 9.60513 5.86362 10.1225Z"
                    fill="#163B6D"
                  />
                </svg>
              </Badge>
            ) : (
              ""
            )}
          </Col>
          {chat && (
            <ChatLayout
              allMessages={allMessages}
              appointment={appointment}
              isDrawer={true}
              show={chat}
              details={appointment?.doctor}
              type={"doctor"}
              loginUser={"patient"}
              setChat={(value: any) => {
                setChat(value);
                setAllMessages([]);
                setChatCount(0);
                markReadMessage();
              }}
            ></ChatLayout>
          )}
      </Row>
    );
  }
}

export default AppointmentWaitingPage;
