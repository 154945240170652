import { Redirect, Route, Switch } from "react-router-dom";
import PharmacyOrderQC from "../pages/order/Pharmacy/orderQc/order-qc.page";
import PharmacyStartQC from "../pages/order/Pharmacy/orderQc/start-qc.page";
import PharmacyOrderRxCreation from "../pages/order/Pharmacy/rxCreation/rx-creation.page";
import PharmacyOrderRxGeneration from "../pages/order/Pharmacy/rxCreation/rx-generation.page";
import OrderDesktopLayout from "../shared/layouts/order-desktop.layout";
import {
  ROUTE_PHARMACY_CREATE_QC,
  ROUTE_PHARMACY_CREATE_RX,
  ROUTE_PHARMACY_ORDER_QC,
  ROUTE_PHARMACY_RX_CREATION,
} from "../shared/routes/order.routes.constants";
import { ROUTE_PAGE_NOT_FOUND } from "../shared/routes/route.constants";

const ROUTES: any[] = [
  {
    name: "Pharmacy Order QC",
    route: ROUTE_PHARMACY_ORDER_QC,
    component: PharmacyOrderQC,
  },
  {
    name: "Start QC",
    route: ROUTE_PHARMACY_CREATE_QC(":id"),
    component: PharmacyStartQC,
  },
  {
    name: "Rx Creation",
    route: ROUTE_PHARMACY_RX_CREATION,
    component: PharmacyOrderRxCreation,
  },
  {
    name: "Rx Generation",
    route: ROUTE_PHARMACY_CREATE_RX(":id"),
    component: PharmacyOrderRxGeneration,
  },
];

const OrderRoutes = () => {
  const RoutesPermitted = ROUTES;
  return (
    <OrderDesktopLayout>
      <Switch>
        {RoutesPermitted.map((route: any) => {
          return <Route exact path={route.route} component={route.component} />;
        })}
        <Redirect to={ROUTE_PAGE_NOT_FOUND()} />
      </Switch>
    </OrderDesktopLayout>
  );
};
export default OrderRoutes;
