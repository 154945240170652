import {notification} from "antd";
import {_BASE_URL} from "./api-client";
import {CustomEvents, sendInternalEvent} from "./utilities";


// export const BASE_URL = 'http://localhost:3333/vault/v2/';
//
export const BASE_URL = (process.env.BASE_URL ||
    _BASE_URL
);

export async function Client(endpoint: string, {body, ...customConfig} = {} as any) {
    const headers: any = {'Content-Type': 'application/json'}
    if (localStorage.getItem('token')) {
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
    if (localStorage.getItem('account_id')) {
        headers['account_id'] = localStorage.getItem('account_id');
    }
    if (localStorage.getItem('application_id')) {
        headers['application_id'] = localStorage.getItem('application_id');
    }
    let application_slug;
    if (localStorage.getItem('application_slug')) {
        application_slug = localStorage.getItem('application_slug');
    }
    switch (application_slug) {
        case 'admin':
            headers['no_auth'] = 'enabled';
            break;
        default:
            headers['no_auth'] = 'disabled';
    }
    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    }
    if (body) {
        config.body = JSON.stringify(body)
    }
    const __BASE_URL = (localStorage.getItem("baseUrl") || BASE_URL).replace('v1/', 'v2/')
    
    let data
    try {
        sendInternalEvent(CustomEvents.REQUEST_SENT, {endpoint,});

        const response = await window.fetch(__BASE_URL + endpoint, config)

        if (response.ok) {
            sendInternalEvent(CustomEvents.REQUEST_SUCCESS, {endpoint,});

            if (response.status === 204) {
                return {
                    status: response.status,
                    headers: response.headers,
                    url: response.url,
                }
            }
            data = await response.json()
            // Return a result object similar to Axios
            return {
                status: response.status,
                data,
                headers: response.headers,
                url: response.url,
            }
        } else if (response.status === 401) {
            notification.error({message: 'Un Authorized'})
            window.localStorage.clear();
            window.location.href = '/';
        } else if (response.status === 403) {
            data = await response.json()
            notification.error({message: data.message || 'Forbidden'})
            return;

        } else if (response.status === 400) {
            data = await response.json()
            let message = data.message;
            if (data.errorDetails) {
                message = data.errorDetails.map((e: any) => `${e.error}`).join(', ')
            }
            notification.error({message: message || 'Forbidden'})
            return {
                status: response.status,
                data,
                headers: response.headers,
                url: response.url,
            };
        }

        console.log(response)
        notification.error({message: response.statusText || 'Something went wrong'})

        return Promise.reject(response.statusText)
    } catch (err: any) {
        notification.error({message: err.message ? err.message : data})
        return Promise.reject(err.message ? err.message : data)
    }
}

Client.get = function (endpoint: string, customConfig: any = {}) {
    let params: any = [];
    if (customConfig.params) {
        for (let p in customConfig.params) {
            let key = p;
            params.push(`${key}=${encodeURIComponent(customConfig.params[p])}`);
        }
    }

    if (customConfig.params) {
        return Client(endpoint + '?' + params.join('&'), {...customConfig, method: 'GET'})
    } else {
        delete customConfig.params
        return Client(endpoint, {...customConfig, method: 'GET'})
    }
}

Client.post = function (endpoint: string, body: any, customConfig = {}) {
    return Client(endpoint, {...customConfig, body, method: 'POST'})
}

Client.put = function (endpoint: string, body: any, customConfig = {}) {
    return Client(endpoint, {...customConfig, body, method: 'PUT'})
}

Client.patch = function (endpoint: string, body: any, customConfig = {}) {
    return Client(endpoint, {...customConfig, body, method: 'PATCH'})
}

Client.delete = function (endpoint: string, customConfig = {}) {
    return Client(endpoint, {...customConfig, method: 'DELETE'})
}

