import { EditFilled, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchBranches } from "../../../redux/reducers/branch.slice";
import { fetchDepartments } from "../../../redux/reducers/department.slice";
import { fetchDoctors } from "../../../redux/reducers/doctor.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import { DOCTOR_MODULE_ACTIONS_READ } from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import {
  ROUTE_DOCTOR_ACTION,
  ROUTE_DOCTOR_PROPERTIES,
  ROUTE_DOCTOR_SPECIALIZATION,
} from "../../../shared/routes/admin.routes.constants";
import { ROUTE_DOCTOR_SLOTS } from "../../../shared/routes/doctor.routes.constants";
import { Branch } from "../../../shared/types/branch.type";
const { Text, Title } = Typography;
const { Option } = Select;
type Props = {};

const DoctorList: React.FunctionComponent<Props> = () => {
  const { doctors, loading } = useSelector(
    (state: RootState) => state.doctor || {}
  );
  const { departments } = useSelector((state: RootState) => state.department);
  const { branches } = useSelector((state: RootState) => state.branch);
  const [searchQuery, setsearchQuery] = useState("");
  const [filters, setFilters] = useState({
    branch_id: null,
    search_column: "doctor",
    search_term: null,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const menu = (a: any) => (
    <Menu>
      <Menu.Item key="0" onClick={() => history.push(ROUTE_DOCTOR_SLOTS(a.id))}>
        Slots
      </Menu.Item>
      {/* <Menu.Item key="1">Services</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">VC BO Settings</Menu.Item> */}
      <Menu.Item
        key="1"
        onClick={() => history.push(ROUTE_DOCTOR_SPECIALIZATION(a?.id))}
      >
        Add Specialization
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() =>
          // history.push(ROUTE_DOCTOR_PROPERTIES(a?.id))
          history.push({
            pathname: ROUTE_DOCTOR_PROPERTIES(a?.id),
            state: a,
          })
        }
      >
        Add More Properties
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: "S.No",
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
    },
    {
      title: "Image",
      key: "image",
      render: (a: any, value: any, index: number) => (
        <Image
          style={{ width: "60px", height: "60px", borderRadius: "60px" }}
          src={a.profile_pic}
        ></Image>
      ),
    },
    {
      title: "Name",
      className: "capitalize",
      render: (a: any, value: any, index: number) => (
        <Text>
          {a.name}
          <br />
          <small>{a.designation}</small>
        </Text>
      ),
    },
    {
      title: "Department",
      dataIndex: "code",
    },
    {
      title: "Hospital",
      className: "capitalize",
      render: (a: any) => {
        return a.branches?.map((val: string) => {
          return (
            <>
              <Text>{val}</Text>
              <br />
            </>
          );
        });
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
    },
    {
      title: "EmailID",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "code",
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <Space size={"small"}>
              <Button
                shape="circle"
                type="primary"
                onClick={() => history.push(ROUTE_DOCTOR_ACTION(a.id, "edit"))}
                size={"middle"}
                icon={<EditFilled />}
              />
              <Dropdown overlay={menu(a)} trigger={["click"]}>
                <Button
                  shape="circle"
                  type="primary"
                  size={"middle"}
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            </Space>
          </Row>
        );
      },
    },
  ];
  const updateFilter = (evt: any, type: string) => {
    let filter = JSON.parse(JSON.stringify(filters));
    if (type === "doctor") {
      filter.search_term = evt;
    }
    if (type === "branch_id") {
      filter.branch_id = evt;
    }
    setFilters(filter);
  };
  useEffect(() => {
    let filter = filters
      ? Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => v != null)
        )
      : {};
    dispatch(fetchDoctors(filter));
  }, [filters]);

  useEffect(() => {
    // dispatch(fetchDoctors(filters));
    dispatch(fetchDepartments());
    dispatch(fetchBranches());
  }, [dispatch]);
  return (
    <PermissionComponent permission={DOCTOR_MODULE_ACTIONS_READ}>
      <div className={"col-md-12 main-container"}>
        <div className={"desktop-list-layout"}>
          <div className="title">
            <Row>
              <Col span={6}>
                <Title level={4}>Doctors</Title>
              </Col>
              <Col span={18} className="text-right">
                <Space size="small">
                  {/* <Select placeholder="Choose Department" onChange={(evt:any)=> searchDoctor(evt,"department")}>
                                    <Option value="">All</Option>
                                    {
                                        departments.map((department: Department) => {
                                            return <Option value={department.name}>{department.name}</Option>
                                        })
                                    }
                                </Select> */}
                  <Input
                    allowClear
                    placeholder="Search Doctor Name"
                    onChange={(evt: any) =>
                      updateFilter(evt.target.value, "doctor")
                    }
                  ></Input>
                  <Select
                    placeholder="Choose Hospital"
                    onChange={(evt: any) => updateFilter(evt, "branch_id")}
                    allowClear={true}
                  >
                    <Option value="">All</Option>
                    {branches.map((branch: Branch) => {
                      return (
                        <Option value={branch.id}>
                          <span className="capitalize">{branch.name}</span>
                        </Option>
                      );
                    })}
                  </Select>
                </Space>
              </Col>
            </Row>
          </div>
          {loading ? (
            <Skeleton></Skeleton>
          ) : (
            <Table
              loading={loading}
              locale={{
                emptyText: (
                  <>
                    <Space direction={"vertical"} size={10}>
                      <Text>No Doctor Added</Text>
                    </Space>
                  </>
                ),
              }}
              scroll={{ x: true }}
              dataSource={doctors}
              columns={columns}
            />
          )}
        </div>
      </div>
    </PermissionComponent>
  );
};

export default DoctorList;
