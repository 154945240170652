import React from "react";
// import Select from 'react-select';

// data contains text and

class MultiAutocompleteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.selectedOption || null,
    };
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      this.props.emitSelectedAutoCompleteData(this.state.selectedOption)
    );
  };

  getInputChangeData = (event) => {
    //console.log(event);
    if (this.props.emitInputChange) {
      this.props.emitInputChange(event);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (prevProps.selectedOption !== this.props.selectedOption) {
        this.setState({ selectedOption: this.props.selectedOption });
      }
    }
  }

  render() {
    const { selectedOption } = this.state;
    const { optionsData, isMulti, closeMenuOnSelect } = this.props;
    // emitSelectedAutoCompleteData
    return (
      <>
        {/* <Select
                    styles={{ zIndex: 222,textTransform: 'capitalize !important' }}
                    placeholder={this.props.placeholder ? this.props.placeholder : 'Select...'}
                    isMulti={isMulti ? true : false}
                    value={selectedOption}
                    closeMenuOnSelect={closeMenuOnSelect ? true : false}
                    onChange={this.handleChange}
                    onInputChange={this.getInputChangeData.bind(this)}
                    options={optionsData}
                    name={this.props.name||""}
                    isDisabled={this.props.disabled || false}
                /> */}
      </>
    );
  }
}

export default MultiAutocompleteComponent;
