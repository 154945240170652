import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Row, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointmentInstantVC } from "../../../redux/reducers/slot.slice";
import { RootState } from "../../../shared/constants";
import TableLayout from "../../../shared/layouts/table.layout";
var _ = require("lodash");
const { Title } = Typography;

function InstantVCDoctorSummaryPage() {
  const dispatch = useDispatch();
  const { instant_vc } = useSelector((state: RootState) => state.slot);
  const [filters, setFilters] = useState(null as any);
  const [status, setStatus] = useState([] as any);
  const [doctors, setDoctors] = useState([] as any);

  const [allVCApppintments, setAllVCAppointments] = useState([] as any);

  useEffect(() => {
    setAllVCAppointments(instant_vc);
    setStatus(_.groupBy(instant_vc, "practice_status"));
    setDoctors(
      _.groupBy(instant_vc, function (item: any) {
        return item?.doctor?.name;
      })
    );
  }, [instant_vc]);

  const headers = [
    { label: "Doctor Name", key: "doctor.name" },
    { label: "Live Status", key: "practice_status" },
    { label: "Last Active", key: "updated_at" },
    { label: "No. of Appointments", key: "appointment_count" },
    { label: "Pending Appointments", key: "pending_count" },
    { label: "Live Queue No.", key: "live_queue_no" },
  ];

  const columns: any = [
    {
      title: "Doctor Name",
      dataIndex: ["doctor"],
      key: "name",
      render: (key: any) => (
        <>
          {" "}
          <span className={"capitalize"}>{key.name}</span> <br></br>
          <span className={"sub-text"}>
            {" "}
            {key?.user?.country_code} {key?.user?.phone}
          </span>{" "}
        </>
      ),
    },
    {
      title: "Live Status",
      dataIndex: "practice_status",
      key: "practice_status",
      render: (key: any) =>
        key === "start" ? (
          <Tag color="green" className={"success-tag"}>
            Practice Started
          </Tag>
        ) : (
          <Tag color="red" className={"danger-tag"}>
            Practice Stopped
          </Tag>
        ),
    },
    {
      title: "Last Active",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (key: any, record: any) =>
        record?.practice_status === "stop"
          ? key && moment(key).format("LLL")
          : "",
    },
    {
      title: "No. of Appointments",
      dataIndex: "appointment_count",
      key: "appointment_count",
    },
    {
      title: "Pending Appointments",
      dataIndex: "pending_count",
      key: "pending_count",
    },
    {
      title: "Live Queue No.",
      dataIndex: "patient_live_queue",
      key: "patient_live_queue",
    },
  ];
  useEffect(() => {
    dispatch(fetchAppointmentInstantVC());
  }, []);

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    for (let key of Object.keys(all)) {
      if (all[key] == "" || all[key] == null || all[key] == undefined) {
      } else {
        filtersObj[key] = all[key];
      }
    }
    setFilters(filtersObj);
    FilterData(filtersObj);
  }

  const cleanify = (instant: any, filtersObj: any) => {
    let result: any = true;
    if (filtersObj?.doctor !== undefined) {
      result = instant?.doctor?.name === filtersObj?.doctor;
    }
    if (filtersObj?.status !== undefined) {
      result = result && instant?.practice_status === filtersObj?.status;
    }
    if (filtersObj?.date !== undefined) {
      result =
        result &&
        instant?.date === moment(filtersObj?.date).format("YYYY-MM-DD");
    }
    if (filtersObj?.phone !== undefined) {
      result =
        (result &&
          instant?.doctor?.user?.phone.indexOf(filtersObj?.phone) !== -1) ||
        instant?.doctor?.user?.first_name.indexOf(
          (filtersObj?.phone).toLowerCase()
        ) !== -1;
    }
    return result;
  };

  const FilterData = (filtersObj: any) => {
    const allAppointments = instant_vc.filter((instant: any) =>
      cleanify(instant, filtersObj)
    );
    setAllVCAppointments(allAppointments);
  };

  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {},
    formElements: [
      {
        label: "",
        type: "Select",
        name: "status",
        colSpan: 3,
        allowClear: true,
        placeholder: "Status",
        options:
          status &&
          Object.keys(status).map((item: any) => {
            return { name: item.toUpperCase(), value: item };
          }),
      },
      {
        label: "",
        type: "Select",
        name: "doctor",
        colSpan: 3,
        allowClear: true,
        placeholder: "Doctor",
        options:
          doctors &&
          Object.keys(doctors).map((item: any) => {
            return { name: item, value: item };
          }),
      },
      {
        label: "",
        type: "Input",
        name: "phone",
        colSpan: 6,
        placeholder: "Search by Patient Name/Phone",
        prefix: <SearchOutlined />,
      },
      {
        label: "",
        type: "DatePicker",
        name: "date",
        colSpan: 4,
        placeholder: "Search by date",
      },
      {
        label: "",
        type: "Button",
        name: (
          <>
            <CSVLink
              data={allVCApppintments}
              headers={headers}
              filename={"allVCAppointments.csv"}
            >
              <DownloadOutlined /> Download Data
            </CSVLink>
          </>
        ),
        colSpan: 6,
        flex: "none",
      },
    ],
  };

  return (
    <Row>
      <Col span={24}>
        <Title className="paddingcollapse" level={4}>
          Instant VC Doctors Summary
        </Title>
        <Row className={"padding10"}>
          <Col span={24}>
            <TableLayout
              loading={false}
              filters={filters}
              dataSource={allVCApppintments}
              columns={columns}
              formData={formElements}
              type="vcfilter"
              pagination={{ position: ["none", "none"] }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default InstantVCDoctorSummaryPage;
