import { Button, Checkbox, Col, message, Row, Select, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPermissions,
  updateAllPermissions,
} from "../../../redux/reducers/settings.slice";
import { RootState } from "../../../shared/constants";
var _ = require("lodash");
const { Option } = Select;
function DoctorPermissions() {
  const dispatch = useDispatch();
  const { loading, permissions, settingId } = useSelector(
    (state: RootState) => state.settings
  );
  const { profile } = useSelector((state: RootState) => state.profile);
  const [allSetting, setSettings] = useState([] as any);

  useEffect(() => {
    dispatch(
      fetchAllPermissions({ type: "user_permission", user_id: profile.id })
    );
  }, []);

  useEffect(() => {
    setSettings(permissions);
  }, [permissions]);

  function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const getchangesSettings = (value: any, key: any) => {
    let data = _.cloneDeep(allSetting);
    data[key] = value;
    setSettings(data);
  };

  const submitChanges = () => {
    (async function () {
      try {
        let response = await dispatch(
          updateAllPermissions({
            id: settingId,
            data: {
              settings: allSetting,
            },
          })
        );
        if (response.payload.status === 200) {
          message.success("Updated Successfully");
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <>
      <div className="fullHeightDiv">
        <Row>
          <Col span={24} className={"white-background padding20"}>
            <h1 className={"doctor-primary-color bold"}>Permissions</h1>
          </Col>

          <Col span={24} className={"padding20"}>
            <Row>
              <Col
                span={24}
                className={
                  "white-background   doctor-box-shadow border-radius5"
                }
                style={{ padding: "20px 20px" }}
              >
                {loading ? (
                  <Skeleton></Skeleton>
                ) : (
                  <>
                    <Row gutter={40}>
                      <Col span={24}>
                        <Checkbox
                          className={"doctor-primary-color"}
                          style={{ marginBottom: "10px" }}
                          onChange={(evt: any) =>
                            getchangesSettings(
                              evt.target.checked,
                              getKeyByValue(
                                allSetting,
                                allSetting?.allow_vc_chat
                              )
                            )
                          }
                          checked={allSetting?.allow_vc_chat}
                        >
                          Enable patient chat for video consultation
                        </Checkbox>
                      </Col>
                      <Col span={4} className="mt10">
                        <label className="doctor-primary-color font12">
                          Start Chat
                        </label>
                        <br />
                        <Select
                          size="large"
                          style={{ width: "100px" }}
                          value={
                            allSetting?.allow_chat_time_in_mins_before_call
                          }
                          onChange={(evt: any) =>
                            getchangesSettings(
                              evt,
                              getKeyByValue(
                                allSetting,
                                allSetting?.allow_chat_time_in_mins_before_call
                              )
                            )
                          }
                        >
                          <Option value={1}>1</Option>
                          <Option value={2}>2</Option>
                          <Option value={4}>4</Option>
                          <Option value={8}>8</Option>
                          <Option value={12}>12</Option>
                          <Option value={24}>24</Option>
                          <Option value={49}>48</Option>
                        </Select>
                        <br />
                        <small
                          style={{ color: "#163B6D ", opacity: 0.5 }}
                          className=" font12"
                        >
                          <i>Before Call</i>
                        </small>
                      </Col>
                      <Col span={4} className="mt10">
                        <label className="doctor-primary-color font12">
                          End Chat
                        </label>
                        <br />
                        <Select
                          style={{ width: "100px" }}
                          size="large"
                          value={allSetting?.allow_chat_time_in_mins_after_call}
                          onChange={(evt: any) =>
                            getchangesSettings(
                              evt,
                              getKeyByValue(
                                allSetting,
                                allSetting?.allow_chat_time_in_mins_after_call
                              )
                            )
                          }
                        >
                          <Option value={1}>1</Option>
                          <Option value={2}>2</Option>
                          <Option value={4}>4</Option>
                          <Option value={8}>8</Option>
                          <Option value={12}>12</Option>
                          <Option value={24}>24</Option>
                          <Option value={49}>48</Option>
                        </Select>
                        <br />
                        <small
                          style={{ color: "#163B6D ", opacity: 0.5 }}
                          className=" font12"
                        >
                          <i>After Call</i>
                        </small>
                      </Col>
                    </Row>
                    <Row gutter={40}>
                      {" "}
                      <Col span={8} className="mt10">
                        <label className="doctor-primary-color font12">
                          Message limit for patients per video consultation
                        </label>{" "}
                        <br />
                        <Select
                          size="large"
                          style={{ width: "100px" }}
                          value={allSetting?.chat_message_limit_per_appointment}
                          onChange={(evt: any) =>
                            getchangesSettings(
                              evt,
                              getKeyByValue(
                                allSetting,
                                allSetting?.chat_message_limit_per_appointment
                              )
                            )
                          }
                        >
                          <Option value={1}>1</Option>
                          <Option value={2}>2</Option>
                          <Option value={5}>5</Option>
                          <Option value={10}>10</Option>
                          <Option value={15}>15</Option>
                          <Option value={20}>20</Option>
                          <Option value={25}>25</Option>
                          <Option value={30}>30</Option>
                          <Option value={35}>35</Option>
                          <Option value={40}>40</Option>
                          <Option value={45}>45</Option>
                          <Option value={50}>50</Option>
                        </Select>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24} className={"padding20 bottomFixedButton"}>
            <Button
              className={"doctor-secondary-button"}
              block
              size={"large"}
              onClick={() => submitChanges()}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DoctorPermissions;
