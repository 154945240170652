import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewDepartment } from "../../../../redux/reducers/department.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Department } from "../../../../shared/types/department.type";
import DepartmentForm from "./department-form.page";

const DepartmentCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <DesktopFormLayout
      title={"Department Create"}
      subtitle={"Create new department for .."}
    >
      <DepartmentForm
        onSubmit={async (values: Department) => {
          const response = await dispatch(addNewDepartment(values));
          history.goBack();
        }}
      />
    </DesktopFormLayout>
  );
};

export default DepartmentCreate;
