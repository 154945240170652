import {
  HMSRoomState,
  selectLocalPeerRoleName,
  selectPeerScreenSharing,
  selectPeerSharingAudio,
  selectPeerSharingVideoPlaylist,
  selectRoomState,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import React, { Suspense, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { UI_MODE_ACTIVE_SPEAKER, UI_SETTINGS } from "../common/constants";
import { useBeamAutoLeave, useLeaveOnDuplicate } from "../common/hooks";
import { useUISettings } from "../components/AppData/useUISettings";
import { AppContext } from "../components/context/AppContext";
import FullPageProgress from "../components/FullPageProgress";
import { MainGridView } from "./mainGridView";
import ScreenShareView from "./screenShareView";

const HLSView = React.lazy(() => import("./HLSView"));
const ActiveSpeakerView = React.lazy(() => import("./ActiveSpeakerView"));

export const ConferenceMainView = ({ role }) => {
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const peerSharing = useHMSStore(selectPeerScreenSharing);
  const peerSharingAudio = useHMSStore(selectPeerSharingAudio);
  const peerSharingPlaylist = useHMSStore(selectPeerSharingVideoPlaylist);
  const isAudioOnly = useUISettings(UI_SETTINGS?.isAudioOnly);
  const roomState = useHMSStore(selectRoomState);
  const { appointment } = useSelector((state) => state.appointment);
  const { currentApplication } = useSelector((state) => state.auth);
  useBeamAutoLeave();
  useLeaveOnDuplicate(appointment, currentApplication);

  const hmsActions = useHMSActions();

  const { audioPlaylist, videoPlaylist, uiViewMode, HLS_VIEWER_ROLE } =
    useContext(AppContext);

  useEffect(() => {
    // set list only when room state is connected
    if (roomState !== HMSRoomState.Connected) {
      return;
    }
    if (videoPlaylist.length > 0) {
      hmsActions.videoPlaylist.setList(videoPlaylist);
    }
    if (audioPlaylist.length > 0) {
      hmsActions.audioPlaylist.setList(audioPlaylist);
    }
  }, [roomState, hmsActions]);

  if (!localPeerRole) {
    // we don't know the role yet to decide how to render UI
    return null;
  }

  let ViewComponent;
  if (localPeerRole === HLS_VIEWER_ROLE) {
    ViewComponent = HLSView;
  } else if (
    ((peerSharing && peerSharing.id !== peerSharingAudio?.id) ||
      peerSharingPlaylist) &&
    !isAudioOnly
  ) {
    ViewComponent = ScreenShareView;
  } else if (uiViewMode === UI_MODE_ACTIVE_SPEAKER) {
    ViewComponent = ActiveSpeakerView;
  } else {
    ViewComponent = MainGridView;
  }

  return (
    ViewComponent && (
      <Suspense fallback={<FullPageProgress />}>
        <ViewComponent showStats={true} role={role} />
      </Suspense>
    )
  );
};
