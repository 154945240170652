import { Amplify, Auth } from "aws-amplify";

const AWS_Cognito_Config = {
  aws_app_analytics: "enable",
  aws_cognito_identity_pool_id:
    "ap-south-1:3276cf48-0d6b-48f7-9f9a-032c5956c245",
  aws_cognito_region: "ap-south-1",
  aws_mandatory_sign_in: "disable",
  aws_project_region: "ap-south-1",
  aws_resource_name_prefix: "",
  aws_sign_in_enabled: "disable",
  aws_user_pools: "enable",
  aws_user_pools_id: "ap-south-1_NtSEB0sWi",
  aws_user_pools_mfa_type: "OPTIONAL",
  aws_user_pools_web_client_id: "5u4g1d7uj7vk4uqbgvit8tn5p6",
};
Amplify.configure(AWS_Cognito_Config);
Amplify.Logger.LOG_LEVEL = "DEBUG";

currentSession();
function currentSession() {
  setTimeout(() => {
    if (localStorage.getItem("token") && localStorage.getItem("auth-type")) {
      localStorage.getItem("auth-type") === "cognito" && Auth.currentSession();
    }
    currentSession();
  }, 50 * 60 * 60);
}
