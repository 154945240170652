import { Button, Col, Input, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { createUpdateDoctorProps, fetchDoctorPropsById } from "../../../../redux/reducers/doctor.slice";
import { DOCTOR_MODULE_ACTIONS_CREATE_DOCTOR_PROPS } from "../../../../shared/components/permission.constants";
import { RootState } from "../../../../shared/constants";

const DoctorProperties: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const { Title } = Typography;
  const { TextArea } = Input;
  const { loading } = useSelector((state: RootState) => state.doctor);
  const [videos, setVideos] = useState('' as any);
  const [workExp, setWorkExp] = useState('' as string);
  const [edu, setEdu] = useState('' as string);
  const [speciality, setSpeciality] = useState('' as string);
  const [awards, setAwards] = useState('' as string);
  const [biographical, setBiographical] = useState('' as string);
  const [memberships, setMemberships] = useState('' as string);
  const [publication, setPublication] = useState('' as string);
  const [doctorDetails, setDoctorDetails] = useState({} as any);
  const [isPermission, setIsPermission] = useState(null as any);
  const location = useLocation();
  const permissions = JSON.parse(localStorage.getItem("persist:root") as any)
  const allPermissions = permissions && JSON.parse(permissions?.auth)
  const myPermissions = allPermissions?.myPermissions?.scope_values?.actions

  useEffect(() => {
    fetchDetails();
    setDoctorDetails(location?.state);
    let per = myPermissions.indexOf(DOCTOR_MODULE_ACTIONS_CREATE_DOCTOR_PROPS) > -1 || myPermissions.indexOf("doctor_module") > -1;
    setIsPermission(per);
  }, []);

  const fetchDetails =() => {
    if (RouteParams["id"]) {
      dispatch(fetchDoctorPropsById(RouteParams["id"])).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          let docProps = val?.payload?.data?.data
          docProps.forEach( (ele:any) => {
            if(ele.value_type === "video_link"){
              let vd = ele?.value?.video_link.map((vd:any) => {return vd.url} );
              setVideos(vd.join('|'))
            }
            if(ele.code === "work_experience") setWorkExp(ele?.value?.html)
            if(ele.code === "education_and_training") setEdu(ele?.value?.html)
            if(ele.code === "speciality_interest") setSpeciality(ele?.value?.html)
            if(ele.code === "awards_information") setAwards(ele?.value?.html)
            if(ele.code === "biographical_sketch") setBiographical(ele?.value?.html)
            if(ele.code === "memberships") setMemberships(ele?.value?.html)
            if(ele.code === "research_and_publication") setPublication(ele?.value?.html)
          });
        }
      })
    }
  }

  const handleChange = (e:any, code:string) => {
    if(code === 'work_experience'){
      setWorkExp(e.target.value)
    } else if(code === 'education_and_training'){
      setEdu(e.target.value)
    } else if(code === 'speciality_interest'){
      setSpeciality(e.target.value)
    } else if(code === 'awards_information'){
      setAwards(e.target.value)
    } else if(code === 'biographical_sketch'){
      setBiographical(e.target.value)
    } else if(code === 'memberships'){
      setMemberships(e.target.value)
    } else if(code === 'research_and_publication'){
      setPublication(e.target.value)
    }
  }

  const handleChangeVidoes = (e:any) => {
        setVideos(e.target.value);
  }

  const handleSubmit = async () => {
    let body = [{
      "work_experience" : workExp,
      "education_and_training" : edu,
      "speciality_interest" : speciality,
      "awards_information" : awards,
      "biographical_sketch" : biographical,
      "memberships" : memberships,
      "research_and_publication" : publication,
      "video_link" : videos,
    }]
    // console.log('eeeee 55555', body);
    let data = {
      id: RouteParams["id"],
      body : body
    }
    await dispatch(createUpdateDoctorProps(data));
    await fetchDetails()
  }

  return (
    <div className={"col-md-12 main-container"}>
      <Row>
        <Col span={24}>
          <Title level={4}>Doctor Properties </Title>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Work Experience</Title>
            <TextArea disabled={!isPermission} onChange={(e)=> {handleChange(e, 'work_experience')}} rows={5} value={ workExp }/>
          </Row>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Education & Training</Title>
            <TextArea disabled={!isPermission} onChange={(e)=> {handleChange(e, 'education_and_training')}} rows={5} value={edu}/>
          </Row>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Speciality interest</Title>
            <TextArea disabled={!isPermission} onChange={(e)=> {handleChange(e, 'speciality_interest')}} rows={5} value={ speciality}/>
          </Row>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Awards</Title>
            <TextArea disabled={!isPermission} onChange={(e)=> {handleChange(e, 'awards_information')}} rows={5} value={ awards }/>
          </Row>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Biographical Sketch</Title>
            <TextArea disabled={!isPermission} onChange={(e)=> {handleChange(e, 'biographical_sketch')}} rows={5} value={ biographical }/>
          </Row>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Memberships</Title>
            <TextArea disabled={!isPermission} onChange={(e)=> {handleChange(e, 'memberships')}} rows={5} value={ memberships }/>
          </Row>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Research & Publication</Title>
            <TextArea disabled={!isPermission} onChange={(e)=> {handleChange(e, 'research_and_publication')}} rows={5} value={ publication }/>
          </Row>
          <Row className="mt20" gutter={20}>
            <Title level={5}>Youtube Links <span style={{fontSize:'12px'}}>(add multiple links seperated by '|' )</span></Title>
            <TextArea disabled={!isPermission} rows={2} onChange={(e)=> {handleChangeVidoes(e)}} className="mt10" value={videos}/>
          </Row>
        </Col>
      </Row>
      <Row className="mt20">
        <Button type="primary" size={"middle"} />
          <Button disabled={!isPermission} loading={loading} type="primary" onClick={handleSubmit} htmlType="submit"> Submit </Button>
      </Row>
    </div>
  );
};

export default DoctorProperties;
