import { CrossIcon } from "@100mslive/react-icons";
import { Flex, IconButton, Text } from "@100mslive/react-ui";
import React from "react";
import { useSelector } from "react-redux";
import { useToggleChat } from "../AppData/useChatState";

export const ChatHeader = React.memo(
  ({ selection, selectorOpen, onToggle }) => {
    const toggleChat = useToggleChat();
    const { appointment } = useSelector((state) => state.appointment);
    const { currentApplication } = useSelector((state) => state.auth);
    return (
      <Flex
        onClick={onToggle}
        align="center"
        css={{
          bg: "$menuBg",
          color: "$textPrimary",
          h: "$16",
          borderTopLeftRadius: "$2",
          borderTopRightRadius: "$2",
          pl: "$8",
          pr: "$4",
        }}
      >
        <Flex align="center" css={{ cursor: "pointer" }}>
          {/* <PeopleIcon /> */}
          <Text css={{ mx: "$2", textTransform: "capitalize" }}>
            {currentApplication === "doctor"
              ? appointment?.patient?.pii?.name
              : appointment?.doctor?.name}
          </Text>
          {/* {selectorOpen ? (
            <ChevronUpIcon width={18} height={18} />
          ) : (
            <ChevronDownIcon width={18} height={18} />
          )} */}
        </Flex>
        <IconButton
          css={{ ml: "auto" }}
          onClick={(e) => {
            e.stopPropagation();
            selectorOpen ? onToggle() : toggleChat();
          }}
          data-testid="close_chat"
        >
          <CrossIcon />
        </IconButton>
      </Flex>
    );
  }
);
