import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchAppointmentById,
  setVideoRoom,
  validateAppointmentCallToken,
} from "../../../redux/reducers/appointment.slice";
import PreviewScreen from "./components/PreviewScreen";

interface Props {
  appointment: string;
  participant_id?: string;
  id: string;
  source: string;
  onClose: () => void;
}
const VideoChat: React.FunctionComponent<Props> = (Params: Props) => {
  const dispatch = useDispatch();
  const Params1: any = useParams();
  const [isConnected, setisConnected] = useState(true);
  const [token, setToken] = useState(null as any);
  const [username, setUserName] = useState(null as any);
  const { currentApplication } = useSelector((state: any) => state.auth);

  useEffect(() => {
    window.onpopstate = function () {
      if (currentApplication === "doctor") {
        dispatch(setVideoRoom(null));
        return;
      }
    };
  }, [window]);

  useEffect(() => {
    (async function () {
      try {
        dispatch(
          fetchAppointmentById(Params1["appointment"] || Params["appointment"])
        );
        var response;
        if (Params1["source"] === "guest") {
          response = await dispatch(
            validateAppointmentCallToken({
              room_id: Params1["id"],
              participant_id: Params1["participant_id"],
            })
          );
        } else if (Params1["source"] === "patient") {
          response = await dispatch(
            validateAppointmentCallToken({
              room_id: Params1["id"],
              source: Params1["source"],
            })
          );
        } else {
          response = await dispatch(
            validateAppointmentCallToken({
              room_id: Params["id"],
              source: Params["source"],
            })
          );
        }

        if (response.payload.status === 200) {
          setToken(response?.payload?.data?.data.token);
          setUserName(response?.payload?.data?.data.identity);
          setisConnected(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  if (isConnected) {
    return (
      <Row
        justify={"center"}
        style={{ height: "100vh", backgroundColor: "black" }}
      >
        {" "}
        <Col span={24} className={"text-center mt100"}>
          <Spin className={"mt200"}></Spin>
          <p className={"white-text"}>Getting you in ..</p>
        </Col>{" "}
      </Row>
    );
  } else {
    return (
      <Row
        justify={"center"}
        style={{ height: "100vh", backgroundColor: "black" }}
      >
        {" "}
        <Col span={24} className={"text-center"}>
          <PreviewScreen
            token={token}
            room_id={Params["id"] || Params1["id"]}
            loginType={Params1["source"] || Params["source"]}
            username={username}
            appointmentId={Params1["appointment"] || Params["appointment"]}
          ></PreviewScreen>
        </Col>
      </Row>
    );
  }
};

export default VideoChat;
