import { Avatar, Space, Typography, Divider } from "antd";
import { ReactComponent as BackIcon } from "../../assets/icons/backicon.svg";
import {
  getAge,
  getFullName,
  getGender,
  getInitials,
} from "../Utils/utilities";
import moment from "moment";
const { Text } = Typography;
type Props = {
  details?: any;
  onBack?: (values: any) => void;
  showBack?: any;
  type?: any;
  showImage?: any;
  headName?: any;
};

function PatientNameCard({
  headName,
  details,
  onBack,
  showBack,
  type,
  showImage,
}: Props) {
  if (type === "doctor") {
    return (
      <>
        <Space align="center" className="pl10 PatientNameCard">
          {
            showBack && (
              <BackIcon
                style={{ fontSize: "15px" }}
                className="cursor"
                onClick={() => onBack && onBack(false)}
              />
            )
            // <LeftOutlined style={{ fontSize: "20px" }} onClick={() => onBack && onBack(false)} className="cursor" />
          }
          <p className="doctor-primary-color bold cursor mt15 capitalize">
            {getFullName(details)}

            <small
              style={{ fontSize: "16px" }}
              className="sub-text dateColors light-text"
            >
              {details?.age}
              {details?.birth_year && getAge(details?.birth_year)}
              {details?.birth_year && details?.genderMaster?.name && ","}
              {/* {details?.genderMaster?.name
                ? getGender(details?.genderMaster?.name)
                : ""}
              ) */}
            </small>
            {details?.user?.phone &&
              (details?.reg_no || details?.user?.source_id) && (
                <>
                  <br />
                  <Text className="sub-text light-text bold font12 cursor">
                    <a style={{ color: "#23BCB5" }} href={details?.user?.phone}>
                      +91-{details?.user?.phone}
                    </a>
                  </Text>
                  <small
                    className="max-subHead-color 1"
                    id="maxIDDoctor1"
                    style={{ fontSize: "14px" }}
                  >
                    {" "}
                    | UHID:
                    <span className={"capitalize"}>
                      {details?.reg_no || details?.user?.source_id}
                    </span>
                  </small>
                </>
              )}
          </p>
        </Space>
      </>
    );
  }
  if (type === "relationship") {
    return (
      <Space align="center">
        {" "}
        {showBack && (
          // <LeftOutlined style={{ fontSize: "20px" }} onClick={() => onBack && onBack(false)} className="cursor" />}
          <BackIcon
            style={{ fontSize: "15px" }}
            className="cursor"
            onClick={() => onBack && onBack(false)}
          />
        )}
        {showImage && (
          <Avatar
            shape={"square"}
            className="user-frame"
            size={"large"}
            src={""}
          >
            {" "}
            {details?.name && getInitials(details?.name)}
          </Avatar>
        )}
        <p className="doctor-primary-color bold cursor mt10 capitalize">
          {details?.name}{" "}
          <small className="sub-text dateColors light-text">
            ({getGender(details?.gender)}
            {details?.gender && details?.dob && ","}
            {details?.dob ? getAge(details.dob) : ""})
          </small>
          <br />
          <Text className="sub-text light-text  font12 cursor">
            {details?.relationship || "+91 " + details?.mobile}
          </Text>
          <small className="max-subHead-color 2" id="maxIDDoctor2">
            {details?.uhid && (
              <>
                | UHID: <span className={"capitalize "}>{details.uhid}</span>
              </>
            )}
          </small>
        </p>
      </Space>
    );
  }
  if (type === "ipd") {
    return (
      <>
        <Space align="center">
          {showBack && (
            // <LeftOutlined style={{ fontSize: "20px" }} onClick={() => onBack && onBack(false)} className="cursor" />
            <BackIcon
              style={{ fontSize: "15px" }}
              className="cursor"
              onClick={() => onBack && onBack(false)}
            />
          )}
          {showImage && (
            <Avatar
              shape={"square"}
              className="user-frame"
              size={"large"}
              src={""}
            >
              {details?.name && getInitials(details?.name)}{" "}
            </Avatar>
          )}
          <p className="doctor-primary-color bold cursor mt10 capitalize">
            {details?.name}{" "}
            {
              <small className="sub-text dateColors light-text">
                ({(details?.age).split(" ")[0] + "yrs"},
                {getGender(details?.gender)})
              </small>
            }
            <br />
            {/* <small className="max-subHead-color font14 3" id="maxIDDoctor3">  
                {details?.max_id && <>| UHID: <span
                        className={"capitalize font14"}>{details.max_id}</span></>}
                </small> */}
            {/* <Text className="sub-text light-text bold font12 cursor">
              <a style={{ color: "#23BCB5" }} href={details?.user?.phone}>
                +91-{details?.user?.phone}
              </a>
            </Text> */}
            <small className="max-subHead-color font14">
              <span className={"capitalize "}> UHID: {details?.max_id}</span>
            </small>{" "}
            <span className="max-subHead-color padding10">|</span>
            <small className="max-subHead-color font14">
              {" "}
              {moment(details?.admission_date).format("Do MMM' YYYY")}
              {details?.room_no && `| Room: ${details?.room_no}`}
            </small>
            {details?.bed_no && (
              <span className="sub-text dateColors">
                {" "}
                | Bed no: {details?.bed_no}
              </span>
            )}
          </p>
        </Space>
      </>
    );
  } else {
    return (
      <Space align="center" size={"middle"}>
        {showBack && (
          <BackIcon
            style={{ fontSize: "15px" }}
            className="cursor"
            onClick={() => onBack && onBack(false)}
          />
        )}
        {showImage && (
          <Avatar shape={"square"} className="user-frame" size={"large"}>
            {" "}
            {details && getInitials(details?.first_name)}{" "}
            {details && getInitials(details?.last_name)}{" "}
          </Avatar>
        )}
        <p className="doctor-primary-color nnn  cursor mt15 capitalize">
          {headName === "all" || headName === "doctor" ? (
            <>
              <span className="bold">Max Records </span>
            </>
          ) : (
            <>
              <span style={{ display: "block" }}>
                <span style={{ fontWeight: 600 }}>
                  {details?.pii !== undefined
                    ? getFullName(details?.pii) + " "
                    : getFullName(details) + " "}
                </span>

                <small
                  style={{ fontWeight: 400 }}
                  className="sub-text dateColors light-text"
                >
                  (
                  {details?.age_in_ymd ? (
                    details?.age_in_ymd
                  ) : details && details?.dob ? (
                    <>{getAge(details?.dob || details?.birth_year) + ","}</>
                  ) : (
                    ""
                  )}
                  ,{getGender(details?.gender || details?.genderMaster?.name)})
                </small>
              </span>

              {(details?.pii?.mobile || details?.mobile) && details?.uhid && (
                <>
                  <span
                    style={{
                      display: "block",
                      marginTop: "-3px",
                      fontSize: "14px ",
                      fontWeight: 400,
                    }}
                  >
                    <Text className="sub-text phoneNumberColor mt-5 font14 cursor">
                      <span>
                        +91-
                        {details?.mobile === undefined
                          ? details?.pii?.mobile
                          : details?.mobile}
                      </span>
                    </Text>
                    <span className="max-subHead-color padding10">|</span>
                    <small className="max-subHead-color font14">
                      <span className={"capitalize "}>
                        UHID: {details?.uhid}
                      </span>
                    </small>
                  </span>
                </>
              )}
            </>
          )}
        </p>
      </Space>
    );
  }
}

export default PatientNameCard;
