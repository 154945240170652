import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchMyPermissions,
  fetchSelf,
  setToken,
} from "../../../redux/reducers/auth.slice";
import { fetchProfile } from "../../../redux/reducers/profile.slice";
import { RootState } from "../../constants";
import { ROUTE_ADMIN_DASHBOARD } from "../../routes/admin.routes.constants";
//import {useEffect, useState} from "react";
import { ROUTE_CRM_TICKET_LIST } from "../../routes/crm.routes.constants";

export const SignInButton = () => {
  const { instance } = useMsal();
  const { account } = useSelector((state: RootState) => state.auth);
  //const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();

  return (
    <Button
      className="submit doctor-secondary-button mt20"
      size={"large"}
      block
      onClick={() => {
        // @ts-ignore
        instance
          .loginPopup()
          .then(async (a) => {
            await dispatch(setToken(a.idToken));
            await dispatch(fetchSelf());
            await dispatch(fetchMyPermissions());
            const response = await dispatch(fetchProfile("users"));
            if (response.payload.status === 200) {
              if (RouteParams["source"] === "admin") {
                (async function () {
                  try {
                    await dispatch(fetchSelf());
                    await dispatch(fetchMyPermissions());
                    const response = await dispatch(fetchProfile("users"));
                    if (response.payload.status === 200) {
                      window.location.href = ROUTE_ADMIN_DASHBOARD(
                        account?.slug
                      );
                    }
                  } catch (e) {
                    console.error(e);
                  }
                })();
              }
              if (RouteParams["source"] === "crm") {
                (async function () {
                  try {
                    await dispatch(fetchSelf());
                    await dispatch(fetchMyPermissions());
                    const response = await dispatch(fetchProfile("users"));
                    if (response.payload.status === 200) {
                      window.location.href = ROUTE_CRM_TICKET_LIST;
                    }
                  } catch (e) {
                    console.error(e);
                  }
                })();
              }
            }
          })
          .catch((e) => {
            console.error("login failed", e);
          });
      }}
    >
      Microsoft Sign in
    </Button>
  );
};
