import React from 'react';
import { Tabs, Typography } from 'antd';
import RxTable from './components/rx-table.component';
const { Title } = Typography
type Props = {}


const PharmacyOrderRxCreation: React.FunctionComponent<Props> = () => {


    return (
      <div className={'col-md-12 main-container pd20'}>
        <Title level={2}>New Rx Creation</Title>
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Open" key="1">
                <RxTable />
            </Tabs.TabPane>
            <Tabs.TabPane tab="History" key="2">
                <RxTable />
            </Tabs.TabPane>
        </Tabs>
      </div>
    );
};

export default PharmacyOrderRxCreation;

