import { Button, Checkbox, Col, message, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllNotificationSettings,
  updateNotificationSettings,
} from "../../../redux/reducers/settings.slice";
import { RootState } from "../../../shared/constants";
var _ = require("lodash");

function DoctorNotification() {
  const dispatch = useDispatch();
  const { loading, settings } = useSelector(
    (state: RootState) => state.settings
  );
  const [allSetting, setSettings] = useState([] as any);
  useEffect(() => {
    dispatch(fetchAllNotificationSettings());
  }, []);

  useEffect(() => {
    setSettings(settings ? settings : []);
  }, [settings]);

  const getchangesSettings = (checked: any, item: any) => {
    let data = _.cloneDeep(allSetting);
    var notifications = _.find(data, { type: item.type });
    notifications.status = checked === true ? "active" : "disable";
    setSettings(data ? data : []);
  };

  const submitChanges = () => {
    (async function () {
      try {
        let Preferences = [] as any;
        allSetting.map((item: any) => {
          return Preferences.push({
            type: item.type,
            notification_status: item.status,
          });
        });
        let response = await dispatch(
          updateNotificationSettings({ preferences: Preferences })
        );
        if (response.payload.status === 201) {
          message.success("Updated Successfully");
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <div className="fullHeightDiv">
      <Row>
        <Col span={24} className={"white-background padding20"}>
          <h1 className={"doctor-primary-color bold"}>Notifications</h1>
        </Col>

        <Col span={24} className={"padding20"}>
          <Row>
            <Col
              span={24}
              className={
                "white-background padding20  doctor-box-shadow border-radius5"
              }
              style={{ paddingBottom: "20px", paddingTop: "5px" }}
            >
              <Row className="mt10">
                {loading ? (
                  <Skeleton></Skeleton>
                ) : (
                  allSetting &&
                  allSetting.length > 0 &&
                  allSetting.map((item: any) => {
                    return (
                      <Col span={24} style={{ marginBottom: "15px" }}>
                        <Checkbox
                          className={"doctor-primary-color"}
                          onChange={(evt) =>
                            getchangesSettings(evt.target.checked, item)
                          }
                          checked={item.status === "active" ? true : false}
                        >
                          {item.name}
                        </Checkbox>
                      </Col>
                    );
                  })
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="padding20 bottomFixedButton">
          <Button
            className={"doctor-secondary-button"}
            block
            size={"large"}
            onClick={() => submitChanges()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default DoctorNotification;
