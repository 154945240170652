const VideoCircleIcon =({height="20" , width="20",color='#23BCB5'}) =>(
    <>
       
        <svg  width={width} height={height} viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6 0.763184H21.0153C31.4317 0.763184 39.8855 9.21706 39.8855 19.6334C39.8855 30.0498 31.4317 38.5037 21.0153 38.5037H19.6C9.18362 38.5037 0.729736 30.0498 0.729736 19.6334C0.729736 9.19819 9.18362 0.763184 19.6 0.763184Z" fill="#00B5AD" />
                            <path d="M23.3552 13.3687H11.2404C10.429 13.3687 9.78735 14.0291 9.78735 14.8217V24.4266C9.78735 25.238 10.4478 25.8796 11.2404 25.8796H23.3552C24.1666 25.8796 24.8082 25.2191 24.8082 24.4266V14.8217C24.8271 14.0103 24.1666 13.3687 23.3552 13.3687Z" fill="white" />
                            <path d="M25.7896 21.6144L30.8089 24.3507V14.9155L25.7896 17.6517V21.6144Z" fill="white" />
                          </svg>
    </>
);

export default VideoCircleIcon;