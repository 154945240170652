import { Card, Col, Divider, Row, Space, Tag, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAppointmentPaymentRecipet } from "../../../../redux/reducers/appointment.slice";

import {
  formatOnlyDate,
  formatTimes,
  secondsToHourMins,
} from "../../../../shared/Utils/utilities";
const { Text } = Typography;

type Props = {
  appointment?: any;
  ipd_status?: any;
};

const AppointmentDetailsIPD: React.FunctionComponent<Props> = ({
  appointment,
  ipd_status,
}) => {
  const dispatch = useDispatch();
  const RouteParams: any = useParams();

  const viewReceipt = () => {
    (async function () {
      try {
        let response: any;
        if (appointment?.type === "historical") {
          response = await dispatch(
            fetchAppointmentPaymentRecipet({
              external_appointment_id: RouteParams["id"],
            })
          );
        } else {
          response = await dispatch(
            fetchAppointmentPaymentRecipet({
              appointment_id: appointment.id,
              patient_id: appointment?.patient_id,
            })
          );
        }
        if (response.payload.status === 200) {
          window.open(response?.payload?.data?.data?.signed_url);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };
  return (
    <Card className="noborder">
      {appointment?.appointment_date && (
        <Row>
          <Col span={12}>
            <Text className="doctor-primary-color">OPD Date & Time:</Text>
          </Col>
          <Col span={12} className="text-right">
            <Text className="doctor-primary-color fWeight400">
              {appointment?.appointment_date &&
                formatOnlyDate(appointment?.appointment_date)}
              {<Divider type="vertical"></Divider>} {appointment?.start_time}
            </Text>
          </Col>
        </Row>
      )}

      {appointment?.patient_checked_in_time && (
        <Row>
          <Col span={12}>
            <Text className="doctor-primary-color">Checked In:</Text>
          </Col>
          <Col span={12} className="text-right">
            <Text className="doctor-primary-color fWeight400">
              {appointment?.patient_checked_in_time &&
                formatOnlyDate(appointment?.patient_checked_in_time)}
              {<Divider type="vertical"></Divider>}
              {appointment?.patient_checked_in_time &&
                formatTimes(appointment?.patient_checked_in_time)}
            </Text>
          </Col>
        </Row>
      )}

      {appointment?.patient_last_visit_time && (
        <Row>
          <Col span={12}>
            <Text className="doctor-primary-color">Last Visit:</Text>
          </Col>
          <Col span={12} className="text-right">
            <Text className="doctor-primary-color fWeight400">
              {appointment?.patient_last_visit_time &&
                formatOnlyDate(appointment?.patient_last_visit_time)}
            </Text>
          </Col>
        </Row>
      )}

      <Row>
        <Col span={12}>
          <Text className="doctor-primary-color">Invoice Details:</Text>
        </Col>
        <Col span={12} className="text-right">
          {appointment?.bill_ready === true ? (
            <Text
              underline
              style={{ float: "right" }}
              className="doctor-secondary-color cursor font12 "
              onClick={() => viewReceipt()}
            >
              View Invoice
            </Text>
          ) : (
            <Text className="doctor-primary-color text-right">
              Invoice Not Generated
            </Text>
          )}
        </Col>
      </Row>

      <Row>
        <Col span={24} className={"mt10"}>
          <p>
            {appointment?.patient_room_wait_time &&
            appointment?.patient_room_wait_time !== 0 ? (
              <Tag
                className="no-border doctor-tags capitalize"
                style={{ paddingBottom: "3px" }}
              >
                {appointment?.patient_room_wait_time < 40 ? (
                  <>
                    <img
                      src={
                        require("../../../../assets/images/clock.svg").default
                      }
                      style={{ width: "13%" }}
                    />
                    {appointment?.patient_room_wait_time} mins
                  </>
                ) : (
                  <>
                    <img
                      src={
                        require("../../../../assets/images/clockred.svg")
                          .default
                      }
                      style={{ width: "13%", marginTop: "-3px" }}
                    />
                    <span style={{ color: "#B2403A" }}>
                      {" "}
                      {secondsToHourMins(
                        appointment?.patient_room_wait_time
                      )}{" "}
                    </span>
                  </>
                )}
              </Tag>
            ) : (
              ""
            )}

            <Tag className="doctor-default-color-tag  capitalize">
              {ipd_status}{" "}
            </Tag>
          </p>
        </Col>
      </Row>
    </Card>
  );
};

export default AppointmentDetailsIPD;
