import { Checkbox, Col, Collapse, Row, Select } from "antd";
import { Option } from "antd/es/mentions";
import Paragraph from "antd/es/typography/Paragraph";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { fetchDepartments } from "../../../../redux/reducers/department.slice";
import { fetchAccessGroups } from "../../../../redux/reducers/role.slice";
import { assignAccessGroup } from "../../../../redux/reducers/user.slice";
import { RootState } from "../../../../shared/constants";
import { Branch } from "../../../../shared/types/branch.type";
import { Department } from "../../../../shared/types/department.type";
import { Role } from "../../../../shared/types/role.type";
import { User } from "../../../../shared/types/user.type";
import { getFullName } from "../../../../shared/Utils/utilities";

const UserRoles: React.FunctionComponent<{ user: User; refresh: any }> = ({
  user,
  refresh,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentApplication, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [activeKey, setActiveKey] = useState(currentApplication);
  const {
    role: { accessGroups, loading: loadingAccessGroups },
    user: { savingRole },
  } = useSelector((state: RootState) => state);
  useEffect(() => {
    dispatch(fetchAccessGroups({ limit: 60 }));
  }, []);

  console.log("user?.user_accesses", user?.user_accesses);

  return (
    <div className={"side-section-wrapper"}>
      <Row style={{ marginTop: "20px" }}>
        <Col flex={"auto"}>
          <Paragraph>
            Assign access group to user <strong>{getFullName(user)}</strong>
          </Paragraph>
        </Col>
      </Row>
      <Collapse activeKey={[activeKey]} onChange={(e) => setActiveKey(e?.[1])}>
        {applications.map((application: any) => {
          return (
            <Collapse.Panel header={application.name} key={application.slug}>
              <p>Access Groups</p>

              <Select
                allowClear={true}
                style={{ minWidth: "250px" }}
                value={
                  user?.user_accesses?.find(
                    (a) => a.application_id === application.id
                  )?.access_group_id
                }
                onChange={async (e) => {
                  await dispatch(
                    assignAccessGroup({
                      id: user.id,
                      data: {
                        application_id: application.id,
                        access_group_id: e,
                      },
                    })
                  );
                  refresh();
                }}
              >
                {accessGroups.map((ag: any) => {
                  return <Option value={ag.id}>{ag.name}</Option>;
                })}
              </Select>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
const ScopeBranches: React.FunctionComponent<{
  user: User;
  role: Role;
  onUpdate: (branches: string[]) => void;
}> = ({ user, role, onUpdate }) => {
  const { branches } = useSelector((state: RootState) => state.branch);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBranches());
  }, []);
  return (
    <Collapse>
      <Collapse.Panel key={"branch"} header={"Branches"}>
        <Checkbox.Group
          options={branches.map((branch: Branch) => {
            return {
              label: branch.name,
              value: branch.id,
            };
          })}
          defaultValue={[]}
          onChange={(e: any) => onUpdate(e)}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
const ScopeDepartments: React.FunctionComponent<{
  user: User;
  role: Role;
  branchIds: string[];
  selectedDepartmentIds: string[];
  onUpdate: (departments: string[]) => void;
}> = ({ user, role, branchIds, selectedDepartmentIds, onUpdate }) => {
  const { departments } = useSelector((state: RootState) => state.department);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDepartments());
  }, []);

  useEffect(() => {
    if (departments.length) {
      const filteredDepartments: string[] = [];
      selectedDepartmentIds.forEach((sd: string) => {
        if (
          departments.find(
            (department: Department) =>
              branchIds.indexOf(department.branch_id) > -1
          )
        ) {
          filteredDepartments.push(sd);
        }
      });
      console.log(filteredDepartments);
      onUpdate(filteredDepartments);
    }
  }, [branchIds, departments]);

  return (
    <Collapse>
      <Collapse.Panel key={"department"} header={"Departments"}>
        <Checkbox.Group
          options={departments
            .filter((d: Department) => branchIds.indexOf(d.branch_id) > -1)
            .map((department: Branch) => {
              return {
                label: department.name,
                value: department.id,
              };
            })}
          defaultValue={[]}
          value={selectedDepartmentIds}
          onChange={(e: any) => onUpdate(e)}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
export default UserRoles;
