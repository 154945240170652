import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewAccessGroup } from "../../../../redux/reducers/role.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Role } from "../../../../shared/types/role.type";
import AccessGroupForm from "./access-group-form.page";

const AccessGroupCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <DesktopFormLayout
      title={"Access Group Create"}
      subtitle={"Create new access group for .."}
    >
      <AccessGroupForm
        onSubmit={async (values: Role) => {
          try {
            await dispatch(addNewAccessGroup(values));
            history.goBack();
          } catch (e) {}
        }}
      />
    </DesktopFormLayout>
  );
};

export default AccessGroupCreate;
