import { Button, Card, Checkbox, Col, Row, Select, Space, Tag } from "antd";
import { Option } from "antd/es/mentions";
import React from "react";
import { getFullName } from "../../../../shared/Utils/utilities";

export const ScopeValueSelector = ({
  data,
  values,
  options,
  updateValues,
}: any) => {
  return (
    <div>
      <label>{data?.name}</label>
      {options === "bool" ? (
        <div>
          <Checkbox
            checked={values}
            onChange={(e) => updateValues(e.target.checked ? true : null)}
          />
        </div>
      ) : (
        <SelectTypeSelector
          data={data}
          values={values}
          options={options}
          handleChange={(e: any) => updateValues(e)}
        />
      )}
    </div>
  );
};
const SelectTypeSelector = ({
  values,
  data,
  handleChange: _handleChange,
  options,
}: any) => {
  const handleChange = (value: any) => {
    if (value === "*") {
      _handleChange(["*"]);
    } else {
      _handleChange(value);
    }
  };
  if (values?.indexOf("*") > -1) {
    return (
      <div style={{ background: "white", padding: "5px" }}>
        <Space>
          <Tag>All {data.name} Selected</Tag>
          <Button danger={true} size={"small"} onClick={() => handleChange([])}>
            {"Clear"}
          </Button>
        </Space>
      </div>
    );
  }
  if (!options?.length) {
    return (
      <Card>
        <Tag color={"red"}>No Data Available</Tag>
      </Card>
    );
  }

  return (
    <div>
      <Row>
        <Col flex={"auto"}>
          <Select
            disabled={values?.indexOf("*") > -1}
            mode="tags"
            placeholder={"Please Select"}
            defaultValue={values}
            onChange={(e) => handleChange(e)}
            style={{ width: "100%" }}
          >
            {options.map((value: any) => {
              return (
                <Option key={value.id || value} value={value.id || value}>
                  {value.name || getFullName(value) || value}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col>
          <Button type={"primary"} onClick={() => handleChange("*")}>
            {"Assign All"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
