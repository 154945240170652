import { Col, message } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { fetchDepartments } from "../../../../redux/reducers/department.slice";
import {
  addDoctorSlotConfig,
  addDoctorSlotProcedure,
  fetchDoctors
} from "../../../../redux/reducers/doctor.slice";
import {
  fetchMasterDays,
  fetchMasterProcedures,
  fetchMasterProperty
} from "../../../../redux/reducers/master.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { ROUTE_DOCTOR } from "../../../../shared/routes/admin.routes.constants";
import {
  SlotConfigration
} from "../../../../shared/types/slot.type";
import DocterSlotForm from "./slot-form.page";

const DocterSlotCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const [current, setCurrent] = React.useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { slot_id } = useSelector((state: RootState) => state.doctor);
  useEffect(() => {
    dispatch(fetchDoctors());
    dispatch(fetchDepartments());
    dispatch(fetchBranches());
    dispatch(fetchMasterDays());
    dispatch(fetchMasterProcedures());
    dispatch(fetchMasterProperty());
  }, [dispatch]);

  return (
    <DesktopFormLayout
      title={"Add Slot"}
      subtitle={"Create new slot for Doctor"}
    >
      <Col span={24}>
        <DocterSlotForm
          onSubmitSlot={async (values: SlotConfigration) => {
            let _available_days: any = [];
            values.availability_days?.map((day: String) => {
              _available_days.push({
                day_master_id: day,
              });
            });
            values.availability_days = _available_days;
            if (
              values?.end_time &&
              values?.start_time &&
              values?.session_end_date &&
              values?.session_start_date
            ) {
              if (values.start_time >= values.end_time) {
                message.error("End Time should be more than Start Time");
                return;
              } else {
                values = {
                  ...values,
                  end_time: moment(values?.end_time.toString()).format(
                    "HH:mm a"
                  ),
                  start_time: moment(values?.start_time.toString()).format(
                    "HH:mm a"
                  ),
                  session_start_date: moment(
                    values?.session_start_date.toString()
                  ).format("YYYY-MM-DD"),
                  session_end_date: moment(
                    values?.session_end_date.toString()
                  ).format("YYYY-MM-DD"),
                };
                await dispatch(addDoctorSlotConfig(values)).then((val: any) => {
                  if (val?.payload?.data?.statusCode == 200) {
                    next();
                  }
                });
              }
            }
          }}
          onSubmitProperty={async (values: any) => {
            values.doctor_slot_config_id = slot_id;
            values = {...values, fee: parseInt(values.fee), interval: parseInt(values.interval), interval_capacity: parseInt(values.interval_capacity)}
            // console.log('eeeeee 2222',values);
            await dispatch(addDoctorSlotProcedure(values)).then((val: any) => {
              if (val?.payload?.data?.statusCode == 200) {
                history.push(ROUTE_DOCTOR);
              }
            });
          }}
          current={current}
        />
      </Col>
    </DesktopFormLayout>
  );
};

export default DocterSlotCreate;
