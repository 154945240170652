import { CalendarOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer, Dropdown, Form, Input, InputNumber, Menu, message,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Typography
} from "antd";

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchAssignedOrders,
  fetchStations,
  fetchCancelledOrders,
  fetchDeliveredOrders,
  fetchInvoicedOrders, fetchPharmacyOrderRequest,
  fetchPlacedOrders,
  fetchReturnedOrders,
  summaryTableCount,
  updateRequestStatus,
  downloadPharmacyData
} from "../../../../redux/reducers/pharmacy.slice";
import { RootState } from "../../../../shared/constants";
import PaginationLayout from "../../../../shared/layouts/pagination.layout";
import TableLayout from "../../../../shared/layouts/table.layout";
import OrderJourney from "./order-journey";
import PatientMessageHistory from "../../../admin/Appointment/components/patient-message-history";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
type Props = {
  status?: any;
};

function OrderTable(props: Props) {
  const csvLink = useRef("" as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const [audit, setAuditTrail] = useState(false);
  const [actionDrawerTitle, setActionDrawerTitle] = useState("");
  const [actionDrawer, setActionDrawer] = useState(false);
  const [drawerAction, setDrawerAction] = useState("");
  const [rowDetail, setRowDetail] = useState(null as any);
  const [cancelReason, setCancelReason] = useState(null as any);
  const [deliverCondition, setDeliverCondition] = useState(false as boolean);
  const [returnRemarks, setReturnRemarks] = useState(null as any);
  const [patientAddress, setPatientAddress] = useState(null as any);
  const [riderName, setRiderName] = useState("" as any);
  const [filterData, setFilterData] = useState(null as any);
  const [fileName, setFileName] = useState("" as any);
  const [pharmacyDetails, setPharmacyDetails] = useState({} as any);
  const [emailModal, setEmailModal] = useState(false);
  const [isReset, setIsReset] = useState(null as any);
  const [downloadRangePickerValidation, setDownloadRangePickerValidation] =
  useState({ type: "", message: "" } as any);
  const { profile } = useSelector((state: RootState) => state.profile);

  const downloadDataOnValuesChange = (_: any, v: any) => {

    if (_.date_range?.length === 2) {
      const start = _.date_range[0];
      const end = _.date_range[1];
      if (end.diff(start, "days") > 30) {
        setDownloadRangePickerValidation({
          type: "error",
          message: "Date range can be maximum 30 days",
        });
      } else {
        setDownloadRangePickerValidation({ type: "", message: "" });
      }
    }
  };

  const { Title, Text } = Typography;
  const { Option } = Select;
  const {
    placedOrderSummary,
    invoicedOrderSummary,
    cancelledOrderSummary,
    assignedOrderSummary,
    deliveredOrderSummary,
    returnedOrderSummary,
    pharmacyOrder,
    loading,
    pagination,
    stationList,
  } = useSelector((state: RootState) => state.pharmacy);
  const cancelReasonArr = [
    { val: "Area Not Servicable", name: "Area Not Servicable" },
    {
      val: "Cancelled Because Partial Medicine Available",
      name: "Cancelled Because Partial Medicine Available",
    },
    {
      val: "Cancelled Because Full Medicine NA",
      name: "Cancelled Because Full Medicine NA",
    },
    { val: "Want More Discount", name: "Want More Discount" },
    {
      val: "Already Purchased From Outside",
      name: "Already Purchased From Outside",
    },
    {
      val: "Already Purchased From Max Pharmacy",
      name: "Already Purchased From Max Pharmacy",
    },
    {
      val: "Already Purchased From Outside- Delay Attempt <1 Hour",
      name: "Already Purchased From Outside- Delay Attempt <1 Hour",
    },
    {
      val: "Already Purchased From Max Pharmacy- Delay Attempt <1 Hour",
      name: "Already Purchased From Max Pharmacy- Delay Attempt <1 Hour",
    },
    {
      val: "Low Amount Delivery Denied By Us",
      name: "Low Amount Delivery Denied By Us",
    },
    {
      val: "Cancelled By Patient No Reason Disclosed",
      name: "Cancelled By Patient No Reason Disclosed",
    },
    { val: "Prescription Not Valid", name: "Prescription Not Valid" },
    {
      val: "Delivery Not Possible Before Required Time by Pt.",
      name: "Delivery Not Possible Before Required Time by Pt.",
    },
    { val: "Prescription Not Received", name: "Prescription Not Received" },
    {
      val: "Patient Didn’t Respond even after 3 attempts",
      name: "Patient Didn’t Respond even after 3 attempts",
    },
    {
      val: "Prescription Expired For Drugs",
      name: "Prescription Expired For Drugs",
    },
    { val: "Others", name: "Others" },
  ];
  const [ordersData, setOrdersData] = useState([] as any);
  const [newOrderAuditTrail, setNewOrderAuditTrail] = useState("" as any);

  useEffect(() => {
    if (props.status === "newOrders") {
      setOrdersData(pharmacyOrder);
    } else if (props.status === "open") {
      setOrdersData(placedOrderSummary);
    } else if (props.status === "invoiced") {
      setOrdersData(invoicedOrderSummary);
    } else if (props.status === "cancelled") {
      setOrdersData(cancelledOrderSummary);
    } else if (props.status === "assigned") {
      setOrdersData(assignedOrderSummary);
    } else if (props.status === "delivered") {
      setOrdersData(deliveredOrderSummary);
    } else if (props.status === "returned") {
      setOrdersData(returnedOrderSummary);
    }
    
  }, [
    pharmacyOrder,
    placedOrderSummary,
    invoicedOrderSummary,
    cancelledOrderSummary,
    assignedOrderSummary,
    deliveredOrderSummary,
    returnedOrderSummary,
  ]);

  useEffect(() => {
    dispatch(fetchStations());
  }, []);

  const sendMailList = async (data:any)=>{

    if (downloadRangePickerValidation?.type === "error") {
      return;
    }
    let params: any = {};
    const start = data.date_range[0];
    const end = data.date_range[1];
    params.start_date = start.format("YYYY-MM-DD");
    params.end_date = end.format("YYYY-MM-DD");
    params.email = data?.email;
    let param = params
      ? Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null))
      : {};

    await  dispatch(downloadPharmacyData(param)).then((val: any) => {
      if (val?.payload?.data?.statusCode == 200) {
        message.success("Report sent to your email.");
        setEmailModal(false);
      }
    });

  }

  const fetchSummaryOrders = async () => {
    let payload = {
      order_status: props.status,
      order_type: "pharmacy",
      offset: pagination.offset,
      limit: pagination.limit,
    }
    if (props.status === "open") {
      await dispatch(fetchPlacedOrders(payload));
    } else if (props.status === "invoiced") {
      await dispatch(fetchInvoicedOrders(payload));
    } else if (props.status === "cancelled") {
      await dispatch(fetchCancelledOrders(payload));
    } else if (props.status === "assigned") {
      await dispatch(fetchAssignedOrders(payload));
    } else if (props.status === "delivered") {
      await dispatch(fetchDeliveredOrders(payload));
    } else if (props.status === "returned") {
      await dispatch(fetchReturnedOrders(payload));
    }
  }

  const columns = [
    // {
    //   title: "Request Id",
    //   render: (a: any) =>
    //     props?.status === "newOrders" ? a?.request_id : a?.req_id,
    // },
    {
      title: "Order Id",
      dataIndex: ["order_id"],
      hidden: props?.status === "newOrders" ? true : false,
    },
    {
      title: "HIS ID",
      render: (key: any) => key?.order_cart_id,
      hidden: props?.status === "newOrders" ? true : false,
    },
    {
      title: "Details",
      dataIndex: "items",
      render: (key: any) => (
        <Popover
          content={
            <ul>
              {key?.map((item: any) => {
                return <li key={item?.id}>{item?.item_name} </li>
              })}
            </ul>
          }
        >
          {(key?.length ? key?.length : 0) + " " + "Items"}
        </Popover>
      ),
      width: "100px",
    },
    {
      title: "Order Type",
      dataIndex: ["source"],
    },
    {
      title: "Patient Details",
      render: (a: any) => {
        return (
          <>
            <span>
              {a.patient
                ? a?.patient?.pii?.first_name + " " + a?.patient?.pii?.last_name
                : ""}
            </span>
            <br />
            {a?.patient?.pii?.mobile}
          </>
        );
      },
    },
    {
      title: "Pharmacy",
      // dataIndex: ["source"],
      render:(a:any)=>{
        return(
          <span>
            {
              a?.station?.name ? `${a?.station?.name } (${a?.station?.code })`: "-"
            }
          </span>
        )
      }

    },
    {
      title: "Invoice Details",
      render: (a: any) => {
        return (
          <>
            <span>Rs {Math.ceil(a?.total_amount)}</span>
            <br />
            {a?.bill_number ? `Invoice: ` + a?.bill_number : ""}
          </>
        );
      },
      hidden: props?.status === "newOrders" ? true : false,
    },
    {
      title: "Order Date",
      render: (key: any) =>
        props?.status === "newOrders"
          ? moment(key?.request_date)
              // .subtract({ hours: 5, minute: 30 })
              .format("DD MMM'YY, hh:mm A")
          : moment(key?.order_date)
              // .subtract({ hours: 5, minute: 30 })
              .format("DD MMM'YY,hh:mm A"),
      width: "170px",
    },
    {
      title: "UHID",
      render: (key: any) => key?.patient?.uhid,
      hidden: props?.status === "newOrders" ? true : false,
    },
    {
      title: "Rx Required",
      render: (a: any) => (a?.rx_required === false ? "No" : "Yes"),
      hidden: props?.status === "newOrders" ? true : false,
    },
    // {
    //   title: "Hospital",
    //   render: (a: any) => a?.branch?.name,
    // },
    {
      title: "Rider Assigned",
      dataIndex: "assigned_to",
      hidden: props.status === "assigned" ? false : true,
    },
    {
      title: "Action",
      key: "action",
      render: (a: any) => (
        <>
          { props?.status === 'invoiced' || props?.status === 'open' || props?.status === 'assigned' || props?.status === 'delivered' ? 
          <>
            <Dropdown overlay={menu(a)} trigger={["click"]}>
              <Button
                shape="circle"
                type="primary"
                size={"middle"}
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </>
          : 
          <Button type={"link"} onClick={() => handleAudit(a)}>
            Audit Trail
          </Button>
        }
      </>
      ),
    },
  ].filter((item) => !item.hidden);

  const menu = (a: any) => (
    <Menu>
      <Menu.Item key="0" onClick={() => handleAudit(a)}> Audit Trail </Menu.Item>
      {props?.status === 'invoiced' || props?.status === 'assigned' ? <Menu.Item key="1" onClick={() => handleActionDrawer(a,'delivered')}> Mark Delivered </Menu.Item> : ''}
      {props?.status === 'invoiced' ? <Menu.Item key="2" onClick={() => handleActionDrawer(a,'assign_rider')}> Assign Rider </Menu.Item> : ""}
      {props?.status === 'invoiced' || props?.status === 'assigned' || props?.status === 'delivered' ? <Menu.Item key="3" onClick={() => handleActionDrawer(a,'mark_return')}> Mark Return </Menu.Item> : ''}
      {props?.status === 'open' ? <Menu.Item key="4" onClick={() => handleActionDrawer(a,'cancel')}> Cancel Order </Menu.Item> : ""}
      {props?.status === 'assigned' ? <Menu.Item key="4" onClick={() => handleActionDrawer(a,'reAssigned')}> Re Assign Rider </Menu.Item> : ""}
    </Menu>
  );

  // this audit trails used when used medicine component
  // const handleNewAudit = async (a: any) => {
  //   setAuditTrail(true);
  //   setRowDetail(a);
  //   setNewOrderAuditTrail("newOrderAuditTrail");
  //   let data = await dispatch(fetchMedicinesById(rowDetail?.id));
  //   setPharmacyDetails(data?.payload?.data?.data?.pharmacy);
  //   a?.patient?.addresses?.forEach((addr: any) => {
  //     if (addr.id === a.delivery_address_id) {
  //       setPatientAddress(addr);
  //     }
  //   });
  // };

  // this audit trails used now
  const handleAudit = async (a: any) => {
    setRowDetail(a);
    // a?.patient?.addresses?.forEach((addr: any) => {
    //   if (addr.id === a.delivery_address_id) {
    //     setPatientAddress(addr);
    //   }
    // });
    setAuditTrail(true);
  };

  const onPageChange = async (e: any) => {
    let pageNumber = 0;
    pageNumber = e - 1;
    let payload = {
      order_status: props.status,
      order_type: "pharmacy",
      offset: pageNumber * 10,
      limit: pagination.limit,
    }
    if (props.status === "newOrders") {
      dispatch(
        fetchPharmacyOrderRequest({
          offset: pageNumber * 10,
          limit: pagination.limit,
        })
      )
    } else if (props.status === "open") {
      await dispatch(fetchPlacedOrders(payload));
    } else if (props.status === "invoiced") {
      await dispatch(fetchInvoicedOrders(payload));
    } else if (props.status === "cancelled") {
      await dispatch(fetchCancelledOrders(payload));
    } else if (props.status === "assigned") {
      await dispatch(fetchAssignedOrders(payload));
    } else if (props.status === "delivered") {
      await dispatch(fetchDeliveredOrders(payload));
    } else if (props.status === "returned") {
      await dispatch(fetchReturnedOrders(payload));
    }
  }

  const handleActionDrawer = async (data:any, action: any) => {
    setRowDetail(data);
    setDrawerAction(action);
    if (action === "cancel") {
      setActionDrawerTitle("Cancel Medicine Order");
    } else if (action === "delivered") {
      setActionDrawerTitle("Mark Deliver Medicine Order");
    } else if (action === "assign_rider") {
      setActionDrawerTitle("Assign Rider");
    } else if (action === "mark_return") {
      setActionDrawerTitle("Mark Return Medicine Order");
    } else if (action === "reAssigned") {
      setActionDrawerTitle("Reassign Rider");
      setRiderName(data?.assigned_to)
    }
    setActionDrawer(true);
  }

  const chooseCancelReason = (e: any) => {
    setCancelReason(e);
  };

  const handleCancelReason = async () => {
    try {
      if (!cancelReason) {
        message.error("PLease select cancel reason", 4);
      } else {
        let param = {
          order_id: rowDetail?.id,
          cancel_remarks: cancelReason,
          order_status: "cancelled",
        }
        await dispatch(updateRequestStatus(param));
        await fetchSummaryOrders();
        getStatusCount();
        closeDrawer();
      }
    } catch (error) {}
  };

  const handleDeliverCondition = (e: any) => {
    setDeliverCondition(e.target.checked);
  }

  const handleMarkDeliver = async () => {
    try {
      if (!deliverCondition) {
        message.error("PLease select checkbox", 4);
      } else {
        let param = {
          order_id: rowDetail?.id,
          order_status: "delivered",
        };
        await dispatch(updateRequestStatus(param));
        await fetchSummaryOrders();
        getStatusCount();
        closeDrawer();
      }
    } catch (error) {}
  }

  const handleMarkReturn = async () => {
    try {
      if (!returnRemarks) {
        message.error("Please Enter Reason", 4);
      } else {
        let param = {
          order_id: rowDetail?.id,
          order_status: "returned",
          reason: returnRemarks,
        };
        await dispatch(updateRequestStatus(param));
        await fetchSummaryOrders();
        getStatusCount();
        closeDrawer();
      }
    } catch (error) {}
  }

  const handleAssignRider = async () => {
    try {
      if (!riderName) {
        message.error("Please Enter Rider Name", 4);
      } else {
        let param = {
          order_id: rowDetail?.id,
          order_status: "assigned",
          assigned_to: riderName,
          comments: "",
        };
        await dispatch(updateRequestStatus(param));
        await fetchSummaryOrders();
        getStatusCount();
        closeDrawer();
      }
    } catch (error) {}
  }

  const closeDrawer = () => {
    setActionDrawer(false);
    setReturnRemarks("");
    setRiderName("");
  }

  const getStatusCount = async () => {
    await dispatch(summaryTableCount());
  }

  const fetchSummaryOrdersByFilters = async (
    e: any,
    selectedFilter: string
  ) => {
    let payload = {} as any;
    let newOrderPayload = {} as any;
    newOrderPayload = {
      order_type: 'pharmacy',
      offset: pagination.offset,
      limit: pagination.limit,
    }
    payload = {
      order_status: props?.status,
      order_type: 'pharmacy',
      offset: pagination.offset,
      limit: pagination.limit,
    }
    if (selectedFilter) {
      payload = {...payload, 
        [selectedFilter]: e?.searchValue || filterData?.searchValue,
      }
    }
    if (e.branch_id) {
      payload["station_id"] = e?.branch_id || filterData?.branch_id;
    }
    if (e.request_date) {
      payload["order_date"] =
        e?.request_date ||
        filterData?.request_date ||
        moment().format("YYYY-MM-DD");
    }
    if (props.status === "newOrders") {
      if (selectedFilter) {
        newOrderPayload = {
          [selectedFilter]: e?.searchValue,
        };
      }
      if (e.branch_id) {
        newOrderPayload["station_id"] = e?.branch_id || filterData?.branch_id;
      }
      if (e.request_date) {
        newOrderPayload["order_date"] =
          e?.request_date || filterData?.request_date;
      }
      setFileName("new_orders");
      await dispatch(fetchPharmacyOrderRequest(newOrderPayload));
    } else if (props.status === "open") {
      setFileName("qc_done");
      await dispatch(fetchPlacedOrders(payload));
    } else if (props.status === "invoiced") {
      setFileName("invoice");
      await dispatch(fetchInvoicedOrders(payload));
    } else if (props.status === "cancelled") {
      setFileName("cancel");
      await dispatch(fetchCancelledOrders(payload));
    } else if (props.status === "assigned") {
      setFileName("assign");
      await dispatch(fetchAssignedOrders(payload));
    } else if (props.status === "delivered") {
      setFileName("deliver");
      await dispatch(fetchDeliveredOrders(payload));
    } else if (props.status === "returned") {
      setFileName("return");
      await dispatch(fetchReturnedOrders(payload));
    }
  }

  const filterFormSubmit = (e: any) => {
    setFilterData(e);
    fetchSummaryOrdersByFilters(e, e.selectedFilter);
  }

  const downloadFilterData = (e: any) => {
    // console.log('eeeee 3333', e)
    // setFilterData(e);
    // fetchSummaryOrdersByFilters(e, e.selectedFilter);
    // setTimeout(() => { 
    //   csvLink?.current.link.click();
    // }, 1000);

    setEmailModal(true)
  }

  const onCloseAuditTrail = () => {
    setAuditTrail(false);
    setIsReset("reset");
  }
  
  return (
    <div>
      <CSVLink
        data={ordersData}
        className="hidden"
        onClick={() => downloadFilterData}
        filename={fileName + ".csv"}
        ref={csvLink}
        target="_blank"
      ></CSVLink>
      <Row className="padding10">
        <Col span={24}>
          <TableLayout
            loading={loading}
            dataSource={ordersData}
            columns={columns}
            filterFormSubmit={filterFormSubmit}
            downloadFilterData={downloadFilterData} 
            type="pharmacyfilter"
            total={pagination.total}
            pagination={{ position: ["none", "none"] }}
            branches={stationList}
          />
        </Col>
        <Col span={10} offset={14} className="text-right">
          <PaginationLayout
            total={pagination.total}
            onChange={(e: any) => onPageChange(e)}
          ></PaginationLayout>
        </Col>
      </Row>

      {/*  Audit Trail Drawer */}
      <Drawer
        title={`Audit Trail`}
        placement="right"
        width={"80%"}
        onClose={onCloseAuditTrail}
        visible={audit}
      >
        <Row className="mt30">
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Pharmacy Order" key="1">
                <Row className="bordered-grey-color">
                  <Col
                    className="pd20 grey-background"
                    span={props.status === "newOrders" ? 24 : 14}
                  >
                    {/* <SelectedMedicine newOrderAuditTrail={newOrderAuditTrail} /> */}
                    { rowDetail?.items?.length > 0 ? <>
                      <Row className="full-width pdl10 pdr10">
                        <table className="" style={{ fontSize: '11px!important', width: '100%', borderSpacing:'10px', borderCollapse:'separate'}}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: 'left'}}>Medicine Name</th>
                              <th style={{ textAlign: 'left'}}>Qty/Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rowDetail?.items?.map((indRow: any, index: any) =>
                            <>
                              <tr key={index}>
                                <td style={{textTransform: 'uppercase', fontSize: '12px', width: '300px'}}>{index + 1}. {indRow?.item_name ? indRow?.item_name : '-'}
                                </td>
                                <td >
                                  <InputNumber disabled={true} defaultValue={1} value={indRow?.item_quantity} style={{width: '60px'}} />
                                  <span style={{padding: '0 15px', position: 'relative', display: 'inline-block', height: '30px'}}>Rs. {Math.ceil(indRow?.item_amount * indRow?.item_quantity)}
                                    <span className="sub-title" style={{position: 'absolute', top: '18px', left: '13px', fontSize: '10px'}}>(Rs. {(indRow?.item_amount?.toFixed(2))} * {indRow?.item_quantity})</span>
                                  </span>
                                </td>
                              </tr>
                              <tr><td colSpan={2}><Divider className="mt10 mb-10"/></td></tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </Row>
                      <Row>
                          <Col>
                              <strong>Pharmacy</strong> : { rowDetail?.station?.name && `${rowDetail?.station?.name} (${rowDetail?.station?.code})` }
                          </Col>
                        </Row>
                    </>
                       : 
                      <>
                        <Row className="sub-title full-width"> 
                          <Col span={9} > </Col>
                          <Col span={13} >  <SearchOutlined style={{fontSize: '70px'}} /> </Col>
                        </Row>
                        <Row className="sub-title full-width"> 
                          <Col span={8} > </Col>
                          <Col span={14}> <Text type="secondary" className="bold sub-text">No medicines added.</Text></Col>
                        </Row>
                      </>
                    }
                  </Col>
                  <Col className="full-height white-background pd20" span={props.status === "newOrders" ? 0 : 10}>
                    {/* <PlaceOrderComponent /> */}
                    <Row>
                      <b>Place Order</b>
                    </Row>
                    <Divider />
                    <Row className="">
                      <Col span={24}>
                        <Text className="sub-heading mt10">Delivery Address</Text>
                      </Col>
                      <Col span={24}>
                        <Text className="mt5">
                          {rowDetail?.address ? rowDetail?.address : ""}
                        </Text>
                      </Col>
                    </Row>
                    <Divider />
                    <Row className="mt20 full-width sub-heading">
                      <Col span={20}>
                        <Text>Total Price</Text>
                      </Col>
                      <Col span={4}>
                        <Text>Rs. {Math.ceil(rowDetail?.total_amount)}</Text>
                      </Col>
                      <Col span={20}>
                        <Text>Delivery Charges</Text>
                      </Col>
                      <Col span={4}>
                        <Text>Rs. {(rowDetail?.deliveryCharge || 0)}</Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <h4 className="mt20">Remarks</h4>
                        <Text> {rowDetail?.comments } </Text>
                      </Col>
                    </Row>
                    <Divider />
                    <Row className="mt20 full-width sub-heading">
                      <Col span={20}>
                        <Text className=" fts16">Total Amount to be Paid</Text>
                      </Col>
                      <Col span={4}>
                        <Text className=" fts16">
                          Rs. {Math.ceil(rowDetail?.total_amount + (rowDetail?.deliveryCharge || 0))}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Patient Journey" key="2">
                <OrderJourney
                  order_id={rowDetail?.id}
                  request_id={rowDetail?.request_id}
                  isReset={isReset}
                ></OrderJourney>
              </TabPane>
              <TabPane tab="Message History" key="3">
                <PatientMessageHistory
                  patient_id={rowDetail?.patient?.id}
                  isReset={isReset}
                ></PatientMessageHistory>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Drawer>

      {/*  Action Drawer */}
      <Drawer
        title={actionDrawerTitle}
        placement="right"
        width={"80%"}
        onClose={closeDrawer}
        visible={actionDrawer}
      >
        <Row className="mt20">
          <Col span={24}>
            <Title level={5}>
              {" "}
              <CalendarOutlined />{" "}
              <span className="pdl5">Pharmacy Order Details</span>
            </Title>
          </Col>
        </Row>
        <Row
          className="mt20"
          style={{
            padding: "10px",
            background: "#c9c4c4",
            borderRadius: "5px",
          }}
        >
          <Col span={6} className="pdh20">
          <Text>Order ID: </Text>
            <Text className="sub-heading capitalize">{rowDetail?.order_id}</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text>Order Source: </Text>
            <Text className="sub-heading capitalize">
              {rowDetail?.source}
            </Text>
          </Col>
          
          <Col span={6} className="pdh5">
            <Text>Order Date: </Text>
            <Text className="sub-heading">
              {moment(rowDetail?.order_date).subtract(330, 'minutes').format("DD MMM'YY,hh:mm A")}
            </Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text>Total: </Text>
            <Text className="sub-heading">
              Rs. {Math.ceil(rowDetail?.total_amount)}
              {rowDetail?.order_display_status ? <Tag style={{marginLeft: '5px'}} color="cyan">{rowDetail?.order_display_status}</Tag> : ''}
            </Text>
          </Col>
        </Row>
        <Row className="mt20">
          <Col span={18} className="pdh20">
            <Text className="sub-heading">Medicine Details</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text className="sub-heading">Price </Text>
          </Col>
        </Row>
        <Row className="mt10">
          {rowDetail?.items?.map((item: any, index: any) => (
            <>
              <Col span={18} className="pdh20">
                <Text>
                  {index + 1}. {item?.item_name}
                </Text>
              </Col>
              <Col span={6} className="pdh20">
                <Text>Rs. {Math.ceil(item?.item_amount * item?.item_quantity)} </Text>
              </Col>
            </>
          ))}
        </Row>
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Discount</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">-Rs. {rowDetail?.discount || 0} </Text>
          </Col>
        </Row>
        <Divider />
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Patient Name</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">
              {rowDetail?.patient?.pii?.first_name +
                " " +
                rowDetail?.patient?.pii?.last_name}
            </Text>
          </Col>
        </Row>
        <Row className="mt10">
          <Col span={18} className="pdh20">
            <Text type="secondary">Delivery Address</Text>
          </Col>
          <Col span={6} className="pdh20">
            <Text type="secondary">
            {rowDetail?.address}
            </Text>
          </Col>
        </Row>
        <Divider />

        {(() => {
          if (drawerAction === "cancel") {
            return (
              <>
                {/* cancel order */}
                <div>
                  <Row className="mt10">
                    <Col span={24} className="pdh20">
                      <Text
                        className="sub-heading"
                        style={{ fontSize: "16px" }}
                      >
                        Invoice for the order has not been generated yet. Are
                        you sure you want to cancel this order?
                      </Text>
                    </Col>
                  </Row>
                  <Row className="mt10 pdh20">
                    <Col span={24}>
                      <Text className="sub-heading">Select Reason*</Text>
                    </Col>
                    <Col span={24}>
                      <Select
                        className="mt10"
                        onChange={(evt: any) => chooseCancelReason(evt)}
                        style={{ width: "250px" }}
                        placeholder="Select Option"
                      >
                        {cancelReasonArr?.map((ind: any, index: any) => (
                          <Option value={ind?.val}>{ind?.name}</Option>
                        ))}
                        {/* <Option value='test'>Order Not Required</Option> */}
                      </Select>
                    </Col>
                  </Row>
                  <Row className="mt10 pdh20">
                    <Col span={24}>
                      <Button
                        type="primary"
                        size={"large"}
                        onClick={handleCancelReason}
                      >
                        {" "}
                        Yes, Cancel Order{" "}
                      </Button>
                      <Button
                        style={{ marginLeft: "10px" }}
                        onClick={closeDrawer}
                        size={"large"}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                </div>

                {/* cancel order invoice ready */}
                {/* <div>
                                <Row className="mt10">
                                    <Col span={24} className='pdh20'>
                                        <Text className="sub-heading" style={{fontSize:'16px', color:"red"}}>Invoice is already generated.</Text>
                                    </Col>
                                    <Col span={24} className='pdh20'>
                                        <Text className="sub-heading">Please contact Unit Pharmacist to cancel the order.</Text>
                                    </Col>
                                </Row>
                            </div> */}
              </>
            )
          } else if (drawerAction === "delivered") {
            return (
              // mark deliver
              <div>
                <Row className="mt10">
                  <Col span={24} className="pdh20">
                    <Checkbox
                      className="sub-heading"
                      style={{ fontSize: "16px" }}
                      onChange={handleDeliverCondition}
                    >
                      Are you sure you want to mark this order delivered?
                    </Checkbox>{" "}
                  </Col>
                </Row>
                <Row className="mt10 pdh20">
                  <Col span={24}>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={handleMarkDeliver}
                    >
                      {" "}
                      Yes, Mark Deliver{" "}
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={closeDrawer}
                      size={"large"}
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </div>
            )
          } else if (drawerAction === "mark_return") {
            return (
              // mark Return
              <div>
                <Row className="mt10">
                  <Col span={24} className="pdh20">
                    <Text className="sub-heading" style={{ fontSize: "16px" }}>
                      {" "}
                      Are you sure you want to mark this order returned?
                    </Text>
                  </Col>
                </Row>
                <Row className="mt10 pdh20">
                  <Col span={24}>
                    <Text className="sub-heading">Remarks</Text>
                  </Col>
                  <Col span={24}>
                    <Input
                      value={returnRemarks}
                      placeholder="Type Here..."
                      onChange={(e) => {
                        setReturnRemarks(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt10 pdh20">
                  <Col span={24}>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={handleMarkReturn}
                    >
                      {" "}
                      Yes, Mark Return{" "}
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={closeDrawer}
                      size={"large"}
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </div>
            )
          } else if (drawerAction === "assign_rider" || drawerAction === "reAssigned") {
            return (
              // assign rider
              <div>
                <Row className="mt10 pdh20">
                  <Col span={24}>
                    <Text className="sub-heading">Assign Rider</Text>
                  </Col>
                  <Col span={8}>
                    <Input
                      value={riderName}
                      onChange={(e) => {
                        setRiderName(e.target.value);
                      }}
                      placeholder="Enter Name"
                    />
                  </Col>
                </Row>
                <Row className="mt10 pdh20">
                  <Col span={24}>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={handleAssignRider}
                    >
                      {" "}
                      Assign Rider{" "}
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={closeDrawer}
                      size={"large"}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            )
          }
        })()}
      </Drawer>

      {emailModal && (
        <Modal
          centered
          visible={emailModal}
          onCancel={() => setEmailModal(false)}
          title="Send Pharmacy Report in Email"
          footer={null}
        >
          <Form
            onFinish={sendMailList} 
            onValuesChange={downloadDataOnValuesChange}
            className="crm-form"
            initialValues={{ email: profile?.email }}
          >
            
            <Form.Item
              name="date_range"
              label="Date Range"
              {...(downloadRangePickerValidation?.type === "error"
                ? {
                  validateStatus: "error",
                  help: downloadRangePickerValidation?.message,
                }
                : {})}
              rules={[
                {
                  required: true,
                  message: "Please select a valid date range.",
                },
              ]}
            >
              <RangePicker
                disabledDate={(d: any) => {
                  return d > moment();
                }}
                style={{ width: "100%" }} 
                format="DD MMM'YY"
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: "email", message: "Please enter a valid email Id." },
                { required: true, message: "Please enter an email Id." },
              ]}
            >
              <Input placeholder="Enter email Id" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  )
}

export default OrderTable;
