import React from "react";

class TextboxWithIncrementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue = (event) => {
    this.props.emitTextboxValue({
      name: event.target.name,
      value: event.target.value,
    });
  };

  onAddButtonClicked = (event) => {
    this.props.emitAddNewRowObjectData(true);
  };

  onDeleteButtonClicked = (event) => {
    this.props.emitDeleteRowObjectData(true);
  };

  checkForLabelValue(str) {
    if (!str || !str.trim()) {
      return false;
    }
    return true;
  }

  render() {
    const {
      type = "textbox",
      name,
      placeholder,
      label,
      labelSize,
      size,
      value,
      rowIndex,
      offset,
      showType,
    } = this.props;
    return (
      <React.Fragment>
        <div
          className={`ant-col ant-col-${size ? size * 2 : 24}  ${
            showType === "table" ? "p-0" : ""
          }`}
        >
          <input
            id={name + rowIndex}
            className="form-control"
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={this.changeValue}
          />
        </div>
        <div className="ant-col ant-col-4" style={{ padding: 0 }}>
          <div
            className="btn-group plus-group"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              className="btn btn-outline-danger btn-rounded"
              onClick={this.onDeleteButtonClicked.bind(this)}
            >
              <i className="mdi mdi-minus "></i>
            </button>
            <button
              type="button"
              className="btn btn-outline-success btn-rounded"
              onClick={this.onAddButtonClicked.bind(this)}
            >
              <i className="mdi mdi-plus "></i>
            </button>
          </div>
        </div>
        {offset && <div className={offset}> </div>}
      </React.Fragment>
    );
  }
}

export default TextboxWithIncrementComponent;
