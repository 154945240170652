import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { addNewUserBranch, deleteUserBranch, fetchUsersBranch } from "../../../../redux/reducers/user.slice";
import { RootState } from "../../../../shared/constants";
import TableLayout from '../../../../shared/layouts/table.layout';
import { User } from "../../../../shared/types/user.type";


const UserBranches: React.FunctionComponent<{ user: User }> = ({user}) => {
    const dispatch = useDispatch();
    const RouteParams: any = useParams();
    const { Title } = Typography;
    const { Option } = Select;
    const { userBranches, loading } = useSelector((state: RootState) => state.user);
    const { branches } = useSelector((state: RootState) => state.branch);
    const [ branchesModalVisible, setBranchesModalVisible ] = useState(false);
    const [ selectedId, setSelectedId ] = useState('' as string);
    const [ deleteModalVisible, setDeleteModalVisible ] = useState(false);
    const [ deleteModalText, setDeleteModalText ] = useState('' as string);
    const [ deleteSpcId, setDeleteSpcId ] = useState('' as string);
    const [ branchArr, setBranchArr ] = useState([] as any);
    
    

    const columns: any = [
        {
            title: 'Name',
            render:(key: any) => ( key?.branch?.name ?  key?.branch?.name : '-'),
        },
        {
            title: 'Code',
            render: (key: any) => ( key?.branch?.code ?  key?.branch?.code : '-'),
        },
        {
            fixed: 'right' as 'right',
            width: '150px',
            title: 'Action',
            key: 'action',
            render: (a: any) => {
                return <Row gutter={10}>
                    <Col>
                    {
                        <Button shape='circle' title={'Delete Branch'} 
                        icon={<CloseOutlined />} onClick={ () =>  {openDeleteModal(a?.id)} } size={'middle'}/> 
                    }
                                            
                    </Col>
                </Row>;
            }
        }
        
    ]

    const getBranch = (id:string) => {
        dispatch(fetchUsersBranch(id));
    }

    useEffect(() => {
        dispatch(fetchBranches());
        getBranch(RouteParams?.id)
    }, [])

    const openBranchModal = () => {
        const allSelected = userBranches.map((val:any)=>{
            return val?.branch_id
        })
        setBranchArr(branches?.filter((val:any)=>{
            return allSelected.indexOf(val?.id) === -1
        }));
        setBranchesModalVisible(true)
    }

    
    const handleSpcId = (id:any) => {
        setSelectedId(id);
    }

    const handleBranch = async () => {
        // console.log('eeeee 22222', user, selectedId);
        let param = {
            user_id: user?.id,
            branch_id: selectedId
          }
          await dispatch(addNewUserBranch(param))
          await closeBranchModal();
          await getBranch(user?.id);
    }

    const closeBranchModal = () => {
        setSelectedId('');
        setBranchesModalVisible(false)
    }

    const openDeleteModal = async (id:string) => {
        setDeleteModalVisible(true)
        setDeleteModalText('Are you sure, you want to delete this branch.')
        setDeleteSpcId(id)
    }

    const handleDeleteModal = async () => {
          await dispatch(deleteUserBranch(deleteSpcId))
          await closeDeleteModal();
          await getBranch(user?.id);
    }

    const closeDeleteModal = () => {
        setDeleteModalText('');
        setDeleteModalVisible(false)
    }

    return (
        <div className={'col-md-12 main-container'}>
            <Row>
            <Col span={22}>
                <Title  level={4}>Branch</Title>      
            </Col>
            <Col span={2}>
            <Tooltip title="Add New Branch">
                <Button type="primary" shape="circle" onClick={ openBranchModal } icon={<PlusOutlined />} /> 
            </Tooltip>
                
            </Col>
            </Row>
            <TableLayout loading={loading} dataSource={userBranches} columns={columns} type='empty_filter' />
            
            <Modal title="Add Branch" visible={ branchesModalVisible} onOk={handleBranch} onCancel={closeBranchModal}>
                <Row>
                    <Col span={24}>
                        <Select onChange={handleSpcId} style={{width:'80%'}}>
                            {
                                branchArr.map((branch: any) => {
                                    return <Option value={branch?.id}>{branch?.name +' ('+ branch?.code+')'}</Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
            
            </Modal>
            <Modal title="Delete Branch" visible={ deleteModalVisible } onOk={handleDeleteModal} onCancel={closeDeleteModal}>
               {deleteModalText}
            </Modal>
        </div>
    );
};

export default UserBranches;
