import { Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../constants";

const PermissionComponent = ({
  children,
  permission,
}: {
  children: React.ReactChild;
  permission: string;
}) => {
  const { myPermissions } = useSelector((state: RootState) => state.auth);
  if (!myPermissions?.scope_values?.actions || !permission ||true) {
    return <>{children}</>;
  }
  const actions = myPermissions?.scope_values?.actions;
  return actions.indexOf(permission) > -1 ||
    actions.indexOf(permission.split(".")[0]) > -1 ? (
    // <Tooltip title={permission} placement={"bottom"}>
    <span>{children}</span>
  ) : (
    // </Tooltip>
    <>
      <Tag>
        <strong>{permission}</strong> is expected
      </Tag>
    </>
  );
};
export default PermissionComponent;
