import { useState, useEffect } from 'react';
import { Col, Row, Typography, Select, Modal, Divider, Tag, InputNumber } from "antd";
import { DeleteFilled, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchMedicinesById, fetchStationsList, fetchRxMasterList, deleteMedicineById, updateMedicineItem, fetchMedicinePriceInventory } from '../../redux/reducers/pharmacy.slice';
import { RootState } from "../../shared/constants";

type Props = {
  medicineTotalPrice?: any;
  medicineUsedComponent?: any;
  newOrderAuditTrail?: any;
  medicineArrCallBack?:any;
  requestId?:string
  makeMedicineComponentEditable?:any
  deliveryAddress?:string
}
function MedicineComponent(props: Props) {
  const dispatch = useDispatch();
  const { medicine, rxList, pharmacyDetails, stationNameFromAddress } = useSelector((state: RootState) => state.pharmacy);
  const { Text } = Typography;
  const { Option } = Select;
  const orderStatus = pharmacyDetails.status;
  // const stationId = pharmacyDetails.station_id;
  const [ medicineArr,  setMedicineArr] = useState(medicine);
  const [ deliveryAddress,  setDeliveryAddress] = useState(null as any);
  const [ stationNameFromAddressArr,  setStationNameFromAddressArr] = useState(stationNameFromAddress);

  // console.log(stationNameFromAddress)

  useEffect(()=>{
    let clear = 0;
    if(props?.deliveryAddress === null){
      setStationNameFromAddressArr([])
      clear = 1
    }else{
      if(deliveryAddress !== props.deliveryAddress && deliveryAddress !== null && deliveryAddress !== false){
        clear = 1
      }
      setDeliveryAddress(props.deliveryAddress)
    }
    if(clear === 1){
      if(medicineArr?.length > 0){
        for(let k=0; k<medicineArr.length; ++k){
          let medPayload = medicinePayload(medicineArr[k]);
          if(medPayload.station_id !== null){
            medPayload.station_id = null;
            medPayload.station_id = null;
            dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload } ))
          }
        }
        setMedicineArr( (prevState:any) => {
          return prevState.map( (item: any) => { 
              return { ...item, station_id: null, station: null }
          });
        })
      }
    }
  }, [props?.deliveryAddress]) 
  useEffect(()=>{
    if(stationNameFromAddress){
      setStationNameFromAddressArr(stationNameFromAddress)
    }
  }, [stationNameFromAddress])
  // const [medicine, setMedicine] = useState([
  //     {
  //       id:1,
  //       name: 'ceftum 500 gm tab',
  //       quantity: 2,
  //       price: 150,
  //       isprescription: true,
  //       prescriptionValue: 'yes',
  //       otherDetails: 'cefuroxime 500 mg' ,
  //       manufacturerName: 'Cipla',
  //       isDelivered: true,
  //     },
  //   ]);

  const [isDeleteMediModalVisible, setIsDeleteMediModalVisible] = useState(false);
  const [deletedMedicineData, setDeletedMedicineData] = useState({"item_name": ''});


  // const addQuantity = (data: any) => {
  //   setMedicineArr( (prevState:any) => {
  //     return prevState.map( (item: any) => { 
  //       if (item.id === data.id) {
  //         addItem(item)
  //         return { ...item, item_quantity: item.item_quantity+1 }
  //       } else{
  //         return item;
  //       }
  //     });
  //   })
  // }

  // const removeQuantity = (data: any) => {
  //   setMedicineArr( (prevState:any) => {
  //     return prevState.map( (item: any) => { 
  //       if (item.item_quantity > 0 && item.id === data.id) {
  //         removeItem(item)
  //         return { ...item, item_quantity: item.item_quantity-1 }
  //       } else{
  //         return item;
  //       }
  //     });
  //   })
  // }

  // const addItem = async(data: any) => {
  //   let medPayload = medicinePayload(data);
  //   medPayload.item_quantity = data.item_quantity+1;
  //   await dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload }))
  // }

  // const removeItem = async (data: any) => {
  //   let medPayload = medicinePayload(data);
  //   medPayload.item_quantity = data.item_quantity-1;
  //   await dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload }))
  // }

  useEffect(() => {
    dispatch(fetchStationsList());
    dispatch(fetchRxMasterList());
  }, [])

  useEffect( () => {
    //console.log('eeeee 33333',medicine)
    // let sortedMedicine = medicine.sort(function(a:any, b:any) { return a.created_at - b.created_at })
    setMedicineArr(medicine)
    getTotalMedicineCost(medicine)
  },[medicine])

  useEffect(() => {
    console.log('eeeee 4444',medicineArr)
    getTotalMedicineCost(medicineArr)
    if(props && props?.medicineArrCallBack){
      props?.medicineArrCallBack(medicineArr);
    }
  },[medicineArr])

  const showDeleteMediModal = ( data: any) => {
    let payload = {
      "item_id": data.id,
      "item_name": data.item_name,
      "item_quantity": data.item_quantity,
      "station_id": pharmacyDetails.station_id,
      "hospital_id": pharmacyDetails.hospital_id,
      "item_price": data.item_price,
      "request_id": data.request_id,
      "request_date": data.request_date,
      "item_code": data.item_code.toString()
  }
    setIsDeleteMediModalVisible(true);
    setDeletedMedicineData(payload);
  };
  const RouteParams: any = useParams();

  const handleDeleteMedicine = async () => {
    await dispatch(deleteMedicineById(deletedMedicineData))
    await dispatch(fetchMedicinesById( props.requestId ? props.requestId : RouteParams['id']));
    setIsDeleteMediModalVisible(false);
    setDeletedMedicineData({item_name:''});
  };

  const handleDeleteMediCancel = () => {
    setIsDeleteMediModalVisible(false);
    setDeletedMedicineData({item_name:''});
  };

  const getTotalMedicineCost = (medi: any) => {
    let totalPrice = 0;
    if(medi && medi.length > 0){
      medi.forEach((i: any) => {
        totalPrice += i.item_price*i.item_quantity;
      });
      let medicineDetails = {
        totalPrice: totalPrice,
        medicineCount: medi.length
      }
      props.medicineTotalPrice(medicineDetails);
    } else{
      let medicineDetails = {
        totalPrice: 0,
        medicineCount: 0
      }
      props.medicineTotalPrice(medicineDetails);
    }
  }

  const medicinePayload = (data: any) => { 
    let item = {
      "request_id": data.request_id,
      "item_id": data.id,
      "item_code": (data.item_code).toString(),
      "item_name": data.item_name,
      "item_price": data.item_price,
      "item_quantity": data.item_quantity,
      "request_date": data.request_date,
      "station_id": data.station_id,
      "hospital_id": data.hospital_id || null,
      "status": data.status,
      "prescription_required": data.prescription_required ? data.prescription_required : false,
      "rx_info": data.rx_info,
      "appointment_id": pharmacyDetails.appointment_id
    }
    return item;
  }

  const handlePrescriptionValue = (val: any, data: any) => {
    setMedicineArr( (prevState:any) => {
      return prevState.map( (item: any) => { 
        if ( item.id === data.id && val === 'yes') {
          let medPayload = medicinePayload(data);
          medPayload.prescription_required = true;
          dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload }))
          return { ...item, prescription_required: true }
        } else if ( item.id === data.id && val === 'no') {
          let medPayload = medicinePayload(data);
          medPayload.prescription_required = false;
          dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload }))
          return { ...item, prescription_required: false }
        } else{
          return item;
        }
      });
    })
  }

  const handleRxValue = (val: any, data: any) => {
    setMedicineArr( (prevState:any) => {
      return prevState.map( (item: any) => { 
        if ( item.id === data.id) {
          let medPayload = medicinePayload(data);
          medPayload.rx_info = val;
          dispatch(updateMedicineItem({ id: medPayload.item_id, data: medPayload }))
          return { ...item, rx_info: val }
        } else {
          return item;
        }
      });
    })
  }

  const handlePharmacy = async (evt:any, data: any) => {
    let medPayload = medicinePayload(data);
    medPayload.station_id = evt;
    // const prevMed = medicineArr.slice();
    const ret = await handleInventry(data, evt, { id: medPayload.item_id, data: medPayload })
    console.log('eeeee 222111', ret)
    if(ret){
      setMedicineArr( (prevState:any) => {
        return prevState.map( (item: any) => { 
          if ( item.id === data.id) {
            return { ...item, station_id: evt }
          } else{
            return item;
          }
        });
      })
    }
    
  }

  const handleInventry = async (data: any, station_id: any, updatePayload:any) => {
    let res = await dispatch(fetchMedicinePriceInventory( { item_id: data.item_code, station_id })) //station_id: data?.station?.code
    if(res?.payload?.data?.statusCode === 200){
      calculatePrice(res?.payload?.data?.data, data?.id)
      dispatch(updateMedicineItem( updatePayload ))
      return true
    }else{
      return false
    }
  }

  const calculatePrice = (inventoryArr: any, itemId: string) => {
    if(inventoryArr && inventoryArr.length > 0){
      setMedicineArr(
        medicineArr.map( (item:any) => 
          item.id === itemId 
          ? {...item, item_price: inventoryArr[0]?.MRP} 
          : item 
      ))
    }
  }

  function handleMedQuantity(value:any, data:any) {
    // console.log('changed', value);
    if(value){
      setMedicineArr( (prevState:any) => {
        return prevState.map( (item: any) => { 
          if (item.item_quantity > 0 && item.id === data.id) {
            medicineCal(item, value)
            return { ...item, item_quantity: value }
          } else{
            return item;
          }
        });
      })
    }
  }

  const medicineCal = async(data: any, quantity:any) => {
    let medPayload = medicinePayload(data);
    medPayload.item_quantity = quantity;
    await dispatch(updateMedicineItem( { id: medPayload.item_id, data: medPayload }))
  }
  return (
    <>
      { medicineArr?.length > 0 ? 
        <Row className="full-width pdl10 pdr10">
          <table className="" style={{ fontSize: '11px!important', width: '100%', borderSpacing:'10px', borderCollapse:'separate'}}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left'}}>Medicine Name</th>
                <th style={{ textAlign: 'left'}}>Qty/Price</th>
                {/* <th>As Per Prescription?</th>
                <th>Pharmacy</th> */}
              </tr>
            </thead>
            <tbody>
              {medicineArr?.map((indRow: any, index: any) =>
              <>
                <tr key={index}>
                  <td style={{textTransform: 'uppercase', fontSize: '12px', position: 'relative', width: '300px'}}>{index + 1}. {indRow?.item_name ? indRow?.item_name : '-'}
                    <br />
                    <span className="sub-title cursor" style={{top: '28px', left: '10px', fontSize: '10px', textTransform:'capitalize'}}>{indRow?.manufacturerName} 
                      {indRow?.prescription_required === true ? <Tag className="solid" color="blue" style={{ marginLeft: '10px', fontSize: '10px'}}>Rx Required</Tag>: ''}
                    </span>
                  </td>
                  { 
                    indRow?.station_id ? <td >
                    {/* { orderStatus === 'pending' || (orderStatus === 'open' && props?.newOrderAuditTrail !== 'newOrderAuditTrail' ) || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ? 
                      <MinusCircleOutlined className='cursor' onClick={() => {removeQuantity(indRow)}}/>
                      : ''
                    }
                    <Text className='mrgl5'>{indRow?.item_quantity}</Text>
                    { orderStatus === 'pending' || (orderStatus === 'open' && props?.newOrderAuditTrail !== 'newOrderAuditTrail') || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ? 
                        <PlusCircleOutlined className='mrgl5 cursor' onClick={() => {addQuantity(indRow)}}/>
                      : ''
                    } */}
                    { orderStatus === 'pending' || (orderStatus === 'open' && props?.newOrderAuditTrail !== 'newOrderAuditTrail' ) || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ? 
                      <InputNumber min={1} defaultValue={indRow.item_quantity || 1} style={{width: '70px'}} onChange={(e) => {handleMedQuantity(e,indRow)}} />
                      :
                      <InputNumber min={1} disabled={true} defaultValue={indRow.item_quantity || 1} style={{width: '70px'}} />
                    }
                    

                    <span style={{padding: '0 15px', position: 'relative', display: 'inline-block', height: '30px'}}>Rs. {(indRow?.item_price * indRow?.item_quantity).toFixed(2)}
                      <span className="sub-title" style={{position: 'absolute', top: '18px', left: '13px', fontSize: '10px'}}>(Rs. {(indRow?.item_price?.toFixed(2))} * {indRow?.item_quantity})</span>
                    </span>
                    { orderStatus === 'pending' || (orderStatus === 'open' && props?.newOrderAuditTrail !== 'newOrderAuditTrail') || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ?
                    <span className="sub-title cursor"><DeleteFilled onClick={() => {showDeleteMediModal(indRow)}} /></span>
                    : '' }
                  </td> : <td><small className="bold-600 text-danger">Select Pharmacy Station</small> <span className="sub-title cursor"><DeleteFilled onClick={() => {showDeleteMediModal(indRow)}} /></span></td> }
                </tr>
                {(props?.medicineUsedComponent !== 'placeOrdeModal' && props?.newOrderAuditTrail !== 'newOrderAuditTrail') || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ? 
                  <>
                    <tr>
                      <Text className="" style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'bold'}}>Is Prescription Required?</Text>
                      <td>
                      { orderStatus === 'pending' || orderStatus === 'open' || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ?
                        <Select style={{maxWidth: '70px'}} onChange={(evt:any)=> handlePrescriptionValue(evt, indRow)} value={indRow?.prescription_required === false ? 'no' : 'yes'} placeholder="">
                          <Option value="no">No</Option>
                          <Option value="yes">Yes</Option>
                        </Select>
                        :
                        <Text>{indRow?.prescription_required === false ? 'No' : 'Yes'}</Text>
                      }
                      { orderStatus === 'pending' || orderStatus === 'open' || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ?
                        <>
                          {indRow?.prescription_required === true ? 
                          <Select style={{paddingLeft: '10px', maxWidth: '200px'}} defaultValue={indRow?.rx_info} onChange={(evt:any)=> handleRxValue(evt, indRow)} placeholder="Select Option">
                            {
                              rxList?.map((ind: any, index: any) =>
                                <Option value={ind?.name}>{ind?.name}</Option>
                              )
                            }
                          </Select>
                          : ''}
                        </> 
                        :
                        <Text>{indRow?.prescription_required === false ? '' : indRow?.rx_info ? ' ('+indRow?.rx_info+')' : "" }</Text>
                      }
                      </td>
                    </tr>
                    <tr>
                      <Text className="" style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'bold'}}>Pharmacy</Text>
                      <td>
                      { orderStatus === 'pending' || orderStatus === 'open' || (orderStatus === 'qc_done' && props?.makeMedicineComponentEditable === 'handleEditMedicine') ?
                        <Select onChange={(evt:any)=> handlePharmacy(evt, indRow)} value={indRow?.station_id}  placeholder="Select Option" style={{width: '200px'}}>
                        {
                          stationNameFromAddressArr?.station?.map((indRow: any, index: any) =>
                            <Option key={indRow?.id} value={indRow?.id}>{indRow?.name}</Option>
                          )
                        }
                        </Select>
                        : 
                        <Text>{indRow?.station?.name}</Text>
                      }
                      </td>
                    </tr>
                  </> : ''
                }
                <tr><td colSpan={2}><Divider className="mt10 mb-10"/></td></tr>
                </>
              )}
            </tbody>
          </table>
        </Row> : 
        <>
          <Row className="sub-title full-width"> 
            <Col span={9} > </Col>
            <Col span={13} >  <SearchOutlined style={{fontSize: '70px'}} /> </Col>
          </Row>
          <Row className="sub-title full-width"> 
            <Col span={8} > </Col>
            <Col span={14}> <Text type="secondary" className="bold sub-text">No medicines added.</Text></Col>
          </Row>
        </>
      }
      <Modal title={deletedMedicineData.item_name} visible={isDeleteMediModalVisible} onOk={handleDeleteMedicine} onCancel={handleDeleteMediCancel}>
        Above mentioned medicine will be removed. Do you want to proceed?
      </Modal>
    </>
  );
}

export default MedicineComponent;
