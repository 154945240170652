import { t } from "i18next";
import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AccessGroupList from "../pages/admin/AccessGroups/access-groups.page";
import AccessGroupCreate from "../pages/admin/AccessGroups/components/access-group-create.page";
import AccessGroupEdit from "../pages/admin/AccessGroups/components/access-group-edit";
import AdminDashboardPage from "../pages/admin/admin.dashboard.page";
import AdminStats from "../pages/admin/AdminStats/admin-stats.page";
import AppointmentHistory from "../pages/admin/Appointment/appointment-history-page";
import AppointmentList from "../pages/admin/Appointment/appointment.page";
import BookAppointment from "../pages/admin/Appointment/components/book-appointment";
import BranchesList from "../pages/admin/Branches/branches.page";
import BranchCreate from "../pages/admin/Branches/components/branch-create.page";
import BranchEdit from "../pages/admin/Branches/components/branch-edit";
import CmsData from "../pages/admin/CmsData/cms-data.page";
import DepartmentCreate from "../pages/admin/Departments/components/department-create.page";
import DepartmentEdit from "../pages/admin/Departments/components/department-edit";
import DepartmentsList from "../pages/admin/Departments/departments.page";
import DocterCreate from "../pages/admin/Doctors/components/doctor-create.page";
import DocterEdit from "../pages/admin/Doctors/components/doctor-edit";
import DoctorProperties from "../pages/admin/Doctors/components/doctor-properties";
import DoctorSpecialization from "../pages/admin/Doctors/components/doctor-specialization";
import DocterList from "../pages/admin/Doctors/doctors.page";
import AdminPermissionsList from "../pages/admin/Permissions/PermissionsList.page";
import ProfilePage from "../pages/admin/Profile/profile.page";
import RegionsCreate from "../pages/admin/Regions/components/region-create.page";
import RegionsEdit from "../pages/admin/Regions/components/region-edit";
import RegionsList from "../pages/admin/Regions/regions.page";
import RoleCreate from "../pages/admin/Roles/components/role-create.page";
import RoleEdit from "../pages/admin/Roles/components/role-edit";
import AdminRolesList from "../pages/admin/Roles/roles.page";
import GeneralSettings from "../pages/admin/Settings/general-settings.page";
import AddSlot from "../pages/admin/Slots/components/slot-create";
import SlotEdit from "../pages/admin/Slots/components/slot-edit";
import Slots from "../pages/admin/Slots/slots.page";
import TeamCreate from "../pages/admin/Teams/components/team-create.page";
import TeamEdit from "../pages/admin/Teams/components/team-edit";
import Teams from "../pages/admin/Teams/teams.page";
import UserEdit from "../pages/admin/Users-Component/components/user-edit.page";
import DoctorCordinator from "../pages/boss/DoctotCordinator/doctorCordinator";
import AdminDesktopLayout from "../shared/layouts/admin-desktop.layout";

import {
    ROUTE_ADMIN_DASHBOARD, ROUTE_ADMIN_STATS, ROUTE_APPOINTMENTS,
    ROUTE_APPOINTMENTS_HISTORY,
    ROUTE_BOOK_APPOINTMENTS, ROUTE_BRANCHES, ROUTE_BRANCH_ACTION, ROUTE_BRANCH_CREATE, ROUTE_CMS_DATA, ROUTE_DEPARTMENTS, ROUTE_DEPARTMENT_ACTION,
    ROUTE_DEPARTMENT_CREATE, ROUTE_DOCTOR,
    ROUTE_DOCTOR_ACTION,
    ROUTE_DOCTOR_CREATE, ROUTE_DOCTOR_PROPERTIES, ROUTE_DOCTOR_SPECIALIZATION, ROUTE_GENERAL_SETTINGS, ROUTE_PERMISSIONS, ROUTE_REGIONS, ROUTE_REGION_ACTION, ROUTE_REGION_CREATE, ROUTE_REQUEST_LOGS, ROUTE_REQUEST_LOG_ACTION, ROUTE_ROLES, ROUTE_ROLE_ACTION,
    ROUTE_ROLE_CREATE, ROUTE_TEAMS, ROUTE_TEAM_ACTION,
    ROUTE_TEAM_CREATE, ROUTE_THB_SYNC_FAILED_APPS, ROUTE_TP_SYNC_FAILED_APPS, ROUTE_TRIGGER_LOGS, ROUTE_USERS, ROUTE_USER_ACTION,
    ROUTE_USER_CREATE
} from "../shared/routes/admin.routes.constants";
import {
    ROUTE_DOCTOR_CORDINATOR, ROUTE_DOCTOR_SLOTS, ROUTE_DOCTOR_SLOT_CREATE, ROUTE_SLOT_ACTION
} from "../shared/routes/doctor.routes.constants";
import {
    ROUTE_ACCESS_GROUPS, ROUTE_ACCESS_GROUP_ACTION,
    ROUTE_ACCESS_GROUP_CREATE, ROUTE_MESSAGE_TEMPLATES, ROUTE_MESSAGE_TEMPLATE_ACTION,
    ROUTE_MESSAGE_TEMPLATE_CREATE, ROUTE_PAGE_NOT_FOUND, ROUTE_PROFILE
} from "../shared/routes/route.constants";


const ROUTES: any[] = [
    {
        name: 'Admin Profile',
        route: ROUTE_PROFILE(':account', 'admin'),
        component: ProfilePage
    },
    {
        name: 'Admin Dashboard',
        route: ROUTE_ADMIN_DASHBOARD(':account'),
        component: AdminDashboardPage
    },
    {
        name: 'Users',
        route: ROUTE_USERS(':account'),
        component: lazy(() => import('../pages/admin/Users-Component/users.page'))
    },
    {
        name: 'User Create',
        route: ROUTE_USER_CREATE,
        component: lazy(() => import('../pages/admin/Users-Component/components/user-create.page'))
    },
    {
        name: 'User Edit',
        route: ROUTE_USER_ACTION(':id', 'edit'),
        component: UserEdit
    },
    {
        name: 'RequestLogs',
        route: ROUTE_REQUEST_LOGS(':account'),
        component: lazy(() => import('../pages/admin/RequestLogs/request-logs.page'))
    },
    {
        name: 'TriggerLogs',
        route: ROUTE_TRIGGER_LOGS(':account'),
        component: lazy(() => import('../pages/admin/TpResponse/tp-response.page'))
    },
    {
        name: 'TriggerLogs',
        route: ROUTE_TP_SYNC_FAILED_APPS(':account'),
        component: lazy(() => import('../pages/admin/TpSyncFailed/tp-sync-failed.page'))
    },
    {
        name: 'TriggerLogs',
        route: ROUTE_THB_SYNC_FAILED_APPS(':account'),
        component: lazy(() => import('../pages/admin/THBSyncFailed/thb-sync-failed.page'))
    },
    {
        name: 'Request Log View',
        route: ROUTE_REQUEST_LOG_ACTION(':account', ':id', 'view'),
        component: lazy(() => import('../pages/admin/RequestLogs/components/request-log-view'))
    },
    {
        name: 'Message template',
        route: ROUTE_MESSAGE_TEMPLATES(':account'),
        component: lazy(() => import('../pages/admin/MessageTemplates/message-templates.page'))
    },
    {
        name: 'Message Template Create',
        route: ROUTE_MESSAGE_TEMPLATE_CREATE(':account'),
        component: lazy(() => import('../pages/admin/MessageTemplates/components/message-template-create.page'))
    },
    {
        name: 'Message Template Edit',
        route: ROUTE_MESSAGE_TEMPLATE_ACTION(':account', ':id', 'edit'),
        component: lazy(() => import('../pages/admin/MessageTemplates/components/message-template-edit'))
    },
    {
        name: 'Permissions',
        route: ROUTE_PERMISSIONS,
        component: AdminPermissionsList
    },
    {
        name: "Roles",
        route: ROUTE_ROLES,
        component: AdminRolesList
    },

    {
        name: 'Role Create',
        route: ROUTE_ROLE_CREATE,
        component: RoleCreate
    },
    {
        name: 'Role Edit',
        route: ROUTE_ROLE_ACTION(':id', 'edit'),
        component: RoleEdit
    },
    {
        name: "AccessGroups",
        route: ROUTE_ACCESS_GROUPS(':account'),
        component: AccessGroupList
    },
    {
        name: 'Access Group Create',
        route: ROUTE_ACCESS_GROUP_CREATE(':account'),
        component: AccessGroupCreate
    },
    {
        name: 'Access Group Edit',
        route: ROUTE_ACCESS_GROUP_ACTION(':account', ':id', 'edit'),
        component: AccessGroupEdit
    },
    {
        name: "Branches",
        route: ROUTE_BRANCHES,
        component: BranchesList
    },
    {
        name: 'Branch Create',
        route: ROUTE_BRANCH_CREATE,
        component: BranchCreate
    },
    {
        name: 'Branch Edit',
        route: ROUTE_BRANCH_ACTION(':id', 'edit'),
        component: BranchEdit
    },
    {
        name: "Regions",
        route: ROUTE_REGIONS,
        component: RegionsList
    },
    {
        name: 'Region Create',
        route: ROUTE_REGION_CREATE,
        component: RegionsCreate
    },
    {
        name: 'Region Edit',
        route: ROUTE_REGION_ACTION(':id', 'edit'),
        component: RegionsEdit
    },
    {
        name: 'Departments',
        route: ROUTE_DEPARTMENTS,
        component: DepartmentsList
    },
    {
        name: 'Department Create',
        route: ROUTE_DEPARTMENT_CREATE,
        component: DepartmentCreate
    },
    {
        name: 'Department Edit',
        route: ROUTE_DEPARTMENT_ACTION(':id', 'edit'),
        component: DepartmentEdit
    },
    {
        name: 'Doctor',
        route: ROUTE_DOCTOR,
        component: DocterList
    },
    {
        name: 'Doctor Create',
        route: ROUTE_DOCTOR_CREATE(':id'),
        component: DocterCreate
    },
    {
        name: 'Doctor Edit',
        route: ROUTE_DOCTOR_ACTION(':id', 'edit'),
        component: DocterEdit
    },
    {
        name: 'Doctor Slots',
        route: ROUTE_DOCTOR_SLOTS(':id'),
        component: Slots
    },
    {
        name: "Slot Create",
        route: ROUTE_DOCTOR_SLOT_CREATE(':id'),
        component: AddSlot
    },
    {
        name: 'Slot Edit',
        route: ROUTE_SLOT_ACTION(':id', 'edit'),
        component: SlotEdit
    },
    {
        name: 'Teams',
        route: ROUTE_TEAMS,
        component: Teams
    },
    {
        name: 'Team Create',
        route: ROUTE_TEAM_CREATE,
        component: TeamCreate
    },
    {
        name: 'Team Edit',
        route: ROUTE_TEAM_ACTION(':id', 'edit'),
        component: TeamEdit
    },
    {
        name: t('NavMenu.Appointment'),
        route: ROUTE_APPOINTMENTS,
        component: AppointmentList
    },
    {
        name: t('NavMenu.BookAppointment'),
        route: ROUTE_BOOK_APPOINTMENTS,
        component: BookAppointment
    },
    {
        name: t('Appointment History'),
        route: ROUTE_APPOINTMENTS_HISTORY,
        component: AppointmentHistory
    },
    // {
    //     name: "DUmmy",
    //     route: DUMMY,
    //     component: AdminDashboardPage
    // }
    {
        name: 'Settigs',
        route: ROUTE_GENERAL_SETTINGS,
        component: GeneralSettings
    },
    {
        name: 'Stats',
        route: ROUTE_ADMIN_STATS,
        component: AdminStats
    },
    {
        name: 'Doctor Specialization',
        route: ROUTE_DOCTOR_SPECIALIZATION(':id'),
        component: DoctorSpecialization
    },
    {
        name: 'Doctor Properties',
        route: ROUTE_DOCTOR_PROPERTIES(':id'),
        component: DoctorProperties
    },
    {
        name: 'CMS Data',
        route: ROUTE_CMS_DATA,
        component: CmsData
    },
    {
        name : "Coordinator",
        Route : ROUTE_DOCTOR_CORDINATOR,
        component: DoctorCordinator
    },
    
];

const AdminRoutes = () => {
    return (
        <AdminDesktopLayout>
            <Switch>
                {
                    ROUTES.map((route: any) => {
                        return <Route exact path={route.route}
                                      component={route.component}/>;
                    })
                }
                <Redirect to={ROUTE_PAGE_NOT_FOUND()}/>

            </Switch>
        </AdminDesktopLayout>
    );
}
export default AdminRoutes;