import { EditOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Card, Row, Space, Tag } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewPatient } from "../../../redux/reducers/patients.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import { PATIENT_MODULE_ACTIONS_READ } from "../../../shared/components/permission.constants";
import { Patient } from "../../../shared/types/patient.type";
import PatientForm from "./components/patients.form";
type Props = {
  title: string;
  data?: Array<any>;
  mode: string;
};

const PatientDetails: React.FunctionComponent<Props> = ({
  title,
  mode,
  data,
}) => {
  const [intialmode, setMode] = useState(mode);
  const dispatch = useDispatch();
  const onSubmit = (values: Patient) => {
    dispatch(addNewPatient(values));
    setMode("view");
  };
  return (
    <PermissionComponent permission={PATIENT_MODULE_ACTIONS_READ}>
      <Card
        title={
          <Space>
            {title} <Tag color="blue">VIP</Tag>{" "}
          </Space>
        }
        extra={
          intialmode === "view" ? (
            <EditOutlined className="cursor" onClick={() => setMode("edit")} />
          ) : (
            <MinusCircleOutlined
              className="cursor"
              onClick={() => setMode("view")}
            />
          )
        }
        style={{ width: "100%" }}
      >
        <Row>
          <PatientForm
            mode={intialmode}
            patient={data && data[0]}
            onSubmit={(values) => onSubmit(values)}
          />
        </Row>
      </Card>
    </PermissionComponent>
  );
};

export default PatientDetails;
