export const countryCodes = [
    {
        "dial_code": "+1",
        "flag": "\ud83c\udde8\ud83c\udde6",
        "cc": "CA",
        "country": "Canada"
    },
    {
        "dial_code": "+1",
        "flag": "\ud83c\uddfa\ud83c\uddf8",
        "cc": "US",
        "country": "United States"
    },
    {
        "dial_code": "+1242",
        "flag": "\ud83c\udde7\ud83c\uddf8",
        "cc": "BS",
        "country": "Bahamas"
    },
    {
        "dial_code": "+1246",
        "flag": "\ud83c\udde7\ud83c\udde7",
        "cc": "BB",
        "country": "Barbados"
    },
    {
        "dial_code": "+1264",
        "flag": "\ud83c\udde6\ud83c\uddee",
        "cc": "AI",
        "country": "Anguilla"
    },
    {
        "dial_code": "+1268",
        "flag": "\ud83c\udde6\ud83c\uddec",
        "cc": "AG",
        "country": "Antigua and Barbuda"
    },
    {
        "dial_code": "+1284",
        "flag": "\ud83c\uddfb\ud83c\uddec",
        "cc": "VG",
        "country": "Virgin Islands"
    },
    {
        "dial_code": "+1340",
        "flag": "\ud83c\uddfb\ud83c\uddee",
        "cc": "VI",
        "country": "Virgin Islands"
    },
    {
        "dial_code": "+1441",
        "flag": "\ud83c\udde7\ud83c\uddf2",
        "cc": "BM",
        "country": "Bermuda"
    },
    {
        "dial_code": "+1473",
        "flag": "\ud83c\uddec\ud83c\udde9",
        "cc": "GD",
        "country": "Grenada"
    },
    {
        "dial_code": "+1649",
        "flag": "\ud83c\uddf9\ud83c\udde8",
        "cc": "TC",
        "country": "Turks and Caicos Islands"
    },
    {
        "dial_code": "+1664",
        "flag": "\ud83c\uddf2\ud83c\uddf8",
        "cc": "MS",
        "country": "Montserrat"
    },
    {
        "dial_code": "+1670",
        "flag": "\ud83c\uddf2\ud83c\uddf5",
        "cc": "MP",
        "country": "Northern Mariana Islands"
    },
    {
        "dial_code": "+1671",
        "flag": "\ud83c\uddec\ud83c\uddfa",
        "cc": "GU",
        "country": "Guam"
    },
    {
        "dial_code": "+1684",
        "flag": "\ud83c\udde6\ud83c\uddf8",
        "cc": "AS",
        "country": "American Samoa"
    },
    {
        "dial_code": "+1758",
        "flag": "\ud83c\uddf1\ud83c\udde8",
        "cc": "LC",
        "country": "Saint Lucia"
    },
    {
        "dial_code": "+1767",
        "flag": "\ud83c\udde9\ud83c\uddf2",
        "cc": "DM",
        "country": "Dominica"
    },
    {
        "dial_code": "+1784",
        "flag": "\ud83c\uddfb\ud83c\udde8",
        "cc": "VC",
        "country": "Saint Vincent and the Grenadines"
    },
    {
        "dial_code": "+1849",
        "flag": "\ud83c\udde9\ud83c\uddf4",
        "cc": "DO",
        "country": "Dominican Republic"
    },
    {
        "dial_code": "+1868",
        "flag": "\ud83c\uddf9\ud83c\uddf9",
        "cc": "TT",
        "country": "Trinidad and Tobago"
    },
    {
        "dial_code": "+1869",
        "flag": "\ud83c\uddf0\ud83c\uddf3",
        "cc": "KN",
        "country": "Saint Kitts and Nevis"
    },
    {
        "dial_code": "+1876",
        "flag": "\ud83c\uddef\ud83c\uddf2",
        "cc": "JM",
        "country": "Jamaica"
    },
    {
        "dial_code": "+1939",
        "flag": "\ud83c\uddf5\ud83c\uddf7",
        "cc": "PR",
        "country": "Puerto Rico"
    },
    {
        "dial_code": "+20",
        "flag": "\ud83c\uddea\ud83c\uddec",
        "cc": "EG",
        "country": "Egypt"
    },
    {
        "dial_code": "+211",
        "flag": "\ud83c\uddf8\ud83c\uddf8",
        "cc": "SS",
        "country": "South Sudan"
    },
    {
        "dial_code": "+212",
        "flag": "\ud83c\uddf2\ud83c\udde6",
        "cc": "MA",
        "country": "Morocco"
    },
    {
        "dial_code": "+213",
        "flag": "\ud83c\udde9\ud83c\uddff",
        "cc": "DZ",
        "country": "Algeria"
    },
    {
        "dial_code": "+216",
        "flag": "\ud83c\uddf9\ud83c\uddf3",
        "cc": "TN",
        "country": "Tunisia"
    },
    {
        "dial_code": "+218",
        "flag": "\ud83c\uddf1\ud83c\uddfe",
        "cc": "LY",
        "country": "Libyan Arab Jamahiriya"
    },
    {
        "dial_code": "+220",
        "flag": "\ud83c\uddec\ud83c\uddf2",
        "cc": "GM",
        "country": "Gambia"
    },
    {
        "dial_code": "+221",
        "flag": "\ud83c\uddf8\ud83c\uddf3",
        "cc": "SN",
        "country": "Senegal"
    },
    {
        "dial_code": "+222",
        "flag": "\ud83c\uddf2\ud83c\uddf7",
        "cc": "MR",
        "country": "Mauritania"
    },
    {
        "dial_code": "+223",
        "flag": "\ud83c\uddf2\ud83c\uddf1",
        "cc": "ML",
        "country": "Mali"
    },
    {
        "dial_code": "+224",
        "flag": "\ud83c\uddec\ud83c\uddf3",
        "cc": "GN",
        "country": "Guinea"
    },
    {
        "dial_code": "+225",
        "flag": "\ud83c\udde8\ud83c\uddee",
        "cc": "CI",
        "country": "Cote d'Ivoire"
    },
    {
        "dial_code": "+226",
        "flag": "\ud83c\udde7\ud83c\uddeb",
        "cc": "BF",
        "country": "Burkina Faso"
    },
    {
        "dial_code": "+227",
        "flag": "\ud83c\uddf3\ud83c\uddea",
        "cc": "NE",
        "country": "Niger"
    },
    {
        "dial_code": "+228",
        "flag": "\ud83c\uddf9\ud83c\uddec",
        "cc": "TG",
        "country": "Togo"
    },
    {
        "dial_code": "+229",
        "flag": "\ud83c\udde7\ud83c\uddef",
        "cc": "BJ",
        "country": "Benin"
    },
    {
        "dial_code": "+230",
        "flag": "\ud83c\uddf2\ud83c\uddfa",
        "cc": "MU",
        "country": "Mauritius"
    },
    {
        "dial_code": "+231",
        "flag": "\ud83c\uddf1\ud83c\uddf7",
        "cc": "LR",
        "country": "Liberia"
    },
    {
        "dial_code": "+232",
        "flag": "\ud83c\uddf8\ud83c\uddf1",
        "cc": "SL",
        "country": "Sierra Leone"
    },
    {
        "dial_code": "+233",
        "flag": "\ud83c\uddec\ud83c\udded",
        "cc": "GH",
        "country": "Ghana"
    },
    {
        "dial_code": "+234",
        "flag": "\ud83c\uddf3\ud83c\uddec",
        "cc": "NG",
        "country": "Nigeria"
    },
    {
        "dial_code": "+235",
        "flag": "\ud83c\uddf9\ud83c\udde9",
        "cc": "TD",
        "country": "Chad"
    },
    {
        "dial_code": "+236",
        "flag": "\ud83c\udde8\ud83c\uddeb",
        "cc": "CF",
        "country": "Central African Republic"
    },
    {
        "dial_code": "+237",
        "flag": "\ud83c\udde8\ud83c\uddf2",
        "cc": "CM",
        "country": "Cameroon"
    },
    {
        "dial_code": "+238",
        "flag": "\ud83c\udde8\ud83c\uddfb",
        "cc": "CV",
        "country": "Cape Verde"
    },
    {
        "dial_code": "+239",
        "flag": "\ud83c\uddf8\ud83c\uddf9",
        "cc": "ST",
        "country": "Sao Tome and Principe"
    },
    {
        "dial_code": "+240",
        "flag": "\ud83c\uddec\ud83c\uddf6",
        "cc": "GQ",
        "country": "Equatorial Guinea"
    },
    {
        "dial_code": "+241",
        "flag": "\ud83c\uddec\ud83c\udde6",
        "cc": "GA",
        "country": "Gabon"
    },
    {
        "dial_code": "+242",
        "flag": "\ud83c\udde8\ud83c\uddec",
        "cc": "CG",
        "country": "Congo"
    },
    {
        "dial_code": "+243",
        "flag": "\ud83c\udde8\ud83c\udde9",
        "cc": "CD",
        "country": "Congo"
    },
    {
        "dial_code": "+244",
        "flag": "\ud83c\udde6\ud83c\uddf4",
        "cc": "AO",
        "country": "Angola"
    },
    {
        "dial_code": "+245",
        "flag": "\ud83c\uddec\ud83c\uddfc",
        "cc": "GW",
        "country": "Guinea-Bissau"
    },
    {
        "dial_code": "+246",
        "flag": "\ud83c\uddee\ud83c\uddf4",
        "cc": "IO",
        "country": "British Indian Ocean Territory"
    },
    {
        "dial_code": "+248",
        "flag": "\ud83c\uddf8\ud83c\udde8",
        "cc": "SC",
        "country": "Seychelles"
    },
    {
        "dial_code": "+249",
        "flag": "\ud83c\uddf8\ud83c\udde9",
        "cc": "SD",
        "country": "Sudan"
    },
    {
        "dial_code": "+250",
        "flag": "\ud83c\uddf7\ud83c\uddfc",
        "cc": "RW",
        "country": "Rwanda"
    },
    {
        "dial_code": "+251",
        "flag": "\ud83c\uddea\ud83c\uddf9",
        "cc": "ET",
        "country": "Ethiopia"
    },
    {
        "dial_code": "+252",
        "flag": "\ud83c\uddf8\ud83c\uddf4",
        "cc": "SO",
        "country": "Somalia"
    },
    {
        "dial_code": "+253",
        "flag": "\ud83c\udde9\ud83c\uddef",
        "cc": "DJ",
        "country": "Djibouti"
    },
    {
        "dial_code": "+254",
        "flag": "\ud83c\uddf0\ud83c\uddea",
        "cc": "KE",
        "country": "Kenya"
    },
    {
        "dial_code": "+255",
        "flag": "\ud83c\uddf9\ud83c\uddff",
        "cc": "TZ",
        "country": "Tanzania"
    },
    {
        "dial_code": "+256",
        "flag": "\ud83c\uddfa\ud83c\uddec",
        "cc": "UG",
        "country": "Uganda"
    },
    {
        "dial_code": "+257",
        "flag": "\ud83c\udde7\ud83c\uddee",
        "cc": "BI",
        "country": "Burundi"
    },
    {
        "dial_code": "+258",
        "flag": "\ud83c\uddf2\ud83c\uddff",
        "cc": "MZ",
        "country": "Mozambique"
    },
    {
        "dial_code": "+260",
        "flag": "\ud83c\uddff\ud83c\uddf2",
        "cc": "ZM",
        "country": "Zambia"
    },
    {
        "dial_code": "+261",
        "flag": "\ud83c\uddf2\ud83c\uddec",
        "cc": "MG",
        "country": "Madagascar"
    },
    {
        "dial_code": "+262",
        "flag": "\ud83c\uddf9\ud83c\uddeb",
        "cc": "TF",
        "country": "French Southern Territories"
    },
    {
        "dial_code": "+262",
        "flag": "\ud83c\uddfe\ud83c\uddf9",
        "cc": "YT",
        "country": "Mayotte"
    },
    {
        "dial_code": "+262",
        "flag": "\ud83c\uddf7\ud83c\uddea",
        "cc": "RE",
        "country": "Reunion"
    },
    {
        "dial_code": "+263",
        "flag": "\ud83c\uddff\ud83c\uddfc",
        "cc": "ZW",
        "country": "Zimbabwe"
    },
    {
        "dial_code": "+264",
        "flag": "\ud83c\uddf3\ud83c\udde6",
        "cc": "NA",
        "country": "Namibia"
    },
    {
        "dial_code": "+265",
        "flag": "\ud83c\uddf2\ud83c\uddfc",
        "cc": "MW",
        "country": "Malawi"
    },
    {
        "dial_code": "+266",
        "flag": "\ud83c\uddf1\ud83c\uddf8",
        "cc": "LS",
        "country": "Lesotho"
    },
    {
        "dial_code": "+267",
        "flag": "\ud83c\udde7\ud83c\uddfc",
        "cc": "BW",
        "country": "Botswana"
    },
    {
        "dial_code": "+268",
        "flag": "\ud83c\uddf8\ud83c\uddff",
        "cc": "SZ",
        "country": "Swaziland"
    },
    {
        "dial_code": "+269",
        "flag": "\ud83c\uddf0\ud83c\uddf2",
        "cc": "KM",
        "country": "Comoros"
    },
    {
        "dial_code": "+27",
        "flag": "\ud83c\uddff\ud83c\udde6",
        "cc": "ZA",
        "country": "South Africa"
    },
    {
        "dial_code": "+290",
        "flag": "\ud83c\uddf8\ud83c\udded",
        "cc": "SH",
        "country": "Saint Helena"
    },
    {
        "dial_code": "+291",
        "flag": "\ud83c\uddea\ud83c\uddf7",
        "cc": "ER",
        "country": "Eritrea"
    },
    {
        "dial_code": "+297",
        "flag": "\ud83c\udde6\ud83c\uddfc",
        "cc": "AW",
        "country": "Aruba"
    },
    {
        "dial_code": "+298",
        "flag": "\ud83c\uddeb\ud83c\uddf4",
        "cc": "FO",
        "country": "Faroe Islands"
    },
    {
        "dial_code": "+299",
        "flag": "\ud83c\uddec\ud83c\uddf1",
        "cc": "GL",
        "country": "Greenland"
    },
    {
        "dial_code": "+30",
        "flag": "\ud83c\uddec\ud83c\uddf7",
        "cc": "GR",
        "country": "Greece"
    },
    {
        "dial_code": "+31",
        "flag": "\ud83c\uddf3\ud83c\uddf1",
        "cc": "NL",
        "country": "Netherlands"
    },
    {
        "dial_code": "+32",
        "flag": "\ud83c\udde7\ud83c\uddea",
        "cc": "BE",
        "country": "Belgium"
    },
    {
        "dial_code": "+33",
        "flag": "\ud83c\uddeb\ud83c\uddf7",
        "cc": "FR",
        "country": "France"
    },
    {
        "dial_code": "+34",
        "flag": "\ud83c\uddea\ud83c\uddf8",
        "cc": "ES",
        "country": "Spain"
    },
    {
        "dial_code": "+345",
        "flag": "\ud83c\uddf0\ud83c\uddfe",
        "cc": "KY",
        "country": "Cayman Islands"
    },
    {
        "dial_code": "+350",
        "flag": "\ud83c\uddec\ud83c\uddee",
        "cc": "GI",
        "country": "Gibraltar"
    },
    {
        "dial_code": "+351",
        "flag": "\ud83c\uddf5\ud83c\uddf9",
        "cc": "PT",
        "country": "Portugal"
    },
    {
        "dial_code": "+352",
        "flag": "\ud83c\uddf1\ud83c\uddfa",
        "cc": "LU",
        "country": "Luxembourg"
    },
    {
        "dial_code": "+353",
        "flag": "\ud83c\uddee\ud83c\uddea",
        "cc": "IE",
        "country": "Ireland"
    },
    {
        "dial_code": "+354",
        "flag": "\ud83c\uddee\ud83c\uddf8",
        "cc": "IS",
        "country": "Iceland"
    },
    {
        "dial_code": "+355",
        "flag": "\ud83c\udde6\ud83c\uddf1",
        "cc": "AL",
        "country": "Albania"
    },
    {
        "dial_code": "+356",
        "flag": "\ud83c\uddf2\ud83c\uddf9",
        "cc": "MT",
        "country": "Malta"
    },
    {
        "dial_code": "+357",
        "flag": "\ud83c\udde8\ud83c\uddfe",
        "cc": "CY",
        "country": "Cyprus"
    },
    {
        "dial_code": "+358",
        "flag": "\ud83c\udde6\ud83c\uddfd",
        "cc": "AX",
        "country": "\u00c5land Islands"
    },
    {
        "dial_code": "+358",
        "flag": "\ud83c\uddeb\ud83c\uddee",
        "cc": "FI",
        "country": "Finland"
    },
    {
        "dial_code": "+359",
        "flag": "\ud83c\udde7\ud83c\uddec",
        "cc": "BG",
        "country": "Bulgaria"
    },
    {
        "dial_code": "+36",
        "flag": "\ud83c\udded\ud83c\uddfa",
        "cc": "HU",
        "country": "Hungary"
    },
    {
        "dial_code": "+370",
        "flag": "\ud83c\uddf1\ud83c\uddf9",
        "cc": "LT",
        "country": "Lithuania"
    },
    {
        "dial_code": "+371",
        "flag": "\ud83c\uddf1\ud83c\uddfb",
        "cc": "LV",
        "country": "Latvia"
    },
    {
        "dial_code": "+372",
        "flag": "\ud83c\uddea\ud83c\uddea",
        "cc": "EE",
        "country": "Estonia"
    },
    {
        "dial_code": "+373",
        "flag": "\ud83c\uddf2\ud83c\udde9",
        "cc": "MD",
        "country": "Moldova"
    },
    {
        "dial_code": "+374",
        "flag": "\ud83c\udde6\ud83c\uddf2",
        "cc": "AM",
        "country": "Armenia"
    },
    {
        "dial_code": "+375",
        "flag": "\ud83c\udde7\ud83c\uddfe",
        "cc": "BY",
        "country": "Belarus"
    },
    {
        "dial_code": "+376",
        "flag": "\ud83c\udde6\ud83c\udde9",
        "cc": "AD",
        "country": "Andorra"
    },
    {
        "dial_code": "+377",
        "flag": "\ud83c\uddf2\ud83c\udde8",
        "cc": "MC",
        "country": "Monaco"
    },
    {
        "dial_code": "+378",
        "flag": "\ud83c\uddf8\ud83c\uddf2",
        "cc": "SM",
        "country": "San Marino"
    },
    {
        "dial_code": "+379",
        "flag": "\ud83c\uddfb\ud83c\udde6",
        "cc": "VA",
        "country": "Holy See (Vatican City State)"
    },
    {
        "dial_code": "+380",
        "flag": "\ud83c\uddfa\ud83c\udde6",
        "cc": "UA",
        "country": "Ukraine"
    },
    {
        "dial_code": "+381",
        "flag": "\ud83c\uddf7\ud83c\uddf8",
        "cc": "RS",
        "country": "Serbia"
    },
    {
        "dial_code": "+382",
        "flag": "\ud83c\uddf2\ud83c\uddea",
        "cc": "ME",
        "country": "Montenegro"
    },
    {
        "dial_code": "+383",
        "flag": "\ud83c\uddfd\ud83c\uddf0",
        "cc": "XK",
        "country": "Kosovo"
    },
    {
        "dial_code": "+385",
        "flag": "\ud83c\udded\ud83c\uddf7",
        "cc": "HR",
        "country": "Croatia"
    },
    {
        "dial_code": "+386",
        "flag": "\ud83c\uddf8\ud83c\uddee",
        "cc": "SI",
        "country": "Slovenia"
    },
    {
        "dial_code": "+387",
        "flag": "\ud83c\udde7\ud83c\udde6",
        "cc": "BA",
        "country": "Bosnia and Herzegovina"
    },
    {
        "dial_code": "+389",
        "flag": "\ud83c\uddf2\ud83c\uddf0",
        "cc": "MK",
        "country": "Macedonia"
    },
    {
        "dial_code": "+39",
        "flag": "\ud83c\uddee\ud83c\uddf9",
        "cc": "IT",
        "country": "Italy"
    },
    {
        "dial_code": "+40",
        "flag": "\ud83c\uddf7\ud83c\uddf4",
        "cc": "RO",
        "country": "Romania"
    },
    {
        "dial_code": "+41",
        "flag": "\ud83c\udde8\ud83c\udded",
        "cc": "CH",
        "country": "Switzerland"
    },
    {
        "dial_code": "+420",
        "flag": "\ud83c\udde8\ud83c\uddff",
        "cc": "CZ",
        "country": "Czech Republic"
    },
    {
        "dial_code": "+421",
        "flag": "\ud83c\uddf8\ud83c\uddf0",
        "cc": "SK",
        "country": "Slovakia"
    },
    {
        "dial_code": "+423",
        "flag": "\ud83c\uddf1\ud83c\uddee",
        "cc": "LI",
        "country": "Liechtenstein"
    },
    {
        "dial_code": "+43",
        "flag": "\ud83c\udde6\ud83c\uddf9",
        "cc": "AT",
        "country": "Austria"
    },
    {
        "dial_code": "+44",
        "flag": "\ud83c\uddec\ud83c\uddec",
        "cc": "GG",
        "country": "Guernsey"
    },
    {
        "dial_code": "+44",
        "flag": "\ud83c\uddee\ud83c\uddf2",
        "cc": "IM",
        "country": "Isle of Man"
    },
    {
        "dial_code": "+44",
        "flag": "\ud83c\uddef\ud83c\uddea",
        "cc": "JE",
        "country": "Jersey"
    },
    {
        "dial_code": "+44",
        "flag": "\ud83c\uddec\ud83c\udde7",
        "cc": "GB",
        "country": "United Kingdom"
    },
    {
        "dial_code": "+45",
        "flag": "\ud83c\udde9\ud83c\uddf0",
        "cc": "DK",
        "country": "Denmark"
    },
    {
        "dial_code": "+46",
        "flag": "\ud83c\uddf8\ud83c\uddea",
        "cc": "SE",
        "country": "Sweden"
    },
    {
        "dial_code": "+47",
        "flag": "\ud83c\udde7\ud83c\uddfb",
        "cc": "BV",
        "country": "Bouvet Island"
    },
    {
        "dial_code": "+47",
        "flag": "\ud83c\uddf3\ud83c\uddf4",
        "cc": "NO",
        "country": "Norway"
    },
    {
        "dial_code": "+47",
        "flag": "\ud83c\uddf8\ud83c\uddef",
        "cc": "SJ",
        "country": "Svalbard and Jan Mayen"
    },
    {
        "dial_code": "+48",
        "flag": "\ud83c\uddf5\ud83c\uddf1",
        "cc": "PL",
        "country": "Poland"
    },
    {
        "dial_code": "+49",
        "flag": "\ud83c\udde9\ud83c\uddea",
        "cc": "DE",
        "country": "Germany"
    },
    {
        "dial_code": "+500",
        "flag": "\ud83c\uddeb\ud83c\uddf0",
        "cc": "FK",
        "country": "Falkland Islands (Malvinas)"
    },
    {
        "dial_code": "+500",
        "flag": "\ud83c\uddec\ud83c\uddf8",
        "cc": "GS",
        "country": "South Georgia and the South Sandwich Islands"
    },
    {
        "dial_code": "+501",
        "flag": "\ud83c\udde7\ud83c\uddff",
        "cc": "BZ",
        "country": "Belize"
    },
    {
        "dial_code": "+502",
        "flag": "\ud83c\uddec\ud83c\uddf9",
        "cc": "GT",
        "country": "Guatemala"
    },
    {
        "dial_code": "+503",
        "flag": "\ud83c\uddf8\ud83c\uddfb",
        "cc": "SV",
        "country": "El Salvador"
    },
    {
        "dial_code": "+504",
        "flag": "\ud83c\udded\ud83c\uddf3",
        "cc": "HN",
        "country": "Honduras"
    },
    {
        "dial_code": "+505",
        "flag": "\ud83c\uddf3\ud83c\uddee",
        "cc": "NI",
        "country": "Nicaragua"
    },
    {
        "dial_code": "+506",
        "flag": "\ud83c\udde8\ud83c\uddf7",
        "cc": "CR",
        "country": "Costa Rica"
    },
    {
        "dial_code": "+507",
        "flag": "\ud83c\uddf5\ud83c\udde6",
        "cc": "PA",
        "country": "Panama"
    },
    {
        "dial_code": "+508",
        "flag": "\ud83c\uddf5\ud83c\uddf2",
        "cc": "PM",
        "country": "Saint Pierre and Miquelon"
    },
    {
        "dial_code": "+509",
        "flag": "\ud83c\udded\ud83c\uddf9",
        "cc": "HT",
        "country": "Haiti"
    },
    {
        "dial_code": "+51",
        "flag": "\ud83c\uddf5\ud83c\uddea",
        "cc": "PE",
        "country": "Peru"
    },
    {
        "dial_code": "+52",
        "flag": "\ud83c\uddf2\ud83c\uddfd",
        "cc": "MX",
        "country": "Mexico"
    },
    {
        "dial_code": "+53",
        "flag": "\ud83c\udde8\ud83c\uddfa",
        "cc": "CU",
        "country": "Cuba"
    },
    {
        "dial_code": "+54",
        "flag": "\ud83c\udde6\ud83c\uddf7",
        "cc": "AR",
        "country": "Argentina"
    },
    {
        "dial_code": "+55",
        "flag": "\ud83c\udde7\ud83c\uddf7",
        "cc": "BR",
        "country": "Brazil"
    },
    {
        "dial_code": "+56",
        "flag": "\ud83c\udde8\ud83c\uddf1",
        "cc": "CL",
        "country": "Chile"
    },
    {
        "dial_code": "+57",
        "flag": "\ud83c\udde8\ud83c\uddf4",
        "cc": "CO",
        "country": "Colombia"
    },
    {
        "dial_code": "+58",
        "flag": "\ud83c\uddfb\ud83c\uddea",
        "cc": "VE",
        "country": "Venezuela"
    },
    {
        "dial_code": "+590",
        "flag": "\ud83c\uddec\ud83c\uddf5",
        "cc": "GP",
        "country": "Guadeloupe"
    },
    {
        "dial_code": "+590",
        "flag": "\ud83c\udde7\ud83c\uddf1",
        "cc": "BL",
        "country": "Saint Barthelemy"
    },
    {
        "dial_code": "+590",
        "flag": "\ud83c\uddf2\ud83c\uddeb",
        "cc": "MF",
        "country": "Saint Martin"
    },
    {
        "dial_code": "+591",
        "flag": "\ud83c\udde7\ud83c\uddf4",
        "cc": "BO",
        "country": "Bolivia"
    },
    {
        "dial_code": "+592",
        "flag": "\ud83c\uddec\ud83c\uddfe",
        "cc": "GY",
        "country": "Guyana"
    },
    {
        "dial_code": "+593",
        "flag": "\ud83c\uddea\ud83c\udde8",
        "cc": "EC",
        "country": "Ecuador"
    },
    {
        "dial_code": "+594",
        "flag": "\ud83c\uddec\ud83c\uddeb",
        "cc": "GF",
        "country": "French Guiana"
    },
    {
        "dial_code": "+595",
        "flag": "\ud83c\uddf5\ud83c\uddfe",
        "cc": "PY",
        "country": "Paraguay"
    },
    {
        "dial_code": "+596",
        "flag": "\ud83c\uddf2\ud83c\uddf6",
        "cc": "MQ",
        "country": "Martinique"
    },
    {
        "dial_code": "+597",
        "flag": "\ud83c\uddf8\ud83c\uddf7",
        "cc": "SR",
        "country": "Suriname"
    },
    {
        "dial_code": "+598",
        "flag": "\ud83c\uddfa\ud83c\uddfe",
        "cc": "UY",
        "country": "Uruguay"
    },
    {
        "dial_code": "+599",
        "flag": "",
        "cc": "AN",
        "country": "Netherlands Antilles"
    },
    {
        "dial_code": "+60",
        "flag": "\ud83c\uddf2\ud83c\uddfe",
        "cc": "MY",
        "country": "Malaysia"
    },
    {
        "dial_code": "+61",
        "flag": "\ud83c\udde6\ud83c\uddfa",
        "cc": "AU",
        "country": "Australia"
    },
    {
        "dial_code": "+61",
        "flag": "\ud83c\udde8\ud83c\uddfd",
        "cc": "CX",
        "country": "Christmas Island"
    },
    {
        "dial_code": "+61",
        "flag": "\ud83c\udde8\ud83c\udde8",
        "cc": "CC",
        "country": "Cocos (Keeling) Islands"
    },
    {
        "dial_code": "+62",
        "flag": "\ud83c\uddee\ud83c\udde9",
        "cc": "ID",
        "country": "Indonesia"
    },
    {
        "dial_code": "+63",
        "flag": "\ud83c\uddf5\ud83c\udded",
        "cc": "PH",
        "country": "Philippines"
    },
    {
        "dial_code": "+64",
        "flag": "\ud83c\uddf3\ud83c\uddff",
        "cc": "NZ",
        "country": "New Zealand"
    },
    {
        "dial_code": "+64",
        "flag": "\ud83c\uddf5\ud83c\uddf3",
        "cc": "PN",
        "country": "Pitcairn"
    },
    {
        "dial_code": "+65",
        "flag": "\ud83c\uddf8\ud83c\uddec",
        "cc": "SG",
        "country": "Singapore"
    },
    {
        "dial_code": "+66",
        "flag": "\ud83c\uddf9\ud83c\udded",
        "cc": "TH",
        "country": "Thailand"
    },
    {
        "dial_code": "+670",
        "flag": "\ud83c\uddf9\ud83c\uddf1",
        "cc": "TL",
        "country": "Timor-Leste"
    },
    {
        "dial_code": "+672",
        "flag": "\ud83c\udde6\ud83c\uddf6",
        "cc": "AQ",
        "country": "Antarctica"
    },
    {
        "dial_code": "+672",
        "flag": "\ud83c\udded\ud83c\uddf2",
        "cc": "HM",
        "country": "Heard Island and Mcdonald Islands"
    },
    {
        "dial_code": "+672",
        "flag": "\ud83c\uddf3\ud83c\uddeb",
        "cc": "NF",
        "country": "Norfolk Island"
    },
    {
        "dial_code": "+673",
        "flag": "\ud83c\udde7\ud83c\uddf3",
        "cc": "BN",
        "country": "Brunei Darussalam"
    },
    {
        "dial_code": "+674",
        "flag": "\ud83c\uddf3\ud83c\uddf7",
        "cc": "NR",
        "country": "Nauru"
    },
    {
        "dial_code": "+675",
        "flag": "\ud83c\uddf5\ud83c\uddec",
        "cc": "PG",
        "country": "Papua New Guinea"
    },
    {
        "dial_code": "+676",
        "flag": "\ud83c\uddf9\ud83c\uddf4",
        "cc": "TO",
        "country": "Tonga"
    },
    {
        "dial_code": "+677",
        "flag": "\ud83c\uddf8\ud83c\udde7",
        "cc": "SB",
        "country": "Solomon Islands"
    },
    {
        "dial_code": "+678",
        "flag": "\ud83c\uddfb\ud83c\uddfa",
        "cc": "VU",
        "country": "Vanuatu"
    },
    {
        "dial_code": "+679",
        "flag": "\ud83c\uddeb\ud83c\uddef",
        "cc": "FJ",
        "country": "Fiji"
    },
    {
        "dial_code": "+680",
        "flag": "\ud83c\uddf5\ud83c\uddfc",
        "cc": "PW",
        "country": "Palau"
    },
    {
        "dial_code": "+681",
        "flag": "\ud83c\uddfc\ud83c\uddeb",
        "cc": "WF",
        "country": "Wallis and Futuna"
    },
    {
        "dial_code": "+682",
        "flag": "\ud83c\udde8\ud83c\uddf0",
        "cc": "CK",
        "country": "Cook Islands"
    },
    {
        "dial_code": "+683",
        "flag": "\ud83c\uddf3\ud83c\uddfa",
        "cc": "NU",
        "country": "Niue"
    },
    {
        "dial_code": "+685",
        "flag": "\ud83c\uddfc\ud83c\uddf8",
        "cc": "WS",
        "country": "Samoa"
    },
    {
        "dial_code": "+686",
        "flag": "\ud83c\uddf0\ud83c\uddee",
        "cc": "KI",
        "country": "Kiribati"
    },
    {
        "dial_code": "+687",
        "flag": "\ud83c\uddf3\ud83c\udde8",
        "cc": "NC",
        "country": "New Caledonia"
    },
    {
        "dial_code": "+688",
        "flag": "\ud83c\uddf9\ud83c\uddfb",
        "cc": "TV",
        "country": "Tuvalu"
    },
    {
        "dial_code": "+689",
        "flag": "\ud83c\uddf5\ud83c\uddeb",
        "cc": "PF",
        "country": "French Polynesia"
    },
    {
        "dial_code": "+690",
        "flag": "\ud83c\uddf9\ud83c\uddf0",
        "cc": "TK",
        "country": "Tokelau"
    },
    {
        "dial_code": "+691",
        "flag": "\ud83c\uddeb\ud83c\uddf2",
        "cc": "FM",
        "country": "Micronesia"
    },
    {
        "dial_code": "+692",
        "flag": "\ud83c\uddf2\ud83c\udded",
        "cc": "MH",
        "country": "Marshall Islands"
    },
    {
        "dial_code": "+7",
        "flag": "\ud83c\uddf0\ud83c\uddff",
        "cc": "KZ",
        "country": "Kazakhstan"
    },
    {
        "dial_code": "+7",
        "flag": "\ud83c\uddf7\ud83c\uddfa",
        "cc": "RU",
        "country": "Russia"
    },
    {
        "dial_code": "+81",
        "flag": "\ud83c\uddef\ud83c\uddf5",
        "cc": "JP",
        "country": "Japan"
    },
    {
        "dial_code": "+82",
        "flag": "\ud83c\uddf0\ud83c\uddf7",
        "cc": "KR",
        "country": "Korea"
    },
    {
        "dial_code": "+84",
        "flag": "\ud83c\uddfb\ud83c\uddf3",
        "cc": "VN",
        "country": "Vietnam"
    },
    {
        "dial_code": "+850",
        "flag": "\ud83c\uddf0\ud83c\uddf5",
        "cc": "KP",
        "country": "Korea"
    },
    {
        "dial_code": "+852",
        "flag": "\ud83c\udded\ud83c\uddf0",
        "cc": "HK",
        "country": "Hong Kong"
    },
    {
        "dial_code": "+853",
        "flag": "\ud83c\uddf2\ud83c\uddf4",
        "cc": "MO",
        "country": "Macao"
    },
    {
        "dial_code": "+855",
        "flag": "\ud83c\uddf0\ud83c\udded",
        "cc": "KH",
        "country": "Cambodia"
    },
    {
        "dial_code": "+856",
        "flag": "\ud83c\uddf1\ud83c\udde6",
        "cc": "LA",
        "country": "Laos"
    },
    {
        "dial_code": "+86",
        "flag": "\ud83c\udde8\ud83c\uddf3",
        "cc": "CN",
        "country": "China"
    },
    {
        "dial_code": "+880",
        "flag": "\ud83c\udde7\ud83c\udde9",
        "cc": "BD",
        "country": "Bangladesh"
    },
    {
        "dial_code": "+886",
        "flag": "\ud83c\uddf9\ud83c\uddfc",
        "cc": "TW",
        "country": "Taiwan"
    },
    {
        "dial_code": "+90",
        "flag": "\ud83c\uddf9\ud83c\uddf7",
        "cc": "TR",
        "country": "Turkey"
    },
    {
        "dial_code": "+91",
        "flag": "\ud83c\uddee\ud83c\uddf3",
        "cc": "IN",
        "country": "India"
    },
    {
        "dial_code": "+92",
        "flag": "\ud83c\uddf5\ud83c\uddf0",
        "cc": "PK",
        "country": "Pakistan"
    },
    {
        "dial_code": "+93",
        "flag": "\ud83c\udde6\ud83c\uddeb",
        "cc": "AF",
        "country": "Afghanistan"
    },
    {
        "dial_code": "+94",
        "flag": "\ud83c\uddf1\ud83c\uddf0",
        "cc": "LK",
        "country": "Sri Lanka"
    },
    {
        "dial_code": "+95",
        "flag": "\ud83c\uddf2\ud83c\uddf2",
        "cc": "MM",
        "country": "Myanmar"
    },
    {
        "dial_code": "+960",
        "flag": "\ud83c\uddf2\ud83c\uddfb",
        "cc": "MV",
        "country": "Maldives"
    },
    {
        "dial_code": "+961",
        "flag": "\ud83c\uddf1\ud83c\udde7",
        "cc": "LB",
        "country": "Lebanon"
    },
    {
        "dial_code": "+962",
        "flag": "\ud83c\uddef\ud83c\uddf4",
        "cc": "JO",
        "country": "Jordan"
    },
    {
        "dial_code": "+963",
        "flag": "\ud83c\uddf8\ud83c\uddfe",
        "cc": "SY",
        "country": "Syrian Arab Republic"
    },
    {
        "dial_code": "+964",
        "flag": "\ud83c\uddee\ud83c\uddf6",
        "cc": "IQ",
        "country": "Iraq"
    },
    {
        "dial_code": "+965",
        "flag": "\ud83c\uddf0\ud83c\uddfc",
        "cc": "KW",
        "country": "Kuwait"
    },
    {
        "dial_code": "+966",
        "flag": "\ud83c\uddf8\ud83c\udde6",
        "cc": "SA",
        "country": "Saudi Arabia"
    },
    {
        "dial_code": "+967",
        "flag": "\ud83c\uddfe\ud83c\uddea",
        "cc": "YE",
        "country": "Yemen"
    },
    {
        "dial_code": "+968",
        "flag": "\ud83c\uddf4\ud83c\uddf2",
        "cc": "OM",
        "country": "Oman"
    },
    {
        "dial_code": "+970",
        "flag": "\ud83c\uddf5\ud83c\uddf8",
        "cc": "PS",
        "country": "Palestinian Territory"
    },
    {
        "dial_code": "+971",
        "flag": "\ud83c\udde6\ud83c\uddea",
        "cc": "AE",
        "country": "United Arab Emirates"
    },
    {
        "dial_code": "+972",
        "flag": "\ud83c\uddee\ud83c\uddf1",
        "cc": "IL",
        "country": "Israel"
    },
    {
        "dial_code": "+973",
        "flag": "\ud83c\udde7\ud83c\udded",
        "cc": "BH",
        "country": "Bahrain"
    },
    {
        "dial_code": "+974",
        "flag": "\ud83c\uddf6\ud83c\udde6",
        "cc": "QA",
        "country": "Qatar"
    },
    {
        "dial_code": "+975",
        "flag": "\ud83c\udde7\ud83c\uddf9",
        "cc": "BT",
        "country": "Bhutan"
    },
    {
        "dial_code": "+976",
        "flag": "\ud83c\uddf2\ud83c\uddf3",
        "cc": "MN",
        "country": "Mongolia"
    },
    {
        "dial_code": "+977",
        "flag": "\ud83c\uddf3\ud83c\uddf5",
        "cc": "NP",
        "country": "Nepal"
    },
    {
        "dial_code": "+98",
        "flag": "\ud83c\uddee\ud83c\uddf7",
        "cc": "IR",
        "country": "Iran"
    },
    {
        "dial_code": "+992",
        "flag": "\ud83c\uddf9\ud83c\uddef",
        "cc": "TJ",
        "country": "Tajikistan"
    },
    {
        "dial_code": "+993",
        "flag": "\ud83c\uddf9\ud83c\uddf2",
        "cc": "TM",
        "country": "Turkmenistan"
    },
    {
        "dial_code": "+994",
        "flag": "\ud83c\udde6\ud83c\uddff",
        "cc": "AZ",
        "country": "Azerbaijan"
    },
    {
        "dial_code": "+995",
        "flag": "\ud83c\uddec\ud83c\uddea",
        "cc": "GE",
        "country": "Georgia"
    },
    {
        "dial_code": "+996",
        "flag": "\ud83c\uddf0\ud83c\uddec",
        "cc": "KG",
        "country": "Kyrgyzstan"
    },
    {
        "dial_code": "+998",
        "flag": "\ud83c\uddfa\ud83c\uddff",
        "cc": "UZ",
        "country": "Uzbekistan"
    }
] 