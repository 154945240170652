import { Button, Card, Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  fetchDoctorSlots,
  updateDoctorSlotConfig
} from "../../../../redux/reducers/doctor.slice";
import { ROUTE_SLOT_ACTION } from "../../../../shared/routes/doctor.routes.constants";
const { Text } = Typography;

type Props = {
  slot?: any;
};

const DoctorSlotGrid: React.FunctionComponent<Props> = ({ slot }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const UpdateStatus = async (id: string, key: any) => {
    await dispatch(
      updateDoctorSlotConfig({ id: id, data: { is_active: key } })
    );
    await dispatch(fetchDoctorSlots(RouteParams["id"]));
  };

  return (
    <Card style={{ marginRight: "10px" }}>
      <Row>
        <Col span={8}>
          <label>Session Date</label>
        </Col>
        <Col span={16}>
          <label>
            {" "}
            {slot.session_start_date
              ? moment(slot.session_start_date).format("Do, MMM YY")
              : "-"}{" "}
            to{" "}
            {slot.session_end_date
              ? moment(slot.session_end_date).format("Do, MMM YY")
              : "-"}
          </label>
        </Col>
      </Row>
      <Row className="mt10">
        <Col span={8}>
          <label>Session Time</label>
        </Col>
        <Col span={16}>
          <label>
            {slot.start_time} to {slot.end_time}
          </label>
        </Col>
      </Row>
      {/* <Row className="mt10">
                <Col span={8}>
                    <label>Slot Type</label>
                </Col>
                <Col span={16}>
                    <label>Regular</label>
                </Col>
            </Row> */}
      <Row className="mt10">
        <Col span={8}>
          <label>Applicable</label>
        </Col>
        <Col span={16}>
          <label>
            {slot.availability_days.map((day: any) => {
              return day.day.name + " ";
            })}
          </label>
        </Col>
      </Row>
      <Row className="mt10">
        <Col span={8}>
            <label>Shift</label>
        </Col>
        <Col span={16}>
            <label>{slot?.shift_type === 'instant' ? 'Instant' : 'No Slot' }</label>
        </Col>
    </Row>
      <Row className="mt10">
        <Col span={8}>
          <label>Status</label>
        </Col>
        <Col span={16}>
          {slot.is_active ? (
            <Text type="success">Active</Text>
          ) : (
            <Text type="danger">Disabled</Text>
          )}
        </Col>
      </Row>

      <Row>
        <Divider></Divider>
      </Row>

      <Row>
        <Col span={12}>
          <Button
            type="primary"
            onClick={() => 
              // history.push(ROUTE_SLOT_ACTION(slot?.id, "edit"))
              history.push({
                pathname: ROUTE_SLOT_ACTION(slot?.id, "edit"),
                state: {
                  'id': slot?.id,
                  'mode': 'edit'
                },
              })}
          >
            Edit Slot
          </Button>
        </Col>
        <Col span={12} className="text-right">
          {slot.is_active ? (
            <Button type="primary" onClick={() => UpdateStatus(slot.id, false)}>
              Disable
            </Button>
          ) : (
            <Button type="primary" onClick={() => UpdateStatus(slot.id, true)}>
              Enable
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default DoctorSlotGrid;
