import moment from "moment";
//import {User} from "../types/user.type";
import React from "react";
import { useLocation } from "react-router";
import { ROUTE_LANDING } from "../routes/route.constants";
import { _BASE_URL } from "./api-client";
//import {User} from "../types/user.type";

export function latestAllowedRoute() {
  return ROUTE_LANDING;
}

export function generateGreeting() {
  const hour = moment().hour();

  if (hour > 16) {
    return "Good Evening";
  }

  if (hour > 11) {
    return "Good Afternoon";
  }

  return "Good Morning";
}

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname: string, cvalue: any, exdays: any) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Can not select days before today and today
export function disabledPrevoiusDates(current: any) {
  return current && current < moment().subtract(1, "day").endOf("day");
}

export function disabledNonBookingDates(current: any) {
  return current && current.isAfter(moment().add(90, "days"));
}

export function disabledFutureDates(current: any) {
  return current && current.valueOf() > Date.now();
}

export const getJSONFile = (jsonData: any) => {
  const fileData = JSON.stringify(jsonData);
  const blob: any = new Blob([fileData], { type: "text/plain" });
  const fileName = new Date().getTime() + ".json";
  blob["lastModifiedDate"] = new Date();
  blob["name"] = fileName;
  return blob;
};

export function getFullName(user: any, falseReturn = "") {
  return user && (user?.first_name || user?.last_name)
    ? `${user?.first_name || ""} ${user?.last_name || ""}`
    : user?.name
    ? user?.name
    : falseReturn
    ? "NA"
    : "";
}

export function getDayFormat(date: any, show: any) {
  const _date = moment(date);
  //console.log(_date)
  const today = moment().endOf("day");
  const tomorrow = moment().add(1, "day").endOf("day");
  const yesterday = moment().subtract(1, "day").endOf("day");
  const day_before_yesterday = moment().subtract(2, "day").endOf("day");
  // const later = moment().add(2, 'day').endOf('day')
  if (_date < day_before_yesterday) return moment(date).format("dddd");
  if (_date < yesterday) return "Yesterday";
  if (_date < today) return "Today";
  if (_date < tomorrow) return "Tomorrow";
  if (show) {
    return moment(date).format("Do MMM' YYYY");
  } else {
    return moment(date).format("dddd");
  }
}

export function getChatDateFormat(date: any) {
  const _date = moment(date);
  //console.log(_date)
  const today = moment().endOf("day");
  const yesterday = moment().subtract(1, "day").endOf("day");
  const day_before_yesterday = moment().subtract(2, "day").endOf("day");
  // const later = moment().add(2, 'day').endOf('day')
  if (_date < day_before_yesterday) return moment(date).format("Do MMM' YYYY");
  if (_date < yesterday) return "Yesterday";
  if (_date < today) return "Today";
}

export function timeDiffrence(_startTime: any, _endTime: any) {
  var startTime = moment(_startTime, "HH:mm:ss a");
  var endTime = moment(_endTime, "HH:mm:ss a");
  return endTime.diff(startTime, "minutes");
}

export function getAge(year: any) {
  // const __date = year && year.toString();
  // const _date: any = moment(__date).format("YYYY");
  // const _current: any = moment().format("YYYY");
  // if (__date === undefined || __date === null) {
  //   return null;
  // }
  // return _current - _date + "yrs";

  var today = new Date();
  var DOB = new Date(year);
  var totalMonths =
    (today.getFullYear() - DOB.getFullYear()) * 12 +
    today.getMonth() -
    DOB.getMonth();
  totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
  var years = today.getFullYear() - DOB.getFullYear();
  if (DOB.getMonth() > today.getMonth()) years = years - 1;
  else if (DOB.getMonth() === today.getMonth())
    if (DOB.getDate() > today.getDate()) years = years - 1;

  var days;
  var months;

  if (DOB.getDate() > today.getDate()) {
    months = totalMonths % 12;
    if (months == 0) months = 11;
    var x = today.getMonth();
    switch (x) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12: {
        var a = DOB.getDate() - today.getDate();
        days = 31 - a;
        break;
      }
      default: {
        var a = DOB.getDate() - today.getDate();
        days = 30 - a;
        break;
      }
    }
  } else {
    days = today.getDate() - DOB.getDate();
    if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
    else months = (totalMonths % 12) + 1;
  }

  // if (year && months !== 0) {
  //   if (years === 1 && months !== 0) {
  //     return years + " Y " + months + " M " + days + " D";
  //   }
  //   if (years === 0 && months !== 0) {
  //     return months + " M " + days + " D";
  //   }
  //   if (years === 0 && months === 1) {
  //     return months + " M " + days + " D";
  //   }
  //   return years + " Y " + months + " M ";
  // }
  // if (year && months === 0) {
  //   if (years === 1) {
  //     return years + " Y " + days + " D";
  //   }
  //   return years + " Y " + days + " D";
  // }

  // if (months) {
  //   if (months === 1) {
  //     return years + " Y " + months + " M " + days + " D";
  //   }
  //   return months + " M " + days + " D";
  // }
  // if (days === 1) {
  //   return days + " D";
  // }
  return  years + "Y " + months + "M " + days + "D";
}

export function dobToAge(dobIn: string) {
  return moment().diff(dobIn, "years");
}

export function formatDate(date: string, format: string = "DD MMM'YY, LT") {
  return moment(date).local().format(format);
}

export function getGender(data: any) {
  return data && data.split("")[0].toUpperCase();
}

export function formatTimes(date: string, format: string = "LT") {
  return moment(date).format(format);
}
export function formatOnlyDate(date: string, format: string = "DD MMM'YY") {
  return moment(date).format(format);
}

export function secondsToTime(seconds: number) {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
}

export function secondsToHourMins(seconds: number) {
  var totalMinutes = seconds;
  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;
  if (hours === 0) {
    return minutes + "mins";
  } else {
    return hours + "h " + minutes + "mins";
  }
}

export function contactNameFormat(
  firstName: string,
  lastName: string,
  gender: string,
  dob: string
) {
  let extra = "";
  if (gender || dob) {
    extra = `(${dob ? dobToAge(dob) + "yrs, " : ""}${
      gender ? gender.split("")[0].toUpperCase() : ""
    })`;
  }

  return `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${extra}`;
}

export function formatDropDownValue(value: string) {
  return value ? value.split("_").join(" ").toUpperCase() : value;
}

export function titleCase(value: any) {
  value = value.toLowerCase().split(" ");
  for (var i = 0; i < value.length; i++) {
    value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
  }
  return value.join(" ");
}

export function getInitials(string: any) {
  var names = [];
  var initials = [];
  if (string == null) {
    return;
  }
  if (string !== null || string !== undefined) {
    names = string?.split(" ");
    if (names.length === 0) {
      initials = names.substring(0, 1).toUpperCase();
    } else {
      initials = names[0].substring(0, 1).toUpperCase();
    }
  }
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function getYoutubeId(url: any) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

export enum CustomEvents {
  REQUEST_SENT = "REQUEST_SENT",
  REQUEST_SUCCESS = "REQUEST_SUCCESS",
  REQUEST_FAIL = "REQUEST_FAIL",
}

export const sendInternalEvent = (eventType: CustomEvents, data: any = {}) => {
  switch (eventType) {
    case CustomEvents.REQUEST_SENT:
      let requestSent = document.getElementById("req-sent");
      if (requestSent != null) {
        requestSent.innerHTML =
          parseInt(requestSent.innerHTML as string) + 1 + "";
      }

      break;
    case CustomEvents.REQUEST_SUCCESS:
      let requestSuccess = document.getElementById("req-success");
      if (requestSuccess != null) {
        requestSuccess.innerHTML =
          parseInt(requestSuccess.innerHTML as string) + 1 + "";
      }

      break;
    case CustomEvents.REQUEST_FAIL:
      let requestFailed = document.getElementById("req-failed");
      if (requestFailed != null) {
        requestFailed.innerHTML =
          parseInt(requestFailed.innerHTML as string) + 1 + "";
      }

      break;
  }
};
export const getCurrentEnvironmentName = () => {
  const baseUrl = _BASE_URL?.toLocaleLowerCase();
  if (baseUrl.indexOf("localhost") > -1) {
    return "LOCAL";
  }
  if (baseUrl.indexOf("betamedi.hlthclub.in") > -1) {
    return "STAGING";
  }
  if (baseUrl.indexOf("medi.maxhospitals.in") > -1) {
    return "PROD";
  }
  if (
    baseUrl.indexOf("medi.maxhospitals.in") > -1 ||
    baseUrl.indexOf("medi.thbanalytics.in") > -1
  ) {
    return "PROD";
  }
  if (
    baseUrl.indexOf("betamedi.thbanalytics.in") > -1 ||
    baseUrl.indexOf("betamedi.maxhospitals.in") > -1
  ) {
    return "UAT";
  }
  return "NA";
};
/*
    TO detect browser resolution means mobile or web

*/
export function getScreenType() {
  /*
    return {
        desktop:useMediaQuery({ minWidth: 992 }),
        tablet:useMediaQuery({ minWidth: 768, maxWidth: 991 }),
        mobile:useMediaQuery({ maxWidth: 767 }),
  
    };
    */
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export function secondsToHms(d: any) {
  d = Number(d);
  if (d === 0) return "0 sec";
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
}
export function disabledPreviousDates(current: any) {
  return current && current < moment().subtract(1, "day").endOf("day");
}