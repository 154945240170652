import { Badge, Calendar, Col, Row, Select } from "antd";
import moment from "moment";
//var _ = require('lodash');

function CalenderTaskView({
  onSelect,
  data,
  onCancel,
  onChangeMonth,
  currentDate,
}: any) {
  function getListData(value: any) {
    let listData = [] as any;
    data.map((appointment: any) => {
      if (
        moment(value).format("MMMM") ===
        moment(appointment?.appointment_date).format("MMMM")
      ) {
        switch (value.date()) {
          case moment(appointment?.appointment_date).date():
            listData = [appointment];
            break;
        }
      }
      return true;
    });
    return listData || [];
  }

  function dateCellRender(value: any) {
    const listData = getListData(value);
    return (
      <ul style={{ padding: "0px" }}>
        {listData &&
          listData.map((item: any) => {
            return (
              <li key={item?.content}>
                {item?.procedures &&
                  item?.procedures.map((_item: any) => {
                    return (
                      <Badge
                        status={
                          (_item?.procedure_name).toLowerCase() ===
                          "video consultation"
                            ? "processing"
                            : "success"
                        }
                        text={` ${_item?.appointment_count}   ${
                          (_item?.procedure_name).toLowerCase() ===
                          "video consultation"
                            ? "VC"
                            : (_item?.procedure_name).toLowerCase() ===
                              "instant"
                            ? "Instant"
                            : "In Hospital"
                        }`}
                      />
                    );
                  })}
              </li>
            );
          })}
      </ul>
    );
  }

  return (
    <Calendar
      headerRender={({ value, onChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [] as any;
        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current.month(i);
          months.push(localeData.monthsShort(current));
        }

        for (let index = start; index < end; index++) {
          monthOptions.push(
            <Select.Option
              value={index}
              className="month-item"
              key={`${index}`}
            >
              {months[index]}
            </Select.Option>
          );
        }

        const year = value.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          );
        }

        return (
          <div style={{ padding: 8, backgroundColor: "#F8F9FB" }}>
            <Row gutter={8}>
              <Col span={12}></Col>
              <Col span={12} className={"text-right"}>
                <Row justify={"end"} gutter={20}>
                  <Col>
                    <Select
                      size="middle"
                      style={{ width: "120px" }}
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      onChange={(newYear: any) => {
                        const now = value.clone().year(newYear);
                        onChangeMonth(now);
                      }}
                      value={moment(currentDate).format("YYYY")}
                    >
                      {options}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      size="middle"
                      style={{ width: "120px" }}
                      placeholder={"Choose Month"}
                      value={moment(currentDate).format("MMM")}
                      dropdownMatchSelectWidth={false}
                      onChange={(selectedMonth: any) => {
                        const newValue = value.clone();
                        newValue.month(parseInt(selectedMonth, 10));
                        onChangeMonth(newValue);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                  <Col>
                    <svg
                      width="22"
                      className={"cursor mt5"}
                      height="21"
                      onClick={() => onCancel(false)}
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3387 11.9879L18.5914 5.10767C18.7957 4.91386 19 4.52624 19 4.23553C19 3.94482 18.8979 3.5572 18.5914 3.36339C18.0806 2.87887 17.2634 2.87887 16.7527 3.36339L9.5 10.2436L2.24731 3.36339C1.73656 2.87887 0.919355 2.87887 0.408602 3.36339C0.204301 3.5572 0 3.94482 0 4.23553C0 4.52624 0.102151 4.91386 0.408602 5.10767L7.66129 11.9879L0.408602 18.8681C0.204301 19.0619 0 19.4495 0 19.7402C0 20.031 0.102151 20.4186 0.408602 20.6124C0.919355 21.0969 1.73656 21.0969 2.24731 20.6124L9.5 13.7322L16.7527 20.6124C16.957 20.8062 17.3656 21 17.672 21C17.9785 21 18.3871 20.9031 18.5914 20.6124C19.1022 20.1279 19.1022 19.3526 18.5914 18.8681L11.3387 11.9879Z"
                        fill="#163B6D"
                      />
                    </svg>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        );
      }}
      mode="month"
      dateCellRender={dateCellRender}
      monthCellRender={dateCellRender}
      value={currentDate}
      onSelect={(date: any) => onSelect(date)}
      onChange={(date: any) => onChangeMonth(date)}
    />
  );
}

export default CalenderTaskView;
