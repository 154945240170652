import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewBranch } from "../../../../redux/reducers/branch.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Branch } from "../../../../shared/types/branch.type";
import BranchForm from "./branch-form.page";

const BranchCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <DesktopFormLayout title={"Branch Create"} subtitle={"Create new branch"}>
      <BranchForm
        onSubmit={async (values: Branch) => {
          const { error } = await dispatch(addNewBranch(values));
          if (!error)
            history.goBack();
        }}
      />
    </DesktopFormLayout>
  );
};

export default BranchCreate;
