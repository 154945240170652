import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewRole } from "../../../../redux/reducers/role.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Role } from "../../../../shared/types/role.type";
import RoleForm from "./role-form.page";

const RoleCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <DesktopFormLayout
      title={"Role Create"}
      subtitle={"Create new role for .."}
    >
      <RoleForm
        onSubmit={async (values: Role) => {
          try {
            await dispatch(addNewRole(values));
            history.goBack();
          } catch (e) {}
        }}
      />
    </DesktopFormLayout>
  );
};

export default RoleCreate;
