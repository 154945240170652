import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../shared/constants";
import { User } from "../../../../shared/types/user.type";
import { MobileNumberRegex } from "../../../../shared/Utils/regex";

type Props = {
  onSubmit: (values: User) => void;
  user?: User;
};
const UserForm: React.FunctionComponent<Props> = ({ user, onSubmit }) => {
  const [form] = useForm();
  const { savingUser } = useSelector((state: RootState) => state.user);
  return (
    <Form
      form={form}
      layout={"vertical"}
      name="userCreate"
      size={"large"}
      initialValues={
        user || {
          country_code: "+91",
        }
      }
      onFinish={async (values: User) => {
        onSubmit(values);
      }}
      autoComplete="off"
    >
      <Row gutter={10}>
        <Col sm={24} md={8}>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[{ required: true, message: "First Name required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Form.Item label="Last Name" name="last_name" rules={[]}>
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Form.Item label="Type" name="type" rules={[]}>
            <Select>
              {[
                "doctor",
                "admin",
                "anonymous",
                "vc_backoffice",
                "system",
                "call_center",
                "coordinator",
                "front_desk",
                "transport_department",
                "pharmacy_supervisor",
                "instant_vc_supervisor",
              ].map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Form.Item label="AD Username" name="ad_username" rules={[]}>
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Form.Item
            label="Cognito Username"
            name="cognito_username"
            rules={[]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: "email", required: true, message: "Email required" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Space size={0}>
            <Form.Item
              style={{ marginTop: "40px" }}
              name="country_code"
              rules={[{ required: true, message: "Please input your phone!" }]}
            >
              <Select>
                <Select.Option value={"+91"}>+91</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                { required: true, message: "Please input your phone!" },
                { pattern: MobileNumberRegex, message: "Please valid phone!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Space>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button loading={savingUser} type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
