import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import AccountRouteFetcher from "../AccountRouteFetcher";
import { ACCOUNT_PREFIX, RootState } from "../shared/constants";
import { ROUTE_PAGE_NOT_FOUND } from "../shared/routes/route.constants";
import AdminRoutes from "./admin.routes";
import BossRoutes from "./boss.routes";
import DoctorRoutes from "./doctor.routes";
import OrderRoutes from "./order.routes";
import PatientRoutes from "./patient.routes";

function RouteGuard({ ws, app, ...rest }: any) {
  const { token } = useSelector((state: RootState) => state.auth);
  const _host = window.location.host.split(".")[0];
  var App = null as any;
  if (_host === "medi" || _host === "localhost:3000") {
    App =
      app === "doctor"
        ? DoctorRoutes
        : app === "order"
        ? OrderRoutes
        : app === "patient"
        ? PatientRoutes
        : app === "boss"
        ? BossRoutes
        : AdminRoutes;
  } else {
    App =
      _host === "doctor"
        ? DoctorRoutes
        : _host === "order"
        ? OrderRoutes
        : _host === "patient"
        ? PatientRoutes
        : _host === "boss"
        ? BossRoutes
        : (window.location.href = ROUTE_PAGE_NOT_FOUND());
  }

  return (
    <Route
      {...rest}
      render={
        (props) =>
          token ? (
            <AccountRouteFetcher>
              {" "}
              <App {...props} ws={ws} />
            </AccountRouteFetcher>
          ) : (
            <Redirect
              to={{
                // pathname: ROUTE_LOGIN(account?.slug, app),
                pathname: "/" + ACCOUNT_PREFIX,
                state: { from: rest.location },
              }}
            />
          )
        // <App {...props} />
      }
    />
  );
}

export default RouteGuard;