import React, { useState, useEffect } from "react";
import SectionViewComponent from "./Component/SectionViewComponent";
import { formDataJson } from "./formJson";

function DynamicFormContainer(props) {
  return (
    <>
      <>
        {props.formData && (
          <SectionViewComponent
            currentSectionId={props.formData.sections}
            viewType={props.viewType}
            onCancel={props.onCancel}
            onSubmit={props.onSubmit}
            saving={props.saving}
            dynamicFormData={props.formData}
            template_id={props.templateId}
          ></SectionViewComponent>
        )}
        {/* <DynamicFormContainer */}
      </>
    </>
  );
}

export default DynamicFormContainer;
