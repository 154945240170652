import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Divider, Row, Tag, Typography } from "antd";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatOnlyDate,
  formatTimes,
} from "../../../../../src/shared/Utils/utilities";
import { RootState } from "../../../../shared/constants";
import { fetchAppointmentPaymentRecipet } from "../../../../redux/reducers/appointment.slice";
const { Text } = Typography;

type Props = {
  appointment?: any;
  joinCall?: (values: any) => void;
  showCall?: any;
  endCall?: (values: any) => void;
  checkinAppointment?: () => void;
};

const AppointmentDetails: React.FunctionComponent<Props> = ({
  appointment,
  joinCall,
  showCall,
  endCall,
  checkinAppointment,
}) => {
  const { pipEnabled } = useSelector((state: RootState) => state.appointment);
  const dispatch = useDispatch();
  const RouteParams: any = useParams();

  const viewReceipt = () => {
    (async function () {
      try {
        let response: any;
        if (appointment?.type === "historical") {
          response = await dispatch(
            fetchAppointmentPaymentRecipet({
              external_appointment_id: RouteParams["id"],
            })
          );
        } else {
          response = await dispatch(
            fetchAppointmentPaymentRecipet({
              appointment_id: appointment.id,
              patient_id: appointment?.patient_id,
            })
          );
        }
        if (response.payload.status === 200) {
          window.open(response?.payload?.data?.data?.signed_url);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };
  console.log(appointment?.patient_appt_status, "patient_appt_status");
  return (
    <Card className="noborder">
      {appointment?.appointment_date && (
        <Row>
          <Col span={12}>
            <Text className="doctor-primary-color">Date & Time:</Text>
          </Col>
          <Col span={12} className="text-right">
            <Text className="doctor-primary-color fWeight400">
              {appointment?.appointment_date &&
                formatOnlyDate(appointment?.appointment_date)}
              {<Divider type="vertical"></Divider>} {appointment?.start_time}
            </Text>
          </Col>
        </Row>
      )}
      {appointment?.patient_checked_in_time && (
        <Row>
          <Col span={12}>
            <Text className="doctor-primary-color">Checked In:</Text>
          </Col>
          <Col span={12} className="text-right">
            <Text className="doctor-primary-color fWeight400">
              {appointment?.patient_checked_in_time &&
                formatTimes(appointment?.patient_checked_in_time)}
            </Text>
          </Col>
        </Row>
      )}

      {appointment?.patient_last_visit_time && (
        <Row>
          <Col span={12}>
            <Text className="doctor-primary-color">Last Visit:</Text>
          </Col>
          <Col span={12} className="text-right">
            <Text className="doctor-primary-color fWeight400">
              {appointment?.patient_last_visit_time &&
                formatOnlyDate(appointment?.patient_last_visit_time)}
            </Text>
          </Col>
        </Row>
      )}

      <Row>
        <Col span={12}>
          <Text className="doctor-primary-color">Invoice Details:</Text>
        </Col>
        <Col span={12} className="text-right">
          {appointment?.bill_ready === true ? (
            <Text
              underline
              style={{ float: "right" }}
              className="doctor-secondary-color cursor font12 "
              onClick={() => viewReceipt()}
            >
              View Invoice
            </Text>
          ) : (
            <Text className="doctor-primary-color text-right">
              Invoice Not Generated
            </Text>
          )}
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {showCall && (
            <p>
              {" "}
              <Fragment>
                {appointment?.patient_room_wait_time &&
                appointment?.patient_room_wait_time !== 0 ? (
                  <Tag
                    className="no-border doctor-tags capitalize"
                    style={{ paddingBottom: "3px" }}
                  >
                    {appointment?.patient_room_wait_time < 40 ? (
                      <>
                        <img
                          src={
                            require("../../../../assets/images/clock.svg")
                              .default
                          }
                          width={"17%"}
                        />
                        {appointment?.patient_room_wait_time} mins
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            require("../../../../assets/images/clockred.svg")
                              .default
                          }
                          width={"17%"}
                        />
                        <span style={{ color: "#B2403A" }}>
                          {" "}
                          {appointment?.patient_room_wait_time} mins{" "}
                        </span>
                      </>
                    )}
                  </Tag>
                ) : (
                  ""
                )}

                {appointment?.patient_appt_status && (
                  <>
                    {appointment?.patient_appt_status === "Offline" ? (
                      <Tag
                        className="noborder  capitalize"
                        style={{ borderRadius: "8.1px" }}
                        color={"#B2403A"}
                      >
                        Patient {appointment?.patient_appt_status}
                      </Tag>
                    ) : (
                      <Tag className="doctor-color-tag-green noborder capitalize">
                        {appointment?.patient_appt_status}
                      </Tag>
                    )}
                  </>
                )}

                {appointment?.patient?.his_patient_type &&
                  appointment?.patient?.his_patient_type !== "null" && (
                    <Tag
                      style={{ borderRadius: "8.1px" }}
                      className="doctor-primary-color-tag-dark noborder  capitalize capitalize mt5"
                    >
                      {appointment?.patient?.his_patient_type}
                    </Tag>
                  )}
                {appointment?.patient?.his_patient_visit_type &&
                  appointment?.patient?.his_patient_visit_type === "new" && (
                    <Tag
                      style={{ borderRadius: "8.1px" }}
                      className="doctor-primary-color-tag-danger noborder capitalize mt5"
                    >
                      New
                    </Tag>
                  )}

                {appointment?.is_walk_in_appointment && (
                  <Tag
                    style={{ borderRadius: "8.1px" }}
                    className="doctor-primary-color-tag-danger noborder capitalize mt5"
                  >
                    Walk in
                  </Tag>
                )}
                {appointment?.book_source === "overbooking" && (
                  <Tag
                    style={{ borderRadius: "8.1px" }}
                    className="doctor-primary-color-tag-danger-text noborder capitalize mt5"
                  >
                    Overbooking
                  </Tag>
                )}
                {appointment?.boss_payment_tag &&
                  appointment?.boss_payment_tag === "prepaid" && (
                    <Tag className="doctor-primary-color-tag-dark noborder capitalize mt5">
                      Prepaid
                    </Tag>
                  )}
              </Fragment>
            </p>
          )}
        </Col>
      </Row>

      <Row gutter={20}>
        {showCall && (
          <Fragment>
            {appointment?.enable_start_consultation &&
            appointment?.procedure_info?.name === "consultation" ? (
              <Col span={24}>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  block
                  className="doctor-secondary-button mt20"
                  onClick={() => checkinAppointment && checkinAppointment()}
                >
                  {" "}
                  Start Consultation{" "}
                </Button>
              </Col>
            ) : (
              ""
            )}

            {appointment?.enable_video && !pipEnabled && (
              <Col span={appointment?.latest_status !== "consulting" ? 24 : 12}>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  block
                  className="doctor-secondary-button mt20"
                  disabled={appointment?.room_id === null ? true : false}
                  onClick={() => joinCall && joinCall(appointment)}
                >
                  {" "}
                  {appointment?.latest_status !== "consulting"
                    ? "Start Video Call"
                    : "Re-join Call"}{" "}
                </Button>
              </Col>
            )}

            {appointment?.enable_checkout ? (
              <Col
                span={
                  appointment?.latest_status !== "consulting"
                    ? 24
                    : (appointment?.procedure_info?.name).toLowerCase() ===
                      "consultation"
                    ? 24
                    : 12
                }
              >
                <Button
                  danger
                  block
                  size={"large"}
                  className={"mt20"}
                  onClick={() => endCall && endCall(appointment)}
                >
                  Checkout Patient
                </Button>
              </Col>
            ) : (
              ""
            )}
          </Fragment>
        )}
      </Row>
    </Card>
  );
};

export default AppointmentDetails;