import { Button, Col, Result, Row } from "antd";

export const NotFoundPage = () => {
  return (
    <Row justify={"center"} className={"full-height"}>
      <Col span={24} className={"mt200"}>
        <Result
          title={
            <h1
              style={{
                color: "#163B6D",
                opacity: "0.5",
                fontWeight: "600",
                fontSize: "46px",
                marginBottom: "0px",
              }}
            >
              Page not Found
            </h1>
          }
          icon={
            <img
              alt=""
              src={require("../assets/icons/no result found.png").default}
            ></img>
          }
          subTitle={
            <p
              style={{
                color: "#163B6D",
                opacity: "0.5",
                fontSize: "22px",
                marginBottom: "0px",
              }}
            >
              The page you requested could not be found
            </p>
          }
          extra={[
            <Button
              type="link"
              key="console"
              className="doctor-secondary-button  mt10 btn-shadow"
              onClick={() =>
                (window.location.href = window.location.host + "/max")
              }
            >
              Back To Home
            </Button>,
          ]}
        />
      </Col>
    </Row>
  );
};
