import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updateRegion } from "../../../../redux/reducers/region.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import RegionForm from "./region-form.page";

const RegionsEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  
  return (
    <DesktopFormLayout title={"Region Edit"} subtitle={"Edit region for .."}>
      <RegionForm
        onSubmit={async (values: any) => {
          values.id= RouteParams["id"]
          await dispatch(updateRegion(values));
          history.goBack();
        }}
      />
    </DesktopFormLayout>
  );
};

export default RegionsEdit;
