import { useSelector } from "react-redux";
import { Col, Row, Typography, Divider } from "antd";
import { EditOutlined } from '@ant-design/icons';
import MedicineComponent from '../../../../../shared/layouts/pharmacy-medicine.layout';
import { RootState } from "../../../../../shared/constants";
import moment from 'moment';
const { Text } = Typography;
type Props = {
  newOrderAuditTrail?: any;
  handleEditMedicine?: any;
  makeMedicineComponentEditable?: any;
}

  function SelectedMedicine(props: Props) {
    const { medicine, pharmacyDetails } = useSelector((state: RootState) => state.pharmacy);
    const medicineTtlPrice = (childData: any) =>{ }

    const handleEdit = () =>{
      let payload = {
        pharmacyDetails,
        action: "handleEditMedicine"
      }
      props.handleEditMedicine(payload);
    }
    return (
      <div>
        <Row>
          <Col span={20} >
             {/* <Text className="sub-heading">6 Medicines in Order | 3 Remaining in Cart</Text> */}
            <Text className="sub-heading">{medicine.length} Medicines in Order</Text>
          </Col>
          {(props?.newOrderAuditTrail ==='newOrderAuditTrail' || pharmacyDetails?.status === 'placed') ? '' : 
            <Col span={4} >
              <EditOutlined onClick={handleEdit} className='font20 cursor' />
            </Col>
          }
        </Row>
        <Row>
          <Text type="secondary">Time: {moment(pharmacyDetails.created_at).format("DD MMM'YY, hh:mm A")}</Text>
        </Row>
        <Divider />
        {/* <Row>
          <Text className="sub-heading">Qc Passed Medicine</Text>
        </Row> */}
        <Row>
          <MedicineComponent 
            medicineTotalPrice = {medicineTtlPrice} 
            newOrderAuditTrail = {props?.newOrderAuditTrail} 
          />
        </Row>
        {/* <Divider />
       
        <Row>
          <MedicineComponent medicineTotalPrice = {medicineTtlPrice}/>
        </Row> */}
        {/* <Divider />
        <Row>
          <Text className="sub-heading">Other Services</Text>
        </Row>
        <Row>
          <MedicineComponent medicineTotalPrice = {medicineTtlPrice} />
        </Row> */}
      </div>
    );
};

export default SelectedMedicine;

