import React from "react";
import { Flex, Box, Text } from "@100mslive/react-ui";
import PlaceholderBg from "../images/first_person.svg";

export const FirstPersonDisplay = React.memo(({user}) => {
  return (
    <Box
      css={{
        position: "relative",
        overflow: "hidden",
        w: "37.5rem",
        maxWidth: "80%",
        h: "100%",
        r: "$3",
        m: "0 auto",
        backgroundImage: `url(${PlaceholderBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor:"#23BCB5"
      }}
      data-testid="first_person_img"
    >
      <Flex
        align="center"
        direction="column"
        css={{
          position: "absolute",
          w: "100%",
          top: "43.33%",
          left: 0,
          textAlign: "center",
        }}
      >
       
        <Text
          color="white"
          variant="h6"
          css={{ mt: "$4", "@md": { fontSize: "$sm" } }}
        >
         Waiting for {user === "doctor" ? "Patient" : "Doctor"} to join the call
        </Text>
       
      </Flex>
    </Box>
  );
});
