function GlobalLayout({ children }: any) {
  //console.log(user)
  return (
    <div>
      {/* <div className="debug-info">
                <Text><strong>Debug Session: {moment(startedAt).format('LLL')}</strong></Text>
                <Space size={20}>
                    <Tag color={'blue'}>Sent: <span id={'req-sent'}>0</span></Tag>
                    <Tag color={'green'}>Success: <span id={'req-success'}>0</span></Tag>
                    <Tag color={'red'}>Failed: <span id={'req-failed'}>0</span></Tag>
                </Space>
                <Space size={20}>
                    <Tag>Account: {account?.name}</Tag>
                    <Tag>Application: {currentApplication}</Tag>
                    <Tag color={'red'}>{currentEnvironment}</Tag>
                </Space>
                {
                    user &&
                    <Space>
                        {
                            token && <Tag onClick={() => {
                                message.success(' copied');
                                navigator.clipboard.writeText(token);
                            }}>
                                <CopyOutlined/> Token
                            </Tag>
                        }
                        {
                            user && <Tag onClick={() => {
                                message.success(' copied');
                                navigator.clipboard.writeText(user?.id);
                            }}>
                                <CopyOutlined/> UserId
                            </Tag>
                        }
                        {
                            user.phone && <Tag onClick={() => {
                                message.success(' copied');
                                navigator.clipboard.writeText(user.phone);
                            }}>
                                <PhoneOutlined/> {user?.phone}
                            </Tag>
                        }
                        {
                            user.email && <Tag onClick={() => {
                                message.success(' copied');
                                navigator.clipboard.writeText(user.email);
                            }}>
                                <MailOutlined/> {user?.email}
                            </Tag>
                        }
                        {
                            user && getFullName(user) && <Tag onClick={() => {
                                message.success(' copied');
                                navigator.clipboard.writeText(getFullName(user));
                            }}>
                                <MailOutlined/> {getFullName(user)}
                            </Tag>
                        }
                    </Space>
                }
            </div> */}
      <div className="App">{children}</div>
    </div>
  );
}

export default GlobalLayout;
