import { useSelector } from "react-redux";
import { RootState } from "../constants";
import { getFullName } from "../Utils/utilities";

const UserNameComponent = () => {
  const { profile } = useSelector((state: RootState) => state.profile);
  const { impersonatedUser } = useSelector((state: RootState) => state.auth);

  if (!profile) {
    return <>NA</>;
  }
  if (profile.type === "coordinator") {
    if (impersonatedUser?.doctor)
      return (
        <>
          <span className="font16">
            {getFullName(impersonatedUser?.doctor)}
          </span>
        </>
      );
    else
      return (
        <>
          <span className="font16">{getFullName(profile)}</span>{" "}
        </>
      );
  }
  return <>{getFullName(profile)}</>;
};
export default UserNameComponent;
