import { Button, Card, Col, Divider, Input, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchApplications,
  switchApplication,
} from "../redux/reducers/auth.slice";
import { RootState } from "../shared/constants";
import { ROUTE_LOGIN } from "../shared/routes/route.constants";
import { _BASE_URL } from "../shared/Utils/api-client";
function LandingPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [loader, setloader] = useState(true as any);

  const [baseUrl, setBaseUrl] = useState(_BASE_URL);

  useEffect(() => {
    localStorage.clear();
    const _host = window.location.host.split(".")[0];

    dispatch(fetchApplications());
    if (_host === "doctor") {
      window.location.href = ROUTE_LOGIN("max", "doctor");
      return;
    }
    if (_host === "patient") {
      window.location.href = ROUTE_LOGIN("max", "patient");
      return;
    }
    if (_host === "boss") {
      window.location.href = ROUTE_LOGIN("max", "boss");
      return;
    }
    // window.location.href = ROUTE_PAGE_NOT_FOUND();
  }, []);

  useEffect(() => {
    if (account?.id) {
      localStorage.setItem("account_id", account?.id);
      dispatch(fetchApplications());
      setBaseUrl(_BASE_URL);
    }
    // account?.slug && history.push(ROUTE_CRM_LOGIN(account?.slug));
  }, [account]);
  useEffect(() => {
    setloader(false);
  }, [applications]);
  return (
    <>
      {loader ? (
        <Skeleton></Skeleton>
      ) : (
        <>
          <Divider />
          {applications.length > 0 &&
            applications.map((application: any) => {
              return (
                <Button
                  disabled={
                    application.slug === "admin" || application.slug === "crm"
                      ? true
                      : false
                  }
                  onClick={async () => {
                    await dispatch(
                      switchApplication({
                        slug: application.slug,
                        application_id: application.id,
                      })
                    );
                    history.push(ROUTE_LOGIN(account?.slug, application?.slug));
                  }}
                >
                  Go {application.name} Login
                </Button>
              );
            })}
          <Divider />
          <Card>
            <Row>
              <Col flex={"auto"}>
                <Input
                  value={baseUrl}
                  onChange={(e) => setBaseUrl(e.target.value)}
                />
                <div>
                  Sample = <small>https://medi.hlthclub.in/vault/v1/</small>
                </div>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    localStorage.setItem("baseUrl", baseUrl);
                  }}
                >
                  Update Base Url
                </Button>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
}

export default LandingPage;
