import {
  BrbIcon,
  ChatIcon,
  ChatUnreadIcon,
  HandIcon,
} from "@100mslive/react-icons";
import {
  selectIsAllowedToPublish,
  selectUnreadHMSMessagesCount,
  useHMSStore,
} from "@100mslive/react-sdk";
import {
  Flex,
  IconButton,
  Tooltip,
  VerticalDivider,
} from "@100mslive/react-ui";
import { Badge } from "@material-ui/core";
import { Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FeatureFlags } from "../services/FeatureFlags";
import { useIsChatOpen, useToggleChat } from "./AppData/useChatState";
import { AudioVideoToggle } from "./AudioVideoToggle";
import { useMyMetadata } from "./hooks/useMetadata";
import { LeaveRoom } from "./LeaveRoom";
import PIPComponent from "./PIP/PIPComponent";

// const TranscriptionButton = React.lazy(() =>
//   import("../plugins/transcription")
// );

// const ScreenshareAudio = () => {
//   const {
//     amIScreenSharing,
//     screenShareVideoTrackId: video,
//     screenShareAudioTrackId: audio,
//     toggleScreenShare,
//   } = useScreenShare();
//   const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
//   const isAudioScreenshare = amIScreenSharing && !video && !!audio;
//   const [showModal, setShowModal] = useState(false);
//   if (!isAllowedToPublish.screen || !isScreenshareSupported()) {
//     return null;
//   }
//   return (
//     <Fragment>
//       <Tooltip
//         title={`${!isAudioScreenshare ? "Start" : "Stop"} audio sharing`}
//         key="shareAudio"
//       >
//         <IconButton
//           active={!isAudioScreenshare}
//           css={{ mr: "$4" }}
//           onClick={() => {
//             if (amIScreenSharing) {
//               toggleScreenShare(true);
//             } else {
//               setShowModal(true);
//             }
//           }}
//           data-testid="screenshare_audio"
//         >
//           <MusicIcon />
//         </IconButton>
//       </Tooltip>
//       {showModal && (
//         <ScreenShareHintModal onClose={() => setShowModal(false)} />
//       )}
//     </Fragment>
//   );
// };

export const MetaActions = ({ isMobile = false }) => {
  const { isHandRaised, isBRBOn, toggleHandRaise, toggleBRB } = useMyMetadata();

  return (
    <Flex align="center">
      <Tooltip
        title={`${!isHandRaised ? "Raise" : "Unraise"} hand`}
        css={{ mx: "$4" }}
      >
        <IconButton
          onClick={toggleHandRaise}
          active={!isHandRaised}
          data-testid={`${
            isMobile ? "raise_hand_btn_mobile" : "raise_hand_btn"
          }`}
        >
          <HandIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={`${isBRBOn ? `I'm back` : `I'll be right back`}`}>
        <IconButton
          css={{ mx: "$4" }}
          onClick={toggleBRB}
          active={!isBRBOn}
          data-testid="brb_btn"
        >
          <BrbIcon />
        </IconButton>
      </Tooltip>
    </Flex>
  );
};

const Chat = () => {
  const countUnreadMessages = useHMSStore(selectUnreadHMSMessagesCount);
  const isChatOpen = useIsChatOpen();
  const toggleChat = useToggleChat();

  return (
    <Tooltip key="chat" title={`${isChatOpen ? "Close" : "Open"} chat`}>
      <IconButton
        css={{ mx: "$4" }}
        onClick={toggleChat}
        active={!isChatOpen}
        data-testid="chat_btn"
      >
        {countUnreadMessages === 0 ? (
          <ChatIcon />
        ) : (
          <ChatUnreadIcon data-testid="chat_unread_btn" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export const Footer = ({ loginType }) => {
  const { appointment } = useSelector((state) => state.appointment);

  const [time, setTime] = useState(0);
  const [running] = useState(true);

  const Stopwatch = () => {
    useEffect(() => {
      let interval;
      if (running) {
        interval = setInterval(() => {
          setTime((prevTime) => prevTime + 10);
        }, 10);
      } else if (!running) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [running]);
    return (
      <>
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
      </>
    );
  };
  return (
    <Flex
      justify="between"
      align="center"
      css={{
        padding: "$2",
        position: "relative",
        height: "100%",
        "@md": { flexWrap: "wrap" },
        backgroundColor: "Black",
      }}
    >
      <Flex
        align="center"
        css={{
          position: "absolute",
          left: "$7",
          "@md": {
            position: "unset",
            justifyContent: "center",
            w: "100%",
            p: "$4 0",
          },
        }}
      >
        {/* <ScreenshareAudio />
        <Playlist type={HMSPlaylistType.audio} />
        <Playlist type={HMSPlaylistType.video} />
      
        <LeftDivider />
        <VirtualBackground />
        <NoiseSuppression />
        {FeatureFlags.enableTranscription && <TranscriptionButton />} */}

        <h4
          style={{ color: "white", marginLeft: "20px", marginTop: "10px" }}
          className={"capitalize"}
        >
          <Space>
            {loginType === "patient" ? (
              <span className="font20">{appointment?.doctor?.name} </span>
            ) : (
              <span className="font20">
                {appointment?.patient?.pii?.first_name}{" "}
                {appointment?.patient?.pii?.last_name}{" "}
              </span>
            )}

            <Tag color="#163B6D" className={"callTageType-" + loginType}>
              {" "}
              <span>
                {" "}
                <Badge color={"red"} /> {Stopwatch()}{" "}
              </span>
            </Tag>
          </Space>
        </h4>

        {/* <LeftDivider /> */}

        <Flex
          align="center"
          css={{
            display: "none",
            "@md": {
              display: "flex",
            },
          }}
        >
          <VerticalDivider space={4} />
          <MetaActions isMobile />
        </Flex>
      </Flex>
      <Flex align="center" justify="center" css={{ w: "100%" }}>
        <AudioVideoToggle />
        {/* <ScreenshareToggle css={{ mx: "$4" }} /> */}
        {loginType === "doctor" ? <PIPComponent /> : ""}
        {/* <MoreSettings /> */}
        {loginType === "patient" || loginType === "doctor" ? <Chat /> : ""}
        <VerticalDivider space={4} />

        <LeaveRoom />
      </Flex>
      <Flex
        align="center"
        css={{
          position: "absolute",
          right: "$7",
          "@md": {
            display: "none",
          },
        }}
      >
        {/* <MetaActions /> */}
        {/* <VerticalDivider space={4} /> */}
        {/* <Chat /> */}
      </Flex>
    </Flex>
  );
};

const LeftDivider = () => {
  const allowedToPublish = useHMSStore(selectIsAllowedToPublish);
  return (
    <>
      {allowedToPublish.screen || FeatureFlags.enableWhiteboard ? (
        <VerticalDivider space={4} />
      ) : null}
    </>
  );
};
