import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
  Switch,
} from "antd";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPermissions } from "../../../../redux/reducers/permission.slice";
import {
  fetchAccessGroups,
  fetchAllModules,
  fetchAllScopeValues,
  fetchRoles,
} from "../../../../redux/reducers/role.slice";
import { RootState } from "../../../../shared/constants";
import { ScopeValueSelector } from "./scope-value-selector";

type Props = {
  onSubmit: (values: any) => void;
  accessGroup?: any;
};
const AccessGroupForm: React.FunctionComponent<Props> = ({
  accessGroup,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("");
  const [type, setType] = useState("dynamic");
  const [activeKeyActions, setActiveKeyActions] = useState("");
  const [roleId, setRoleId] = useState(
    accessGroup?.role_id || accessGroup?.role?.id
  );
  console.log(accessGroup?.role_id || accessGroup?.role?.id)
  console.log('--------------')
  console.log(roleId)
  const [selectedRole, setSelectedRole] = useState(null as any);
  const {
    role: { roles, modules, allScopeValues },
    user: { savingRole },
    permission: { permissions, loading },
    auth: { applications },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(fetchAccessGroups());
    dispatch(fetchAllModules());
    dispatch(fetchRoles());
    dispatch(fetchPermissions({}));
  }, []);

  useEffect(() => {
    const _role = getRoleById(roleId);
    setSelectedRole(_role);
    if (_role?.scopes?.length) {
      dispatch(fetchAllScopeValues({ scopes: _role?.scopes.join(",") }));
    }
  }, [roleId, roles]);

  useEffect(() => {
    if (selectedRole) {
      if (!scope_values.actions) {
        const actions = Object.keys(permissions.moduleSpecific).map((key) => {
          return {
            title: key,
            key: permissions.moduleSpecific[key].name,
            children: [
              ...Object.keys(permissions.moduleSpecific[key].actions).map(
                (k) => {
                  return {
                    title: k,
                    key: `${permissions.moduleSpecific[key].name}.actions.${permissions.moduleSpecific[key].actions[k]}`,
                  };
                }
              ),
              ...Object.keys(permissions.global).map((k) => {
                return {
                  title: k,
                  key: `${permissions.moduleSpecific[key].name}.actions.${permissions.global[k]}`,
                };
              }),
            ],
          };
        });
        const assignedActions = selectedRole.actions;
        let actionSpecific: any[] = [];
        assignedActions.forEach((a: string) => {
          actions.forEach((aInner) => {
            if (aInner.key === a) {
              actionSpecific = [
                ...actionSpecific,
                a,
                ...aInner.children.map((c) => c.key),
              ];
            } else {
              aInner.children.forEach((c) => {
                if (c.key === a) {
                  actionSpecific.push(a);
                }
              });
            }
          });
        });
        setScopeValues({
          ...scope_values,
          ["actions"]: actionSpecific,
          ["actionSpecific"]: scope_values["actionSpecific"] || {},
          ["moduleSpecific"]: scope_values["moduleSpecific"] || {},
        });
      }
    }
  }, [selectedRole, permissions]);
  useEffect(() => {
    if (accessGroup) {
      setName(accessGroup.name);
      setRoleId(accessGroup.role_id || accessGroup.role?.id);
      setType(accessGroup.type);
      setScopeValues(accessGroup.scope_values);
    }
  }, [accessGroup]);
  const [scope_values, setScopeValues] = useState({} as any);
  const [name, setName] = useState(accessGroup?.name as string);

  function handleChange(
    value: string | string[] | boolean,
    scope: string,
    module?: string | null,
    action?: string
  ) {
    if (action) {
      let actionSpecific = scope_values.actionSpecific || {
        [action]: { [scope]: value },
      };
      let _value: any = actionSpecific?.[action]?.[scope]
        ? [...actionSpecific?.[action]?.[scope]]
        : [];
      if (value === "*") {
        if ([...actionSpecific?.[action]?.[scope]]?.indexOf("*") > -1) {
          _value.splice(
            [...actionSpecific?.[action]?.[scope]]?.indexOf("*"),
            1
          );
        } else {
          _value = [..._value, "*"];
        }
      } else {
        _value = value;
      }
      actionSpecific = {
        ...actionSpecific,
        [action]: { ...actionSpecific[action], [scope]: _value },
      };
      setScopeValues({ ...scope_values, actionSpecific });

      return;
    }
    if (module) {
      let moduleSpecific = scope_values.moduleSpecific || {
        [module]: { [scope]: value },
      };
      let _value: any;
      if (typeof value == "boolean") {
        _value = value;
      } else {
        _value = moduleSpecific?.[module]?.[scope]
          ? [...moduleSpecific?.[module]?.[scope]]
          : [];
      }
      if (value === "*") {
        if ([...moduleSpecific?.[module]?.[scope]]?.indexOf("*") > -1) {
          _value.splice(
            [...moduleSpecific?.[module]?.[scope]]?.indexOf("*"),
            1
          );
        } else {
          _value = [..._value, "*"];
        }
      } else {
        _value = value;
      }
      moduleSpecific = {
        ...moduleSpecific,
        [module]: { ...moduleSpecific[module], [scope]: _value },
      };
      setScopeValues({ ...scope_values, moduleSpecific });

      return;
    }
    if (value === "*") {
      const _scope_values = { ...scope_values };
      if (!_scope_values[scope]) {
        _scope_values[scope] = [];
      }
      if ([..._scope_values[scope]]?.indexOf("*") === -1) {
        _scope_values[scope] = [..._scope_values[scope], "*"];
      } else {
        _scope_values[scope] = [..._scope_values[scope]];
        _scope_values[scope].splice([..._scope_values[scope]]?.indexOf("*"), 1);
      }
      setScopeValues(JSON.parse(JSON.stringify(_scope_values)));

      return;
    } else {
      setScopeValues({ ...scope_values, [scope]: value });
    }
  }

  const isScopeInModule = (s: any, m: any) => {
    let found = false;
    for (let i in m?.scopes) {
      if (m?.scopes[i].isGlobal || m?.scopes[i].name === s.scope) {
        console.log(
          s,
          m,
          m?.scopes[i].isGlobal,
          m?.scopes[i].name === s.scope,
          found,
          s.scope,
          m?.scopes[i].name
        );

        found = true;
      }
    }
    return found;
  };
  const getRoleById = (roleId: string) => {
    return roles.find((role: any) => role.id === roleId);
  };

  // console.log(allScopeValues)

  const _updateScopeValues = (
    scope: any,
    value: any,
    module?: string,
    action?: string
  ) => {
    if (action) {
      const _tempScopeValues = JSON.parse(JSON.stringify(scope_values || {}));
      _tempScopeValues.actionSpecific = _tempScopeValues.actionSpecific || {};
      _tempScopeValues.actionSpecific[action] =
        _tempScopeValues.actionSpecific[action] || {};
      _tempScopeValues.actionSpecific[action][scope] = value;
      setScopeValues(_tempScopeValues);
    } else if (module) {
      const _tempScopeValues = JSON.parse(JSON.stringify(scope_values || {}));
      _tempScopeValues.moduleSpecific = _tempScopeValues.moduleSpecific || {};
      _tempScopeValues.moduleSpecific[module] =
        _tempScopeValues.moduleSpecific[module] || {};
      _tempScopeValues.moduleSpecific[module][scope] = value;
      setScopeValues(_tempScopeValues);
    } else {
      setScopeValues({ ...scope_values, [scope]: value });
    }
  };
  console.log(allScopeValues);
  console.log("------------------");
  if (loading) {
    return <Skeleton />;
  }

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Row gutter={20}>
          <Col sm={24} md={12}>
            <label>Name</label>
            <div>
              <Input onChange={(e) => setName(e.target.value)} value={name} />
            </div>
          </Col>
          {!accessGroup && (
            <Col sm={24} md={12}>
              <label>Type</label>
              <div>
                <Select
                  defaultValue={"dynamic"}
                  onChange={(e: any) => setType(e)}
                  value={type}
                  style={{ minWidth: "100%" }}
                >
                  <Option value={"dynamic"}>Dynamic</Option>
                </Select>
              </div>
            </Col>
          )}
          <Col sm={24} md={12}>
            <label>Role</label>
            <div>
              <Select
                allowClear={true}
                value={roleId}
                onChange={(e) => {
                  setScopeValues({});
                  setRoleId(e);
                }}
                style={{ minWidth: "100%" }}
              >
                {roles.map((role: any) => {
                  return <Option value={role.id}>{role.name}</Option>;
                })}
              </Select>
            </div>
          </Col>
        </Row>
        <Divider />
        <div>
          <h5>Global Scope Values</h5>
          {allScopeValues?.length > 0 &&
            allScopeValues
              .filter((s: any) => s.data?.isGlobal)
              ?.map(({ scope, values, data }: any) => {
                return (
                  <div className="scopes">
                    <ScopeValueSelector
                      data={data}
                      scope={scope}
                      values={scope_values?.[scope]}
                      options={values}
                      updateValues={(e: any) => {
                        _updateScopeValues(scope, e);
                      }}
                    />
                  </div>
                );
              })}
        </div>
        <Divider />
        <h5>Module Specific Scope Values</h5>
        <Collapse
          activeKey={[activeKey]}
          onChange={(e) => setActiveKey(e?.[1])}
        >
          {modules
            .filter((m: any) => {
              const role = getRoleById(roleId);
              let found = false;
              if (role && role.actions) {
                role.actions.forEach((a: any) => {
                  if (a.indexOf(m.value) > -1) {
                    found = true;
                  }
                });
              }
              return found;
            })
            .map((module: any) => {
              return (
                <Collapse.Panel header={module.key} key={module.value}>
                  <p>Scope Values</p>
                  {allScopeValues?.length > 0 &&
                    allScopeValues
                      .filter(
                        (s: any) =>
                          s?.data?.isGlobal ||
                          module.scopeKeys?.indexOf(s.scope) > -1
                      )
                      ?.map(({ scope, values, data }: any) => (
                        <ScopeValueSelector
                          data={data}
                          scope={scope}
                          values={
                            scope_values?.["moduleSpecific"]?.[module.value]?.[
                              scope
                            ]
                          }
                          options={values}
                          updateValues={(e: any) => {
                            _updateScopeValues(scope, e, module.value);
                          }}
                        />
                      ))}
                </Collapse.Panel>
              );
            })}
        </Collapse>
        <Divider />
        <h5>Action Specific Scope Values</h5>
        <Collapse
          activeKey={[activeKeyActions]}
          onChange={(e) => setActiveKeyActions(e?.[1])}
        >
          {scope_values.actions &&
            modules
              .filter((m: any) => {
                const role = getRoleById(roleId);
                let found = false;
                if (role && role.actions) {
                  role.actions.forEach((a: any) => {
                    if (a.indexOf(m.value) > -1) {
                      found = true;
                    }
                  });
                }
                return found;
              })
              .map((module: any) => {
                return (
                  <Collapse.Panel header={module.key} key={module.value}>
                    {scope_values.actions
                      .filter((a: string) => a.startsWith(module.value))
                      .map((action: string) => {
                        return (
                          <Card style={{ marginBottom: "20px" }}>
                            <p>
                              Scope Values of{" "}
                              <strong>{action.replaceAll(".", " ")}</strong>{" "}
                              <Switch
                                checked={
                                  scope_values?.["actionSpecific"]?.[action]
                                }
                                onChange={(e) =>
                                  setScopeValues({
                                    ...scope_values,
                                    ["actionSpecific"]: {
                                      ...scope_values["actionSpecific"],
                                      [action]: e ? {} : null,
                                    },
                                  })
                                }
                              />
                            </p>
                            {scope_values?.["actionSpecific"]?.[action] &&
                              allScopeValues?.length > 0 &&
                              allScopeValues
                                .filter(
                                  (s: any) =>
                                    s?.data?.isGlobal ||
                                    module.scopeKeys?.indexOf(s.scope) > -1
                                )
                                ?.map(({ scope, values, data }: any) => (
                                  <ScopeValueSelector
                                    data={data}
                                    scope={scope}
                                    values={
                                      scope_values?.["actionSpecific"]?.[
                                        action
                                      ]?.[scope]
                                    }
                                    options={values}
                                    updateValues={(e: any) => {
                                      _updateScopeValues(
                                        scope,
                                        e,
                                        module.value,
                                        action
                                      );
                                    }}
                                  />
                                ))}
                          </Card>
                        );
                      })}
                  </Collapse.Panel>
                );
              })}
        </Collapse>
        <Button
          onClick={() => {
            if (!name) {
              notification.error({ message: "Name required" });
              return;
            }
            if (!roleId) {
              notification.error({ message: "Role required" });
              return;
            }
            onSubmit({
              name,
              role_id: roleId,
              type,
              scope_values: scope_values,
            });
          }}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );
};

export default AccessGroupForm;

// <Collapse activeKey={[activeKey]} onChange={(e: any) => setActiveKey(e?.[1])}>
//     {
//         applications.map((application: any, applicationIndex: number) => {
//             return <Collapse.Panel header={application.name} key={application.slug}>
//                 <p>Roles </p>
//                 <Space direction={'vertical'}>
//                     <Select
//                         allowClear={true}
//                         value={scope_values[application.id]?.role_id}
//                         onChange={(e) => handleRoleChange(applicationIndex, e)}
//                         style={{minWidth: '250px'}}>
//                         {
//                             roles.map((role: any) => {
//                                 return <Option value={role.id}>
//                                     {role.name}
//                                 </Option>
//                             })
//                         }
//                     </Select>
//                     {
//                         scope_values[application.id]?.scopes?.map((scope: string) => {
//                             return <div className="scopes">
//                                 {
//                                     scope === 'branch' && <div>
//                                         <label>Branches</label>
//                                         <Select
//                                             mode="tags"
//                                             placeholder="Please select branches"
//                                             defaultValue={scope_values[application.id][scope]}
//                                             onChange={(e) => handleChange(application.id, e, scope)}
//                                             style={{width: '100%'}}>
//                                             {
//                                                 branches.map((branch: Branch) => {
//                                                     return <Option key={branch.id}
//                                                                    value={branch.id}>{branch.name}</Option>
//                                                 })
//                                             }
//                                         </Select>
//                                     </div>
//                                 }
//                             </div>
//                         })
//                     }
//                 </Space>
//             </Collapse.Panel>
//         })
//     }
//
// </Collapse>
