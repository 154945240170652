const CrossIcon =({height="15" , width="15",color="#163B6D",onClick}:any) =>(
    <>
       
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.8458 0.707031L13.1115 10.4413L3.37722 0.707031L0.921875 3.16238L10.6562 12.8967L0.921875 22.631L3.37722 25.0864L13.1115 15.352L22.8458 25.0864L25.3012 22.631L15.5669 12.8967L25.3012 3.16238L22.8458 0.707031Z" fill={color}/>
        </svg>

    </> 
);

export default CrossIcon;