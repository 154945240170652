import React from "react";
import ValidationComponent from "./ValidationComponent";

import { Space, Col, Tooltip, Checkbox } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./tooltip.css";
class CheckboxComponent extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  changeValue = (event) => {
    this.props.emitTextboxValue({
      name: event.target.name,
      value: event.target.value,
      type: "checkbox",
    });
  };

  checkIfValuePresentInValuesArray(valuesArray, value) {
    if (!value || !valuesArray) {
      return;
    }
    for (let index in valuesArray) {
      if (valuesArray[index] === value) {
        return true;
      }
    }
    return false;
  }

  setErrorStatus = (errorStatus) => {
    this.props.emitErrorStatus(errorStatus);
  };

  checkForLabelValue(str) {
    if (!str || !str.trim()) {
      return false;
    }
    return true;
  }

  render() {
    const {
      type,
      name,
      classname,
      value,
      placeholder,
      label,
      labelSize,
      size,
      options = [],
      offset,
      rowType,
      info,
      viewType,
      validation,
      values,
    } = this.props;
    return (
      <>
        {labelSize && (
          <>
            <Col
              span={`${labelSize * 2}`}
              style={{
                textAlign: "left",
                paddingRight: 10,
                display: this.checkForLabelValue(label, value) ? "" : "none",
              }}
            >
              <label
                ant-form-item-label
                for="basic_username"
                className="ant-form-item"
                style={{ color: "#000000d9" }}
              >
                {/* ant-form-item-required */}
                {label}{" "}
                {validation && validation.required && (
                  <span style={{ color: "#ff4d4f" }}>*</span>
                )}{" "}
                {info && (
                  <Tooltip title={info}>
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                )}
              </label>
            </Col>
          </>
        )}

        <Col span={`${size * 2}`} style={{ marginBottom: "2rem" }}>
          {!labelSize && (
            <label
              className="form-label "
              style={{
                display: this.checkForLabelValue(label, value) ? "" : "none",
                paddingRight: 10,
                color: "#000000d9",
              }}
            >
              {label}
            </label>
          )}
          <div className="d-sm-flex" style={{ flexWrap: "wrap" }}>
            <Space size={[8, 14]} wrap>
              {viewType ? (
                <>{values ? values.join() : ""}</>
              ) : (
                <>
                  {options &&
                    options.map((indOption, index) => {
                      return (
                        <div
                          className="form-check mr-3 "
                          key={index}
                          style={{ marginTop: 5 }}
                        >
                          <label className="form-check-label">
                            <Checkbox
                              type="checkbox"
                              className="form-check-input"
                              style={{ margin: 5 }}
                              name={`${label}-${name}`}
                              id={name}
                              value={indOption.value || ""}
                              checked={this.checkIfValuePresentInValuesArray(
                                values,
                                indOption.value
                              )}
                              onChange={this.changeValue.bind(this)}
                            />
                            {indOption.value}
                            <i className="input-helper"></i>
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      );
                    })}
                </>
              )}
            </Space>
          </div>
          {this.props.validation && (
            <ValidationComponent
              value={values}
              checkForm={this.props.checkForm}
              isDirty={this.props.isDirty}
              validation={this.props.validation}
              emitErrorStatus={this.setErrorStatus.bind(this)}
            />
          )}
        </Col>
        {offset && <div className={offset}> </div>}
      </>
    );
  }
}

export default CheckboxComponent;

{
  /* <label className={`col-lg-${labelSize}`} htmlFor={name}>{label}</label>
{
    options &&
    <React.Fragment>
        {options.map((indOption, index) =>
            <div className={`col-lg-${size}`} key={index}>
                <div className="form-check">
                    <label className="form-check-label" style={{ fontWeight: 500 }}>
                        <input type="checkbox" className="form-check-input" name={`${label}-${name}`} id={name} value={indOption.value || ''} checked={this.checkIfValuePresentInValuesArray(indOption.value, indOption.values)} onChange={this.changeValue.bind(this)} />
                        {indOption.value}
                        <i className="input-helper"></i></label>
                </div>
            </div>
        )}
    </React.Fragment>
} */
}
