import { Col, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchPaymentHistory } from "../../../../redux/reducers/tickets.slice";
const { Text } = Typography;

const PatientPaymentHistory: React.FunctionComponent<any> = (Props) => {
  const [dataSource, setdataSource] = useState([] as any);
  const dispatch = useDispatch();
  console.log("orderId", Props.orderId);
  const columns = [
    {
      title: "Payments Status",
      dataIndex: "payment_status",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Action By",
      dataIndex: "action_by",
    },
    {
      title: "Transaction ID",
      dataIndex: "gateway_order_id",
    },
    {
      title: "Date Time",
      dataIndex: "payment_link_date",
      render: (key: any) => moment(key).format("LLL"),
    },
    {
      title: "Details",
      dataIndex: "details",
      width: "10%",
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchPaymentHistory(Props.orderId)).then((item: any) => {
      setdataSource(
        item?.payload?.data?.data?.rows ? item?.payload?.data?.data?.rows : []
      );
    });
  }, []);

  console.log("dataSource", dataSource);

  return (
    <Col span={24}>
      <Table
        loading={false}
        locale={{
          emptyText: (
            <>
              <Space direction={"vertical"} size={10}>
                <Text>No Records</Text>
              </Space>
            </>
          ),
        }}
        pagination={false}
        scroll={{ x: true }}
        dataSource={dataSource}
        columns={columns}
      />
    </Col>
  );
};

export default PatientPaymentHistory;
