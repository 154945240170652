import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADD_REGION, REGIONS, UPDATE_REGION } from "../../shared/routes/route.constants";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "regions",
};
export const fetchRegions: any = createAsyncThunk(
  `${config.name}/fetchRegions`,
  async (params: any) => {
    return await Client.get(REGIONS, { params });
  }
);

export const addNewRegion: any = createAsyncThunk(
  `${config.name}/addNewRegion`,
  async (data: any) => {
    return await Client.post(ADD_REGION, data);
  }
);

export const updateRegion: any = createAsyncThunk(
  `${config.name}/updateRegion`,
  async (params: any) => {
    return await Client.post(UPDATE_REGION, params);
  }
);

export const regionSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    error: "" as string,
    regions: [] as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = "";
      state.regions = [];
    },
    setRegion : (state, action) => {
      state.regions = action?.payload;
    }
  },
  extraReducers(builder) {
    builder
      // Region List
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.regions = action?.payload?.data?.data;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {setRegion} = regionSlice.actions;

export default regionSlice.reducer;
