import * as React from 'react';
import {Form, Input, Button, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

interface Props {
    onVerify: (a: any) => void;
    loading: boolean;
    username: string;
}

export default function SignupConfirmForm({

                                              onVerify,
                                              username,
                                              loading,
                                          }: Props) {
    const {t} = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();

    function handleSubmit(values: any) {
        if (onVerify) {
            const {username, code} = values;
            onVerify({username, code});
        }
    }

    return (
        <Form
            size='large'
            form={form}
            onFinish={handleSubmit}
            layout='vertical'
            initialValues={
                {
                    username,
                }
            }
            requiredMark={false}
        >
            <Form.Item
                label={'Username/Phone'}
                name='username'
                rules={[
                    {required: true, message: 'Username required'},
                ]}
            >
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                label={'Code'}
                name='code'
                rules={[
                    {required: true, message: 'Code required'},
                ]}
            >
                <Input/>
            </Form.Item>
            <br/>
            <Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    shape='round'
                    size={'large'}
                    className='submit'
                >
                    Verify Code
                </Button>
            </Form.Item>
        </Form>
    );
}
