import { Button, Col, Form, message, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import {
  attemptOTPLogin,
  fetchMyPermissions,
  fetchSelf,
  validateOTP,
  validatePatientOTP,
} from "../../../redux/reducers/auth.slice";
import { fetchProfile } from "../../../redux/reducers/profile.slice";
import { RootState } from "../../../shared/constants";
import GoBacklayout from "../../../shared/layouts/go-back.layout";
import TimerLayout from "../../../shared/layouts/timer-desktop.layout";
import { ROUTE_ADMIN_DASHBOARD } from "../../../shared/routes/admin.routes.constants";
import { ROUTE_BOSS_DASHBAORD } from "../../../shared/routes/boss.routes.constants";
import { ROUTE_CRM_TICKET_LIST } from "../../../shared/routes/crm.routes.constants";
import { ROUTE_DOCTOR_DASHBOARD } from "../../../shared/routes/doctor.routes.constants";
import {
  ROUTE_PATIENT_DASHBOARD,
  ROUTE_PATIENT_LINKING,
} from "../../../shared/routes/patient.routes.constants";
import { useQuery } from "../../../shared/Utils/utilities";

const { Text } = Typography;

interface Props {
  loginByPin: (b: boolean) => void;
  username: string;
  kind: string;
  countryCode: string;
  setOtp: (value: any) => void;
}

export const OTPForm = ({
  loginByPin,
  countryCode,
  kind,
  username,
  setOtp,
}: Props) => {
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const { otpSessionId, loggingIn } = useSelector(
    (state: RootState) => state.auth
  );
  const [applicationId, setApplicationId] = useState("");
  const RouteParams: any = useParams();
  const [resend, setResend] = useState(false);
  const [optValue, setOptValue] = useState("••••••");
  const [_otp, _setOtp] = useState(true);
  const [time, setTime] = useState(90);
  const dispatch = useDispatch();
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
  const query = useQuery();
  const isImpersonate = query.get("impersonate") === "true";
  const userId = query.get("user_id");
  useEffect(() => {
    const selectedApplication = applications?.find(
      (a: any) => a.slug === RouteParams?.source
    );
    setApplicationId(selectedApplication?.id);
  }, [applications, account]);

  return (
    <>
      <Row>
        <Col
          span={2}
          md={2}
          xs={2}
          style={{
            alignItems: "center",
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
          <GoBacklayout></GoBacklayout>
        </Col>
        <Col span={22} md={22} xs={22}>
          <Title
            className={
              screenType.desktop
                ? "  bold"
                : "bold text-center doctor-secondary-color"
            }
            level={3}
          >
            Enter OTP
          </Title>
        </Col>
      </Row>

      <p
        className={
          screenType.desktop
            ? "doctor-primary-color text-left fts16"
            : "doctor-primary-color text-center fts16"
        }
      >
        We have sent an OTP on your mobile {countryCode} {username} <br></br>
        {/** change? is suggested by max team */}
        <Text
          className="doctor-secondary-color bold  cursor"
          underline
          onClick={() => setOtp(false)}
        >
          Change?
        </Text>
      </p>
      <Form
        onFinish={async ({ otp }) => {
          let response;
          if (RouteParams["source"] === "patient") {
            response = await dispatch(
              validatePatientOTP({
                otp,
                kind,
                session_id: otpSessionId,
                application_id: applicationId,
              })
            );
          } else {
            response = await dispatch(
              validateOTP({
                otp,
                kind,
                impersonate: isImpersonate,
                session_id: otpSessionId,
                user_id: isImpersonate ? userId : "",
                application_id: applicationId,
                current_application: RouteParams["source"],
              })
            );
          }
          if (response?.payload?.data?.data?.access_token) {
            if (RouteParams["source"] === "doctor") {
              (async function () {
                try {
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (response.payload.status === 200) {
                    window.location.href = ROUTE_DOCTOR_DASHBOARD;
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }
            if (RouteParams["source"] === "patient") {
              (async function () {
                try {
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("patients"));
                  if (response.payload.status === 200) {
                    if (
                      Object.keys(response?.payload?.data?.data).length === 0
                    ) {
                      window.location.href = ROUTE_PATIENT_LINKING(username);
                    } else {
                      window.location.href = ROUTE_PATIENT_DASHBOARD;
                    }
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }

            if (RouteParams["source"] === "admin") {
              (async function () {
                try {
                  await dispatch(fetchSelf());
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (response.payload.status === 200) {
                    window.location.href = ROUTE_ADMIN_DASHBOARD(account?.slug);
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }
            if (RouteParams["source"] === "boss") {
              (async function () {
                try {
                  await dispatch(fetchSelf());
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (
                    response.payload.status === 201 ||
                    response.payload.status === 200
                  ) {
                    window.location.href = ROUTE_BOSS_DASHBAORD;
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }
            if (RouteParams["source"] === "crm") {
              (async function () {
                try {
                  await dispatch(fetchSelf());
                  await dispatch(fetchMyPermissions());
                  const response = await dispatch(fetchProfile("users"));
                  if (response.payload.status === 200) {
                    window.location.href = ROUTE_CRM_TICKET_LIST;
                  }
                } catch (e) {
                  console.error(e);
                }
              })();
            }
          }
        }}
      >
        <Form.Item
          name="otp"
          className="mb-20"
          rules={[{ required: true, message: "Please input your OTP!" }]}
        >
          {/* <Input
                    className="noborderWrapper otp-box border-radius overflow-hidden doctor-app-box-shadow" size={"large"}
                    placeholder={"••••••"} /> */}
          <OtpInput
            value={optValue}
            inputStyle={"ant-input-otp"}
            onChange={(evt: any) => setOptValue(evt)}
            numInputs={6}
            isInputNum={true}
            separator={<span></span>}
          />
        </Form.Item>

        {!resend ? (
          <div className=" bold text-center primary-color-text ">
            <TimerLayout
              placeholder="Resend OTP"
              onCompletion={async () => {
                _setOtp(false);
                const response = await dispatch(
                  attemptOTPLogin({
                    username: username,
                    account_id: account.id,
                    kind: kind,
                    country_code: countryCode,
                  })
                );
                if (response.payload?.data?.data?.session_id) {
                  setResend(false);
                  setTime(90);
                  setCurrentTimeIndex(currentTimeIndex + 1);
                  message.success("OTP Sent");
                }
              }}
              time={time}
              currentTimeIndex={currentTimeIndex}
            ></TimerLayout>
          </div>
        ) : (
          ""
        )}

        <Row gutter={32}>
          {/* <Col span={12}>
            <Button size="large" className="doctor-border-button doctor-padding-15 mt10" block
                onClick={async () => {
                    await dispatch(resetOTPSessionId());
                    loginByPin(true)
                }
                }> Login By PIN</Button>
                </Col> */}
          <Col span={24}>
            <Button
              size="large"
              className="doctor-secondary-button doctor-padding-15 mt10 btn-shadow"
              block
              htmlType={"submit"}
              style={{ float: "right" }}
              loading={loggingIn}
            >
              {" "}
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
