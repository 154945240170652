import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FETCH_CMS_DATA, FETCH_GENERAL_SETTINGS_BY_TYPE, FETCH_STATS, UPDATE_CMS_DATA, UPDATE_GENERAL_SETTINGS_BY_ID } from '../../shared/routes/admin.routes.constants';
import { REDIRECT_URL } from '../../shared/routes/route.constants';
import { UPDATE_SETTINGS_PERMISSIONS_BY_ID, USER_NOTIFICATION_SETTINGS, USER_SETTINGS, USER_SETTINGS_PERMISSIONS } from '../../shared/routes/user.routes.constants';
import { Client } from "../../shared/Utils/api-client";


const config = {
    name: 'setting',
};
export const fetchAllNotificationSettings: any = createAsyncThunk(`${config.name}/fetchAllNotificationSettings`, async (params) => {
    return await Client.get(USER_NOTIFICATION_SETTINGS, {params})
})


export const fetchAllPermissions: any = createAsyncThunk(`${config.name}/fetchAllPermissions`, async (params:any) => {
    return await Client.get(USER_SETTINGS_PERMISSIONS, {params})
})

export const updateAllPermissions: any = createAsyncThunk(`${config.name}/updateAllPermissions`, async (data:any) => {
    return await Client.patch(UPDATE_SETTINGS_PERMISSIONS_BY_ID(data.id), data.data)
})

export const updateSettings: any = createAsyncThunk(`${config.name}/updateSettings`, async (data: any) => {
    return await Client.post(USER_SETTINGS, data)
})
export const updateNotificationSettings: any = createAsyncThunk(`${config.name}/updateNotificationSettings`, async (data: any) => {
    return await Client.post(USER_NOTIFICATION_SETTINGS, data)
})

export const getSettings: any = createAsyncThunk(`${config.name}/getSettings`, async (params: any) => {
    return await Client.get(USER_SETTINGS, {params})
})

export const redirectUrl: any = createAsyncThunk(`${config.name}/redirectUrl`, async (id: any) => {
    return await Client.get(REDIRECT_URL(id), {shouldHide:true})
})

export const fetchGeneralSettingsByType: any = createAsyncThunk(`${config.name}/fetchGeneralSettingsByType`, async (params:any) => {
    return await Client.get(FETCH_GENERAL_SETTINGS_BY_TYPE, {params})
})

export const updateGeneralSettingById: any = createAsyncThunk(`${config.name}/updateGeneralSettingById`, async ({
    id, data
}: { id: string, data: any }) => {
    return await Client.patch(UPDATE_GENERAL_SETTINGS_BY_ID(id), data)
})

export const fetchAdminStats: any = createAsyncThunk(`${config.name}/fetchAdminStats`, async (data: any) => {
    return await Client.get(FETCH_STATS(data), {})
})

export const fetchCMSData: any = createAsyncThunk(`${config.name}/fetchCMSData`, async (params: any) => {
    return await Client.get(FETCH_CMS_DATA, { params })
})

export const updateCMSData: any = createAsyncThunk(`${config.name}/updateCMSData`, async (data: any) => {
    return await Client.post(UPDATE_CMS_DATA, data)
})



export const settingsSlice = createSlice({
    name: config.name,
    initialState: {
        loading: true,
        settings: [],
        error: '' as string,
        permissions: [],
        settingId : null as any,
        redirect_url:null as any,
        generalSettings: null as any
    },
    reducers: {
        reset: (state) => {
            state.settings = []
            state.loading = false
            state.error = ''
        }
    }, extraReducers(builder) {
        builder
            // Settings  List
            .addCase(fetchAllNotificationSettings.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(fetchAllNotificationSettings.fulfilled, (state, action) => {
                state.loading = false
                state.settingId = action.payload?.data?.data?.id
                state.settings = action.payload?.data?.data;
            })
            .addCase(fetchAllNotificationSettings.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            // Settings  List
            .addCase(fetchAllPermissions.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(fetchAllPermissions.fulfilled, (state, action) => {
                state.loading = false
                state.settingId = action.payload?.data?.data?.id
                state.permissions = action.payload?.data?.data?.settings;
            })
            .addCase(fetchAllPermissions.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

            .addCase(getSettings.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(getSettings.fulfilled, (state, action) => {
                state.loading = false
                state.settingId = action.payload?.data?.data?.id
                state.settings = action.payload?.data?.data?.settings;
            })
            .addCase(getSettings.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

            .addCase(redirectUrl.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(redirectUrl.fulfilled, (state, action) => {
                state.loading = false
                state.redirect_url = action.payload?.data?.data
            })
            .addCase(redirectUrl.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

            // Fetch order details by id
            .addCase(fetchGeneralSettingsByType.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(fetchGeneralSettingsByType.fulfilled, (state, action) => {
                state.loading = false
                state.generalSettings = action?.payload?.data?.data;
            })
            .addCase(fetchGeneralSettingsByType.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    }
})

export const { reset } = settingsSlice.actions

export default settingsSlice.reducer
