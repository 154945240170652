import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewRegion } from "../../../../redux/reducers/region.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import RegionForm from "./region-form.page";

const RegionCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <DesktopFormLayout title={"Region Create"} subtitle={"Create new region"}>
      <RegionForm
        onSubmit={async (values: any) => {
          // console.log(values);
          const {error} = await dispatch(addNewRegion(values));
          if (!error)
          history.goBack();
        }}
      />
    </DesktopFormLayout>
  );
};

export default RegionCreate;
