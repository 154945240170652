import { CompressOutlined, ExpandOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Modal,
  Row,
  Skeleton,
  Spin,
  Typography,
} from "antd";
import moment from "moment";
//import { DeleteOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import CrossIcon from "../icons/CrossIcon";
const { Text } = Typography;
function FileViewLayout({
  details,
  fileviewer,
  setFileViewer,
  file,
  deleteDocuments,
  showNext,
  showPrev,
  selectedIndex,
  total,
  id,
  modal,
  isDoctor,
  data,
  showNextBtn,
  showPrevBtn,
}: any) {
  const [rotate, setRotate] = useState(
    details?.allData?.rotation
      ? parseInt(details?.allData?.rotation)
      : (0 as any)
  );
  const [scale, setScale] = useState(1 as any);
  const [_loader, setLoader] = useState(true as any);
  const [modalVal, setModal] = useState(modal as any);

  useEffect(() => {
    setLoader(false);
  }, [file]);

  const downloadFile = async (file: any) => {
    const originalImage = file;
    const image = await fetch(originalImage);

    //Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!modal) {
    return (
      <Row
        className={modalVal ? "full-screen-iframe" : ""}
        onClick={() => {
          modalVal && setModal(false);
        }}
      >
        <Col
          span={details?.img && details?.img === "00" ? 22 : 24}
          style={{ overflow: "hidden" }}
        >
          <div
            style={{
              transform: `rotate(${rotate}deg)`,
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            {_loader ? (
              <Skeleton></Skeleton>
            ) : details?.img === "image" ||
              details?.img === "png" ||
              details?.img === "jpg" ||
              details?.img === "jpeg" ? (
              <Image
                style={{ transform: `scale(${scale})`, overflow: `scroll` }}
                src={file?.result}
                height={"100%"}
                width={"100%"}
              ></Image>
            ) : details?.img === "json" ? (
              <>
                {/* <FormView prescriptionData={selectedTemplateDataRaw} /> */}
              </>
            ) : (
              <div className="expandable-iframe">
                <iframe
                  title="expandable-iframe"
                  src={file?.result}
                  style={{
                    border: "none",
                    height: "calc( 100vh - 200px)",
                    width: "100%",
                  }}
                ></iframe>
                <Button
                  className={modalVal ? "d-none" : "iframe-hover-button"}
                  size="large"
                  shape="circle"
                  type="link"
                  onClick={() => setModal(true)}
                  icon={<ExpandOutlined />}
                />
              </div>
            )}
          </div>
        </Col>
        <Button
          className={!modalVal ? "d-none" : "iframe-hover-button-close"}
          size="large"
          shape="circle"
          type="link"
          onClick={() => setModal(false)}
          icon={<CompressOutlined />}
        />
        {details?.img && details?.img === "00" ? (
          <Col span={2} className="text-center">
            <p>
              <small>Zoom</small>
            </p>
            <p>
              <svg
                width="45"
                onClick={() => {
                  setScale(scale + 0.5);
                }}
                style={{ padding: "5px 10px" }}
                className={"cursor "}
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.71875"
                  y="0.0859375"
                  width="44"
                  height="44"
                  rx="6.6383"
                  fill="#23BCB5"
                />
                <path
                  d="M23.4483 24.4199H29.3195V21.9393H23.4483V16.5859H20.877V21.9393H15.0195V24.4199H20.877V29.7859H23.4483V24.4199Z"
                  fill="white"
                />
              </svg>
            </p>
            <p>
              <Button
                icon={
                  <svg
                    width="16"
                    height="4"
                    viewBox="0 0 16 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7841 3.00608H9.53342H6.796H0.559937V0.365234H6.796H9.53342H15.7841V3.00608Z"
                      fill="#23BCB5"
                    />
                  </svg>
                }
                disabled={scale > 1}
                onClick={() => setScale(scale - 0.5)}
                style={{ padding: "5px 10px" }}
                className="doctor-white-color-background doctor-secondary-color border-radius5"
              ></Button>
            </p>

            <p>
              <small>Rotate</small>
            </p>
            <p>
              <Button
                onClick={() => setRotate(rotate + 90)}
                icon={
                  <svg
                    width="21"
                    height="14"
                    viewBox="0 0 21 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7004 13.7463C19.5295 13.7463 19.3728 13.6485 19.3056 13.4881C19.2564 13.3717 18.025 10.6096 13.421 10.0625C12.462 9.94611 11.3134 9.8864 9.91855 9.87596V13.3187C9.91855 13.4776 9.83272 13.6217 9.69167 13.6978C9.55136 13.7709 9.38344 13.7605 9.25284 13.6732L0.40758 7.71912C0.288171 7.63927 0.218018 7.50493 0.218018 7.36388C0.218018 7.22133 0.288171 7.09148 0.40758 7.00789L9.25657 1.05309C9.38717 0.962783 9.5536 0.956812 9.6954 1.03144C9.8372 1.10757 9.92153 1.2516 9.92153 1.40609V4.61C11.8455 4.86076 20.1266 6.3489 20.1266 13.3224C20.1266 13.5247 19.984 13.7015 19.784 13.7418C19.7564 13.7463 19.7273 13.7463 19.7004 13.7463Z"
                      fill="#23BCB5"
                    />
                  </svg>
                }
                style={{ padding: "5px 10px" }}
                className="doctor-white-color-background doctor-secondary-color border-radius5"
              ></Button>
            </p>
            <p>
              <Button
                disabled={rotate === 0 ? true : false}
                onClick={() => setRotate(rotate - 90)}
                icon={
                  <svg
                    width="21"
                    height="14"
                    viewBox="0 0 21 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.815083 13.1726C0.985987 13.1726 1.14271 13.0748 1.20988 12.9144C1.25914 12.7979 2.49055 10.0358 7.09453 9.4888C8.05354 9.37238 9.20211 9.31267 10.597 9.30223V12.745C10.597 12.9039 10.6828 13.048 10.8238 13.1241C10.9641 13.1972 11.1321 13.1868 11.2627 13.0994L20.1079 7.14539C20.2273 7.06554 20.2975 6.9312 20.2975 6.79015C20.2975 6.6476 20.2273 6.51775 20.1079 6.43416L11.2589 0.479355C11.1283 0.389052 10.9619 0.383082 10.8201 0.457713C10.6783 0.533836 10.594 0.677874 10.594 0.83236V4.03627C8.66999 4.28703 0.388939 5.77517 0.388939 12.7487C0.388939 12.9509 0.531485 13.1278 0.731495 13.1681C0.759109 13.1726 0.788216 13.1726 0.815083 13.1726Z"
                      fill="#23BCB5"
                    />
                  </svg>
                }
                style={{ padding: "5px 10px" }}
                className="doctor-white-color-background doctor-secondary-color border-radius5"
              ></Button>
            </p>
            {data !== 0 && typeof showNext === "function" ? (
              <>
                <p>
                  <small>Next Doc</small>
                </p>
                <p>
                  <Button
                    disabled={total === selectedIndex + 1 ? true : false}
                    onClick={() => showNext("PRESCRIPTION", 1)}
                    icon={
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.21875"
                          y="0.459473"
                          width="46.8436"
                          height="46.8436"
                          rx="7.06732"
                          fill="white"
                        />
                        <path
                          d="M16.1921 16.306C15.5365 15.7161 14.5267 15.7694 13.9368 16.4251C13.347 17.0807 13.4003 18.0905 14.0559 18.6803L16.1921 16.306ZM21.5118 23.2402L22.6016 24.4075C22.9288 24.1019 23.1128 23.6731 23.1087 23.2254C23.1045 22.7778 22.9127 22.3524 22.5799 22.053L21.5118 23.2402ZM14.0342 28.0368C13.3895 28.6387 13.3549 29.6492 13.9567 30.2939C14.5586 30.9386 15.5692 30.9732 16.2138 30.3713L14.0342 28.0368ZM14.0559 18.6803L20.4437 24.4274L22.5799 22.053L16.1921 16.306L14.0559 18.6803ZM20.422 22.0729L14.0342 28.0368L16.2138 30.3713L22.6016 24.4075L20.422 22.0729Z"
                          fill="#23BCB5"
                        />
                        <path
                          d="M26.8384 16.306C26.1827 15.7161 25.173 15.7694 24.5831 16.4251C23.9932 17.0807 24.0465 18.0905 24.7022 18.6803L26.8384 16.306ZM32.158 23.2402L33.2478 24.4075C33.5751 24.1019 33.759 23.6731 33.7549 23.2254C33.7508 22.7778 33.5589 22.3524 33.2261 22.053L32.158 23.2402ZM24.6804 28.0368C24.0358 28.6387 24.0011 29.6492 24.603 30.2939C25.2049 30.9386 26.2154 30.9732 26.8601 30.3713L24.6804 28.0368ZM24.7022 18.6803L31.0899 24.4274L33.2261 22.053L26.8384 16.306L24.7022 18.6803ZM31.0682 22.0729L24.6804 28.0368L26.8601 30.3713L33.2478 24.4075L31.0682 22.0729Z"
                          fill="#23BCB5"
                        />
                      </svg>
                    }
                    style={{ padding: "5px 10px" }}
                    className="doctor-white-color-background doctor-secondary-color border-radius5"
                  ></Button>
                </p>
              </>
            ) : (
              ""
            )}
            {data !== 0 && typeof showPrev === "function" ? (
              <>
                <p>
                  <small>Prev Doc</small>
                </p>
                <p>
                  <Button
                    disabled={selectedIndex === 0 ? true : false}
                    onClick={() => showPrev("PRESCRIPTION", 0)}
                    icon={
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0901 0.708327C18.7458 0.118435 19.7555 0.171751 20.3454 0.827413C20.9353 1.48307 20.882 2.4928 20.2263 3.08269L18.0901 0.708327ZM12.7704 7.64252L11.6806 8.8098C11.3534 8.50428 11.1694 8.07545 11.1736 7.62779C11.1777 7.18013 11.3695 6.75476 11.7023 6.45534L12.7704 7.64252ZM20.248 12.4391C20.8927 13.041 20.9274 14.0516 20.3255 14.6962C19.7236 15.3409 18.7131 15.3756 18.0684 14.7737L20.248 12.4391ZM20.2263 3.08269L13.8385 8.8297L11.7023 6.45534L18.0901 0.708327L20.2263 3.08269ZM13.8602 6.47525L20.248 12.4391L18.0684 14.7737L11.6806 8.8098L13.8602 6.47525Z"
                          fill="#23BCB5"
                        />
                        <path
                          d="M7.44387 0.708327C8.09953 0.118435 9.10925 0.171751 9.69914 0.827413C10.289 1.48307 10.2357 2.4928 9.58006 3.08269L7.44387 0.708327ZM2.12419 7.64252L1.03438 8.8098C0.707152 8.50428 0.523189 8.07545 0.527319 7.62779C0.531449 7.18013 0.723292 6.75476 1.0561 6.45534L2.12419 7.64252ZM9.60178 12.4391C10.2464 13.041 10.2811 14.0516 9.67924 14.6962C9.07735 15.3409 8.06682 15.3756 7.42215 14.7737L9.60178 12.4391ZM9.58006 3.08269L3.19229 8.8297L1.0561 6.45534L7.44387 0.708327L9.58006 3.08269ZM3.21401 6.47525L9.60178 12.4391L7.42215 14.7737L1.03438 8.8098L3.21401 6.47525Z"
                          fill="#23BCB5"
                        />
                      </svg>
                    }
                    style={{ padding: "5px 10px" }}
                    className="doctor-white-color-background doctor-secondary-color border-radius5"
                  ></Button>
                </p>
              </>
            ) : (
              ""
            )}
            {typeof deleteDocuments === "function" && !isDoctor ? (
              <>
                <p>
                  <small>Delete</small>
                </p>
                <p>
                  <svg
                    width="48"
                    height="48"
                    onClick={() => deleteDocuments(id)}
                    className={"cursor"}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.335938"
                      y="0.671387"
                      width="46.8436"
                      height="46.8436"
                      rx="7.06732"
                      fill="white"
                    />
                    <path
                      d="M17.1719 31.0752C17.1719 32.1752 18.0719 33.0752 19.1719 33.0752H27.1719C28.2719 33.0752 29.1719 32.1752 29.1719 31.0752V19.0752H17.1719V31.0752ZM30.1719 16.0752H26.6719L25.6719 15.0752H20.6719L19.6719 16.0752H16.1719V18.0752H30.1719V16.0752Z"
                      fill="#B2403A"
                    />
                  </svg>
                </p>
              </>
            ) : (
              ""
            )}
          </Col>
        ) : (
          <></>
        )}
      </Row>
    );
  } else {
    return (
      <>
        <Modal
          centered
          className="doctor-app-dark-modal"
          closeIcon={<CrossIcon height="18" />}
          title={
            _loader ? (
              <Skeleton.Input style={{ width: 200 }} active size={"small"} />
            ) : (
              details?.name +
              " " +
              moment(details?.created_at).local().format("DD, MMM YYYY")
            )
          }
          style={{ overflowY: "hidden", paddingBottom: "0px" }}
          width={"90%"}
          footer={false}
          visible={fileviewer}
          maskClosable={false}
          onCancel={() => setFileViewer(false)}
        >
          <Row>
            <Button
              block
              className={"doctor-secondary-button capitalize"}
              target={"_blank"}
              download
              style={{ marginBottom: "20px" }}
              disabled={file?.result !== undefined ? false : true}
              // href={file?.result}
              onClick={() =>
                downloadFile(file?.result)
                  .then(() => {})
                  .catch(() => window.open(file?.result))
              }
              size={"large"}
            >
              Download{" "}
              {details && details?.type && (details?.type).toLowerCase()}
            </Button>

            {/* <Col span={8}></Col> */}

            <Col
              span={22}
              className={"text-center"}
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <div style={{ transform: `rotate(${rotate}deg)` }}>
                {!_loader &&
                  details?.dropdown_value &&
                  details?.dropdown_value?.name === "fail" && (
                    <p>
                      <Text type="danger">
                        QC failed. Please delete and re-upload this document
                      </Text>
                    </p>
                  )}

                {_loader || _loader === undefined ? (
                  <Spin className={"text-center mt200"}></Spin>
                ) : details?.content_type === "image" ? (
                  <Image
                    wrapperClassName={
                      (scale > 1 ? "fileOverflowScrolled" : "") +
                      " fileOverflow"
                    }
                    style={{ transform: `scale(${scale})`, overflow: "scroll" }}
                    src={file?.result}
                    preview={false}
                    width={"100%"}
                  ></Image>
                ) : details?.content_type === "json" ? (
                  <>
                    {/* <FormView prescriptionData={selectedTemplateDataRaw} /> */}
                  </>
                ) : (
                  <>
                    <iframe
                      title="file-view-result"
                      src={file?.result}
                      style={{ border: "none", height: "90vh", width: "100%" }}
                    ></iframe>
                  </>
                )}
              </div>
            </Col>
            <Col span={2} className="text-center">
              <p>
                <small>Zoom</small>
              </p>
              <p>
                <svg
                  width="45"
                  onClick={() => setScale(scale + 0.5)}
                  className={"cursor"}
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.0859375"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M23.4483 24.4199H29.3195V21.9393H23.4483V16.5859H20.877V21.9393H15.0195V24.4199H20.877V29.7859H23.4483V24.4199Z"
                    fill="white"
                  />
                </svg>
              </p>

              <p>
                <svg
                  onClick={() => scale > 1 && setScale(scale - 0.5)}
                  className={"cursor"}
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.722656"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M29.3195 25.0567H23.4483H20.877H15.0195V22.5762H20.877H23.4483H29.3195V25.0567Z"
                    fill="white"
                  />
                </svg>
              </p>

              <p>
                <small>Rotate</small>
              </p>
              <p>
                <svg
                  width="45"
                  onClick={() => setRotate(rotate === 0 ? 270 : rotate - 90)}
                  className={"cursor"}
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.0429688"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M31.1181 28.589C30.9576 28.589 30.8103 28.4972 30.7473 28.3465C30.701 28.2371 29.5443 25.6427 25.2198 25.1289C24.319 25.0195 23.2402 24.9634 21.93 24.9536V28.1873C21.93 28.3367 21.8494 28.4719 21.7169 28.5434C21.5851 28.6121 21.4274 28.6023 21.3047 28.5203L12.9964 22.9277C12.8843 22.8527 12.8184 22.7265 12.8184 22.594C12.8184 22.4601 12.8843 22.3382 12.9964 22.2596L21.3082 16.6663C21.4309 16.5815 21.5872 16.5759 21.7204 16.646C21.8536 16.7175 21.9328 16.8528 21.9328 16.9979V20.0073C23.74 20.2429 31.5184 21.6407 31.5184 28.1908C31.5184 28.3808 31.3845 28.547 31.1966 28.5848C31.1707 28.589 31.1433 28.589 31.1181 28.589Z"
                    fill="white"
                  />
                </svg>
              </p>
              <p>
                <svg
                  width="45"
                  height="45"
                  onClick={() => setRotate(rotate === 270 ? 0 : rotate + 90)}
                  className={"cursor"}
                  viewBox="0 0 45 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.71875"
                    y="0.679688"
                    width="44"
                    height="44"
                    rx="6.6383"
                    fill="#23BCB5"
                  />
                  <path
                    d="M14.3194 29.2257C14.4799 29.2257 14.6272 29.1339 14.6902 28.9832C14.7365 28.8738 15.8932 26.2794 20.2177 25.7656C21.1185 25.6562 22.1973 25.6001 23.5075 25.5903V28.8241C23.5075 28.9734 23.5881 29.1087 23.7206 29.1802C23.8524 29.2489 24.0101 29.239 24.1328 29.157L32.4411 23.5644C32.5532 23.4894 32.6191 23.3632 32.6191 23.2307C32.6191 23.0968 32.5532 22.9749 32.4411 22.8964L24.1293 17.303C24.0066 17.2182 23.8503 17.2126 23.7171 17.2827C23.5839 17.3542 23.5047 17.4895 23.5047 17.6346V20.644C21.6975 20.8796 13.9191 22.2774 13.9191 28.8276C13.9191 29.0175 14.053 29.1837 14.2409 29.2215C14.2668 29.2257 14.2942 29.2257 14.3194 29.2257Z"
                    fill="white"
                  />
                </svg>
              </p>
              {typeof showNext === "function" && showNextBtn ? (
                <>
                  <p>
                    <small>Next Doc</small>
                  </p>
                  <p>
                    <svg
                      width="45"
                      height="44"
                      onClick={() => showNext("PRESCRIPTION", 1)}
                      className={"cursor"}
                      viewBox="0 0 45 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.609375"
                        width="44"
                        height="44"
                        rx="6.6383"
                        fill="#23BCB5"
                      />
                      <path
                        d="M14.6094 16L20.6094 21.3981L14.6094 27"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24.6094 16L30.6094 21.3981L24.6094 27"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </>
              ) : (
                ""
              )}
              {typeof showPrev === "function" && showPrevBtn ? (
                <>
                  <p>
                    <small>Prev Doc</small>
                  </p>
                  <p>
                    <svg
                      width="45"
                      onClick={() => showPrev("PRESCRIPTION", 0)}
                      className={"cursor"}
                      height="44"
                      viewBox="0 0 45 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.71875"
                        width="44"
                        height="44"
                        rx="6.6383"
                        fill="#23BCB5"
                      />
                      <path
                        d="M30.6094 17L24.6094 22.3981L30.6094 28"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.6094 17L14.6094 22.3981L20.6094 28"
                        stroke="white"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </>
              ) : (
                ""
              )}
              {typeof deleteDocuments === "function" && !isDoctor ? (
                <>
                  <p>
                    <small>Delete</small>
                  </p>
                  <p>
                    <svg
                      width="48"
                      height="48"
                      onClick={() => deleteDocuments(id)}
                      className={"cursor"}
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.335938"
                        y="0.671387"
                        width="46.8436"
                        height="46.8436"
                        rx="7.06732"
                        fill="white"
                      />
                      <path
                        d="M17.1719 31.0752C17.1719 32.1752 18.0719 33.0752 19.1719 33.0752H27.1719C28.2719 33.0752 29.1719 32.1752 29.1719 31.0752V19.0752H17.1719V31.0752ZM30.1719 16.0752H26.6719L25.6719 15.0752H20.6719L19.6719 16.0752H16.1719V18.0752H30.1719V16.0752Z"
                        fill="#B2403A"
                      />
                    </svg>
                  </p>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default FileViewLayout;
