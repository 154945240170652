import { Column } from "@ant-design/plots";

const ColumnChartLayout = ({ status }) => {
  const data = status;
  const brandColor = "#23BCB5";
  const config = {
    data,
    xField: "type",
    yField: "value",
    seriesField: "",
    color: () => {
      return brandColor;
    },
    label: {
      content: (originData) => {
        const val = parseFloat(originData?.value);
        if (val < 0.05) {
          return (val * 100).toFixed(0) + "%";
        }
      },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return <Column {...config} />;
};

export default ColumnChartLayout;
