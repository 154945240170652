import {Col, Row} from "antd"

type Props = {
    placeholder: string,
    className:string
    vh?:string
}

function NoDataLayout({ placeholder,className,vh="100vh" }: Props) {
    return (
        <Row style={{height:vh,alignItems:'center',justifyContent:'center',width:'100%'}}>
            <Col span={24} className={`text-center  ${className}`}>
            <img alt="" src={require("../../assets/icons/no result found.png").default}></img>
            <p className={`text-center mt20 font18  max-subHead-color fontWeight600`}>{placeholder} </p>
            <p className={`text-center  ${className}`}></p>
        </Col>
        </Row>
    ); 
}

export default NoDataLayout; 
