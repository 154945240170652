import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchDoctorById,
  updateDocter,
} from "../../../../redux/reducers/doctor.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Doctor } from "../../../../shared/types/doctor.type";
import DocterForm from "./doctor-form.page";

const DoctorEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const docter: Doctor = useSelector((state: RootState) => state.doctor.doctor);

  useEffect(() => {
    if (RouteParams["id"]) {
      dispatch(fetchDoctorById(RouteParams["id"]));
    }
  }, []);
  return (
    <DesktopFormLayout
      title={"Doctor Edit"}
      subtitle={"Edit Doctor information along with Doctor Slots"}
    >
      <DocterForm
        // doctor={docter}
        onSubmit={async (values: Doctor) => {
          try {
            values.id = RouteParams["id"];
            await dispatch(updateDocter(values));
            history.goBack();
          } catch (e) {}
        }}
      />
    </DesktopFormLayout>
  );
};

export default DoctorEdit;
