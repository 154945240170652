import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentSummary,
  paymentRefund
} from "../../../../redux/reducers/pharmacy.slice";
import { RootState } from "../../../../shared/constants";
import TableLayout from "../../../../shared/layouts/table.layout";

type Props = {
  status?: any;
};

function PaymentTable(props: Props) {
  const csvLink = useRef("" as any);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(null as any);
  const [confirmMsg, setConfirmMsg] = useState(null as any);
  const [generateRefundModalVisible, setGenerateRefundModalVisible] =
    useState(false);
  const { paymentSummary, loading, pagination } = useSelector(
    (state: RootState) => state.pharmacy
  );

  const columns = [
    {
      title: "Payment ID",
      render: (key: any) => (
        <Button
        size={"small"}
        onClick={() => navigator.clipboard.writeText(key?.payment_summary?.id)}
      >
        Copy
      </Button>
      ),
      width:'100px'
    },
    {
      title: "Order ID",
      render: (key: any) => (key?.order_id ? key?.order_id : "-"),
      width: "90px",
    },
    {
      title: "Payment Date",
      render: (key: any) =>
        key?.payment_summary?.payment_date
          ? moment(key?.payment_summary?.payment_date).format("DD MMM'YY, hh:mm A")
          : "-",
    },
    {
      title: "Patient Details",
      render: (a: any) => {
        return (
          <>
            <span>
              {a.patient
                ? a?.patient?.pii?.first_name + " " + a?.patient?.pii?.last_name
                : ""}
            </span>
            <br />
            {a?.patient?.pii?.mobile} <br />
            {a?.patient?.uhid}
          </>
        );
      },
      width: "150px",
    },
    {
      title: "Amount",
      render: (key: any) => (key?.total_amount ? key?.total_amount : "-"),
      width: "90px",
    },
    {
      title: "Mode",
      render: (key: any) => (key?.payment_mode ? key?.payment_mode : "-"),
      width: "100px",
    },
    {
      title: "Status",
      render: (key: any) =>
        key?.payment_summary?.payment_status
          ? key?.payment_summary?.payment_status
          : "-",
      width: "90px",
    },
    {
      title: "Service Type",
      render: (key: any) => (key?.order_type ? key?.order_type : "-"),
      width: "110px",
    },
    {
      title: "Transaction Details",
      render: (key: any) => {
        return (
          <>
            <span>
              {key?.payment_summary?.logs[0]?.details?.txnid
                ? `Txn ID: ` + key?.payment_summary?.logs[0]?.details?.txnid
                : ""}
            </span>
            <br />
            {key?.payment_summary?.logs[0]?.gateway_payment_id
              ? `Gateway ID: ` +
                key?.payment_summary?.logs[0]?.gateway_payment_id
              : ""}
          </>
        );
      },
    },
    {
      title: "Transfer MID",
      render: (key: any) => {
        return (
          <>
            <span>{key?.payment_summary?.account_mid}</span>
          </>
        );
      },
    },
    {
      title: "Bill Details",
      render: (a: any) => {
        return (
          <>
            <span>{a?.bill_id ? `Bill ID: ` + a?.bill_id : ""}</span>
            <br />
            {a?.bill_number ? `Bill No: ` + a?.bill_number : ""}
          </>
        );
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (a: any) => {
    //     return a?.payment_summary?.payment_status === "success" ? (
    //       <Button type={"link"} onClick={() => generateRefund(a)}>
    //         Refund
    //       </Button>
    //     ) : (
    //       ""
    //     );
    //   },
    // },
  ];

  const generateRefund = async (a: any) => {
    console.log("eeeee 222", a);
    setConfirmMsg(a);
    setGenerateRefundModalVisible(true);
  };

  const onPageChange = async (e: any) => {
    let pageNumber = e - 1;
    let payload = {
      offset: pageNumber * 10,
      limit: pagination.limit,
      ...filters,
    };
    await dispatch(fetchPaymentSummary(payload));
  };

  useEffect(() => {
    getPaymentSummary();
  }, []);

  useEffect(() => {
    getPaymentSummary();
  }, [filters]);

  const getPaymentSummary = async () => {
    let filter = filters
      ? Object.fromEntries(
          Object.entries(filters).filter(([_, v]) => v != null)
        )
      : {};
    let payload = {
      offset: pagination.offset,
      limit: pagination.limit,
      ...filter,
    };
    await dispatch(fetchPaymentSummary(payload));
  };

  const formElements = {
    onFinish: () => {},
    onFinishFailed: () => {},
    onValuesChange: filterFormSubmit,
    initialValues: {},
    setValues: {
      ...filters,
      dateVal: filters?.order_date ? moment(filters?.order_date) : null,
    },
    formElements: [
      {
        label: "",
        type: "Select",
        name: "order_type",
        colSpan: 5,
        allowClear: true,
        placeholder: "Service Type",
        options: [
          { name: "Pharmacy", value: "pharmacy" },
          { name: "Diagnostics", value: "diagnostics" },
          { name: "Appointment", value: "appointment" },
          { name: "Athome Services", value: "at_home_services" },
          { name: "Intent", value: "intent" },
        ],
      },
      {
        label: "",
        type: "DatePicker",
        name: "dateVal",
        colSpan: 5,
      },
      {
        label: "",
        type: "Select",
        name: "search_by",
        colSpan: 5,
        allowClear: true,
        placeholder: "Select Option",
        options: [
          { name: "Order ID", value: "order_id" },
          { name: "Patient ID", value: "uhid" },
          // {name: 'Request ID', value: 'external_id'},
          { name: "Mobile", value: "mobile" },
          { name: "Bill No", value: "bill_number" },
        ],
      },
      {
        label: "",
        type: "Input",
        name: "search_value",
        colSpan: 8,
        placeholder: "Search by Option",
        prefix: <SearchOutlined />,
      },
    ],
  };

  function filterFormSubmit(changed: any, all: any) {
    let filtersObj = {} as any;
    let search_by_key;
    for (let key of Object.keys(all)) {
      if (all[key] == "" || all[key] == null || all[key] == undefined) {
      } else {
        if (key === "dateVal") {
          filtersObj.order_date = moment(all.dateVal).format("YYYY-MM-DD");
        } else if (key === "search_value") {
          filtersObj[all.search_by] = all.search_value;
        } else if (key === "search_by") {
          search_by_key = all.search_by;
          delete filtersObj.search_by;
        } else {
          filtersObj[key] = all[key];
        }
      }
      delete filtersObj.search_by;
      delete filtersObj.search_value;
    }
    // console.log('eeeee 33333', filtersObj)
    setFilters(filtersObj);
  }

  const downloadFilterData = (e: any) => {
    fetchSummaryOrdersByFilters(e);
    setTimeout(() => {
      csvLink?.current.link.click();
    }, 1000);
  };

  const fetchSummaryOrdersByFilters = async (e: any) => {
    let payload = {} as any;
    payload = {
      offset: pagination.offset,
      limit: pagination.limit,
    };
    if (e?.order_date) payload["order_date"] = e?.order_date;
    if (e?.order_type) payload["order_type"] = e?.order_type;
    if (e?.order_id) payload["order_id"] = e?.order_id;
    if (e?.uhid) payload["uhid"] = e?.uhid;
    if (e?.external_id) payload["external_id"] = e?.external_id;
    if (e?.mobile) payload["mobile"] = e?.mobile;
    if (e?.bill_no) payload["bill_no"] = e?.bill_no;
    await dispatch(fetchPaymentSummary(payload));
  };

  const closeGenerateRefund = () => {
    setGenerateRefundModalVisible(false);
  };

  const handleGenerateRefund = async () => {
    dispatch(paymentRefund(confirmMsg?.id)).then((res: any) => {
      // console.log('eeee 33333', res)
      if (res?.payload?.data?.statusCode == 200) {
        getPaymentSummary();
      }
      setGenerateRefundModalVisible(false);
    });
  };

  return (
    <>
      <CSVLink
        data={paymentSummary}
        className="hidden"
        // onClick={() => downloadFilterData}
        filename={"payment_summary.csv"}
        ref={csvLink}
        target="_blank"
      ></CSVLink>
      <div>
        <Row className="padding10">
          <Col span={24}>
            <TableLayout
              loading={loading}
              dataSource={paymentSummary}
              columns={columns}
              onChange={(e) => onPageChange(e)}
              downloadFilterData={downloadFilterData}
              type="paymentSummaryFilter"
              formData={formElements}
              total={pagination.total}
              pagination={{ position: ["none", "none"] }}
              maxWidth={1500}
            />
          </Col>
        </Row>
        <Modal
          visible={generateRefundModalVisible}
          onOk={handleGenerateRefund}
          onCancel={closeGenerateRefund}
        >
          <Row>
            <Col span={24} className="text-center">
              <h3 className="primary-color-text mt50 bold">
                Generate Refund for Order ID {confirmMsg?.order_id}.{" "}
              </h3>
              <p className="sub-text mt10">
                Are you sure you want to refund of Rs.{" "}
                <b>{confirmMsg?.total_amount}</b> for patient{" "}
                <b>
                  {confirmMsg?.patient?.pii?.first_name +
                    " " +
                    confirmMsg?.patient?.pii?.last_name}
                </b>
                .{" "}
              </p>
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  );
}

export default PaymentTable;
