import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNewTeam } from "../../../../redux/reducers/team.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Team } from "../../../../shared/types/team.type";
import TeamForm from "./team-form.page";

const TeamCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <DesktopFormLayout
      title={"Team Create"}
      subtitle={"Create new team for .."}
    >
      <TeamForm
        onSubmit={async (values: Team) => {
          await dispatch(addNewTeam(values));
          history.goBack();
        }}
      />
    </DesktopFormLayout>
  );
};

export default TeamCreate;
