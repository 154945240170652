import { Col, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchPharmacyAuditTrail } from "../../../../redux/reducers/pharmacy.slice";
const { Text } = Typography;

const OrderJourney: React.FunctionComponent<any> = (Props) => {
  const [patientData, setpatientData] = useState();
  const dispatch = useDispatch();
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   className: "capitalize",
    // },
    {
      title: "Status",
      dataIndex: "status_display_text",
      className: "capitalize",
    },
    // {
    //   title: "User Email",
    //   dataIndex: "user_email",
    //   className: "capitalize",
    // },
    {
      title: "Source",
      dataIndex: "source",
      className: "capitalize",
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      render: (a: any) => {
        return <>{moment(a).format("DD MMM'YY, h:m A")}</>;
      },
    },
  ];

  useEffect(() => {
    let data = {
        'order_id': Props?.order_id,
        'request_id': Props?.request_id
    }
    dispatch(fetchPharmacyAuditTrail(data)).then(
      (val: any) => {
        setpatientData(val?.payload?.data?.data?.rows);
      }
    );
  }, []);

//   useEffect(() => {
//     let data = {
//         'order_id': Props?.order_id,
//         'request_id': Props?.request_id
//     }
//     if (Props?.isReset !== "reset" && Props?.isReset !== undefined) {
//       dispatch(fetchPharmacyAuditTrail(data)).then(
//         (val: any) => {
//           setpatientData(val?.payload?.data?.data);
//         }
//       );
//     }
//   }, [Props.isReset]);

  return (
    <Col span={24}>
      <Table
        loading={false}
        locale={{
          emptyText: (
            <>
              <Space direction={"vertical"} size={10}>
                <Text className="max-subHead-color">No Records</Text>
              </Space>
            </>
          ),
        }}
        pagination={false}
        scroll={{ x: true }}
        dataSource={patientData}
        columns={columns}
      />
    </Col>
  );
};

export default OrderJourney;
