import {Button, Col, Divider, notification, Row, Select, Tabs, Tree} from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {fetchPermissions} from "../../../../redux/reducers/permission.slice";
import {
    fetchAccessGroupById,
    fetchRoleById, fetchRoles,
    updateRoleById
} from "../../../../redux/reducers/role.slice";
import {RootState} from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import {Role} from "../../../../shared/types/role.type";
import RoleForm from "./role-form.page";
import {fetchUserById, fetchUsers} from "../../../../redux/reducers/user.slice";
import {User} from "../../../../shared/types/user.type";
import {getFullName} from "../../../../shared/Utils/utilities";
// @ts-ignore
import ReactJsonViewCompare from 'react-json-view-compare';

const RoleEdit: React.FunctionComponent<any> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const RouteParams: any = useParams();
    const role: Role = useSelector((state: RootState) => state.role.role);
    const roles: Role[] = useSelector((state: RootState) => state.role.roles);
    const [selectedPermissions, setSelectedPermissions] = useState(
        [] as string[]
    );
    const [enableEdit, setEnableEdit] = useState(true);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedApplication, setSelectedApplication] = useState('');
    const [force, setForce] = useState(false);
    const {users,} = useSelector((state: RootState) => state.user);
    const {currentApplication, applications} = useSelector(
        (state: RootState) => state.auth
    );
    const [copiedActions, setCopiedActions] = useState(null as any);
    const [activeTab, setActiveTab] = useState('1' as any);
    const {
        permission: {permissions, loading: loadingPermissions},
    } = useSelector((state: RootState) => state);

    const copyRole = async () => {
        setCopiedActions(null);
        const fetchedUser = await dispatch(fetchUserById({id: selectedUser, withUserAccess: true}));
        if (fetchedUser?.payload?.data?.data) {
            if (!fetchedUser?.payload?.data?.data.user_accesses) {
                notification.error({message: "No User access found for this user"});
                return;
            }
            if (!fetchedUser?.payload?.data?.data.user_accesses.find((u: any) => u.application_id === selectedApplication)) {
                notification.error({message: "User has no access assigned to this application"});
                return;
            }
            const accessGroupId = fetchedUser?.payload?.data?.data.user_accesses.find((u: any) => u.application_id === selectedApplication)?.access_group_id;
            const accessGroup = await dispatch(fetchAccessGroupById({id: accessGroupId, withDetails: true}));
            if (!accessGroup?.payload?.data?.data) {
                notification.error({message: "No Access Group found for this ID"});
                return
            }
            if (!accessGroup?.payload?.data?.data?.scope_values?.actions) {
                notification.error({message: "No Role assigned to this access group"});
                return
            }
            setCopiedActions(accessGroup?.payload?.data?.data?.scope_values?.actions);
            navigator.clipboard.writeText(accessGroup?.payload?.data?.data?.scope_values?.actions);
            notification.success({message: "Item Copied"})
        } else {
            notification.error({message: "Unable to fetch the user"});
            return;
        }
    }
    const copyRoleFromAnotherRole = async () => {
        setCopiedActions(null);
        const _r = roles.find((r) => r.id === selectedRole);
        if (_r) {
            setCopiedActions(_r?.actions);
            navigator.clipboard.writeText(_r?.actions);
            notification.success({message: "Item Copied"})
        }
    }
    useEffect(() => {
        if (RouteParams["id"]) {
            dispatch(fetchRoleById(RouteParams["id"]));
        }
        dispatch(fetchUsers());
        dispatch(fetchRoles());
        dispatch(fetchPermissions({}));
    }, []);
    useEffect(() => {
        if (role?.actions) {
            setSelectedPermissions(role?.actions || []);
        }
    }, [role]);

    return (
        <DesktopFormLayout title={"Roles Edit"} subtitle={"Edit role for .."}>
            <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={(e) => {
                setCopiedActions(null)
                setActiveTab(e)
            }}>
                <Tabs.TabPane tab="Role" key="1">
                    <Row gutter={20}>
                        <Col sm={24} md={12}>
                            <RoleForm
                                role={role}
                                onSubmit={async (values: Role) => {
                                    const response = await dispatch(
                                        updateRoleById({
                                            id: role.id,
                                            data: {
                                                force,
                                                ...values,
                                                actions: selectedPermissions || [],
                                            },
                                        })
                                    );
                                    if (!response?.error) {
                                        history.goBack();
                                        return;
                                    }
                                    setForce(true);
                                    // history.goBack();
                                }}
                            />

                            <Divider/>
                            <TextArea
                                placeholder={"Super input"}
                                rows={10}
                                disabled={enableEdit}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setSelectedPermissions(e.target.value.split(","));
                                    }
                                }}
                            ></TextArea>
                            <Button onClick={() => setEnableEdit(!enableEdit)}>
                                Toggle Edit Super Input
                            </Button>
                        </Col>
                        {permissions && permissions.global && (
                            <Col sm={24} md={12}>
                                <Button
                                    onClick={() => {
                                        notification.success({message: "Permissions copied"});
                                        navigator.clipboard.writeText(
                                            selectedPermissions.join(",")
                                        );
                                    }}
                                >
                                    Copy Selected Permissions
                                </Button>
                                <Title level={4}>Module Permissions/Actions</Title>
                                <Tree
                                    checkable
                                    onCheck={(e: any) => setSelectedPermissions(e)}
                                    checkedKeys={selectedPermissions}
                                    onSelect={(e) => console.log(e)}
                                    selectedKeys={selectedPermissions}
                                    treeData={Object.keys(permissions.moduleSpecific).map(
                                        (key) => {
                                            return {
                                                title: key,
                                                key: permissions.moduleSpecific[key].name,
                                                children: [
                                                    ...Object.keys(
                                                        permissions.moduleSpecific[key].actions
                                                    ).map((k) => {
                                                        return {
                                                            title: k,
                                                            key: `${permissions.moduleSpecific[key].name}.actions.${permissions.moduleSpecific[key].actions[k]}`,
                                                        };
                                                    }),
                                                    ...Object.keys(permissions.global).map((k) => {
                                                        return {
                                                            title: k,
                                                            key: `${permissions.moduleSpecific[key].name}.actions.${permissions.global[k]}`,
                                                        };
                                                    }),
                                                ],
                                            };
                                        }
                                    )}
                                />
                            </Col>
                        )}
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Copy Role from a User" key="2">
                    <Row gutter={24}>
                        <Col>
                            <Row>
                                <Col>
                                    <Title level={5}>Select User & Application to Copy Roles</Title>
                                </Col>

                            </Row>
                            <Row gutter={20}>
                                <Col>
                                    <Select style={{width: '300px'}} onSelect={setSelectedUser}>
                                        {users.map((user: User) => {
                                            return <Select.Option value={user.id}>{getFullName(user)}</Select.Option>;
                                        })}
                                    </Select>
                                </Col>
                                <Col>
                                    <Select style={{width: '300px'}} onSelect={setSelectedApplication}>
                                        {applications.map((application: any) => {
                                            return <Select.Option
                                                value={application.id}>{getFullName(application)}</Select.Option>;
                                        })}
                                    </Select>
                                </Col>
                            </Row>
                            <br/>
                            <Row gutter={[20, 20]}>
                                <Col>
                                    <Button onClick={copyRole} disabled={!selectedUser || !selectedApplication}>
                                        Copy Role
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        disabled={!copiedActions}
                                        onClick={() => {
                                        setSelectedPermissions(copiedActions);
                                        notification.success({message: "Permissions Pasted, Click save to submit"})
                                        setActiveTab('1')
                                    }}>
                                        Paste Function
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider/>

                    {
                        copiedActions && selectedPermissions &&
                        <Row gutter={20}>
                            <Col>
                                <Title>Diff</Title>
                                <ReactJsonViewCompare oldData={JSON.parse(JSON.stringify(selectedPermissions)).sort()}
                                                      newData={JSON.parse(JSON.stringify(copiedActions)).sort()}/>
                            </Col>
                            <Col>
                                <Title>Current Role</Title>
                                <Tree
                                    checkable
                                    checkedKeys={selectedPermissions}
                                    onSelect={(e) => console.log(e)}
                                    selectedKeys={selectedPermissions}
                                    treeData={Object.keys(permissions.moduleSpecific).map(
                                        (key) => {
                                            return {
                                                title: key,
                                                key: permissions.moduleSpecific[key].name,
                                                children: [
                                                    ...Object.keys(
                                                        permissions.moduleSpecific[key].actions
                                                    ).map((k) => {
                                                        return {
                                                            title: k,
                                                            key: `${permissions.moduleSpecific[key].name}.actions.${permissions.moduleSpecific[key].actions[k]}`,
                                                        };
                                                    }),
                                                    ...Object.keys(permissions.global).map((k) => {
                                                        return {
                                                            title: k,
                                                            key: `${permissions.moduleSpecific[key].name}.actions.${permissions.global[k]}`,
                                                        };
                                                    }),
                                                ],
                                            };
                                        }
                                    )}
                                />
                            </Col>
                            <Col>
                                <Title>Selected Role</Title>
                                <Tree
                                    checkable
                                    checkedKeys={copiedActions}
                                    onSelect={(e) => console.log(e)}
                                    selectedKeys={copiedActions}
                                    treeData={Object.keys(permissions.moduleSpecific).map(
                                        (key) => {
                                            return {
                                                title: key,
                                                key: permissions.moduleSpecific[key].name,
                                                children: [
                                                    ...Object.keys(
                                                        permissions.moduleSpecific[key].actions
                                                    ).map((k) => {
                                                        return {
                                                            title: k,
                                                            key: `${permissions.moduleSpecific[key].name}.actions.${permissions.moduleSpecific[key].actions[k]}`,
                                                        };
                                                    }),
                                                    ...Object.keys(permissions.global).map((k) => {
                                                        return {
                                                            title: k,
                                                            key: `${permissions.moduleSpecific[key].name}.actions.${permissions.global[k]}`,
                                                        };
                                                    }),
                                                ],
                                            };
                                        }
                                    )}
                                />
                            </Col>
                        </Row>
                    }
                </Tabs.TabPane>
                {
                    roles && <Tabs.TabPane tab="Copy Role from another role" key="3">
                        <Row gutter={24}>
                            <Col>
                                <Row>
                                    <Col>
                                        <Title level={5}>Select Role to Copy</Title>
                                    </Col>

                                </Row>
                                <Row gutter={20}>
                                    <Col>
                                        <Select style={{width: '300px'}} onSelect={setSelectedRole}>
                                            {roles.map((user: any) => {
                                                return <Select.Option value={user.id}>{getFullName(user)}</Select.Option>;
                                            })}
                                        </Select>
                                    </Col>
                                </Row>
                                <br/>
                                <Row gutter={[20, 20]}>
                                    <Col>
                                        <Button onClick={copyRoleFromAnotherRole} disabled={!selectedRole}>
                                            Copy Role
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            disabled={!copiedActions}
                                            onClick={() => {
                                            setSelectedPermissions(copiedActions);
                                            notification.success({message: "Permissions Pasted, Click save to submit"})
                                            setActiveTab('1')
                                        }}>
                                            Paste Function
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider/>

                        {
                            copiedActions && selectedPermissions &&
                            <Row gutter={20}>
                                <Col>
                                    <Title>Diff</Title>
                                    <ReactJsonViewCompare oldData={JSON.parse(JSON.stringify(selectedPermissions)).sort()}
                                                          newData={JSON.parse(JSON.stringify(copiedActions)).sort()}/>
                                </Col>
                                <Col>
                                    <Title>Current Role</Title>
                                    <Tree
                                        checkable
                                        checkedKeys={selectedPermissions}
                                        onSelect={(e) => console.log(e)}
                                        selectedKeys={selectedPermissions}
                                        treeData={Object.keys(permissions.moduleSpecific).map(
                                            (key) => {
                                                return {
                                                    title: key,
                                                    key: permissions.moduleSpecific[key].name,
                                                    children: [
                                                        ...Object.keys(
                                                            permissions.moduleSpecific[key].actions
                                                        ).map((k) => {
                                                            return {
                                                                title: k,
                                                                key: `${permissions.moduleSpecific[key].name}.actions.${permissions.moduleSpecific[key].actions[k]}`,
                                                            };
                                                        }),
                                                        ...Object.keys(permissions.global).map((k) => {
                                                            return {
                                                                title: k,
                                                                key: `${permissions.moduleSpecific[key].name}.actions.${permissions.global[k]}`,
                                                            };
                                                        }),
                                                    ],
                                                };
                                            }
                                        )}
                                    />
                                </Col>
                                <Col>
                                    <Title>Selected Role</Title>
                                    <Tree
                                        checkable
                                        checkedKeys={copiedActions}
                                        onSelect={(e) => console.log(e)}
                                        selectedKeys={copiedActions}
                                        treeData={Object.keys(permissions.moduleSpecific).map(
                                            (key) => {
                                                return {
                                                    title: key,
                                                    key: permissions.moduleSpecific[key].name,
                                                    children: [
                                                        ...Object.keys(
                                                            permissions.moduleSpecific[key].actions
                                                        ).map((k) => {
                                                            return {
                                                                title: k,
                                                                key: `${permissions.moduleSpecific[key].name}.actions.${permissions.moduleSpecific[key].actions[k]}`,
                                                            };
                                                        }),
                                                        ...Object.keys(permissions.global).map((k) => {
                                                            return {
                                                                title: k,
                                                                key: `${permissions.moduleSpecific[key].name}.actions.${permissions.global[k]}`,
                                                            };
                                                        }),
                                                    ],
                                                };
                                            }
                                        )}
                                    />
                                </Col>
                            </Row>
                        }
                    </Tabs.TabPane>
                }
            </Tabs>
        </DesktopFormLayout>
    );
};

export default RoleEdit;
