import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DELAY_SLOT_BY_ID,
  INSTANT_APPOINTMENT_VC,
  PREVOIUS_SLOTS,
  SLOT_BY_ID,
  SLOT_PROCEDURE_BY_ID,
  UPDATE_SLOT_BY_ID,
} from "../../shared/routes/route.constants";
import { SlotConfigration, SlotProcedure } from "../../shared/types/slot.type";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "slot",
};
export const fetchSlotById: any = createAsyncThunk(
  `${config.name}/fetchSlotById`,
  async (id: string) => {
    return await Client.get(SLOT_BY_ID(id), {});
  }
);

export const fetchSlotProcedureById: any = createAsyncThunk(
  `${config.name}/fetchSlotProcedureById`,
  async (id: string) => {
    return await Client.get(SLOT_PROCEDURE_BY_ID(id), {});
  }
);

export const fetchPrevoiusSlots: any = createAsyncThunk(
  `${config.name}/fetchPrevoiusSlots`,
  async (id: string) => {
    return await Client.get(PREVOIUS_SLOTS(id), {});
  }
);

export const updateDoctorSlotConfigById: any = createAsyncThunk(
  `${config.name}/updateDoctorSlotConfigById`,
  async (data: string) => {
    return await Client.post(UPDATE_SLOT_BY_ID, data);
  }
);

export const updateDoctorSlotDelay: any = createAsyncThunk(
  `${config.name}/updateDoctorSlotDelay`,
  async (data: string) => {
    return await Client.post(DELAY_SLOT_BY_ID, data);
  }
);

// BOSS API
export const fetchAppointmentInstantVC: any = createAsyncThunk(
  `${config.name}/fetchAppointmentInstantVC`,
  async (id: any) => {
    return await Client.get(INSTANT_APPOINTMENT_VC, {});
  }
);

export const slotSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingDoctor: false,
    slot: null as SlotConfigration | null,
    error: "" as string,
    procedure: null as SlotProcedure | null,
    old_slots: [],
    instant_vc: [] as any,
  },
  reducers: {
    reset: (state) => {
      state.slot = null;
      state.loading = false;
      state.error = "";
      state.procedure = null;
      state.old_slots = [];
    },
  },
  extraReducers(builder) {
    builder
      // Doctor  Slot  List
      .addCase(fetchSlotById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchSlotById.fulfilled, (state, action) => {
        state.loading = false;
        state.slot = action.payload?.data?.data;
      })
      .addCase(fetchSlotById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchPrevoiusSlots.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPrevoiusSlots.fulfilled, (state, action) => {
        state.loading = false;
        state.old_slots = action.payload?.data?.data;
      })
      .addCase(fetchPrevoiusSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Procedure List

      .addCase(fetchSlotProcedureById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchSlotProcedureById.fulfilled, (state, action) => {
        state.loading = false;
        state.procedure = action.payload?.data?.data[0];
      })
      .addCase(fetchSlotProcedureById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateDoctorSlotConfigById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateDoctorSlotConfigById.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload.data?.data !== undefined) {
          state.procedure = action?.payload?.data?.data[0];
        }
      })
      .addCase(updateDoctorSlotConfigById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchAppointmentInstantVC.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAppointmentInstantVC.fulfilled, (state, action) => {
        state.loading = false;
        state.instant_vc = action.payload?.data?.data;
      })
      .addCase(fetchAppointmentInstantVC.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { reset } = slotSlice.actions;

export default slotSlice.reducer;
