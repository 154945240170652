import { Tabs } from "antd";
const { TabPane } = Tabs;

type Props = {
  AllTabs: Array<any>;
  onChange?: (values: string) => void;
  active?: any;
};

function TabsLayout({ AllTabs, onChange, active }: Props) {
  return (
    <div>
      <Tabs activeKey={active || "1"} onChange={onChange}>
        {AllTabs.map((tab: any, index: number) => {
          return (
            <TabPane
              className={"nopadding-tab"}
              tab={tab.title}
              key={index + 1}
            >
              {tab.component}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}

export default TabsLayout;
