const PrescriptionUploadIcon =({height="38" , width="38"}) =>(
    <>
        
        <svg width={width} height={height} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.1" x="0.788574" y="0.400391" width="36.8303" height="36.8303" rx="18.4151" fill="#163B6D"/>
        <path d="M22.7025 12.1855H24.3598C25.2752 12.1855 26.0172 12.9276 26.0172 13.8429V25.4444C26.0172 26.3598 25.2752 27.1018 24.3598 27.1018H14.4157C13.5003 27.1018 12.7583 26.3598 12.7583 25.4444V13.8429C12.7583 12.9276 13.5003 12.1855 14.4157 12.1855H16.073" stroke="#163B6D" stroke-width="1.53459" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="16.0732" y="10.5293" width="6.62945" height="3.31472" rx="1.53459" stroke="#163B6D" stroke-width="1.53459" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    </>
); 

export default PrescriptionUploadIcon;
