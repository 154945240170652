import React from 'react';


import ID from '../../Utils/UUID';
// import Util from '../../Utils/Util';
import Column from './Column';
import ChildRow from './ChildRow';

import { Tooltip, Row, Col } from "antd";
import {
    EditOutlined,
    PlusCircleOutlined,
    CopyOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined
} from '@ant-design/icons';


const NameOptions = ['name', 'value', 'drug', 'drugType', 'frequency', 'secondValue',
    'dosage', 'route', 'childName', 'date', 'duration', 'comment', 'cycle', 'molecule', 'brand', 'unit',
    'inference', "enddate"];
class RowComp extends React.Component {
    constructor(props) {
        super(props);

        let colIdArray = [], showHideArray = [], nameSet = new Set();
        if (this.props.row.type === 'row') {
            this.props.row.columns.forEach(col => {
                if (col.colId && col.class !== 'displayparent') colIdArray.push(col.colId);
            });

            this.props.row.columns.forEach(col => {
                nameSet.add(col.name);
            });
        }
        nameSet = [...nameSet];
        if (this.props.row.children && this.props.row.children.length) {
            this.props.row.children.forEach((child, childIdx) => {
                if (child.columns && child.columns.length) {
                    child.columns.forEach((col, colIdx) => {
                        if (col.colId && col.class === 'displayparent') showHideArray.push({ colIdValue: col.colId, colIdKey: col.colId, values: col.uiData, type: col['type'], childIndex: childIdx, colIndex: colIdx });
                    })
                }
            });
        }

        this.state = {
            expanded: this.props.expanded ? true : false,
            isModalOpen: false,
            requiredError: false,
            currentChildRowId: '',
            showHideParentArray: showHideArray,
            availableNameArr: NameOptions.filter(o => (nameSet.indexOf(o) === -1 || o === "childName")),
            colIdArray: colIdArray // used for creating formulas
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    onDragStart = (e, index) => {
        this.draggedItem = index;
        let dragImg = document.createElement('div');
        dragImg.className = 'childrow-ghost';
        dragImg.innerText = `Subrow #${index + 1}`;
        document.body.appendChild(dragImg);
        dragImg.setAttribute('style', 'position: absolute; left: 0px; top: 0px; z-index: -1');
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(dragImg, 20, 20);
    };

    onDragOver = index => {
        this.draggedOverItem = index;
    };

    onDragEnd = () => {
        // if the item is dragged over itself, ignore
        if (this.draggedItem === this.draggedOverItem) {
            return;
        }

        // filter out the currently dragged item
        let children = this.props.row.children.filter((item, indx) => indx !== this.draggedItem);

        // add the dragged item after the dragged over item
        children.splice(this.draggedOverItem, 0, this.props.row.children[this.draggedItem]);
        this.props.onRowMetaEmit({ rowId: this.props.row['rowId'], name: 'children', value: children });
        this.draggedItem = this.draggedOverItem = null;
    };

    onRowAddition = (e) => {
        let meta = e.target ? {} : e; // meta = meta in case of childRow returning to parent row and meta={childRowId: 'xyz'}
        this.props.onRowAddition(this.prepareAddDelMetaData(meta));
    }

    onRowDeletion = (e) => {
        let meta = e.target ? {} : e;
        if (meta.childRowId) {
            this.setState({ currentChildRowId: meta.childRowId }, () => this.props.onRowDeletion(this.prepareAddDelMetaData(meta)));
        } else {
            this.props.onRowDeletion(this.prepareAddDelMetaData(meta));
        }
    }

    prepareAddDelMetaData(meta) {
        if (this.props.isChildRow) {
            meta = {
                ...meta,
                childRowId: this.props.row['rowId']
            };
        } else {
            meta = {
                ...meta,
                rowId: this.props.row['rowId']
            }
        }
        return meta;
    }


    // now only being used for cloning here
    onRowMetaChange = (e) => {
        let { name, value, childRowId } = e.target ? e.target : e;
        if (name === 'source' || name === 'displayconditionValues') {
            if (name === 'displayconditionValues' && value && value.split(',').length) this.emitAppropriateMetaToParent({ name: 'class', value: 'deferRendering hide', childRowId });
            if (name === 'displayconditionValues' && (!value || !value.trim())) this.emitAppropriateMetaToParent({ name: 'class', value: '', childRowId });

            value = this.getShowHideConfig(name === 'source' ? name : 'value', value === 'none' ? '' : value);
            name = 'config';
        }

        this.emitAppropriateMetaToParent({ name, value, childRowId });
    }

    emitAppropriateMetaToParent(e) {
        let { name, value, childRowId } = e;
        if (childRowId) this.setState({ currentChildRowId: childRowId });
        let meta = {};
        if (this.props.isChildRow) {
            // change was made in child row properties and this is child row
            meta = { ...meta, childRowId: this.props.row['rowId'], name, value };
        } else if (childRowId) {
            // change was made in child row properties and this is parent row
            let children = this.props.row['children'];
            children.forEach((childRow, idx) => {
                if (childRowId === childRow['rowId']) {
                    children[idx] = {
                        ...childRow,
                        [name]: value
                    }
                    return;
                }
            });
            meta = { rowId: this.props.row['rowId'], name: 'children', value: children };
        } else {
            // change was made in parent row properties
            meta = { ...meta, rowId: this.props.row['rowId'], name, value };
        }
        this.props.onRowMetaEmit(meta);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            let { colIdArray, showHideParentArray, availableNameArr } = this.state;
            if (this.props.row && this.props.row.columns) {
                colIdArray = [];
                this.props.row.columns.forEach(col => {
                    if (col.colId && col.class !== 'displayparent') colIdArray.push(col.colId);
                });

                let nameSet = new Set();
                this.props.row.columns.forEach(col => {
                    nameSet.add(col.name);
                });
                nameSet = [...nameSet];
                availableNameArr = NameOptions.filter(o => nameSet.indexOf(o) === -1 || o === "childName");
            }

            if (this.props.row && this.props.row.children && this.props.row.children.length) {
                showHideParentArray = [];
                this.props.row.children.forEach((child, childIdx) => {
                    if (child.columns && child.columns.length) {
                        child.columns.forEach((col, colIdx) => {
                            if (col.colId && col.class === 'displayparent') showHideParentArray.push({ colIdValue: col.colId, colIdKey: col.colId, values: col.uiData, type: col['type'], childIndex: childIdx, colIndex: colIdx });
                        })
                    }
                });
            }
            this.setState({ colIdArray, showHideParentArray, availableNameArr });
        }
    }

    onColAddition = (colIdx) => {
        let { columns } = this.props.row;
        columns.splice(colIdx + 1, 0, {});
        this.emitAppropriateMetaToParent({ name: 'columns', value: columns });
    };

    onColDeletion = (colIdx) => {
        let { columns } = this.props.row;
        columns.splice(colIdx, 1);
        this.emitAppropriateMetaToParent({ name: 'columns', value: columns });
    };

    toggleExpandRow = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    onCloneRowClick = () => {
        this.props.onCloneRow(this.props.serialNo - 1, JSON.parse(JSON.stringify(this.props.row)));
    }

    onEditRowClick = () => {
        let { isChildRow, serialNo, row } = this.props;
        if (this.props.isHoverView) this.props.hideView(true);
        this.props.onEditRowClick({ row: JSON.parse(JSON.stringify(row)), [isChildRow ? 'childIndex' : 'rowIndex']: serialNo - 1 });
        this.setState({ expanded: true });
    }
    onEditCloseClick = () => {
        this.props.hideView(!this.state.expanded);
        this.setState({ expanded: !this.state.expanded });
    }

    onSubRowEditClick = (e) => {
        this.props.onSubRowEditClick({ ...e, rowIndex: this.props.serialNo - 1, showHideParentArray: this.state.showHideParentArray });
        this.setState({ currentChildRowId: e.row['rowId'] });
    }

    onEditColumnClick = (e) => {
        this.props.onEditColumnClick({
            ...e, 'rowIndex': this.props.serialNo - 1, colIdArray: this.state.colIdArray,
            availableNameArr: e && e.column && e.column.name ? this.state.availableNameArr.concat([e.column.name]) : this.state.availableNameArr
        });
    }

    onSubrowEditColumnClick = (e) => {
        this.props.onEditColumnClick({ ...e, rowIndex: this.props.serialNo - 1 });
        this.setState({ currentChildRowId: this.props.row.children[e.childIndex]['rowId'] });
    }

    onChildRowClone = (idx, childRow) => {
        let { children } = this.props.row;
        childRow['rowId'] = ID.uuid();
        children.splice(idx + 1, 0, childRow);
        this.props.onRowMetaEmit({ rowId: this.props.row['rowId'], name: 'children', value: children });
    }

    getHideShowIcon = (expanded) => {
        //console.log(expanded)
        return expanded ? (<Tooltip
            placement="right"
            title="Preview"
            className="tooltip from-builder-tooltip"
        >
            <EyeOutlined />
        </Tooltip>) : (
            <Tooltip
                placement="right"
                title="Columns"
                className="tooltip from-builder-tooltip"
            >
                <EyeInvisibleOutlined />
            </Tooltip>
        )
    }
    render() {
        // can be row | group | childRow | subSection
        let { row, serialNo, showDelete, isHoverView, showType } = this.props;
        const { expanded } = this.state;
        return (<>
            <div className="border-bottom row-form">
                <h6 className="drag"><i className="mdi mdi-drag-vertical"></i>{' '}{row['name'] ? row['name'] : row['type'] === 'subSection' ? `Sub Section ${serialNo}` : `Row ${serialNo}`} :: {row.type === 'row' || row.type === 'compactRow' ? `Columns: ${row.columns.length}` : `${row.type} | Subrows: ${row.children && row.children.length}`}</h6>
                <div className="d-inline float-right border-left">
                    {this.state.requiredError && <div className="text-danger error-text mr-2">Please fill all Required fields marked with *</div>}
                    {isHoverView && <a className="actions" onClick={this.onEditCloseClick.bind(this)} data-tip data-for='preview'>{this.getHideShowIcon(expanded)}</a>}
                    {showType !== 'xyTable' && <>
                        <a className="actions" onClick={this.onEditRowClick.bind(this)} data-tip data-for='editRow'>
                            <Tooltip
                                placement="right"
                                title="Edit Section"
                                className="tooltip from-builder-tooltip"
                            >
                                <EditOutlined />
                            </Tooltip>
                        </a>
                        <a className="actions" onClick={this.onCloneRowClick.bind(this)} data-tip data-for='cloneRow'>
                            <Tooltip
                                placement="right"
                                title="Clone Section"
                                className="tooltip from-builder-tooltip"
                            >
                                <CopyOutlined />
                            </Tooltip>
                        </a>
                        <a className="actions" onClick={this.onRowAddition.bind(this)} data-tip data-for="addRow">
                            <Tooltip
                                placement="right"
                                title="Add Section"
                                className="tooltip from-builder-tooltip"
                            >
                                <PlusCircleOutlined />
                            </Tooltip>
                        </a>
                        {showDelete && <a className="actions" onClick={this.onRowDeletion.bind(this)} data-tip data-for="deleteRow">
                            <Tooltip
                                placement="right"
                                title="Delete Section"
                                className="tooltip from-builder-tooltip"
                            >
                                <DeleteOutlined />
                            </Tooltip>
                        </a>}
                    </>}
                    {/* <a className="actions" onClick={this.toggleExpandRow.bind(this)}><i className={expanded ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}></i></a> */}
                </div>

            </div>
            {expanded && <>
                {(this.props.row['type'] === 'group' || this.props.row['type'] === 'subSection') && <>
                    <Row style={{ paddingLeft: '20px' }}>
                        <Col span={4}>Subrow #</Col>
                        <Col span={20}>
                            <Row >
                                <Col span={14}>Tool</Col>
                                <Col span={4}>Name</Col>
                                <Col span={6}>Actions</Col>
                            </Row>
                        </Col>
                    </Row>
                    <ul>
                        {this.props.row['children'].map((childRow, idx) => <li key={idx} onDragOver={() => this.onDragOver(idx)}>
                            <div className="child-row-form" draggable onDragStart={e => this.onDragStart(e, idx)} onDragEnd={this.onDragEnd}>
                                <ChildRow key={childRow['rowId']}
                                    row={childRow}
                                    sectionOffset={this.props.sectionOffset}
                                    currentChildRowId={this.state.currentChildRowId}
                                    isChildRow={true}
                                    onEditRowClick={this.onSubRowEditClick.bind(this)}
                                    onEditColumnClick={this.onSubrowEditColumnClick.bind(this)}
                                    serialNo={idx + 1}
                                    showDelete={this.props.row['children'].length > 1}
                                    showEdit={true}
                                    onRowMetaEmit={this.onRowMetaChange.bind(this)}
                                    uiDataDict={this.props.uiDataDict}
                                    onCloneRow={this.onChildRowClone.bind(this, idx)}
                                    onRowAddition={this.onRowAddition.bind(this)}
                                    onRowDeletion={this.onRowDeletion.bind(this)} />
                            </div>
                        </li>)}
                    </ul>
                </>
                }
                {(this.props.row['type'] === 'row' || this.props.row['type'] === 'compactRow') && this.props.row['columns'].length > 0 && <>
                    <Row >
                        <Col span={14}>Tool</Col>
                        <Col span={4}>Name</Col>
                        <Col span={6}>Actions</Col>
                    </Row>
                    {this.props.row['columns'].map((col, idx) =>
                        <Column
                            key={idx}
                            col={col}
                            showType={showType}
                            showDelete={this.props.row['columns'].length > 1}
                            sectionOffset={this.props.sectionOffset}
                            colIndex={idx}
                            onColAddition={this.onColAddition.bind(this)}
                            onColDeletion={this.onColDeletion.bind(this)}
                            uiDataDict={this.props.uiDataDict}
                            colIdArray={this.state.colIdArray}
                            onEditColumnClick={this.onEditColumnClick.bind(this)}
                            onModalOpen={() => this.setState({ isModalOpen: true })} />
                    )}</>
                }
            </>
            }
        </>);
    }
}

export default RowComp;