import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGeneralSettingsByType,
  updateGeneralSettingById,
} from "../../../redux/reducers/settings.slice";
import { RootState } from "../../../shared/constants";
const { Title, Text } = Typography;
type Props = {};
const { Option } = Select;

function GeneralSettings(props: Props) {
  const dispatch = useDispatch();
  const { generalSettings, loading } = useSelector(
    (state: RootState) => state.settings
  );
  const [settings, setSettings] = useState(null as any);
  const [typeValue, setTypeValue] = useState("" as any);
  const [typeArr, setTypeArr] = useState([] as any);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false as any);
  const [isServiceUpdated, setIsServiceUpdated] = useState(false as any);
  const [cnfMsg, setCnfMsg] = useState("" as any);
  const [evtVal, SetEvtVal] = useState("" as any);
  const [form] = Form.useForm();

  const fetchData = (value: string) => {
    if (value) {
      let param = {
        type: value,
      };
      dispatch(fetchGeneralSettingsByType(param)).then((response: any) => {
        if (response && response?.payload?.data?.data?.settings) {
          setSettings({
            enable_service:
              response?.payload?.data?.data?.settings?.enable_service,
            alternate_support_no:
              response?.payload?.data?.data?.settings?.alternate_support_no,
            support_no: response?.payload?.data?.data?.settings?.support_no,
          });
          form.setFieldsValue({
            enable_service:
              response?.payload?.data?.data?.settings?.enable_service === true
                ? "true"
                : "false",
            alternate_support_no:
              response?.payload?.data?.data?.settings?.alternate_support_no,
            support_no: response?.payload?.data?.data?.settings?.support_no,
          });
        }
      });
    } else {
      let param = {
        type: "",
      };
      dispatch(fetchGeneralSettingsByType(param)).then((response: any) => {
        if (response && response?.payload?.data?.data) {
          setTypeArr(response?.payload?.data?.data);
        }
      });
    }
  };

  const handleChange = (value: string) => {
    fetchData(value);
    setTypeValue(value);
  };

  useEffect(() => {
    fetchData(typeValue);
  }, []);

  const handleValues = (name: string) => (event: any) => {
    if (name === "enable_service") {
      setIsServiceUpdated(true);
      setSettings({ ...settings, [name]: event === "true" ? true : false });
      event === "true" ? SetEvtVal("true") : SetEvtVal("false");
    } else {
      setSettings({ ...settings, [name]: event.target.value });
    }
  };

  const handleUpdate = async () => {
    setConfirmModalVisible(true);
    if (isServiceUpdated) {
      evtVal === "true"
        ? setCnfMsg("Are you sure you want to Enable Service.")
        : setCnfMsg("Are you sure you want to  Disable Service.");
    } else {
      setCnfMsg("Are you sure you want to update setting.");
    }
  };

  const closeModal = () => {
    setConfirmModalVisible(false);
  };

  const handleSubmit = async () => {
    let param = {
      settings: settings,
    };
    await dispatch(
      updateGeneralSettingById({ id: generalSettings?.id, data: param })
    );
    await fetchData(typeValue);
    await closeModal();
    setIsServiceUpdated(false);
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <div className={"col-md-12 main-container paddingcollapse"}>
      <Title className="mrg10" level={4}>
        General Settings
      </Title>
      <Row className="mrg10">
        <Select
          defaultValue={typeValue}
          placeholder="Select Type"
          style={{ width: 200, textTransform: "capitalize" }}
          onChange={handleChange}
        >
          {typeArr?.map((indRow: any, index: any) => (
            <Option
              key={indRow?.id}
              value={indRow?.type}
              style={{ textTransform: "capitalize" }}
            >
              {indRow?.type}
            </Option>
          ))}
        </Select>
      </Row>
      <Divider />
      <Row className="mt10">
        <Title className="mrg10" level={5}>
          Update Settings
        </Title>
        <Form form={form} className=" crm-form full-width" layout="horizontal">
          <Row>
            <Col span={8}>
              <Form.Item name="enable_service" label="Enable Service">
                <Select
                  defaultValue={
                    settings?.enable_service === true ? "true" : "false"
                  }
                  onChange={handleValues("enable_service")}
                >
                  <Option value={"true"}>True</Option>
                  <Option value={"false"}>False</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item name="support_no" label="Support No">
                <Input
                  type="number"
                  placeholder="Enter Number"
                  onChange={handleValues("support_no")}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
                    <Col span={8}>
                        <Form.Item name='alternate_support_no' label="Alternate Support No">
                        <Input type='number' placeholder="Enter Alternate Number" onChange={handleValues('alternate_support_no')}/>
                        </Form.Item>
                    </Col>
                </Row> */}
        </Form>
      </Row>
      <Row>
        <Button
          onClick={() => {
            handleUpdate();
          }}
          className="mt20 mrg10"
          type="primary"
        >
          Update
        </Button>
      </Row>
      <Modal
        visible={confirmModalVisible}
        onOk={handleSubmit}
        onCancel={closeModal}
      >
        <Row>
          <Col span={24} className="text-center">
            <h3 className="primary-color-text mt50 bold">Confirmation!!! </h3>
            <p className="sub-text mt10">{cnfMsg} </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default GeneralSettings;
