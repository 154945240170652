import { EditFilled } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchBranches } from "../../../redux/reducers/branch.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import {
  BRANCH_MODULE_ACTIONS_READ,
  BRANCH_MODULE_ACTIONS_UPDATE,
} from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import DesktopListLayout from "../../../shared/layouts/desktop-list.layout";
import {
  ROUTE_BRANCH_ACTION,
  ROUTE_BRANCH_CREATE,
} from "../../../shared/routes/admin.routes.constants";

const { Text } = Typography;
type Props = {};

const BranchesList: React.FunctionComponent<Props> = () => {
  const [loading] = useState(false);
  const { branches } = useSelector((state: RootState) => state.branch);
  const history = useHistory();
  const columns = [
    {
      title: "S.No",
      key: "sNo",
      render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <PermissionComponent permission={BRANCH_MODULE_ACTIONS_UPDATE}>
              <Button
                onClick={() => history.push(ROUTE_BRANCH_ACTION(a.id, "edit"))}
                shape="circle"
                icon={<EditFilled />}
                size={"middle"}
              />
            </PermissionComponent>
          </Row>
        );
      },
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBranches());
  }, []);

  return (
    <div className={"col-md-12 main-container"}>
      <PermissionComponent permission={BRANCH_MODULE_ACTIONS_READ}>
        <DesktopListLayout
          columns={columns}
          dataSource={branches}
          enableAddRoute={ROUTE_BRANCH_CREATE}
          loading={loading}
          title={"Branches"}
        />
      </PermissionComponent>
    </div>
  );
};

export default BranchesList;
