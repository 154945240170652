import { DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Typography
} from "antd";
//import { useStore } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchOutlineIcon } from "../../../src/assets/icons/searchOutlineIcon.svg";
import FormBar from "../layouts/formbar.layout";
import PaginationLayout from "../layouts/pagination.layout";
//import { env } from "process";
//import moment from 'moment';
//import { AnySet } from "immer/dist/internal";

const { Option } = Select;
const { Text } = Typography;
//const {RangePicker} = DatePicker;
const { Search } = Input;

export const TransactionFilter = (onChange: any, total: any) => {
  return (
    <Row>
      <Col span={12}>
        <Space>
          <Select placeholder="Status" onChange={(evt: any) => onChange(evt)}>
            <Option value="Yes">Yes</Option>
          </Select>

          <Select placeholder="Transaction Type">
            <Option value="">Yes</Option>
          </Select>

          <DatePicker></DatePicker>
        </Space>
      </Col>
      <Col span={6} offset={6} className="text-right">
        {total > 0 && (
          <PaginationLayout
            total={total}
            onChange={(value: any) => onChange(value)}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

export const BookAppointmentFilter = (onChange: any, total: any) => {
  return (
    <Row className="pd10">
      <Col span={24}>
        <Space size="middle">
          <div>
            {/* <Text>Doctor</Text> <br/> */}
            <Select
              placeholder="Choose Doctor"
              onChange={(evt: any) => onChange(evt)}
            >
              <Option value="Yes">Yes</Option>
            </Select>
          </div>
          <div>
            {/* <Text>Hospital/Region</Text><br/> */}
            <Select
              placeholder="Choose Doctor"
              onChange={(evt: any) => onChange(evt)}
            >
              <Option value="Yes">Yes</Option>
            </Select>
          </div>
          <div>
            {/* <Text>Department</Text><br/> */}
            <Select
              placeholder="Choose Doctor"
              onChange={(evt: any) => onChange(evt)}
            >
              <Option value="Yes">Yes</Option>
            </Select>
          </div>
          <div>
            {/* <Text>Date</Text><br/> */}
            <DatePicker></DatePicker>
          </div>
          <div>
            {/* <Text>Time</Text><br/> */}
            <Select
              placeholder="Choose Doctor"
              onChange={(evt: any) => onChange(evt)}
            >
              <Option value="Yes">Yes</Option>
            </Select>
          </div>
          <div className="mt20">
            <Text underline className="sub-color-text cursor">
              Clear Filter
            </Text>
          </div>
        </Space>
      </Col>
    </Row>
  );
};

export const ContactsFilter = (
  onChange: any,
  total: any,
  buttonEvt: any,
  filterFormSubmit: any,
  defaultCurrentPage: number
) => {
  const { t } = useTranslation();
  return (
    <Row align={"middle"}>
      <Col span={14}>
        <Row gutter={16}>
          <Col span={18}>
            <Input
              placeholder={t(
                "CRM.Contacts.Fields.Search by Name, Mobile Number, Email"
              )}
              onPressEnter={filterFormSubmit}
              prefix={<SearchOutlineIcon width={"13"} height={"13"} />}
            />
          </Col>
          <Col span={6}>
            <Button className="text-primary" onClick={() => buttonEvt()}>
              {t("CRM.Common.Actions.Add New Contact")}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={10} className="text-right">
        {total > 0 && (
          <PaginationLayout
            defaultCurrent={defaultCurrentPage}
            total={total}
            onChange={(value: any) => onChange(value)}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

export const AssignmentFilter = (onChange: any, total: any, buttonEvt: any) => {
  //console.log(total)
  return (
    <Row>
      <Col flex="auto">
        <Space size="middle">
          {/* <div>
                    <Input style={{width: '400px'}} placeholder="Search by Source, Type, Subtype" prefix={<SearchOutlined />} />
                </div> */}
          <div>
            <Button className="text-primary" onClick={() => buttonEvt()}>
              Add Assignment
            </Button>
          </div>
        </Space>
      </Col>
      <Col span={6} className="text-right">
        <PaginationLayout
          total={total}
          onChange={(value: any) => onChange(value)}
        ></PaginationLayout>
      </Col>
    </Row>
  );
};

export const ActionFilter = (onChange: any, total: any, buttonEvt: any) => {
  //console.log(total)
  return (
    <Row>
      <Col flex="auto">
        <Space size="middle">
          {/* <div>
                    <Input style={{width: '400px'}} placeholder="Search by Name, Mobile Number, Email" prefix={<SearchOutlined />} />
                </div> */}
          <div>
            <Button className="text-primary" onClick={() => buttonEvt()}>
              Add Action
            </Button>
          </div>
        </Space>
      </Col>
      <Col span={6} className="text-right">
        <PaginationLayout
          total={total}
          onChange={(value: any) => onChange(value)}
        ></PaginationLayout>
      </Col>
    </Row>
  );
};

export const AgentsFilter = (
  onChange: any,
  total: any,
  buttonEvt: any,
  filterFormSubmit: any
) => {
  //console.log(total)
  return (
    <Row>
      <Col flex="auto">
        <Space size="middle">
          {/* <div>
                    <Input style={{width: '400px'}} placeholder="Search by Name, Mobile Number, Email" prefix={<SearchOutlined />} onPressEnter={filterFormSubmit} />
                </div> */}
          <div>
            <Button className="text-primary" onClick={() => buttonEvt()}>
              Add Agent
            </Button>
          </div>
        </Space>
      </Col>
      <Col span={6} className="text-right">
        {total > 0 && (
          <PaginationLayout
            total={total}
            onChange={(value: any) => onChange(value)}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

// export const LeadsFilter = (onChange: any, total: any) => {
//     return <Row>
//         <Col flex="auto">
//             {/* <Space size="middle">
//                 <div>
//                     <Select placeholder="Status">
//                         <Option value="pending">Pending</Option>
//                     </Select>
//                 </div>
//                 <div>
//                     <RangePicker></RangePicker>
//                 </div>
//                 <div>
//                     <Input style={{width: '400px'}} placeholder="Search by Name, Mobile Number, Email" prefix={<SearchOutlined />} />
//                 </div>
//             </Space> */}
//         </Col>
//         <Col span={12} className="text-right">
//             { total>0 && <PaginationLayout total={total} onChange={(value: any) => onChange(value)}></PaginationLayout> }
//         </Col>
//     </Row>
// }

export const PharmacyOrderSummaryFilter = (
  filterFormSubmit: any,
  branches: any,
  downloadFilterData: any
) => {
  const [filterVal, setFilterVal] = useState({
    selectedFilter: "",
    searchValue: "",
    request_date: "",
    branch_id: "",
  });
  function onDateChange(date: any, dateString: any) {
    setFilterVal({ ...filterVal, request_date: dateString });
    filterFormSubmit({ ...filterVal, request_date: dateString });
  }
  function onBranchChange(evt: any) {
    setFilterVal({ ...filterVal, branch_id: evt });
    filterFormSubmit({ ...filterVal, branch_id: evt });
  }
  function handleDownloadFilter(evt: any) {
    downloadFilterData({ ...filterVal });
  }
  return (
    <Row>
      <Col span={18}>
        <Space>
          <Select
            placeholder="Pharmacy Name"
            onChange={(evt: any) => onBranchChange(evt)}
            allowClear={true}
            style={{ width: 200 }}
          >
            {branches?.map((ind: any, index: any) => (
              <Option value={ind?.id}>{ind?.name}</Option>
            ))}
          </Select>
          <DatePicker
            style={{ width: 130 }}
            onChange={onDateChange}
          ></DatePicker>
          <Select
            onChange={(evt: any) =>
              setFilterVal({ ...filterVal, selectedFilter: evt })
            }
            placeholder="Search By"
            allowClear={true}
          >
            {/* <Option value="">Select Filter</Option> */}
            <Option value="order_id">Order ID</Option>
            <Option value="bill_id">Invoice No</Option>
            <Option value="order_cart_id">HIS ID</Option>
            {/* <Option value="uhid">UHID</Option> */}
            <Option value="mobile">Phone No</Option>
          </Select>
          <Search
            onSearch={() => filterFormSubmit(filterVal)}
            onChange={(evt: any) =>
              setFilterVal({ ...filterVal, searchValue: evt.target.value })
            }
            style={{ width: 400 }}
            placeholder="Search by Patient ID, Order ID, HIS ID or Invoive No"
          ></Search>
        </Space>
      </Col>
      <Col span={6} className="text-right">
        <Button
          type="primary"
          onClick={() => handleDownloadFilter(filterVal)}
          ghost
          icon={<DownloadOutlined />}
        >
          Download Data
        </Button>
      </Col>
    </Row>
  );
};
export const MetadataFilter = (onChange: any, total: any) => {
  return (
    <Row>
      <Col flex="auto">
        <Space size="middle">
          <div>
            <Input
              style={{ width: "400px" }}
              placeholder="Search by Category, Name"
              prefix={<SearchOutlineIcon width={"13"} height={"13"} />}
            />
          </div>
          <Button key="3">Create Metadata</Button>
        </Space>
      </Col>

      <Col span={6} className="text-right">
        <PaginationLayout
          total={total}
          onChange={(value: any) => onChange(value)}
        ></PaginationLayout>
      </Col>
    </Row>
  );
};
export const GenericFilter = (
  onChange: any,
  total: any,
  formData: any,
  buttonEvt: any,
  filters: any,
  defaultCurrentPage: number,
  showTotal: boolean = false
) => {
  return (
    <Row align={"middle"}>
      <Col span={14} className="filters-formbar">
        <FormBar
          name={"generic_filters"}
          onClicks={() => buttonEvt()}
          onFinish={formData.onFinish}
          onFinishFailed={formData.onFinishFailed}
          formElements={formData.formElements}
          initialValues={formData.initialValues}
          onValuesChange={formData.onValuesChange}
          buttonText={""}
          setValues={formData.setValues ? formData.setValues : null}
        ></FormBar>
      </Col>
      <Col span={10} className="text-right">
        {total > 0 && (
          <PaginationLayout
            defaultCurrent={defaultCurrentPage}
            total={total}
            onChange={(value: any) => onChange(value)}
            showTotal={showTotal}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};
export const OnlyFilters = (onChange: any, formData: any, filters: any) => {
  return (
    <Row align={"middle"}>
      <Col span={24} className="filters-formbar">
        <FormBar
          name={"only_filter"}
          onFinish={formData.onFinish}
          onFinishFailed={formData.onFinishFailed}
          formElements={formData.formElements}
          initialValues={formData.initialValues}
          onValuesChange={formData.onValuesChange}
          buttonText={""}
          setValues={formData.setValues ? formData.setValues : null}
        ></FormBar>
      </Col>
    </Row>
  );
};

export const VCFilter = (
  formData: any,
  filters: any,
  buttonEvt: any,
  defaultCurrentPage: number,
  showTotal: any,
  onChange: any,
  total: any
) => {
  return (
    <Row>
      <Col span={16} className="boss-table nopadding">
        <FormBar
          name={"vcfilter"}
          onClicks={() => buttonEvt()}
          filters={filters}
          onFinish={formData.onFinish}
          onFinishFailed={formData.onFinishFailed}
          formElements={formData.formElements}
          initialValues={formData.initialValues}
          onValuesChange={formData.onValuesChange}
          buttonText={""}
          setValues={formData.setValues ? formData.setValues : null}
        ></FormBar>
      </Col>
      <Col span={8} style={{ marginBottom: "20px" }}>
        {total > 0 && (
          <PaginationLayout
            defaultCurrent={defaultCurrentPage}
            total={total}
            onChange={(value: any) => onChange(value)}
            showTotal={showTotal}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

export const MoreFilters = (
  onChange: any,
  total: any,
  formData: any,
  buttonEvt: any,
  filters: any,
  defaultCurrentPage: number,
  showTotal: boolean = false
) => {
  return (
    <Row align={"middle"}>
      <Col
        {...{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}
        className="filters-formbar"
      >
        <FormBar
          name={"moreFilters"}
          filters={filters}
          onClicks={() => buttonEvt()}
          onFinish={formData.onFinish}
          onFinishFailed={formData.onFinishFailed}
          formElements={formData.formElements}
          initialValues={formData.initialValues}
          onValuesChange={formData.onValuesChange}
          buttonText={""}
          setValues={formData.setValues ? formData.setValues : null}
          filtersApplied={formData.filtersApplied}
        ></FormBar>
      </Col>
      <Col {...{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }} className="text-right">
        {total > 0 && (
          <PaginationLayout
            defaultCurrent={defaultCurrentPage}
            total={total}
            showTotal={showTotal}
            onChange={(value: any) => onChange(value)}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

export const EmptyFilter = (onChange: any, total: any) => {
  return (
    <Row>
      <Col span={16} className="text-right"></Col>
      <Col span={8} className="text-right">
        {total > 0 && (
          <PaginationLayout
            total={total}
            onChange={(value: any) => onChange(value)}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

export const CoordinatorFilter = (onChange: any, total: any) => {
  return (
    <Row>
      <Col span={12}></Col>
      <Col span={6} offset={6} className="text-right">
        <Button
          type="primary"
          ghost
          onClick={() => onChange(true)}
          icon={<DownloadOutlined />}
        >
          Add Coordinator
        </Button>
      </Col>
    </Row>
  );
};

export const paymentSummaryFilter = (
  onChange: any,
  total: any,
  formData: any,
  buttonEvt: any,
  filters: any,
  defaultCurrentPage: number,
  downloadFilterData: any
) => {
  /*const buttonEvt2 = (data:any) => {
        downloadFilterData(data?.setValues)
    }*/
  return (
    <Row>
      <Col span={16} className="filters-formbar">
        <FormBar
          name={"paymentSummaryFilter"}
          onFinish={formData.onFinish}
          onFinishFailed={formData.onFinishFailed}
          formElements={formData.formElements}
          initialValues={formData.initialValues}
          onValuesChange={formData.onValuesChange}
          buttonText={""}
          setValues={formData.setValues ? formData.setValues : null}
        ></FormBar>
      </Col>
      {/* <Col span={4}>
            <Button type="primary" ghost onClick={()=>buttonEvt2(formData)} icon={<DownloadOutlined />}>Download</Button>
        </Col> */}
      <Col span={8} className="text-right">
        {total > 0 && (
          <PaginationLayout
            total={total}
            onChange={(value: any) => onChange(value)}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

export const DiagnosticsSummaryFilter = (
  onChange: any,
  total: any,
  formData: any,
  buttonEvt: any,
  filters: any,
  defaultCurrentPage: number,
  downloadFilterData: any
) => {
  const buttonEvt2 = (data: any) => {
    downloadFilterData(data?.setValues);
  };
  return (
    <Row>
      <Col span={12} className="filters-formbar">
        <FormBar
          name={"paymentSummaryFilter"}
          onFinish={formData.onFinish}
          onFinishFailed={formData.onFinishFailed}
          formElements={formData.formElements}
          initialValues={formData.initialValues}
          onValuesChange={formData.onValuesChange}
          buttonText={""}
          setValues={formData.setValues ? formData.setValues : null}
        ></FormBar>
      </Col>
      <Col span={4}>
        <Button
          type="primary"
          ghost
          onClick={() => buttonEvt2(formData)}
          icon={<DownloadOutlined />}
        >
          Download
        </Button>
      </Col>
      <Col span={8} className="text-right">
        {total > 0 && (
          <PaginationLayout
            total={total}
            onChange={(value: any) => onChange(value)}
          ></PaginationLayout>
        )}
      </Col>
    </Row>
  );
};

export const BlankFilter = (onChange: any, total: any) => {
  return <Row></Row>;
};
