import { Flex } from "@100mslive/react-ui";
import { useState ,useEffect} from "react";
import { useSearchParam } from "react-use";
import { submitCallLogs } from "../../../../redux/reducers/appointment.slice";
import {
  QUERY_PARAM_SKIP_PREVIEW,
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  UI_SETTINGS,
} from "../common/constants";
import { useSetUiSettings } from "./AppData/useUISettings";
import Conference from "./conference";
import FullPageProgress from "./FullPageProgress";
import Preview from "./Preview";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios"

const PreviewScreen = ({ token, username, loginType, room_id,appointmentId }) => {
  const env = process.env.REACT_APP_ENV;
  const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
  const beamInToken = useSearchParam("token") === "beam_recording"; // old format to remove
  let skipPreview = useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true";
  const [call, setCall] = useState(false);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const [ip, setIP] = useState("");


  
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };


  const directJoinHeadfulFromEnv =
    process.env.REACT_APP_HEADLESS_JOIN === "true";
  const directJoinHeadful =
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
    directJoinHeadfulFromEnv;
  skipPreview = skipPreview || beamInToken || directJoinHeadful;

  const onJoin = () => {
    !directJoinHeadful && setIsHeadless(skipPreview);
    dispatch(
      submitCallLogs({
        event: "join",
        user_type: loginType,
        room: room_id,
        user_id: profile?.id,
        device_type: "Browser",
        device_name: window.navigator.appCodeName,
        device_ip: ip,
        device_os: window.navigator.appVersion,
        reason: `${loginType} Joined on Web`
      })
    );
    setCall(true);
  };

  return (
    <Flex direction="column" css={{ size: "100%" }}>
      <Flex css={{ flex: "1 1 0" }} justify="center" align="center">
        {token && !call ? (
          <>
            <Preview
              initialName={username.slice(0, username.indexOf("("))}
              loginType={loginType}
              skipPreview={false}
              env={env}
              onJoin={onJoin}
              token={token}
              appointmentId={appointmentId}
            />
          </>
        ) : call ? (
          <Conference loginType={loginType}></Conference>
        ) : (
          <FullPageProgress />
        )}
      </Flex>
    </Flex>
  );
};

export default PreviewScreen;
