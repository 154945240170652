import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_QUICKSIGHT_DASHBOARD } from "../../shared/routes/boss.routes.constants";
import { GET_DASHBOARD_DATA } from "../../shared/routes/crm.routes.constants";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "task",
};

export const fetchDashboardData: any = createAsyncThunk(
  `${config.name}/fetchDashboardData`,
  async (params: any) => {
    return await Client.get(GET_DASHBOARD_DATA, { params });
  }
);

export const fetchQuicksightDashboardData: any = createAsyncThunk(
  `${config.name}/fetchQuicksightDashboardData`,
  async (params: any) => {
    return await Client.get(
      GET_QUICKSIGHT_DASHBOARD(params.dashboardId, params.client),
      {}
    );
  }
);
