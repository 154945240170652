import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { addNewDoctor } from "../../../../redux/reducers/doctor.slice";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { ROUTE_DOCTOR } from "../../../../shared/routes/admin.routes.constants";
import { Doctor } from "../../../../shared/types/doctor.type";
import DocterForm from "./doctor-form.page";

const DoctorCreate: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  return (
    <DesktopFormLayout
      title={"Doctor Create"}
      subtitle={"Create new Doctor for .."}
    >
      <DocterForm
        onSubmit={async (values: Doctor) => {
          values.user_profile_id = RouteParams["id"];
          await dispatch(addNewDoctor(values));
          history.push(ROUTE_DOCTOR);
        }}
      />
    </DesktopFormLayout>
  );
};

export default DoctorCreate;
