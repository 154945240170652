import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Skeleton
} from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchDoctorById } from "../../../../redux/reducers/doctor.slice";
import {
  fetchMasterDesignations,
  fetchMasterGender
} from "../../../../redux/reducers/master.slice";
import { RootState } from "../../../../shared/constants";
import { Doctor } from "../../../../shared/types/doctor.type";
import { Master } from "../../../../shared/types/master.type";

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  onSubmit: (values: Doctor) => void;
  // doctor?: Doctor
};
const DoctorForm: React.FunctionComponent<Props> = ({ onSubmit }) => {
  const [isActive, setIsActive] = useState(true as any);
  const location = useLocation();
  const RouteParams: any = useParams();

  const [form] = useForm();
  const { loading } = useSelector((state: RootState) => state.doctor || {});
  const { designations, gender } = useSelector(
    (state: RootState) => state.master
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMasterDesignations());
    dispatch(fetchMasterGender());
  }, []);
  useEffect(() => {
    if (RouteParams["id"]) {
      dispatch(fetchDoctorById(RouteParams["id"])).then((val: any) => {
        if (val?.payload?.data?.statusCode == 200) {
          if (val) {
            form.setFieldsValue({
              reg_no: val?.payload?.data?.data.reg_no,
              name: val?.payload?.data?.data?.name,
              mobile: val?.payload?.data?.data?.mobile || location?.state,
              gender_id: val?.payload?.data?.data?.gender_id,
              designation_id: val?.payload?.data?.data?.designation_id,
              dob: val?.payload?.data?.data?.dob
                ? moment(val?.payload?.data?.data?.dob)
                : moment(),
              reg_council: val?.payload?.data?.data?.reg_council,
              source_id: val?.payload?.data?.data?.source_id,
              practicing_since: val?.payload?.data?.data?.practicing_since
                ? moment((val?.payload?.data?.data?.practicing_since).toString())
                : moment(),
              external_id: val?.payload?.data?.data?.external_id,
              type: val?.payload?.data?.data?.type,
              external_profile_pic:
                val?.payload?.data?.data?.external_profile_pic,
              qualification: val?.payload?.data?.data?.qualification,
              is_active: val?.payload?.data?.data?.is_active,
            });
          } else {
            form.setFieldsValue({
              mobile: location?.state,
            });
          }
        }
      });
    }
  }, []);
  if (loading) {
    return <Skeleton />;
  }

  return (
    <Form
      name="basic"
      form={form}
      layout={"vertical"}
      // initialValues={editDoctor || {mobile: location?.state}}
      onFinish={(values: Doctor) => {
        if (values?.practicing_since) {
          values = {
            ...values,
            practicing_since: moment(values?.practicing_since).format("YYYY"),
          };
        }
        if (values?.dob) {
          values = {
            ...values,
            dob:
              values?.dob &&
              moment(values?.dob.toString()).format("YYYY-MM-DD"),
          };
        }
        values = {
          ...values,
          is_active: isActive,
        };
        onSubmit(values);
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
      className="mt20"
    >
      <Col span={24}>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label="Registration No/Employee Code"
              name="reg_no"
              rules={[{ required: false, message: "Please input Reg no." }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input Name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Mobile Number"
              name="mobile"
              rules={[
                { required: true, message: "Please input Mobile Number" },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          {/* <Col span={8}>
                        <Form.Item
                            label="Email ID"
                            name="email"
                            rules={[{ required: true, message: 'Please input Emailid', type: "email" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col> */}

          <Col span={8}>
            <Form.Item
              label="Gender"
              name="gender_id"
              rules={[{ required: true, message: "Please choose gender" }]}
            >
              <Radio.Group>
                {gender.map((gender: Master) => {
                  return <Radio value={gender.id}>{gender.name}</Radio>;
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Designation"
              name="designation_id"
              rules={[
                { required: false, message: "Please select designation" },
              ]}
            >
              <Select>
                {designations.map((designation: Master) => {
                  return (
                    <Option value={designation.id}>{designation.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="DOB" name="dob">
              <DatePicker
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Registration Council"
              name="reg_council"
              rules={[{ required: false, message: "Please choose DOB" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="HMS Code/HIS ID"
              name="source_id"
              rules={[{ required: true, message: "Please input Code" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Practicing Since"
              name="practicing_since"
              rules={[{ required: false, message: "Please choose year" }]}
            >
              <DatePicker
                picker="year"
                format="YYYY"
                style={{ width: "100%" }}
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Practo ID"
              name="external_id"
              rules={[{ required: false, message: "Please input ID" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: false, message: "Please select Type" }]}
            >
              <Select>
                <Option value="">None</Option>
                <Option value="instant">Instant</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="external_profile_pic"
              label="Image URL"
              rules={[{ type: "string", min: 6 }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Education/Qualification" name="qualification">
              <TextArea rows={3} autoSize></TextArea>
            </Form.Item>
          </Col>

          <Col span={6} className="paddingy20">
            <Form.Item label="" name="is_active">
              <Checkbox
                onChange={(e: any) => setIsActive(e.target.checked)}
                checked={isActive}
              >
                Active
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default DoctorForm;
