import { Col, Form, Input, Row, Select, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMasterGender } from "../../../../redux/reducers/master.slice";
import { RootState } from "../../../../shared/constants";
import { Master } from "../../../../shared/types/master.type";
import { Patient } from "../../../../shared/types/patient.type";
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

type Props = {
  appointment?: any;
};

const PatientForm: React.FunctionComponent<Props> = (appointment) => {
  const { gender } = useSelector((state: RootState) => state.master);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMasterGender());
  }, []);

  return (
    <Form
      name="basic"
      layout={"vertical"}
      initialValues={appointment?.appointment?.patient?.pii}
      onFinish={(values: Patient) => {
        console.log(values);
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
      className="mt30"
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="Patient Name" name="name">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Mobile Number" name="mobile">
            <Input disabled={true} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="Email Id" name="email">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Gender" name="gender_id">
            <Select>
              {gender.map((gender: Master) => {
                return <Option value={gender.id}>{gender.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Age" name="age">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Patient Type" name="patient_type">
            <Input />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Address" name="address">
            <TextArea />
            <Text className="sub-color-text bold cursor" underline>
              + Add Attendent
            </Text>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PatientForm;
