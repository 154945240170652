const MaxLogoIcon =({height="76" , width="238"}) =>(
    <>
        <svg width={width} height={height} viewBox="0 0 238 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M94.7247 67.9877H85.5709V75.312H83.0957V58.915H85.5709V65.8023H94.7247V58.915H97.207V75.312H94.7247V67.9877Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M102.115 68.4987H109.434C109.384 66.9267 108.317 64.9599 105.785 64.9599C103.381 64.9599 102.243 66.8773 102.115 68.4987ZM105.785 63.1411C109.384 63.1411 111.61 65.9186 111.61 69.5561V70.0989H102.136C102.307 72.115 103.801 73.7858 106.205 73.7858C107.471 73.7858 108.858 73.2923 109.754 72.3829L110.799 73.8351C109.611 74.9912 107.919 75.6045 105.991 75.6045C102.421 75.6045 99.7891 73.0949 99.7891 69.3587C99.782 65.9186 102.293 63.1411 105.785 63.1411Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M121.609 70.6923C120.941 69.8111 119.774 69.3388 118.537 69.3388C116.922 69.3388 115.784 70.2975 115.784 71.7003C115.784 73.1032 116.922 74.0619 118.537 74.0619C119.774 74.0619 120.941 73.5966 121.609 72.7084V70.6923ZM121.609 74.0055C120.663 75.0347 119.305 75.6057 117.69 75.6057C115.706 75.6057 113.522 74.2804 113.522 71.6721C113.522 68.9652 115.678 67.7879 117.69 67.7879C119.305 67.7879 120.692 68.3025 121.609 69.3599V67.3931C121.609 65.9198 120.393 65.0316 118.686 65.0316C117.321 65.0316 116.154 65.5462 115.087 66.6036L114.12 65.0809C115.457 63.7768 117.093 63.1353 119.028 63.1353C121.631 63.1353 123.836 64.2702 123.836 67.2874V75.3026H121.602V74.0055H121.609Z" fill="#003873"/>
            <path d="M129.748 58.915H127.515V75.312H129.748V58.915Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M134.135 72.6752V65.372H132.15V63.4263H134.135V60.1836H136.368V63.4263H138.801V65.372H136.368V72.1817C136.368 73.0206 136.766 73.6339 137.535 73.6339C138.054 73.6339 138.523 73.4154 138.751 73.1687L139.32 74.8394C138.85 75.2835 138.153 75.6007 136.987 75.6007C135.102 75.6007 134.135 74.5433 134.135 72.6752Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M149.812 67.6704C149.812 65.7036 148.794 65.1115 147.258 65.1115C145.871 65.1115 144.605 65.9504 143.958 66.8316V75.312H141.725V58.915H143.958V65.1115C144.755 64.1528 146.362 63.1447 148.197 63.1447C150.75 63.1447 152.045 64.4206 152.045 66.9302V75.312H149.812V67.6704Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M160.772 63.1411C163.027 63.1411 164.393 64.0787 165.26 65.2277L163.774 66.5812C163.056 65.5732 162.088 65.1079 160.872 65.1079C158.518 65.1079 157.003 66.9055 157.003 69.3587C157.003 71.819 158.518 73.6377 160.872 73.6377C162.088 73.6377 163.056 73.1443 163.774 72.1644L165.26 73.5179C164.393 74.674 163.027 75.6045 160.772 75.6045C157.152 75.6045 154.67 72.9257 154.67 69.3587C154.67 65.7988 157.152 63.1411 160.772 63.1411Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M175.304 70.6923C174.635 69.8111 173.469 69.3388 172.231 69.3388C170.617 69.3388 169.479 70.2975 169.479 71.7003C169.479 73.1032 170.617 74.0619 172.231 74.0619C173.469 74.0619 174.635 73.5966 175.304 72.7084V70.6923ZM175.304 74.0055C174.358 75.0347 172.999 75.6057 171.385 75.6057C169.4 75.6057 167.217 74.2804 167.217 71.6721C167.217 68.9652 169.372 67.7879 171.385 67.7879C172.999 67.7879 174.386 68.3025 175.304 69.3599V67.3931C175.304 65.9198 174.088 65.0316 172.381 65.0316C171.015 65.0316 169.848 65.5462 168.782 66.6036L167.814 65.0809C169.151 63.7768 170.787 63.1353 172.722 63.1353C175.325 63.1353 177.53 64.2702 177.53 67.2874V75.3026H175.297V74.0055H175.304Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M181.155 63.4402H183.389V65.259C184.306 64.0817 185.722 63.1724 187.308 63.1724V65.407C187.087 65.3577 186.838 65.3365 186.539 65.3365C185.423 65.3365 183.908 66.1754 183.389 67.0566V75.3185H181.155V63.4402Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M190.808 68.4987H198.127C198.077 66.9267 197.01 64.9599 194.478 64.9599C192.067 64.9599 190.929 66.8773 190.808 68.4987ZM194.478 63.1411C198.077 63.1411 200.303 65.9186 200.303 69.5561V70.0989H190.83C191 72.115 192.494 73.7858 194.898 73.7858C196.164 73.7858 197.551 73.2923 198.447 72.3829L199.493 73.8351C198.305 74.9912 196.612 75.6045 194.685 75.6045C191.114 75.6045 188.482 73.0949 188.482 69.3587C188.475 65.9186 190.979 63.1411 194.478 63.1411Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M63.9205 0V56.5365C63.9205 59.1941 62.6189 61.6826 60.414 63.2335L44.5317 74.435C43.0949 75.4501 41.3595 76 39.5813 76H24.3391C22.561 76 20.8327 75.4501 19.3888 74.435L3.50649 63.2335C1.30871 61.6826 0 59.1871 0 56.5365V0H63.9205Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.7273 35.0935H40.207C43.1161 35.0935 45.8971 33.9515 47.9526 31.9142L57.5261 22.4257H44.7448V9.75781L31.9707 22.4257L19.1895 9.75781V22.4257H6.4082L15.9817 31.9142C18.0301 33.9444 20.8182 35.0935 23.7273 35.0935ZM40.207 35.9183H23.7273C20.8182 35.9183 18.0372 37.0603 15.9817 39.0976L6.4082 48.5861H19.1895V61.254L31.9636 48.5861L44.7448 61.254V48.5861H57.5261L47.9526 39.0976C45.8971 37.0603 43.1161 35.9183 40.207 35.9183Z" fill="#23BCB5"/>
            <path d="M42.0291 32.3706H22.498V38.6235H42.0291V32.3706Z" fill="#E0EBF1"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0291 32.3705H35.4215V25.8215H29.1056V32.3705H22.498V38.6304H29.1056V45.1793H35.4215V38.6304H42.0291V32.3705Z" fill="#E0EBF1"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M169.598 29.5583H156.98L163.289 12.407L169.598 29.5583ZM169.363 0H157.158L137.57 48.5283H150.067L153.751 38.4547H172.834L176.519 48.5283H189.015L169.363 0Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M219.891 23.5874L236.897 0H222.928L212.21 15.7203L201.356 0H187.536L204.542 23.6579L186.427 48.5283H200.254L212.217 31.652L224.102 48.5283H238L219.891 23.5874Z" fill="#003873"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M122.892 0L108.325 20.3306L93.7587 0H83.0898V48.5283H94.5979V18.2933L108.325 36.7487L122.06 18.2933V48.5283H133.56V0H122.892Z" fill="#003873"/>
        </svg>
    </>
);

export default MaxLogoIcon;

