export const ROUTE_CRM_LOGIN = `/crm/login`
export const ROUTE_CRM_LANDING = `/crm`
export const ROUTE_CRM_DASHBOARD = `/crm/dashboard`
export const ROUTE_CRM_TICKET_LIST = `/crm/tickets`
export const ROUTE_CRM_TICKET_DETAILS = (id: string) => `/crm/ticket/${id}`
export const ROUTE_CRM_LEADS_LIST = `/crm/leads`
export const ROUTE_CRM_LEAD_DETAILS = (id: string) => `/crm/lead/${id}`
export const ROUTE_CRM_CONTACTS = `/crm/contacts`
export const ROUTE_CRM_CONTACTS_DETAILS = (id: string) => `/crm/contacts/${id}`
export const ROUTE_CRM_METADATA = `/crm/metadata`
export const ROUTE_CRM_CALL_LOG_CREATE = `/crm/create-call-log`
export const ROUTE_CRM_CALL_LOGS = `/crm/call-logs`
export const ROUTE_CRM_CALL_LOG_DETAILS = (id: string) => `/crm/call-log/${id}`
export const ROUTE_CRM_ASSIGNMENT = `/crm/assignment`
export const ROUTE_CRM_ACTIONMAP = `/crm/actionmap`
export const ROUTE_CRM_AGENTS = `/crm/agents`


export const ROUTE_CRM_BOOK_APPOINTMENT = (id: string) => `/crm/book-appointment/${id}` //lead id
export const ROUTE_CRM_ORDER_MEDICINE = (id: string) => `/crm/order-medicine/${id}` //lead id

//// APIS
export const CONTACTS = `task/contact`
export const CONTACT_CREATE_FROM_PATIENT = `task/create-contact-from-patient`
export const CONTACT_BY_ID = (id: string) => `task/contact/${id}`
export const CONTACT_AND_PATIENT_BY_MOBILE = (phone: string) => `task/find-contact-and-patient-by-mobile/${phone}`

export const LEADS = `task/lead`
export const METADATA = `master/task-drop-down`
export const ALLTASKDATA = `master/all-task-data`
export const METADATAVALUES = `master/task-drop-down-values`
export const PATCH_METADATA_LABEL = (id: string) => `master/task-drop-down/${id}`
export const PATCH_METADATA_VALUES = (id: string) => `master/task-drop-down-values/${id}`
export const METATASKTYPE = `master/task-type`
export const METATASKTYPEUPDATE = `master/task-type/update`
export const METATASKSUBTYPE = `master/task-sub-type`
export const METATASKSUBTYPEBYID = (id: string) => `master/task-sub-type?task_type_id=${id}`
export const METATASKSUBTYPEPPOST = `master/task-sub-type`
export const METATASKSUBTYPEUPDATE = `master/task-sub-type/update`
export const METATASKCATEGORYPOST = `master/task-category`
export const METATASKCATEGORY = (id: string) => `master/task-category?task_sub_type_id=${id}`
export const METATASKCATEGORYUPDATE = `master/task-category/update`

export const LEAD_STATUS = `task/lead-status`
export const LEAD_BY_ID = (id: string) => `task/lead/${id}`
export const LEAD_SERVICE_MAP = `task/lead/services`
export const LEAD_DOWNLOAD = `task/download-lead-data`

export const CALL_LOGS = `task/call-log`
export const CALL_INITIATE = `task/make-a-call`
export const CALL_LOG_BY_ID = (id: string) => `task/call-log/${id}`
export const CALL_LOG_LINK = `task/call-log-link`
export const CALL_LOG_DELINK = (id: string) => `task/call-log-link/${id}`

export const INTALK_LOGIN = `task/login-in-talk`
export const INTALK_LOGOUT = `task/logout-in-talk`

export const TICKETS = `task/ticket`
export const TICKET_DETAIL_BY_ID = (id: string) => `task/ticket/${id}`
export const TICKET_DOCTOR_SUPPORT = `task/ticket/doctor-support`
export const ACTIVITY_HISTORY = (id: string) => `task/ticket/timeline/${id}`
export const VIDEO_LOGS = (id: string, dates: string) => `roomlogs/audit/${id}?date=${dates}`
export const PAYMENT_HISTORY = (id: string) => `payment/get-history?order_id=${id}`
export const UPDATE_TICKET_TYPE = (id: string) => `task/update-ticket-type/${id}`
export const TICKET_SERVICE = `task/ticket/service`
export const TICKET_DOWNLOAD = `task/download-ticket-data`

export const ASSIGNMENT = `task/ticket/assignment-settings`
export const ASSIGNMENTPATCH = (id: string) => `task/ticket/assignment-settings/${id}`
export const TEAMTYPES = `team-types`
export const TEAMIDS = `teams`

export const ACTIONMAP = `task/task/action-mapping`
export const AGENTS = `users`

export const NEW_PATIENT_FROM_CONTACT = `task/create-patient-from-contact`

export const GET_DASHBOARD_DATA = `task/get-dashboard-data`



