import {
  selectHMSMessages,
  selectMessagesByPeerID,
  selectMessagesByRole,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Flex, Text } from "@100mslive/react-ui";
import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

const ChatMessage = React.memo(({ message }) => {
  const { inView } = useInView({ threshold: 0.5, triggerOnce: true });
  const hmsActions = useHMSActions();

  useEffect(() => {
    if (message?.id && !message?.read && inView) {
      hmsActions.setMessageRead(true, message?.id);
    }
  }, [message?.read, hmsActions, inView, message?.id]);

  return (
    <Row justify={"start"}>
      <Col span={24}>
        <Text
          variant="sm"
          css={{
            color: "$textSecondary",
            textTransform: "capitalize",
            float: "left",
            padding: "10px",
          }}
        >
          {message?.senderName || message?.user_name}{" "}
          <span style={{ fontSize: "10px" }}>
            (
            {moment(message?.time).format("LT") ||
              moment(message?.date_t_time).format("lll")}
            )
          </span>
        </Text>
      </Col>
      <Col span={24}>
        <Text
          variant="sub2"
          css={{
            color: "$textSecondary",
            textTransform: "capitalize",
            float: "left",
            padding: "0px 10px",
            maxWidth: "200px",
            wordBreak: "break-word",
            textAlign: "left",
          }}
        >
          <b>{message?.message}</b>
        </Text>
      </Col>
    </Row>
  );
});

export const ChatBody = ({ role, peerId }) => {
  const storeMessageSelector = role
    ? selectMessagesByRole(role)
    : peerId
    ? selectMessagesByPeerID(peerId)
    : selectHMSMessages;
  const messages = useHMSStore(storeMessageSelector) || [];
  const {
    messages: allMessages,
    loading,
    connection,
  } = useSelector((state) => state.chat);
  const [allRoomMessages, setAllRoomMessages] = useState([]);

  useEffect(() => {
    const wsCurrent = connection;
    if (!wsCurrent) return;
    wsCurrent.onmessage = (e) => {
      const _message = JSON.parse(e.data);
      setAllRoomMessages((prevState) => [...prevState, _message]);
    };
  });

  useEffect(() => {
    let chat = messages.concat(allMessages.slice(0).reverse());
    setAllRoomMessages(chat);
  }, [allMessages]);

  if (loading) {
    return <Skeleton></Skeleton>;
  }

  if (allRoomMessages.length === 0 && !loading) {
    return (
      <Flex
        css={{
          width: "100%",
          height: "calc(100% - 1px)",
          textAlign: "center",
          px: "$4",
        }}
        align="center"
        justify="center"
      >
        <Text>There are no messages here</Text>
      </Flex>
    );
  }
  return (
    <Fragment>
      {allRoomMessages.map((message) => {
        return <ChatMessage key={message?.id} message={message} />;
      })}
    </Fragment>
  );
};
