import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GLOBAL_LIST_LIMIT } from "../../shared/constants";
import {
  ADD_USER_BRANCH,
  AGENTS,
  AGENTS_BY_ID,
  AGENTTPATCH,
  ASSIGN_ACCESS_GROUP_BY_USER_ID,
  DELETE_USER_BRANCH,
  FETCH_USER_BRANCH,
  GETCURRENTUSER,
  LOGINS,
  TOGGLE_AGENTS_BY_ID,
  USERS,
  USERS_LOGS,
  USER_BY_ID,
  USER_COGNITO_PASSWORD_BY_ID,
  USER_LOGINS_BY_ID,
} from "../../shared/routes/route.constants";
import { User } from "../../shared/types/user.type";
import { Client } from "../../shared/Utils/api-client";

const config = {
  name: "users",
};

export const fetchUsers: any = createAsyncThunk(
  `${config.name}/fetchUsers`,
  async (params: any, { getState }: any) => {
    const { currentPage, limit: _limit } = getState().user;
    const limit = params?.limit || _limit;
    return await Client.get(USERS, {
      params: { ...params, limit, offset: (currentPage - 1) * limit },
    });
  }
);

export const fetchUsersLogs: any = createAsyncThunk(
  `${config.name}/fetchUsersLogs`,
  async (userId: string) => {
    return await Client.get(USERS_LOGS(userId));
  }
);

export const fetchUserById: any = createAsyncThunk(
  `${config.name}/fetchUserById`,
  async ({ id, withUserAccess }: any) => {
    let params: any = {};
    if (withUserAccess) {
    }
    params["withUserAccess"] = true;
    return await Client.get(USER_BY_ID(id), { params });
  }
);
export const addNewUser: any = createAsyncThunk(
  `${config.name}/addNewUser`,
  async (data: User) => {
    return await Client.post(USERS, data);
  }
);
export const enableLogin: any = createAsyncThunk(
  `${config.name}/enableLogin`,
  async (data: any) => {
    return await Client.post(LOGINS, data);
  }
);
export const assignAccessGroup: any = createAsyncThunk(
  `${config.name}/assignAccessGroup`,
  async ({ id, data }: any) => {
    return await Client.post(ASSIGN_ACCESS_GROUP_BY_USER_ID(id), data);
  }
);
export const updateUserById: any = createAsyncThunk(
  `${config.name}/updateUserById`,
  async ({ id, data }: { id: string; data: User }) => {
    return await Client.put(USER_BY_ID(id), data);
  }
);
export const updateUserLoginById: any = createAsyncThunk(
  `${config.name}/updateUserLoginById`,
  async ({ id, data }: any) => {
    return await Client.post(USER_LOGINS_BY_ID(id), data);
  }
);
export const updateCognitoUserPasswordById: any = createAsyncThunk(
  `${config.name}/updateCognitoUserPasswordById`,
  async ({ id, data }: any) => {
    return await Client.post(USER_COGNITO_PASSWORD_BY_ID(id), data);
  }
);

export const fetchAgents: any = createAsyncThunk(
  `${config.name}/fetchAgents`,
  async (params: any) => {
    return await Client.get(AGENTS, { params });
  }
);
export const addAgent: any = createAsyncThunk(
  `${config.name}/addAgent`,
  async (data: string) => {
    return await Client.post(AGENTS, data);
  }
);
export const fetchAgentsById: any = createAsyncThunk(
  `${config.name}/fetchAgentsById`,
  async (id: string) => {
    return await Client.get(AGENTS_BY_ID(id), {});
  }
);

export const toggelPauseAgent: any = createAsyncThunk(
  `${config.name}/toggelPauseAgent`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.post(TOGGLE_AGENTS_BY_ID(id), data);
  }
);

export const fetchCurrentUser: any = createAsyncThunk(
  `${config.name}/fetchCurrentUser`,
  async (params: any) => {
    return await Client.get(GETCURRENTUSER, { params });
  }
);
export const unPauseShift: any = createAsyncThunk(
  `${config.name}/unPauseShift`,
  async (params: any) => {
    return await Client.post(GETCURRENTUSER, { ...params });
  }
);

export const fetchUsersBranch: any = createAsyncThunk(
  `${config.name}/fetchUsersBranch`,
  async (id: string) => {
    return await Client.get(FETCH_USER_BRANCH(id), {});
  }
);

export const addNewUserBranch: any = createAsyncThunk(
  `${config.name}/addNewUserBranch`,
  async (data: any) => {
    return await Client.post(ADD_USER_BRANCH, data);
  }
);

export const deleteUserBranch: any = createAsyncThunk(
  `${config.name}/deleteUserBranch`,
  async (id: string) => {
    return await Client.delete(DELETE_USER_BRANCH(id), {});
  }
);

export const editAgent: any = createAsyncThunk(
  `${config.name}/editAgent`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.post(AGENTTPATCH(id), data);
  }
);

export const userSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingUser: false,
    total: 0,
    limit: GLOBAL_LIST_LIMIT,
    currentPage: 1,
    offset: 0,
    savingAccessGroup: false,
    savingRole: false,
    user: null as User | null,
    error: "" as string,
    users: [] as User[],
    agents: [] as any,
    currentUser: [] as any,
    userBranches: [] as any,
  },

  reducers: {
    reset: (state) => {
      state.user = null;
      state.savingUser = false;
      state.loading = false;
      state.savingRole = false;
      state.savingAccessGroup = false;
      state.error = "";
      state.users = [];
      state.currentUser = [];
      state.userBranches = [];
    },
    setPage: (state, { payload }) => {
      state.currentPage = payload;
      // console.log(action.payload)
    },
  },
  extraReducers(builder) {
    builder
      // User List
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload?.data?.data?.items;
        state.limit = action.payload?.data?.data?.limit;
        state.total = action?.payload?.data?.data.total;
        state.offset = action?.payload?.data?.data.offset;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // User By Id
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.user = null;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.data;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // User create
      .addCase(addNewUser.pending, (state) => {
        state.savingUser = true;
        state.error = "";
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.savingUser = false;
        //console.log(action.payload);
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.savingUser = false;
        state.error = action.error.message;
      })

      // User create
      .addCase(updateUserById.pending, (state) => {
        state.savingUser = true;
        state.error = "";
      })
      .addCase(updateUserById.fulfilled, (state, action) => {
        state.savingUser = false;
        //console.log(action.payload);
      })
      .addCase(updateUserById.rejected, (state, action) => {
        state.savingUser = false;
        state.error = action.error.message;
      })
      // User create
      .addCase(assignAccessGroup.pending, (state) => {
        state.savingAccessGroup = true;
        state.error = "";
      })
      .addCase(assignAccessGroup.fulfilled, (state, action) => {
        state.savingAccessGroup = false;
      })
      .addCase(assignAccessGroup.rejected, (state, action) => {
        state.savingAccessGroup = false;
        state.error = action.error.message;
      })

      //  Fetch agent
      .addCase(fetchAgents.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action?.payload?.data?.data?.items;
        state.offset = action?.payload?.data?.data?.offset;
        state.total = action?.payload?.data?.data?.total;
        // state.pagination = {
        //   offset: action?.payload?.data?.data?.offset,
        //   limit: action?.payload?.data?.data?.limit,
        //   total: action?.payload?.data?.data?.total,
        // };
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // User By Id
      .addCase(fetchAgentsById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.agents = null;
      })
      .addCase(fetchAgentsById.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action.payload.data.data;
      })
      .addCase(fetchAgentsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // User's branches
      .addCase(fetchUsersBranch.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchUsersBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.userBranches = action?.payload?.data?.data;
      })
      .addCase(fetchUsersBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // // Agent Update
    // .addCase(toggelPauseAgent.pending, (state) => {
    //     state.agents = true
    //     state.error = ''
    // })
    // .addCase(toggelPauseAgent.fulfilled, (state, action) => {
    //     state.agents = false
    //     console.log(action.payload);
    // })
    // .addCase(toggelPauseAgent.rejected, (state, action) => {
    //     state.agents = false
    //     state.error = action.error.message
    // })
  },
});

export const { setPage } = userSlice.actions;

export default userSlice.reducer;
