import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateBranchById } from "../../../../redux/reducers/branch.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Branch } from "../../../../shared/types/branch.type";
import BranchForm from "./branch-form.page";

const BranchEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const branch: Branch = useSelector((state: RootState) => state.branch.branch);

  return (
    <DesktopFormLayout title={"Branch Edit"} subtitle={"Edit branch for .."}>
      <BranchForm
        onSubmit={async (values: Branch) => {
          await dispatch(updateBranchById({ id: branch.id, data: values })).then((data: any) => {
            if (data?.payload?.data?.statusCode == 200) {
              history.goBack();
            }
          });
        }}
      />
    </DesktopFormLayout>
  );
};

export default BranchEdit;
