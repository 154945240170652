import React from "react";
import Validations from "../../Views/EditComponents/Validations";
//import prescriptionService from "../../../Services/prescription.service";
import TypeAhead from "../../Common/FormFields/TypeAhead"// "../../../Common/FormFields/TypeAhead";
import {  fetchItemList } from '../../../../../../redux/reducers/prescription.slice'//'../../../../redux/reducers/prescription.slice';
//import { useDispatch, useSelector } from "react-redux";
import {Client} from "../../../../../../shared/Utils/api-client"//"../../shared/Utils/api-client";
import { PRESCRIPTION,PRESCRIPTION_TEMPLATES, PRESCRIPTION_TEMPLATE_GROUPS, PRESCRIPTION_TEMPLATE_URL_BY_ID,PRESCRIPTION_ITEM_LIST } from '../../../../../../shared/routes/route.constants';

class TypeAheadComponent extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options || null
        }
        // this.changeValue = this.changeValue.bind(this);
        
    }
    
    changeValue = (event) => {
        if (event.preventDefault) event.preventDefault();
        if (event.target.async) {
            let key = this.props.autoKey;
            let options = this.loadSSOptions(event.target.value, key);
            if (options !== -1) {
                this.setState({ options: options });
                // return;
            }
            this.asyncCall(event.target.value, key, options)
            this.props.emitTextboxValue({ name: event.target.name, value: event.target.value, type: 'typeahead', async: event.target.async })
            return;
        }
        if (this.findDataObjectInOptions(event.target.value)) {
            let data = this.getDataObject(event.target.value);
            if (!data) {
                this.props.emitTextboxValue({ name: event.target.name, value: event.target.value, type: 'typeahead', async: event.target.async })
            } else {
                this.props.emitTypeAheadSuggestionObj(data);
            }

        } else {
            this.props.emitTextboxValue({ name: event.target.name, value: event.target.value, type: 'typeahead', async: event.target.async })
        }
    }

    getDataObject(value) {
        if (!value) {
            return false
        }
        let limit = this.state.options.length > 5 && this.state.options.length < 10 ? this.state.options.length : this.state.options.length < 10 ? this.state.options.length : 10
        for (let i = 0; i < limit; i++) {
            if (this.state.options[i].value.startsWith(value, 0) && this.state.options[i].data) {
                return this.state.options[i].data;
            }
        }
    }

    findDataObjectInOptions(value) {
        if (!value || !this.state.options || this.state.options.length == 0) {
            return false;
        }
        let limit = this.state.options.length > 5 && this.state.options.length < 10 ? this.state.options.length : this.state.options.length < 10 ? this.state.options.length : 10
        for (let i = 0; i < limit; i++) {
            if (this.state.options[i].value.startsWith(value, 0) && this.state.options[i].data) {
                return true;
            }
        }
    }


    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    onFocus = () => {
        this.props.emitFocusEvent();
    }

    async asyncCall(str, key, options) {
        try {
            //let dispatch = useDispatch();
            const allowedTypes = ['patient', 'symptoms', 'disease', 'diagnosis', 'drugs', 'vitals', 'biomarkers', 'investigations'];
            let matchingTypeIdx = allowedTypes.findIndex(t => t.includes(key.toLowerCase()) || key.toLowerCase().includes(t));
            let allowedType = allowedTypes[matchingTypeIdx];
            if (matchingTypeIdx === -1) {
                if (('test required').includes(key.toLowerCase()) || key.toLowerCase().includes('test required')) {
                    allowedType = 'labtest';
                } else if (('complaint').includes(key.toLowerCase()) || key.toLowerCase().includes('complaint')) {
                    allowedType = 'symptoms';
                }
            }
            let res = await (Client.get(PRESCRIPTION_ITEM_LIST(allowedType,str),{}))// {}//await prescriptionService.fetchSuggestions(allowedType, 'suggestions', str);
            //console.log("asyncCall",res,res.data.message,res.data)
            if (res && res.data &&res.data.message === 'success' && res.data.data && res.data && res.data.data.length) {
                //console.log("res.data",res.data.data)
                let mappedData = res.data.data.map(v => ({ value: v.item_name }));
                let finalOptions = (options && options !== -1) ? options.concat(mappedData) : mappedData;
                let data = this.filterOptionsData(finalOptions);
                this.setState({ options: data });
            } else {
                this.setState({ options: [] });
            }
        } catch (err) {
            this.setState({ options: [] });
        }
    }

    filterOptionsData(optionsData) {
        let filteredData = {};
        for (let i = 0; i < optionsData.length; i++) {
            let indData = optionsData[i];
            if (!filteredData[indData.value]) {
                filteredData[indData.value] = indData;
            } else {
                if (indData.data) {
                    filteredData[indData.value]['data'] = indData.data;
                }
            }
        }
        let finalData = [];
        for(let key in filteredData){
            finalData.push(filteredData[key]);
        }
        return finalData;
    }

    loadSSOptions(str, autoKey) {
        let existingSuggestions = JSON.parse(sessionStorage.getItem('suggestions'))
        let autoComplete = JSON.parse(localStorage.getItem('autocomplete'));
        let newKey = autoKey;
        let autoOptions = [];
        let allKeys = []
        if (autoKey == 'complaints') {
            newKey = 'symptoms'
        }
        if (autoKey == "drug") {
            newKey = 'drugs'
        }

        let suggestionForSection = existingSuggestions && Object.keys(existingSuggestions).length > 0 ? existingSuggestions[newKey] : {};
        if (suggestionForSection)
            allKeys = Object.keys(suggestionForSection);
        let formattedStr = str.replace(/\s+/g, '').toLowerCase();
        let newOptions = allKeys.filter((e) => { return e.startsWith(formattedStr, 0) }).map(v => ({ value: suggestionForSection[v].result_t, data: suggestionForSection[v] }));
        if (autoComplete && autoComplete[newKey]) {
            autoComplete = autoComplete[newKey];
            autoOptions = autoComplete.filter((e) => { return e.startsWith(formattedStr, 0) }).map(v => ({ value: v }));
        }

        let allOptions = newOptions.concat(autoOptions);
        let jsonObject = allOptions.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        if (uniqueArray.length >= 1) {
            return uniqueArray;
        } else {
            return -1;
        }
    }

    render() {
        // console.log('-----------------------------------select---------------------------------');
        const { type, name, classname, placeholder, value, label, labelSize, size, options, offset, rowType, showType, async } = this.props;
        return (
            <React.Fragment>
                {this.props.viewType=='view'?<>
                <div className={`ant-col ant-col-${size*2} ${showType === "table" ? 'p-0' : ''}`} style={{ padding: rowType === "row" ? ' ' : '', margin: rowType === "row" ? ' ' : '' }}>
                        {value}
                        
                    </div>
                </>:<>
                
                    {labelSize && <label className={`ant-col ant-col-${labelSize*2}`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                        {label}</label>}
                    <div className={`ant-col ant-col-${size*2} ${showType === "table" ? 'p-0' : ''}`} style={{ padding: rowType === "row" ? ' ' : '', margin: rowType === "row" ? ' ' : '' }}>
                        {!labelSize && <label style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                            {label}</label>}
                        <TypeAhead async={async} class='no-class' value={value} name={name} emitTypedText={this.changeValue.bind(this)} placeholder={placeholder}
                            optionKey={'value'} optionValue={'value'} options={this.state.options || options} onFocus={this.onFocus.bind(this)} />
                    </div>
                    {offset &&
                        <div className={offset}> </div>
                    }
                </>}
            </React.Fragment>
        );
    }
}

export default TypeAheadComponent;
