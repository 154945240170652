import {Col, Row} from "antd";
type Props = {
    onSearch: (values: String) => void
}

function AdminHeader({onSearch}: Props) {
    return (
        <Row>
            <Col span={12}>
                {/* <SearchLayout placeholder="Search Patient" onSearch={(values: any) => onSearch(values)}/> */}
            </Col>
            <Col span={12} className="text-right" style={{marginTop: "-15px"}}>
                {/*<Select value={currentApplication}*/}
                {/*        onChange={(e) => dispatch(switchApplicationToken({application_id: e}))}>*/}
                {/*    {*/}
                {/*        applications.map((application: any) => {*/}
                {/*            return <Select.Option value={application.id}>{application.name}</Select.Option>*/}
                {/*        })*/}
                {/*    }*/}
                {/*</Select>*/}
            </Col>
        </Row>
    );
}

export default AdminHeader;
