import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DROPDOWN_MASTER_DATA_BY_ID,
  GET_CRM_TYPES_DATA,
  MASTER_CITIES,
  MASTER_DESIGNATIONS,
  MASTER_DROPDOW_LIST,
  MASTER_GENDER,
  MASTER_NATIONALITY,
  MASTER_PROCEDURE,
  MASTER_PROPERTY,
  MASTER_REGION,
  MASTER_RELIONSHIPS,
  MASTER_SPECIALITIES,
  MASTER_WORKING_DAY,
  TASK_CATEGORIES,
  TASK_DROP_DOWN,
  TASK_SUB_TYPES,
  TASK_TYPES,
} from "../../shared/routes/route.constants";
import { Client } from "../../shared/Utils/api-client";
const config = {
  name: "master",
};
export const fetchMasterDesignations: any = createAsyncThunk(
  `${config.name}/fetchMasterDesignations`,
  async () => {
    return await Client.get(MASTER_DESIGNATIONS);
  }
);

export const fetchMasterGender: any = createAsyncThunk(
  `${config.name}/fetchMasterGender`,
  async () => {
    return await Client.get(MASTER_GENDER);
  }
);

export const fetchMasterDropdownList: any = createAsyncThunk(
  `${config.name}/fetchMasterDropdownList`,
  async () => {
    return await Client.get(MASTER_DROPDOW_LIST);
  }
);

export const fetchMasterCities: any = createAsyncThunk(
  `${config.name}/fetchMasterCities`,
  async () => {
    return await Client.get(MASTER_CITIES);
  }
);
export const fetchMasterRegion: any = createAsyncThunk(
  `${config.name}/fetchMasterRegion`,
  async () => {
    return await Client.get(MASTER_REGION);
  }
);

export const fetchMasterNationality: any = createAsyncThunk(
  `${config.name}/fetchMasterNationality`,
  async () => {
    return await Client.get(MASTER_NATIONALITY);
  }
);

export const fetchMasterDays: any = createAsyncThunk(
  `${config.name}/fetchMasterDays`,
  async () => {
    return await Client.get(MASTER_WORKING_DAY);
  }
);

export const fetchRelationships: any = createAsyncThunk(
  `${config.name}/fetchRelationships`,
  async () => {
    return await Client.get(MASTER_RELIONSHIPS);
  }
);

export const fetchMasterProcedures: any = createAsyncThunk(
  `${config.name}/fetchMasterProcedures`,
  async () => {
    return await Client.get(MASTER_PROCEDURE);
  }
);

export const fetchMasterProperty: any = createAsyncThunk(
  `${config.name}/fetchMasterProperty`,
  async () => {
    return await Client.get(MASTER_PROPERTY);
  }
);

export const fetchMasterTaskTypes: any = createAsyncThunk(
  `${config.name}/fetchMasterTaskTypes`,
  async (params: any) => {
    return await Client.get(TASK_TYPES, { params });
  }
);

export const fetchMasterTaskSubTypes: any = createAsyncThunk(
  `${config.name}/fetchMasterTaskSubTypes`,
  async (params: any) => {
    return await Client.get(TASK_SUB_TYPES, { params });
  }
);
export const fetchMasterCategories: any = createAsyncThunk(
  `${config.name}/fetchMasterCategories`,
  async (params: any) => {
    return await Client.get(TASK_CATEGORIES, { params });
  }
);

export const fetchMasterTaskDropDown: any = createAsyncThunk(
  `${config.name}/fetchMasterTaskDropDown`,
  async (params: any) => {
    return await Client.get(TASK_DROP_DOWN, { params });
  }
);

export const setSearchValue: any = createAsyncThunk(
  `${config.name}/setSearchValue`,
  async (data: any) => {
    return data;
  }
);
export const getDropdownMasterData: any = createAsyncThunk(
  `${config.name}/getDropdownMasterData`,
  async ({ id }: { id: string }) => {
    return await Client.get(DROPDOWN_MASTER_DATA_BY_ID(id));
  }
);

export const fetchMasterSpecialities: any = createAsyncThunk(
  `${config.name}/fetchMasterSpecialities`,
  async () => {
    return await Client.get(MASTER_SPECIALITIES);
  }
);

export const getCrmTypesData: any = createAsyncThunk(
  `${config.name}/getCrmTypesData`,
  async ({ id }: { id: string }) => {
    return await Client.get(GET_CRM_TYPES_DATA(id));
  }
);

export const masterSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    error: "" as string,
    designations: [],
    gender: [],
    nationality: [],
    workingDays: [],
    procedures: [],
    properties: [],
    searchValue: null as any,
    taskTypes: [],
    taskSubTypes: [],
    taskCategories: [],
    taskDropDown: [],
    cities: [],
    relationships: [],
    dropdownMasterData: [],
    specialities: [],
    dropdownList:[] 
  },
  reducers: {
    designations: (state) => {
      state.loading = false;
      state.error = "";
      state.designations = [];
    },
    gender: (state) => {
      state.loading = false;
      state.error = "";
      state.gender = [];
    },
    nationality: (state) => {
      state.loading = false;
      state.error = "";
      state.nationality = [];
    },
    workingDays: (state) => {
      state.loading = false;
      state.error = "";
      state.workingDays = [];
    },
    procedure: (state) => {
      state.loading = false;
      state.error = "";
      state.procedures = [];
    },
    property: (state) => {
      state.loading = false;
      state.error = "";
      state.properties = [];
    },
    taskTypes: (state) => {
      state.loading = false;
      state.error = "";
      state.taskTypes = [];
    },
    taskSubTypes: (state) => {
      state.loading = false;
      state.error = "";
      state.taskSubTypes = [];
    },
    fetchMasterCategories: (state) => {
      state.loading = false;
      state.error = "";
      state.taskCategories = [];
    },
    taskDropDown: (state) => {
      state.loading = false;
      state.error = "";
      state.taskDropDown = [];
    },
    resetTaskTypesCascade: (state) => {
      state.taskTypes = [];
      state.taskSubTypes = [];
      state.taskCategories = [];
    },
    specialities: (state) => {
      state.loading = false;
      state.error = "";
      state.specialities = [];
    },
  },
  extraReducers(builder) {
    builder
      // Designation  List
      .addCase(fetchMasterDesignations.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterDesignations.fulfilled, (state, action) => {
        state.loading = false;
        state.designations = action?.payload?.data?.data;
      })
      .addCase(fetchMasterDesignations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // dropdownmaster data  List
      .addCase(getDropdownMasterData.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getDropdownMasterData.fulfilled, (state, action) => {
        state.loading = false;
        state.dropdownMasterData = action?.payload?.data?.data;
      })
      .addCase(getDropdownMasterData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Gender list
      .addCase(fetchMasterGender.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterGender.fulfilled, (state, action) => {
        state.loading = true;
        state.gender = action?.payload?.data?.data;
      })
      .addCase(fetchMasterGender.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Nationality list
      .addCase(fetchMasterNationality.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterNationality.fulfilled, (state, action) => {
        state.loading = true;
        state.nationality = action?.payload?.data?.data;
      })
      .addCase(fetchMasterNationality.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Working Daya
      .addCase(fetchMasterDays.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterDays.fulfilled, (state, action) => {
        state.loading = true;
        state.workingDays = action?.payload?.data?.data;
      })
      .addCase(fetchMasterDays.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Master Procedures
      .addCase(fetchMasterProcedures.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterProcedures.fulfilled, (state, action) => {
        state.loading = true;
        state.procedures = action?.payload?.data?.data;
      })
      .addCase(fetchMasterProcedures.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Master Property
      .addCase(fetchMasterProperty.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterProperty.fulfilled, (state, action) => {
        state.loading = true;
        state.properties = action?.payload?.data?.data;
      })
      .addCase(fetchMasterProperty.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Search Value
      .addCase(setSearchValue.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(setSearchValue.fulfilled, (state, action) => {
        state.loading = false;
        state.searchValue = action?.payload;
      })
      .addCase(setSearchValue.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Types List
      .addCase(fetchMasterTaskTypes.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterTaskTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.taskTypes = action?.payload?.data?.data;
      })
      .addCase(fetchMasterTaskTypes.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Sub Types List
      .addCase(fetchMasterTaskSubTypes.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterTaskSubTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.taskSubTypes = action?.payload?.data?.data;
      })
      .addCase(fetchMasterTaskSubTypes.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Category List
      .addCase(fetchMasterCategories.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.taskCategories = action?.payload?.data?.data;
      })
      .addCase(fetchMasterCategories.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Task Drop Downs
      .addCase(fetchMasterTaskDropDown.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterTaskDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.taskDropDown = action?.payload?.data?.data;
      })
      .addCase(fetchMasterTaskDropDown.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // Cities
      .addCase(fetchMasterCities.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action?.payload?.data?.data;
      })
      .addCase(fetchMasterCities.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // fetchRelationships
      .addCase(fetchRelationships.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchRelationships.fulfilled, (state, action) => {
        state.loading = false;
        state.relationships = action?.payload?.data?.data;
      })
      .addCase(fetchRelationships.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      // speciality list
      .addCase(fetchMasterSpecialities.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterSpecialities.fulfilled, (state, action) => {
        state.loading = true;
        state.specialities = action?.payload?.data?.data;
      })
      .addCase(fetchMasterSpecialities.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })


      
// Dropdown List
      .addCase(fetchMasterDropdownList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMasterDropdownList.fulfilled, (state, action) => {
        state.loading = true;
        state.dropdownList = action?.payload?.data?.data;
      })
      .addCase(fetchMasterDropdownList.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      
  },
});

export const { resetTaskTypesCascade } = masterSlice.actions;

export default masterSlice.reducer;
