import React from 'react';
import SectionViewComponent from '../Components/ProcedureFromView/Component/SectionViewComponent'//'../components/ProcedureFromView/Component/SectionViewComponent';
 
export default class PreviewComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.updatePreview;
    }

    render() {
        let obj = JSON.parse(JSON.stringify(this.props.previewObj));
        return (
            <>
                {obj.sections.length > 0 ?
                    <SectionViewComponent
                        viewType={""}
                        dynamicFormData={obj}
                        emitEventIfFormValueChange={() => { }}
                        isView={true}
                        template_id={this.props.template_id}
                        emitPartialPrescFormData={(e) => console.log(e)}>
                    </SectionViewComponent>
                    :
                    <div>No Sections added yet.</div>
                }
            </>
        );
    }
}
