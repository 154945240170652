function DoctorAppointmentBadge({ type, count, width, show }: any) {
  return (
    <span className="noborder font12">
      {type !== undefined ? (
        type.toLowerCase() === "consultation" ? (
          <img
            alt="inpersongreen"
            style={{ width: `${width}%`, marginTop: "-3px" }}
            src={require("../../assets/icons/inperson green.png").default}
          ></img>
        ) : type.toLowerCase() === "instant" ? (
          <img
            alt="vc blue"
            style={{ width: `${width}%`, marginTop: "-2px" }}
            className={"mt-5"}
            src={require("../../assets/icons/VC blue.png").default}
          ></img>
        ) : (
          <img
            alt="vc blue"
            style={{ width: `${width}%`, marginTop: "-2px" }}
            src={require("../../assets/icons/VC blue.png").default}
          ></img>
        )
      ) : (
        ""
      )}
      <span className={"doctor-primary-color fWeight400"}>
        {show
          ? type !== undefined &&
            (type.toLowerCase() === "consultation" ? (
              <>{" " + count + " In Hospital"}</>
            ) : type.toLowerCase() === "instant" ? (
              <>{" " + count + " Video Call"}</>
            ) : (
              <>{" " + count + " Video Call"}</>
            ))
          : ""}
      </span>
    </span>
  );
}

export default DoctorAppointmentBadge;
