const UploadIcon =({height="128" , width="128",color='#23BCB5'}) =>(
    <>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 500 500" className="uploadIcon"  xmlSpace="preserve">
            
            <path fill={color} className="st0" d="M448.1,448.3c0-2,0-3.9,0-5.7c0-63.5,0-127,0-190.4c0-9.5,3.1-17.3,11-22.9c8.4-5.9,17.4-6.5,26.4-2.2
                c8.8,4.2,13.4,11.7,14.5,21.3c0.1,1.3,0.1,2.6,0.1,3.9c0,67.7,0.1,135.4,0,203.1c0,18.8-8.2,33.2-25.7,40.9
                c-5.9,2.6-12.9,3.9-19.4,3.9c-46.8,0.3-93.5,0.2-140.3,0.2c-90.1,0-180.1,0-270.2,0c-18.8,0-32.8-8.2-40.7-25.2
                c-2.7-6-3.8-13.2-3.9-19.8c-0.3-51.7-0.2-103.4-0.2-155.1c0-16.6,0-33.1,0-49.7c0-11,5.1-19.1,14.9-23.6c10.3-4.7,20.2-3.1,28.6,4.7
                c5.8,5.3,8.2,12,8.2,19.9c-0.1,37.8,0,75.5,0,113.3c0,26.1,0,52.3,0,78.4c0,1.6,0,3.2,0,5.2C183.4,448.3,315.4,448.3,448.1,448.3z"
                />
            <path fill={color} className="st0" d="M223.8,88.5c-4.8,4.7-8.8,8.6-12.7,12.5c-13.6,13.5-27,27.1-40.7,40.5c-10.5,10.3-26.6,10.4-36.4,0.5
                c-10.5-10.5-10.5-26.5,0-37.1c28-28,56-55.9,84-83.9c4.7-4.7,9.3-9.5,14.2-14.1c9.8-9.2,24.5-9.8,34.3-0.5
                c14.8,14.1,29.1,28.7,43.6,43.2c17.9,17.8,35.7,35.8,53.6,53.6c11.7,11.6,12.7,26.3,2.5,37.6c-9.7,10.8-26.7,11.4-37,1.2
                c-16.8-16.6-33.5-33.4-50.2-50.1c-0.9-0.9-1.8-1.6-3.6-3.3c0,3,0,4.9,0,6.7c0,91.1,0,182.1,0,273.2c0,9.4-2.7,17.4-10.5,23.1
                c-8.3,6.1-17.3,6.9-26.3,2.6c-8.7-4.1-13.7-11.4-14.8-21.1c-0.2-1.9-0.1-3.8-0.1-5.7c0-90.9,0-181.8,0-272.8
                C223.8,93.1,223.8,91.4,223.8,88.5z"/>
        </svg>
    </>
);

export default UploadIcon;