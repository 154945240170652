import Countdown from "react-countdown";
type Props = {
    placeholder?: string,
    onCompletion?:(values:any) => void,
    time: number,
    currentTimeIndex:number
}


function TimerLayout({ placeholder,time,onCompletion,currentTimeIndex }: Props) {
 
    const Completionist = () => <span className={"bold cursor "} onClick={()=>onCompletion && onCompletion(true)}>{placeholder}</span>;
    const renderer = ({ minutes, seconds, completed }:any) => {
        if (completed) {
          // Render a completed state
          return <Completionist />;
        } else {
          // Render a countdown
          return <span>Resend OTP in : <span className="doctor-secondary-color">{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')} sec</span></span>;
        }
      };
      if(time === 0){
        return  <Completionist />
      }
      else{
        return <Countdown key={currentTimeIndex} date={Date.now() + (time * 1000)} renderer={renderer}></Countdown>
      }
}

export default TimerLayout;
