import { Col, Row, Select, Skeleton } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoctorAnalytics } from "../../../redux/reducers/appointment.slice";
import { RootState } from "../../../shared/constants";
import ColumnChartLayout from "../../../shared/layouts/Charts/column.layout";
import PieChartLayout from "../../../shared/layouts/Charts/pie.layout";
const { Option } = Select;

function DoctorAnalyticsPage() {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.profile);
  const { loading, analytics } = useSelector(
    (state: RootState) => state.appointment
  );
  const [status, setStatus] = useState([] as Array<any>);
  const [time, setTime] = useState(7 as any);
  const [appointmentCount, setAppointmentCount] = useState([] as Array<any>);
  useEffect(() => {
    dispatch(
      fetchDoctorAnalytics({
        doctor_id: profile.id,
        start_date: moment().subtract(time, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      })
    );
  }, []);

  const getTimeChart = (time: any) => {
    setTime(time);
    if (time === 0) {
      dispatch(fetchDoctorAnalytics({ doctor_id: profile.id }));
      return;
    }
    if (time === 1) {
      dispatch(
        fetchDoctorAnalytics({
          doctor_id: profile.id,
          end_date: moment().format("YYYY-MM-DD"),
          start_date: moment().format("YYYY-MM-DD"),
        })
      );
      return;
    }
    dispatch(
      fetchDoctorAnalytics({
        doctor_id: profile.id,
        end_date: moment().format("YYYY-MM-DD"),
        start_date: moment().subtract(time, "days").format("YYYY-MM-DD"),
      })
    );
  };

  useEffect(() => {
    let _status = [] as any;
    analytics &&
      analytics?.status_counts.map((item: any) => {
        return _status.push({
          type: item.latest_status,
          value: parseInt(item.appointment_count),
        });
      });
    setStatus(_status);
    let _appointment = [] as any;
    analytics &&
      analytics?.procedure_counts.map((item: any) => {
        return _appointment.push({
          type: item.procedure_name,
          value: parseInt(item.appointment_count),
        });
      });
    setAppointmentCount(_appointment);
  }, [analytics]);

  return (
    <Fragment>
      <Row gutter={20}>
        <Col span={20} className={"white-background padding20"}>
          <h1
            className={"doctor-primary-color bold"}
            style={{ marginLeft: "20px" }}
          >
            Analytics
          </h1>
        </Col>
        <Col span={4} className={"white-background padding20 text-right"}>
          <Select
            bordered={false}
            value={time}
            style={{ width: "150px" }}
            className={"doctor-secondary-color"}
            onChange={(evt) => getTimeChart(evt)}
          >
            <Option value={1}>Today</Option>
            <Option value={7}>Last 7 Days</Option>
            <Option value={15}>Last 15 Days</Option>
            <Option value={30}>Last 1 month</Option>
            <Option value={90}>Last 3 months</Option>
            {/* <Option value={0}>Over All</Option> */}
          </Select>
        </Col>
      </Row>
      <Row gutter={20} className={"padding20"}>
        <Col span={10} className="doctor-top-border  mt20">
          <Row>
            <Col span={24} className="doctor-color-heading2">
              <Row>
                <Col span={24} className={""}>
                  <span className="doctor-primary-color font15 bold">
                    Appointment by Status{" "}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="white-background padding20">
              {loading ? (
                <Skeleton></Skeleton>
              ) : (
                <ColumnChartLayout status={status}></ColumnChartLayout>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={10} className="doctor-top-border  mt20">
          <Row>
            <Col span={24} className="doctor-color-heading2">
              <Row>
                <Col span={24} className={""}>
                  <span className="doctor-primary-color font15 bold">
                    Appointment by Type
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="white-background padding20">
              {loading ? (
                <Skeleton></Skeleton>
              ) : (
                <PieChartLayout status={appointmentCount}></PieChartLayout>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default DoctorAnalyticsPage;
