
function PatientDesktopLayout({ children }: any) {
  return (
    <div className="full-height doctor-app">
      {
        children
      }
    </div>
  );
}

export default PatientDesktopLayout;
