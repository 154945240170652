import { Skeleton, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchUserById,
  updateUserById
} from "../../../../redux/reducers/user.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { User } from "../../../../shared/types/user.type";
import UserBranches from "./user-branches.component";
import UserForm from "./user-form";
import UserLogins from "./user-logins.component";
import UserAccess from "./user-roles.component";

const UserEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const [activeKey, setActive] = useState("1");
  const user: User = useSelector((state: RootState) => state.user.user);
  const authUser: User = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    if (RouteParams["id"]) {
      dispatch(fetchUserById( {id: RouteParams['id'], withUserAccess: true}));
    }
  }, []);
  return (
    <DesktopFormLayout
      title={"Users Edit"}
      subtitle={
        "Create  user information along with roles, branches, department,teams .."
      }
    >
      {user ? (
        <Tabs activeKey={activeKey} onChange={(e) => setActive(e)}>
          <Tabs.TabPane tab="User Info" key="1">
            <UserForm
              user={user}
              onSubmit={async (values: User) => {
                try {
                  await dispatch(updateUserById({ id: user.id, data: values }));
                  history.goBack();
                } catch (e) {}
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Access Group" key="2">
            {/*<PermissionComponent permission={'user_module.actions.assign_access_group'}>*/}
            <UserAccess
              user={user}
              refresh={() => {
                dispatch(fetchUserById( {id: RouteParams['id'], withUserAccess: true}));
              }}
            />
            {/*</PermissionComponent>*/}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Logins" key="3">
            {/*<PermissionComponent permission={'user_module.actions.assign_access_group'}>*/}
            <UserLogins
              user={user}
              refresh={() => {
                dispatch(fetchUserById( {id: RouteParams['id'], withUserAccess: true}));
              }}
            />
            {/*</PermissionComponent>*/}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Branches" key="4">
            {/*<PermissionComponent permission={'user_module.actions.assign_access_group'}>*/}
                <UserBranches user={user}/>
            {/*</PermissionComponent>*/}

          </Tabs.TabPane>
        </Tabs>
      ) : (
        <Skeleton active={true} />
      )}
    </DesktopFormLayout>
  );
};

export default UserEdit;
