import { Col, Row, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchDepartmentById,
  updateDepartmentById,
} from "../../../../redux/reducers/department.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import { Department } from "../../../../shared/types/department.type";
import DepartmentForm from "./department-form.page";

const DepartmentEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const department: Department = useSelector(
    (state: RootState) => state.department.department
  );
  useEffect(() => {
    if (RouteParams["id"]) {
      dispatch(fetchDepartmentById(RouteParams["id"]));
    }
  }, []);
  return (
    <DesktopFormLayout
      title={"Department Edit"}
      subtitle={"Edit Department  for .."}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Department" key="1">
          <Row>
            <Col span={12}>
              <DepartmentForm
                department={department}
                onSubmit={async (values: Department) => {
                  try {
                    await dispatch(
                      updateDepartmentById({ id: department.id, data: values })
                    );
                    history.goBack();
                  } catch (e) {}
                }}
              />
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </DesktopFormLayout>
  );
};

export default DepartmentEdit;
