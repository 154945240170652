import React, { useEffect } from 'react';
import { Button, Row, Space, Table, Select, Input } from 'antd';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../shared/constants";
import { fetchPharmacyOrderRequest } from '../../../../../redux/reducers/pharmacy.slice';
import moment from 'moment';
import { ROUTE_PHARMACY_CREATE_RX } from "../../../../../shared/routes/order.routes.constants";
const { Search } = Input
const { Option } = Select
type Props = {}


const RxTable: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const { appointments } = useSelector((state: RootState) => state.appointment);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPharmacyOrderRequest());
    }, [])

    const columns = [
        {
            title: 'Patient Name',
            key: 'patient_name',
            dataIndex: ['doctor', 'name'],
        },
        {
            title: 'Hospital/Doctor',
            dataIndex: ['doctor', 'name'],
        },
        {
            title: "Appt. Type",
            dataIndex: ['procedure_info', 'name'],
        },
        {
            title: "Order Placement Time",
            dataIndex: 'created_at',
            render: (key: any) => moment(key).format("DD MMM'YY,hh:mm A")
        },
        {
            title: "Order Type",
            dataIndex: 'latest_status',
        },
        {
            title: "No. of Meds",
            dataIndex: 'amount',
        },
        {
            title: "New Rx Required",
            dataIndex: ['patient', 'pii', 'mobile'],
        },
        {
            fixed: 'right' as 'right',
            width: '150px',
            title: 'Action',
            key: 'action',
            render: (a: any) => {
                return <Row gutter={10}>
                    <Space>
                        <Button type="primary" size={'middle'} onClick={ () => history.push(ROUTE_PHARMACY_CREATE_RX(a.id))}>Create Rx</Button>
                    </Space>
                </Row>;
            },
        },
    ];

    return (
      <div className='mt15'>
        <p>
          <Space>
            <Search style={{ width: 400 }} placeholder="Search by Patient Name, Ph No. or Order ID"></Search>
            <Select placeholder="Source">
                <Option value="">Source 1</Option>
            </Select>
            <Select placeholder="Type">
                <Option value="">Type 1</Option>
            </Select>
          </Space>
        </p>
        <Table
          className="mt20"
          columns={columns}
          dataSource={appointments} pagination={false} 
        />
      </div>
    );
};

export default RxTable;

