import {
  Button,
  Col,
  DatePicker,
  PageHeader,
  Row,
  Skeleton,
  Space,
  Tabs
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchDoctorSlots } from "../../../redux/reducers/doctor.slice";
import { RootState } from "../../../shared/constants";
import { ROUTE_DOCTOR_SLOT_CREATE } from "../../../shared/routes/doctor.routes.constants";
import SlotGrid from "./components/slot-grid";

const Slots: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const RouteParams: any = useParams();
  const dispatch = useDispatch();
  const { docter_slots_config, loading } = useSelector(
    (state: RootState) => state.doctor
  );
  useEffect(() => {
    dispatch(fetchDoctorSlots(RouteParams["id"]));
  }, [dispatch]);

  return (
    // <PermissionComponent permission={SLOT_MODULE_ACTIONS_READ}>
      <Row gutter={0}>
        <Col span={12}>
          <PageHeader
            style={{ paddingLeft: "0px", paddingTop: "0px" }}
            onBack={() => history.goBack()}
            title={"Go Back"}
            subTitle={""}
          />
        </Col>
        <Col span={12} className="text-right">
          {/* <PermissionComponent permission={SLOT_MODULE_ACTIONS_CREATE}> */}
            <Space>
              <DatePicker></DatePicker>
              <Button
                type="primary"
                onClick={async () =>
                  // history.push(ROUTE_DOCTOR_SLOT_CREATE(RouteParams["id"]))
                  history.push({
                    pathname: ROUTE_DOCTOR_SLOT_CREATE(RouteParams["id"]),
                    state: {
                      'doc_id': RouteParams["id"],
                      'mode': 'add'
                    },
                  })
                }
              >
                Add Slot
              </Button>
            </Space>
          {/* </PermissionComponent> */}
        </Col>
        <Col span={24}>
          <Tabs defaultActiveKey="2" className="custom-tabs">
            <Tabs.TabPane tab="Calender View" key="1"></Tabs.TabPane>
            <Tabs.TabPane tab="Grid View" key="2">
              {loading === true ? (
                <Skeleton></Skeleton>
              ) : (
                <Row>
                  {docter_slots_config.map((slot: any) => {
                    return (
                      <Col span={8} className="mt10">
                        <SlotGrid slot={slot} />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    // </PermissionComponent>
  );
};

export default Slots;
