import { useEffect } from "react";
import "./App.less";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./shared/constants";
import { fetchAccountByDomain } from "./redux/reducers/auth.slice";

function AccountRouteFetcher({ children }: any) {
  const history = useHistory();
  let routeListener = null;
  const dispatch: any = useDispatch();
  const RouteParams: any = useParams();
  const location: any = useLocation();
  const { account } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    setTimeout(() => {
      if (!account) {
        dispatch(fetchAccountByDomain());
      }
      routeListener = history.listen((location, action) => {
        checkRoute();
      });
    }, 100);
  }, []);

  const checkRoute = () => {
    if (account && !window.location.pathname.startsWith("/" + account?.slug)) {
      // notification.error({message: 'Didn\'t find the account in route, Going back'});
      // history.push('/' + (account?.slug || 'max'));
    }
  };
  useEffect(() => {
    checkRoute();
  }, [account, location]);
  if (!account) {
    return <div>Loading account</div>;
  }
  return children;
}

export default AccountRouteFetcher;
