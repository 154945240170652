import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Select, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  addNewSpecialization,
  deleteSpecialization,
  fetchSpecialization
} from "../../../../redux/reducers/doctor.slice";
import { fetchMasterSpecialities } from "../../../../redux/reducers/master.slice";
import { RootState } from "../../../../shared/constants";
import TableLayout from "../../../../shared/layouts/table.layout";

const DocterSpecialization: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const { Title } = Typography;
  const { Option } = Select;
  const { specialization, loading } = useSelector(
    (state: RootState) => state.doctor
  );
  const { specialities } = useSelector((state: RootState) => state.master);
  const [specializationModalVisible, setSpecializationModalVisible] =
    useState(false);
  const [selectedId, setSelectedId] = useState("" as string);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalText, setDeleteModalText] = useState("" as string);
  const [deleteSpcId, setDeleteSpcId] = useState("" as string);
  const [spcArr, setSpcArr] = useState([] as any);
  

  const columns: any = [
    {
      title: "Name",
      render: (key: any) =>
        key?.specialityMaster?.name ? key?.specialityMaster?.name : "-",
    },
    {
      title: "Code",
      render: (key: any) =>
        key?.specialityMaster?.code ? key?.specialityMaster?.code : "-",
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <Col>
              {
                <Button
                  shape="circle"
                  title={"Delete Specialization"}
                  icon={<CloseOutlined />}
                  onClick={() => {
                    openDeleteModal(a?.id);
                  }}
                  size={"middle"}
                />
              }
            </Col>
          </Row>
        );
      },
    },
  ];

  const getSpecialization = (id: string) => {
    dispatch(fetchSpecialization(id));
  };

  useEffect(() => {
    dispatch(fetchMasterSpecialities());
    getSpecialization(RouteParams?.id);
  }, []);

  const handleSpcId = (id: any) => {
    setSelectedId(id);
  };

  const handleSpecialization = async () => {
    let param = {
      speciality_id: selectedId,
      doctor_id: RouteParams?.id,
    };
    await dispatch(addNewSpecialization(param));
    await closeSpecializationModal();
    await getSpecialization(RouteParams?.id);
  };

  const closeSpecializationModal = () => {
    setSelectedId("");
    setSpecializationModalVisible(false);
  };

  const openDeleteModal = async (id: string) => {
    setDeleteModalVisible(true);
    setDeleteModalText("Are you sure, you want to delete this specialization.");
    setDeleteSpcId(id);
  };

  const handleDeleteModal = async () => {
    await dispatch(deleteSpecialization(deleteSpcId));
    await closeDeleteModal();
    await getSpecialization(RouteParams?.id);
  };

  const closeDeleteModal = () => {
    setDeleteModalText("");
    setDeleteModalVisible(false);
  };

  const openSpecializationModal = () => {
    const allSelected = specialization.map((val:any)=>{
        return val?.specialityMaster?.id
    })
    setSpcArr(specialities?.filter((val:any)=>{
        return allSelected.indexOf(val?.id) === -1
    }));
    setSpecializationModalVisible(true)
}

  return (
    <div className={"col-md-12 main-container"}>
      <Row>
        <Col span={22}>
          <Title level={4}>Specialization</Title>
        </Col>
        <Col span={2}>
          <Tooltip title="Add New Specialization">
            <Button
              type="primary"
              shape="circle"
              onClick={openSpecializationModal}
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
      <TableLayout
        loading={loading}
        dataSource={specialization}
        columns={columns}
        type="empty_filter"
      />

      <Modal
        title="Add Specialization"
        visible={specializationModalVisible}
        onOk={handleSpecialization}
        onCancel={closeSpecializationModal}
      >
        <Row>
          <Col span={24}>
            <Select onChange={handleSpcId} style={{ width: "80%" }}>
              {spcArr.map((speciality: any) => {
                return (
                  <Option value={speciality.id}>
                    {speciality.name + " (" + speciality.code + ")"}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Delete Specialization"
        visible={deleteModalVisible}
        onOk={handleDeleteModal}
        onCancel={closeDeleteModal}
      >
        {deleteModalText}
      </Modal>
    </div>
  );
};

export default DocterSpecialization;
