import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ALLTASKDATA,
  METADATA,
  METADATAVALUES,
  METATASKCATEGORY,
  METATASKCATEGORYPOST,
  METATASKCATEGORYUPDATE,
  METATASKSUBTYPE,
  METATASKSUBTYPEBYID,
  METATASKSUBTYPEPPOST,
  METATASKSUBTYPEUPDATE,
  METATASKTYPE,
  METATASKTYPEUPDATE,
  PATCH_METADATA_LABEL,
  PATCH_METADATA_VALUES,
} from "../../shared/routes/crm.routes.constants";
import { Client } from "../../shared/Utils/api-client";
const config = { name: "master" };

export const fetchLabelList: any = createAsyncThunk(
  `${config.name}/task-drop-down`,
  async (params: any) => {
    return await Client.get(METADATA, { params });
  }
);
export const addNewLabel: any = createAsyncThunk(
  `${config.name}/task-drop-down`,
  async (params: any) => {
    return await Client.post(METADATA, { ...params });
  }
);
export const patchNewLabel: any = createAsyncThunk(
  `${config.name}/PATCH_METADATA_LABEL`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(PATCH_METADATA_LABEL(id), data);
  }
);
//--------

export const addValues: any = createAsyncThunk(
  `${config.name}/task-drop-down-values`,
  async (params: any) => {
    return await Client.post(METADATAVALUES, { ...params });
  }
);
export const patchNewValues: any = createAsyncThunk(
  `${config.name}/PATCH_METADATA_VALUES`,
  async ({ id, data }: { id: string; data: any }) => {
    return await Client.patch(PATCH_METADATA_VALUES(id), data);
  }
);

// ---------

export const fetchTaskType: any = createAsyncThunk(
  `${config.name}/task-type`,
  async (params: any) => {
    return await Client.get(METATASKTYPE, { params });
  }
);
export const patchTaskType: any = createAsyncThunk(
  `${config.name}/task-type/update`,
  async (params: any) => {
    return await Client.post(METATASKTYPEUPDATE, { ...params });
  }
);
//----------
export const fetchTaskSubType: any = createAsyncThunk(
  `${config.name}/task-sub-type`,
  async (params: any) => {
    return await Client.get(METATASKSUBTYPE, { params });
  }
);

export const fetchTaskSubTypeById: any = createAsyncThunk(
  `${config.name}/fetchTaskSubTypeById`,
  async ({ id }: { id: string }) => {
    return await Client.get(METATASKSUBTYPEBYID(id));
  }
);
export const addTaskSubType: any = createAsyncThunk(
  `${config.name}/task-sub-type`,
  async (params: any) => {
    return await Client.post(METATASKSUBTYPEPPOST, { ...params });
  }
);
export const patchTaskSubType: any = createAsyncThunk(
  `${config.name}/task-sub-type/update`,
  async (params: any) => {
    return await Client.post(METATASKSUBTYPEUPDATE, { ...params });
  }
);
//--------
export const fetchTaskCategory: any = createAsyncThunk(
  `${config.name}/task-category`,
  async ({ id }: { id: string }) => {
    return await Client.get(METATASKCATEGORY(id));
  }
);
export const addTaskCategory: any = createAsyncThunk(
  `${config.name}/task-category`,
  async (params: any) => {
    return await Client.post(METATASKCATEGORYPOST, { ...params });
  }
);
export const patchTaskCategory: any = createAsyncThunk(
  `${config.name}/task-category/update`,
  async (params: any) => {
    return await Client.post(METATASKCATEGORYUPDATE, { ...params });
  }
);
//----------
//--------
export const fetchAllData: any = createAsyncThunk(
  `${config.name}/all-task-data`,
  async (params: any) => {
    return await Client.get(ALLTASKDATA, { params });
  }
);

export const metadataSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    metadata: [] as any,
    pagination: { offset: 0, limit: 10, total: 0 },
    error: "" as string,
    taskType: [] as any,
    taskSubType: [] as any,
    taskSubTypeById: [] as any,
    taskCategory: [] as any,
    allTaskData: [] as any,
  },
  reducers: {
    reset: (state) => {
      state.metadata = [];
      state.loading = false;
      state.pagination = { offset: 0, limit: 10, total: 0 };
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      //  Fetch metadata
      .addCase(fetchLabelList.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchLabelList.fulfilled, (state, action) => {
        state.loading = false;
        state.metadata = action?.payload?.data?.data;
        // state.pagination = {
        //     offset: action?.payload?.data?.data?.offset,
        //     limit: action?.payload?.data?.data?.limit,
        //     total: action?.payload?.data?.data?.total
        // };
      })
      .addCase(fetchAllData.fulfilled, (state, action) => {
        state.loading = false;
        state.allTaskData = action?.payload?.data?.data;
        // state.pagination = {
        //     offset: action?.payload?.data?.data?.offset,
        //     limit: action?.payload?.data?.data?.limit,
        //     total: action?.payload?.data?.data?.total
        // };
      })
      .addCase(fetchTaskType.fulfilled, (state, action) => {
        state.loading = false;
        state.taskType = action?.payload?.data?.data;
        // state.pagination = {
        //     offset: action?.payload?.data?.data?.offset,
        //     limit: action?.payload?.data?.data?.limit,
        //     total: action?.payload?.data?.data?.total
        // };
      })
      .addCase(fetchTaskSubType.fulfilled, (state, action) => {
        state.loading = false;
        state.taskSubType = action?.payload?.data?.data;
        // state.pagination = {
        //     offset: action?.payload?.data?.data?.offset,
        //     limit: action?.payload?.data?.data?.limit,
        //     total: action?.payload?.data?.data?.total
        // };
      })
      .addCase(fetchTaskSubTypeById.fulfilled, (state, action) => {
        state.loading = false;
        state.taskSubTypeById = action?.payload?.data?.data;
        // state.pagination = {
        //     offset: action?.payload?.data?.data?.offset,
        //     limit: action?.payload?.data?.data?.limit,
        //     total: action?.payload?.data?.data?.total
        // };
      })
      .addCase(fetchTaskCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.taskCategory = action?.payload?.data?.data;
        // state.pagination = {
        //     offset: action?.payload?.data?.data?.offset,
        //     limit: action?.payload?.data?.data?.limit,
        //     total: action?.payload?.data?.data?.total
        // };
      })
      .addCase(fetchLabelList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // .addCase(fetchContact.pending, (state) => {
    //     state.loading = true
    //     state.error = ''
    // })
    // .addCase(fetchContact.fulfilled, (state, action) => {
    //     state.loading = false
    //     state.metadata = action?.payload?.data?.data?.items;
    // })
    // .addCase(fetchContact.rejected, (state, action) => {
    //     state.loading = false
    //     state.error = action.error.message
    // })
  },
});

//export const {} = metadataSlice.actions

export default metadataSlice.reducer;
