const AddIcon =({height="18" , width="19",color='#23BCB5'}) =>(
    <>
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3384 18.4651H7.61292V10.8412H0.799316V8.33676H7.61292V0.970703H10.3384V8.33676H17.152V10.8412H10.3384V18.4651Z" fill={color} />
    </svg>

        
    </>
);

export default AddIcon;