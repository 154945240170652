import { Row,Col, Space } from "antd";
import {useHistory} from "react-router-dom";
import BackIcon from "../icons/BackIcon";


function GoBacklayout({heading,route}: any) {
    const history = useHistory();
    return (
      <Row>
          <Col span={24}>
            {
              route === undefined ?   <Space style={{ fontSize: "20px" }} onClick={()=> history.goBack()} className="cursor"> <BackIcon height="20"  /> {heading} </Space> :   <Space style={{ fontSize: "20px" }} onClick={()=> history.push(route)} className="cursor"> <BackIcon height="20"   /> {heading} </Space>
            }
          </Col>
      </Row>
    );
  }
  
  export default GoBacklayout;
  