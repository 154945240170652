import { useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { useState } from "react";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  Boolean(anchorEl);

  const handleLogout = (logoutType: string) => {
    setAnchorEl(null);

    if (logoutType === "popup") {
      instance
        .logoutPopup({
          // @ts-ignore
          mainWindowRedirectUri: "/login",
        })
        .then(() => {
          console.log("logout");
        });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };

  return (
    <div>
      <Button
        block
        className="submit doctor-secondary-button mt20"
        size={"large"}
        onClick={() => handleLogout("popup")}
      >
        Microsoft Logout
      </Button>
    </div>
  );
};
