import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { GET_CHAT_MESSAGES,GET_CHAT_ROOMS, GET_MULTIPLE_CHAT_ROOMS, MARK_READ_CHAT } from '../../shared/routes/route.constants';
import { USER_CHAT_SETTING } from '../../shared/routes/user.routes.constants';
import { Client as ClientV2 } from "../../shared/Utils/api-client-v2";
const axios = require('axios').default;


const config = {
    name: 'chat',
};
export const fetchMessages: any = createAsyncThunk(`${config.name}/fetchMessages`, async (data: any) => {
 return await axios({
        method: 'get',
        url: GET_CHAT_MESSAGES(data.patient_id,data.doctor_id,data.limit),
        headers: {
            'Authorization' : `Bearer ${localStorage.getItem('token')}`
        }
      })
})

export const fetchChatRooms: any = createAsyncThunk(`${config.name}/fetchChatRooms`, async (data: any) => {
    return await axios({
           method: 'get',
           url: GET_CHAT_ROOMS(data.user_type,data.limit,data.user_id,data.room_type),
           headers: {
               'Authorization' : `Bearer ${localStorage.getItem('token')}`
           }
         })
   })



export const fetchMultipleRooms: any = createAsyncThunk(`${config.name}/fetchMultipleRooms`, async (data: any) => {
    return await axios({
           method: 'post',
           url: GET_MULTIPLE_CHAT_ROOMS,
           data:data,
           headers: {
               'Authorization' : `Bearer ${localStorage.getItem('token')}`
           }
         })
   })

   export const chatSetting: any = createAsyncThunk(`${config.name}/chatSetting`, async (params:any) => {
    return await ClientV2.get(USER_CHAT_SETTING, {params})
})

export const getConnection: any = createAsyncThunk(`${config.name}/getConnection`, async (connection:any) => {
    return connection
})

export const markReadMessagePatient: any = createAsyncThunk(`${config.name}/markReadMessagePatient`, async (room_id:any) => {
    return await axios({
        method: 'put',
        url: MARK_READ_CHAT(room_id),
        headers: {
            'Authorization' : `Bearer ${localStorage.getItem('token')}`
        }
      })
})

export const chatSlice = createSlice({
    name: config.name,
    initialState: {
        room_loading: false,
        loading:false,
        messages: [] as Array<any>,
        error: '' as string,
        rooms: [] as any,
        ischatAllow:true as any,
        connection : null as any
    },
    reducers: {
        reset: (state) => {
            state.messages = []
            state.room_loading = false
            state.loading=false
            state.error = ''
            state.rooms = []
        }
    }, extraReducers(builder) {
        builder
            //  Fetch messages
            .addCase(fetchMessages.pending, (state) => {
                state.loading = true
                state.error = ''
            })
            .addCase(fetchMessages.fulfilled, (state, action) => {
                state.loading = false
                state.messages = action?.payload?.data?.result;
            })
            .addCase(fetchMessages.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })

  //  Fetch Rooms
            .addCase(fetchChatRooms.pending, (state) => {
                state.room_loading = true
                state.error = ''
            })
            .addCase(fetchChatRooms.fulfilled, (state, action) => {
                state.room_loading = false
                state.rooms = action?.payload?.data?.result;
            })
            .addCase(fetchChatRooms.rejected, (state, action) => {
                state.room_loading = false
                state.error = action.error.message
            })
            

            // fetchMultipleRooms
            .addCase(fetchMultipleRooms.pending, (state) => {
                state.room_loading = true
                state.error = ''
            })
            .addCase(fetchMultipleRooms.fulfilled, (state, action) => {
                state.room_loading = false
                state.rooms = action?.payload?.data?.result;
            })
            .addCase(fetchMultipleRooms.rejected, (state, action) => {
                state.room_loading = false
                state.error = action.error.message
            })

            // chatSetting
            .addCase(chatSetting.pending, (state) => {
                state.room_loading = true
                state.error = ''
            })
            .addCase(chatSetting.fulfilled, (state, action) => {
                state.room_loading = false
                state.ischatAllow = action?.payload?.data?.data?.enable_chat;
            })
            .addCase(chatSetting.rejected, (state, action) => {
                state.room_loading = false
                state.error = action.error.message
            })

            
            .addCase(getConnection.pending, (state) => {
                state.room_loading = true
                state.error = ''
            })
            .addCase(getConnection.fulfilled, (state, action) => {
                state.room_loading = false
                state.connection = action?.payload;
            })
            .addCase(getConnection.rejected, (state, action) => {
                state.room_loading = false
                state.error = action.error.message
            })
    }
})

export const {reset} = chatSlice.actions

export default chatSlice.reducer
