import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BRANCHES, BRANCH_BY_ID } from "../../shared/routes/route.constants";
import { Branch } from "../../shared/types/branch.type";
import { Client } from "../../shared/Utils/api-client";

// Change the Branches API Accordingly and remove this one done

const config = {
  name: "branches",
};
export const fetchBranches: any = createAsyncThunk(
  `${config.name}/fetchBranches`,
  async (params: any) => {
    return await Client.get(BRANCHES, { params });
  }
);

export const fetchBranchById: any = createAsyncThunk(
  `${config.name}/fetchBranchById`,
  async (id: string) => {
    return await Client.get(BRANCH_BY_ID(id), {});
  }
);
export const addNewBranch: any = createAsyncThunk(
  `${config.name}/addNewBranch`,
  async (data: Branch) => {
    return await Client.post(BRANCHES, data);
  }
);
export const updateBranchById: any = createAsyncThunk(
  `${config.name}/updateBranchById`,
  async ({ id, data }: { id: string; data: Branch }) => {
    return await Client.put(BRANCH_BY_ID(id), data);
  }
);
export const branchSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingBranch: false,
    branch: null as Branch | null,
    error: "" as string,
    branches: [] as Branch[],
  },
  reducers: {
    reset: (state) => {
      state.branch = null;
      state.savingBranch = false;
      state.loading = false;
      state.error = "";
      state.branches = [];
    },
    setBranch: (state, action) => {
      state.branch = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      // Branch List
      .addCase(fetchBranches.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchBranches.fulfilled, (state, action) => {
        state.loading = false;
        state.branches = action?.payload?.data?.data.items;
      })
      .addCase(fetchBranches.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Branch By Id
      .addCase(fetchBranchById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.branch = null;
      })
      .addCase(fetchBranchById.fulfilled, (state, action) => {
        state.loading = false;
        state.branch = action.payload?.data?.data;
      })
      .addCase(fetchBranchById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Branch create
      .addCase(addNewBranch.pending, (state) => {
        state.savingBranch = true;
        state.error = "";
      })
      .addCase(addNewBranch.fulfilled, (state, action) => {
        state.savingBranch = false;
      })
      .addCase(addNewBranch.rejected, (state, action) => {
        state.savingBranch = false;
        state.error = action.error.message;
      })

      // Branch create
      .addCase(updateBranchById.pending, (state) => {
        state.savingBranch = true;
        state.error = "";
      })
      .addCase(updateBranchById.fulfilled, (state, action) => {
        state.savingBranch = false;
      })
      .addCase(updateBranchById.rejected, (state, action) => {
        state.savingBranch = false;
        state.error = action.error.message;
      });
  },
});

export const { setBranch } = branchSlice.actions;

export default branchSlice.reducer;
