import { CaretDownOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tag,
  Typography
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  addNewAddress,
  addNewMedicine,
  fetchCity,
  fetchMedicineItems,
  fetchMedicinesById,
  fetchStationfromAddress,
  proceedQCRequest,
  updatePharmacyDetail
} from "../../redux/reducers/pharmacy.slice";
import { RootState } from "../../shared/constants";
import FormBar from "./formbar.layout";
import MedicineComponent from "./pharmacy-medicine.layout";

type Props = {
  request_id?: string;
  makeMedicineComponentEditable?: any;
  requestId?: string;
  handleEditMedicine?: any;
};

function MedicineOrderComponent(props: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { medicines, pharmacyDetails, medicine } = useSelector(
    (state: RootState) => state.pharmacy
  );

  const [isOpenAddNewAddress, setIsOpenAddNewAddress] = useState(false);
  const [medicineTtlPrice, setMedicineTotalPrice] = useState(0);
  const [medicineDiscountPrice, setMedicineDiscountPrice] = useState(0);
  const [showMedicineSearch, setShowMedicineSearch] = useState(false);
  // const [showServicesSearch, setShowServicesSearch] = useState(false);
  const [value, setValue] = useState("");
  const [medicineTotalCount, setMedicineTotalCount] = useState(0);
  // const [options, setOptions] = useState<{ value: string }[]>([]);
  const [cancelProceedModalVisible, setProceedRequestModalVisible] =
    useState(false);
  const [proceedModalAction, setProceedModalAction] = useState("");
  const [proceedModalText, setProceedModalText] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState(false as any);
  const [formData, setFormData] = useState(null as any);
  // const [ patientId, setPatientId ] = useState(null as any);
  const [cityId, setCityId] = useState(null as any);
  const [medicineArr, setMedicineArr] = useState([] as any);
  const [autoCompleteCityArr, setAutoCompleteCityArr] = useState<
    { value: string }[]
  >([]);
  const [newlyAddress, setNewlyAddress] = useState(null as any);
  const [isHideNewlyAddedAddressInput, setIsHideNewlyAddedAddressInput] =
    useState(false);
  const [medArr, setMedArr] = useState([] as any);
  //const [fetchAreas, setFetchAreas] = useState([] as any);
  // const [ addressFromMap, setAddressFromMap ] = useState(null as any);

  const { Text } = Typography;
  const { Option } = Select;
  const RouteParams: any = useParams();
  let request_id: string = "";

  if (props.request_id === "") {
    request_id = RouteParams["id"];
  } else {
    if (props.request_id) {
      request_id = props.request_id;
    }
  }
  const address = pharmacyDetails?.patient?.addresses;
  if (!props.requestId) {
    request_id = pharmacyDetails?.id;
  }

  const showAddMedicine = () => {
    setShowMedicineSearch(true);
  };

  // const showAddServices = () => {
  //   setShowServicesSearch(true);
  // }
  const medicineRXRequired = medicineArr?.filter((val: any) => {
    return val?.prescription_required === true;
  });
  const onCitySearch = async (searchText: string) => {
    if (searchText.length > 2) {
      let param = {
        search_keyword: searchText,
      };
      let cityList = await dispatch(fetchCity(param));
      setAutoCompleteCityArr(cityList?.payload?.data?.data);
    } else {
      setAutoCompleteCityArr([]);
    }
  };

  const onCitySelect = async (data: string) => {
    let selectedItem = autoCompleteCityArr.filter((item: any) => {
      return item.city === data;
    });
    let item = selectedItem[0];
    setCityId(item);
    setAutoCompleteCityArr([]);
  };

  const onSearch = (searchText: string) => {
    if (searchText.length > 3) {
      let param = {
        search_term: searchText,
        search_column: "item_name",
      };
      dispatch(fetchMedicineItems(param));
      setMedArr(medicines);
      // setOptions(medicines)
    } else {
      // setOptions([])
      setMedArr([]);
    }
  };

  useEffect(() => {
    const allSelected = medicine.map((val: any) => {
      return val?.item_id;
    });
    setMedArr(
      medicines?.filter((val: any) => {
        return allSelected.indexOf(val?.id) === -1;
      })
    );
  }, [medicines]);

  const onSelect = async (data: string) => {
    let selectedItem = medArr.filter((item: any) => {
      return item.item_name === data;
    });
    selectedItem = selectedItem[0];
    let finalPayload = {
      item_id: selectedItem.id,
      item_code: selectedItem.item_id,
      item_name: selectedItem.item_name,
      item_quantity: 1,
      station_id: pharmacyDetails.station_id,
      hospital_id: pharmacyDetails.hospital_id,
      item_price: selectedItem.item_price,
      request_id: request_id,
      prescription_required: selectedItem?.is_prescription === 0 ? false : true,
      request_date: moment().format("YYYY-MM-DD HH:MM:ss"),
    };
    try {
      await dispatch(addNewMedicine(finalPayload));
      await dispatch(fetchMedicinesById(request_id));
      setMedArr([]);
      setValue("");
      return;
    } catch (e) {}
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  const medicinePrice = (childData: any) => {
    setMedicineTotalPrice(childData.totalPrice);
    setMedicineTotalCount(childData.medicineCount);
  };

  const AutoOptions = medArr?.map((items: any) => (
    <Option key={items.id} value={items.item_name}>
      {items.item_name}
    </Option>
  ));

  const onFinish = async (values: any) => {
    values = {
      ...values,
      city_id: cityId?.id,
      latitude: 0,
      longitude: 0,
      is_primary: values.address_type === "home" ? true : false,
      patient_id: pharmacyDetails?.patient?.id,
    };
    if (!cityId?.id) {
      message.error("City is invalid, Please select city from dropdown", 4);
    } else {
      const address = await dispatch(addNewAddress(values));
      await dispatch(fetchMedicinesById(request_id));
      if (address?.payload?.data?.statusCode === 200) {
        setDeliveryAddress(address.payload.data.data.id);
        let param = {
          address:
            values?.address_line_1 +
            "," +
            values?.address_line_2 +
            "," +
            values?.locality +
            "," +
            values?.city +
            "," +
            values?.state +
            "," +
            values?.pincode,
        };
        await dispatch(fetchStationfromAddress(param)).then((res: any) => {
          let addrRes = res?.payload?.data?.data;
          if (addrRes?.id) {
            let params = {
              id: request_id,
              delivery_address_id: "",
              address: param.address,
            };
            dispatch(updatePharmacyDetail({ id: request_id, data: params }));
          }
        });
      }
      onCloseAddressModal();
    }
    // setNewlyAddress(values);
    // setIsHideNewlyAddedAddressInput(true);
  };

  const onFinishFailed = (errorInfo: any) => {};

  const onCloseAddressModal = () => {
    setIsOpenAddNewAddress(false);
  };

  useEffect(() => {
    setFormData({
      heading: "",
      onFinish: onFinish,
      onFinishFailed: onFinishFailed,
      initialValues: {
        address1: "",
        address2: "",
        locality: "",
        city: "",
        state: "",
        pincode: "",
        city_id: "",
        latitude: 0,
        longitude: 0,
        is_primary: true,
        patient_id: "",
      },
      buttonText: "Add",
      formElements: [
        {
          label: "Address Type",
          type: "Radio",
          name: "address_type",
          defaultValue: "home",
          options: [
            { value: "home", name: "Home" },
            { value: "work", name: "Work" },
            { value: "other", name: "Other" },
          ],
        },
        {
          label: "Address 1",
          type: "Input",
          name: "address_line_1",
          rules: [{ required: true, message: "Address1 is mandatory" }],
          colSpan: "12",
        },
        {
          label: "Address 2",
          type: "Input",
          name: "address_line_2",
          rules: [{ required: true, message: "Address2 is mandatory" }],
          colSpan: "12",
        },
        {
          label: "Locality/Colony",
          type: "Input",
          name: "locality",
          rules: [{ required: true, message: "Locality is mandatory" }],
          colSpan: "12",
        },
        {
          label: "City",
          type: "AutoComplete",
          name: "city",
          rules: [{ required: true, message: "City is mandatory" }],
          colSpan: "12",
          placeholder: "Enter City Name",
          onSelect: onCitySelect,
          onSearch: onCitySearch,
          AutoCompleteData: autoCompleteCityArr?.map((items: any) => (
            <Option key={items.id} value={items.city}>
              {items.city}
            </Option>
          )),
        },
        {
          label: "State",
          type: "Input",
          name: "state",
          rules: [{ required: true, message: "State is mandatory" }],
          colSpan: "12",
        },
        {
          label: "Pincode",
          type: "Input",
          name: "pincode",
          rules: [{ required: true, message: "Pincode is mandatory" }],
          colSpan: "12",
        },
      ],
    });
  }, [autoCompleteCityArr]);

  const closeCancelOrder = () => {
    setProceedRequestModalVisible(false);
  };

  const handleProceedModal = (action: string) => {
    setProceedModalAction(action);
    if (action === "next") {
      // setProceedModalText('Are you sure, you want to procced this request?')
      // setTimeout(() => {
      handleOrder("next");
      // }, 1000);
    } else {
      setProceedRequestModalVisible(true);
      setProceedModalText("Do you want to cancel this request?");
    }
  };

  const checkMedicineValidation = (items: any) => {
    for (let i = 0; i < items.length; i++) {
      if (!items[i].station_id) {
        return {
          action: false,
          validationFor: "pharmacy",
          message: "Please Select all Pharmacies",
        };
      }
      if (
        (items[i].prescription_required === true ||
          items[i].prescription_required === null) &&
        !items[i].rx_info
      ) {
        return {
          action: false,
          validationFor: "rx_value",
          message: "Please Select all Rx values",
        };
      }
    }
  };

  const handleOrder = async (type = "cancel" as any) => {
    try {
      let valCheck: any = checkMedicineValidation(medicineArr);
      if (proceedModalAction === "next" || type === "next") {
        if (medicine.length === 0) {
          message.error("Please add some medicine", 4);
        } else if (!deliveryAddress) {
          message.error("Please add delivery address", 4);
        } else if (valCheck && valCheck.validationFor === "rx_value") {
          message.error(valCheck.message, 4);
        } else if (valCheck && valCheck.validationFor === "pharmacy") {
          message.error(valCheck.message, 4);
        } else {
          let param = {
            id: request_id,
            status: "qc_done",
            delivery_address_id: deliveryAddress,
          };
          let param2 = {
            total_amount: Math.ceil(medicineTtlPrice + medicineDiscountPrice),
          };
          await dispatch(
            updatePharmacyDetail({ id: request_id, data: param2 })
          );
          await dispatch(proceedQCRequest(param));
          await dispatch(fetchMedicinesById(request_id));
          let payload = {
            pharmacyDetails,
            action: "",
          };
          props?.handleEditMedicine(payload);
        }
      } else {
        let param = {
          id: request_id,
          status: "cancelled",
          delivery_address_id: deliveryAddress,
        };
        dispatch(proceedQCRequest(param));
        history.goBack();
      }
      setProceedRequestModalVisible(false);
    } catch (e) {}
  };

  const handleChooseAddress = (e: any) => {
    let selectedAddress = e ? e : null;
    setDeliveryAddress(selectedAddress);
    if (selectedAddress === "newAddress") {
      setIsOpenAddNewAddress(true);
      // setPatientId(pharmacyDetails?.patient?.id);
    } else {
      let selectedItem = address?.filter((item: any) => {
        return item.id === selectedAddress;
      });
      if (selectedItem?.length > 0) {
        selectedItem = selectedItem[0];
        setDeliveryAddress(selectedAddress);
        let param = {
          address:
            selectedItem.address_line_1 +
            "," +
            selectedItem.address_line_2 +
            "," +
            selectedItem.locality +
            "," +
            selectedItem.city +
            "," +
            selectedItem.state +
            "," +
            selectedItem.pincode,
        };

        dispatch(fetchStationfromAddress(param)).then((res: any) => {
          let addrRes = res?.payload?.data?.data;
          if (addrRes?.id) {
            let params = {
              id: request_id,
              delivery_address_id: selectedAddress,
              address: param.address,
            };
            dispatch(updatePharmacyDetail({ id: request_id, data: params }));
          }
        });
      }
    }
  };

  const medicineArrCallBack = (childData: any) => {
    setMedicineArr(childData);
  };

  const hideNewlyAddedAddressInput = () => {
    setIsHideNewlyAddedAddressInput(false);
  };

  useEffect(() => {
    if (pharmacyDetails?.delivery_address_id && deliveryAddress === false) {
      handleChooseAddress(pharmacyDetails?.delivery_address_id);
    }
  }, [pharmacyDetails]);

  // const onAreaSearch = (searchText: string) => {
  //   console.log('eeeee 11111', searchText)
  //   // if(searchText.length > 2){
  //     let param = {
  //       search_term: searchText,
  //     }
  //     fetch(
  //       "https://jsonplaceholder.typicode.com/users")
  //         .then((res) => res.json())
  //         .then((json) => {
  //             console.log('eeeee 33333', json)
  //             setFetchAreas(json);
  //         })
  //   // }
  // };

  // const onAreaSelect = (val: any, data:any) => {
  //   console.log('eeeee 2222', data)
  //   setAddressFromMap(data.address)
  // };

  // const onAreaChange = (data: string) => {
  //   console.log('eeeee 99999', data)
  // };

  // const AutoAreaOptions = fetchAreas?.map( (items:any) => (
  //   <Option key={items.id} value={items.name} address={items.address}>
  //     {items.name}
  //   </Option>
  // ));

  return (
    <div className={"main-container white-background"}>
      <div className="pd20">
        <Row>
          <Text className="sub-heading">Medicines Order Details</Text>
        </Row>
        <Row>
          <Text type="secondary">
            Order Time:
            {moment(pharmacyDetails?.created_at).format("DD MMM'YY hh:mm A ")}
          </Text>
        </Row>
        <Row>
          <Col span={24} className="mt10 select-address">
            <Text className="sub-heading">Delivery Address</Text>
            {!isHideNewlyAddedAddressInput ? (
              <Row className="mt5">
                {/* <Select style={{width: '40%'}} allowClear defaultValue={newlyAddress?.address_line_1 ? newlyAddress?.address_line_1 + ', '+ newlyAddress?.address_line_2 + ', '+ newlyAddress?.locality + ', '+ newlyAddress?.city + ', '+ newlyAddress?.state + ', '+ newlyAddress?.pincode : ''} onChange={(evt:any)=> handleChooseAddress(evt)} placeholder="Select Address"> */}
                <Select
                  style={{ maxWidth: "700px", width: "80%", minWidth: "400px" }}
                  allowClear
                  value={deliveryAddress}
                  defaultValue={
                    pharmacyDetails?.delivery_address_id
                      ? pharmacyDetails?.delivery_address_id
                      : null
                  }
                  onChange={(evt: any) => handleChooseAddress(evt)}
                  placeholder="Select Address"
                >
                  {address?.map((indRow: any, index: any) => (
                    <Option key={indRow?.id} value={indRow?.id}>
                      {indRow?.address_line_1}, {indRow?.address_line_2},{" "}
                      {indRow?.locality}, {indRow?.city}, {indRow?.state},{" "}
                      {indRow?.pincode}
                    </Option>
                  ))}
                  <Option value="newAddress">
                    <span style={{ color: "044a93" }}>+ Add New Address</span>
                  </Option>
                </Select>
              </Row>
            ) : (
              <Row className="mt5">
                <Input
                  style={{ width: "40%" }}
                  value={
                    newlyAddress?.address_line_1
                      ? newlyAddress?.address_line_1 +
                        ", " +
                        newlyAddress?.address_line_2 +
                        ", " +
                        newlyAddress?.locality +
                        ", " +
                        newlyAddress?.city +
                        ", " +
                        newlyAddress?.state +
                        ", " +
                        newlyAddress?.pincode
                      : ""
                  }
                  onClick={hideNewlyAddedAddressInput}
                  suffix={
                    <CaretDownOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  }
                />
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <Divider className="mt10 mb-10" />
      <Row className="mt20">
        <MedicineComponent
          requestId={request_id}
          deliveryAddress={deliveryAddress}
          medicineTotalPrice={medicinePrice}
          medicineArrCallBack={medicineArrCallBack}
          makeMedicineComponentEditable={props.makeMedicineComponentEditable}
        />

        {deliveryAddress ? (
          <Row className="mt20 fts12 full-width pdl20">
            <Col span={12}>
              {showMedicineSearch === false ? (
                <a href="#top" onClick={showAddMedicine}>
                  <u>+Add Medicines</u>
                </a>
              ) : (
                <Row>
                  <Col span={24}>
                    <Text className="sub-heading">
                      Add New Medicine
                      {/* From  */}
                    </Text>
                  </Col>
                  <AutoComplete
                    value={value}
                    // options={medArr}
                    allowClear={true}
                    dataSource={AutoOptions}
                    style={{ width: 300 }}
                    onSelect={onSelect}
                    onSearch={onSearch}
                    onChange={onChange}
                    placeholder="Search Medicine Name"
                    className="mt5"
                  />
                </Row>
              )}
            </Col>
            {/* <Col span={12} className="pdl20" >
              {
                showServicesSearch === false ? 
                <a onClick={showAddServices}><u>+Add services</u></a> : 
                <Row>
                  <Text className="sub-heading">Add New Service</Text>
                  <Input placeholder="Search Service Name" className="mt5"/>
                </Row>
              }
            </Col> */}
          </Row>
        ) : (
          <Row className="mt20 fts12 full-width pdl20">
            <Col>
              <Text className="sub-heading">
                Please select Delivery Address to add medicine
              </Text>
            </Col>
          </Row>
        )}
      </Row>
      <Divider />
      <Row className="mt20 full-width sub-heading pdl20">
        <Col span={20}>
          <Text>Total Price</Text>
        </Col>
        <Col span={4}>
          <Text>Rs. {Math.round(medicineTtlPrice)}</Text>
        </Col>
        <Col span={20}>
          <Text>Delivery Charges</Text>
        </Col>
        <Col span={4}>
          <Text>Rs. {medicineDiscountPrice}</Text>
        </Col>
        <Col span={20}>
          <Text className="fts16">Total Amount to be Paid</Text>
        </Col>
        <Col span={4}>
          <Text className="fts16">
            Rs. {Math.round(medicineTtlPrice + medicineDiscountPrice)}
          </Text>
        </Col>
      </Row>
      <Row className="mt20 full-width sub-heading pd20">
        <Col span={24}>
          {/* <Tag>{medicineTotalCount} Medicines in Order | 2 Medicine Require Prescription</Tag> */}
          <Tag>
            {medicineTotalCount} Medicines in Order{" "}
            {medicineRXRequired?.length > 0 &&
              `| ${medicineRXRequired?.length} Medicine Require Prescription`}
          </Tag>
        </Col>
        <Col className="mt15" span={24}>
          <Button
            onClick={() => {
              handleProceedModal("cancel");
            }}
          >
            Cancel Order
          </Button>
          <Button
            onClick={() => {
              handleProceedModal("next");
            }}
            className="mrg10"
            type="primary"
          >
            Next
          </Button>
        </Col>
      </Row>

      {/* Add New Address Drawer */}
      <Drawer
        title={`Add New Address`}
        placement="right"
        width={"50%"}
        // footer={<Space>
        //   <Button type="primary" > Add </Button>
        //   <Button onClick={() => setIsOpenAddNewAddress(false)}>Cancel</Button>
        // </Space>}
        onClose={() => {
          setIsOpenAddNewAddress(false);
          setDeliveryAddress(null);
        }}
        visible={isOpenAddNewAddress}
      >
        <Row className="nomargin pd10">
          <Col span={24} className="grey-background padding20">
            {/* <Row>
                  <AutoComplete
                    value={value}
                    // options={medArr}
                    allowClear={true}
                    dataSource={AutoAreaOptions}
                    style={{ width: '100%' }}
                    onSelect={(val, option) => onAreaSelect(val, option)}
                    onSearch={onAreaSearch}
                    onChange={onAreaChange}
                    placeholder="Search Area"
                    className="mt15"
                  />
                </Row>
                <MapComponent
                selectedAddr = {addressFromMap}
                /> */}
            <Row className="mt20">
              {formData && (
                <FormBar
                  name={"update_ticket"}
                  initialValues={formData.initialValues}
                  heading={formData.heading}
                  onFinish={formData.onFinish}
                  onFinishFailed={formData.onFinishFailed}
                  formElements={formData.formElements}
                  buttonText={formData.buttonText}
                ></FormBar>
              )}
            </Row>
          </Col>
        </Row>
      </Drawer>
      <Modal
        visible={cancelProceedModalVisible}
        onOk={handleOrder}
        onCancel={closeCancelOrder}
      >
        {proceedModalText}
      </Modal>
    </div>
  );
}

export default MedicineOrderComponent;
