import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GLOBAL_LIST_LIMIT } from "../../shared/constants";
import {
  PERMISSIONS,
  PERMISSION_BY_ID,
} from "../../shared/routes/route.constants";
import { Client } from "../../shared/Utils/api-client";

const initialState: any = {
  permissions: [],
  loading: false,
  total: 0,
  offset: 0,
  limit: GLOBAL_LIST_LIMIT,
  error: null,
};

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async (
    params: any = {
      limit: GLOBAL_LIST_LIMIT,
      offset: 0,
    }
  ) => {
    return await Client.get(PERMISSIONS, { params });
  }
);

export const addNewPermission: any = createAsyncThunk(
  "permissions/addNewPermission",
  async (initialPermission) => {
    return await Client.post(PERMISSIONS, initialPermission);
  }
);

export const deletePermission: any = createAsyncThunk(
  "permissions/addNewPermission",
  async (id: string) => {
    return await Client.delete(PERMISSION_BY_ID(id));
  }
);

const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    permissionUpdates(state, action) {},
    setLimit(state, action) {
      state.limit = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload?.data?.data;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Add new Permission wala
      .addCase(addNewPermission.fulfilled, (state, action) => {
        //console.log(action.payload);
      })
      .addCase(addNewPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { permissionUpdates } = permissionSlice.actions;

export default permissionSlice.reducer;
