import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Row, Skeleton, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { validateAppointmentDeepLink } from "../redux/reducers/appointment.slice";
import { attemptOTPLogin } from "../redux/reducers/auth.slice";
import MaxHealthLogoIcon from "../shared/icons/MaxHealthLogoIcon";
import MaxLogoIcon from "../shared/icons/MaxLogoIcon";
import { getAge } from "../shared/Utils/utilities";
const { Text } = Typography;

function DeepLinkAppointment() {
  const RouteParams: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [appointment, setAppointment] = useState(null as any);
  const [loading, setLoading] = useState(true);
  const [isError, setError] = useState(false as any);
  const [link, setlink] = useState("");

  useEffect(() => {
    (async function () {
      try {
        const getToken = await dispatch(
          validateAppointmentDeepLink(RouteParams["id"])
        );

        if (getToken.payload.status === 200) {
          setAppointment(getToken.payload?.data?.data?.appointmentInfo);
          setlink(getToken.payload?.data?.data?.static_app_download_link);
          setLoading(false);
        }
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!loading && !isError && appointment?.id) {
      try {
        let link = document.getElementById("isMobile2");
        if (
          (navigator && navigator?.platform === "iPhone") ||
          navigator?.platform === "iPod" ||
          navigator?.platform === "iPad" ||
          navigator?.platform === "MacPPC" ||
          navigator?.platform === "MacIntel" ||
          navigator.userAgentData?.mobile
        ) {
          link?.click();
          return;
        }
      } catch (e) {}
    }
  }, [loading, isError, appointment]);

  const sendOTP = async () => {
    const sendOTP = await dispatch(
      attemptOTPLogin({
        username: appointment?.patient?.pii?.mobile,
        account_id: appointment?.account_id,
        country_code: "+91",
        kind: "patient",
      })
    );
    if (sendOTP.payload.status === 201 || sendOTP.payload.status === 200) {
      sessionStorage.setItem("_patient_id", appointment?.patient_id);
      history.push(
        `/max/patient/login?sessionId=${sendOTP.payload?.data?.data.session_id}&number=${appointment?.patient?.pii?.mobile}`
      );
    }
  };

  return (
    <Row
      className={"padding20 full-height secondary-color-background"}
      justify="center"
    >
      <Col span={6} xs={24} md={6}>
        <MaxLogoIcon width="125" />
      </Col>

      {loading ? (
        <Col span={12} className={"text-center"}>
          <Skeleton className="mt50"></Skeleton>
        </Col>
      ) : isError ? (
        <Col
          span={12}
          className="white-background border-radius5 mt50 text-center"
          style={{ paddingBottom: "0px" }}
        >
          <h4 className="mt20">
            <b>
              <Text type="danger">Not Valid Room</Text>
            </b>
          </h4>
          <h4 className="doctor-primary-color mt20">
            <b>
              Download Max Healthcare Patient App <br />
            </b>
          </h4>
          <p className="doctor-primary-color mt20">
            {" "}
            <MaxHealthLogoIcon height="50" />
          </p>
          <Button
            size="large"
            className="doctor-secondary-button mt50"
            style={{ width: "200px" }}
            href={link}
            target={"_blank"}
          >
            Download App
          </Button>{" "}
          <br />
          <img
            style={{ width: "100%" }}
            alt="public-page"
            src={require("../assets/images/public-page.png").default}
          />
        </Col>
      ) : (
        <Col md={12} xs={24} className={"text-center"}>
          <a
            href={
              `maxadhd://appointment/${appointment?.id}/${appointment?.patient_id}/patient` +
              `/${appointment?.patient_id}`
            }
            id={"isMobile2"}
            target={"_self"}
          />
          <h2 className="doctor-secondary-color capitalize mb0">
            <b> {appointment?.procedure_info?.name} with</b>{" "}
          </h2>
          <h2 className="doctor-primary-color mt-10 capitalize">
            {appointment?.doctor?.name}
          </h2>
          <Row>
            <Col
              span={24}
              className="white-background border-radius5  padding20"
            >
              <h4 className="doctor-primary-color capitalize">
                <b>Appointment Details</b>
              </h4>
              <p className="doctor-primary-color capitalize">
                <UserOutlined /> Patient:{" "}
                {appointment?.patient?.pii?.first_name}{" "}
                {appointment?.patient?.pii?.last_name} (
                {getAge(appointment?.patient?.dob)},{" "}
                {appointment?.patient?.genderMaster?.name} ){" "}
              </p>
              <p className="doctor-primary-color mt-10 ">
                {" "}
                <ClockCircleOutlined />{" "}
                {moment(appointment?.appointment_date).format("MMMM Do YYYY")},
                {appointment?.start_time}{" "}
              </p>
            </Col>

            <Col
              span={24}
              className="white-background border-radius5 mt20 "
              style={{ paddingBottom: "0px" }}
            >
              <h4 className="doctor-primary-color mt20">
                <b>
                  Download Max MyHealth app <br />
                </b>
              </h4>
              <p className="doctor-primary-color mt20">
                <MaxHealthLogoIcon height="50" />
              </p>
              <Button
                size="large"
                className="doctor-secondary-button "
                style={{ width: "200px" }}
                href={link}
                target={"_blank"}
              >
                Download App
              </Button>{" "}
              <br />
              {appointment?.procedure_info?.name !== "consultation" && (
                <div className="mt15">
                  <Text
                    underline
                    className="doctor-secondary-color font12 cursor mt10"
                    onClick={() => sendOTP()}
                  >
                    Continue in Web Browser
                  </Text>
                </div>
              )}
              <img
                alt="public-page"
                style={{ width: "100%" }}
                src={require("../assets/images/public-page.png").default}
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col span={6}></Col>
    </Row>
  );
}

export default DeepLinkAppointment;
