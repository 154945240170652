export const ROUTE_USERS = (account: string) => `/${account}/admin/users`
export const ROUTE_USER_CREATE = '/admin/users/create'
export const ROUTE_USER_ACTION = (id: string, mode: string) => `/admin/users/${id}/${mode}`
export const ROUTE_ADMIN_DASHBOARD = (account: string) => `/${account}/admin/dashboard`;
export const ROUTE_ROLES = '/admin/roles'
export const ROUTE_ROLE_CREATE = '/admin/roles/create'
export const ROUTE_ROLE_ACTION = (id: string, mode: string) => `/admin/roles/${id}/${mode}`
export const ROUTE_REQUEST_LOGS = (account: string) => `/${account}/admin/request-logs`
export const ROUTE_TRIGGER_LOGS = (account: string) => `/${account}/admin/request-trigger-logs`
export const ROUTE_TP_SYNC_FAILED_APPS = (account: string) => `/${account}/admin/sync-failed-appointments-tp`
export const ROUTE_THB_SYNC_FAILED_APPS = (account: string) => `/${account}/admin/sync-failed-appointments-thb`
export const ROUTE_REQUEST_LOG_ACTION = (account: string, id: string, mode: string) => `/${account}/admin/request-logs/${id}/${mode}`
export const ROUTE_BRANCHES = '/admin/branches'
export const ROUTE_BRANCH_CREATE = '/admin/roles/branches'
export const ROUTE_BRANCH_ACTION = (id: string, mode: string) => `/admin/branches/${id}/${mode}`
export const ROUTE_DEPARTMENTS = '/admin/departments'
export const ROUTE_DEPARTMENT_CREATE = '/admin/departments/create'
export const ROUTE_DEPARTMENT_ACTION = (id: string, mode: string) => `/admin/departments/${id}/${mode}`
export const ROUTE_TEAMS = '/admin/teams'
export const ROUTE_TEAM_CREATE = '/admin/roles/teams'
export const ROUTE_TEAM_ACTION = (id: string, mode: string) => `/admin/teams/${id}/${mode}` 
export const ROUTE_PERMISSIONS = '/admin/permissions'
export const ROUTE_APPOINTMENTS = '/admin/appointment/search/patient'
export const ROUTE_BOOK_APPOINTMENTS = '/admin/appointment/book'
export const ROUTE_APPOINTMENTS_HISTORY = '/admin/appointment/history'
export const ROUTE_DOCTOR = '/admin/doctors'
export const ROUTE_GENERAL_SETTINGS = '/admin/settings'
export const ROUTE_ADMIN_STATS = '/admin/stats'
export const ROUTE_REGIONS = "/admin/regions";
export const ROUTE_REGION_CREATE = '/admin/create-region'
export const ROUTE_REGION_ACTION = (id: string, mode: string) => `/admin/region/${id}/${mode}`
export const ROUTE_CMS_DATA = '/admin/cms-data'

export const ROUTE_DOCTOR_CREATE = (id: string) => `/user/${id}/doctor/create`
export const ROUTE_DOCTOR_ACTION = (id: string, mode: string) => `/user/doctor/${id}/${mode}`
export const ROUTE_DOCTOR_SPECIALIZATION = (id: string) => `/user/${id}/doctor/specialization`
export const ROUTE_DOCTOR_PROPERTIES = (id: string) => `/user/${id}/doctor/properties`
//// APIS
export const FETCH_GENERAL_SETTINGS_BY_TYPE = 'setting'
export const UPDATE_GENERAL_SETTINGS_BY_ID = (id: string) => `setting/update/${id}`;
export const FETCH_STATS = (data: any) => `tpresponse/stats/get/${data.type}/${data.date}`;
export const FETCH_CMS_DATA = `maxdata/doctor-cms-data`;
export const UPDATE_CMS_DATA = `maxdata/doctor-cms-data`;

