import { EditFilled, InfoOutlined } from "@ant-design/icons";
import { Button, Collapse, Row, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPermissions } from "../../../redux/reducers/permission.slice";
import {
  fetchAccessGroups,
  fetchAllModules,
  fetchAllScopeValues,
  fetchRoles,
} from "../../../redux/reducers/role.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import {
  ACCESS_GROUP_MODULE_ACTIONS_READ,
  ACCESS_GROUP_MODULE_ACTIONS_UPDATE,
} from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import DesktopListLayout from "../../../shared/layouts/desktop-list.layout";
import {
  ROUTE_ACCESS_GROUP_ACTION,
  ROUTE_ACCESS_GROUP_CREATE,
} from "../../../shared/routes/route.constants";

const { Panel } = Collapse;

const { Text } = Typography;
type Props = {};

const AccessGroupList: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { accessGroups, pagination, loading } = useSelector(
    (state: RootState) => state.role
  );
  const { account } = useSelector((state: RootState) => state.auth);
  const [selectedRole, setSelectedRole] = useState(null as any);
  const [scope_values, setScopeValues] = useState({} as any);
  const [roleId, setRoleId] = useState(""); //accessGroup?.role_id
  const [hoverId, sethoverId] = useState();

  const [mainData, setmainData] = useState([] as any);

  const {
    role: { roles, modules, allScopeValues, loading: loadingAccessGroups },
    user: { savingRole },
    permission: { permissions, loading: loadingPermissions },
    auth: { applications },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(fetchAccessGroups());
    dispatch(fetchAllModules());
    dispatch(fetchRoles());
    dispatch(fetchPermissions({}));
  }, []);

  useEffect(() => {
    const _role = getRoleById(roleId);
    setSelectedRole(_role);
    if (_role?.scopes?.length) {
      dispatch(fetchAllScopeValues({ scopes: _role?.scopes.join(",") }));
    }
  }, [roleId]);

  useEffect(() => {
    if (accessGroups && hoverId) {
      let data = accessGroups.filter((item: any) => {
        return item.id === hoverId;
      });
      if (data.length !== 0) {
        setmainData(data[0]);
      }
    }
  }, [hoverId]);

  const callPermission = (data: any, e: any) => {
    sethoverId(data.id);
    setRoleId(data.role_id);
    const _role = getRoleById(data.role_id);
    setSelectedRole(_role);
    if (_role?.scopes?.length) {
      dispatch(fetchAllScopeValues({ scopes: _role?.scopes.join(",") }));
    }
  };

  const getRoleById = (roleId: string) => {
    return roles.find((role: any) => role.id === roleId);
  };

  useEffect(() => {
    if (selectedRole) {
      // console.log("scope_values",scope_values)

      if (!scope_values.actions) {
        const actions = Object.keys(permissions.moduleSpecific).map((key) => {
          return {
            title: key,
            key: permissions.moduleSpecific[key].name,
            children: [
              ...Object.keys(permissions.moduleSpecific[key].actions).map(
                (k) => {
                  return {
                    title: k,
                    key: `${permissions.moduleSpecific[key].name}.actions.${permissions.moduleSpecific[key].actions[k]}`,
                  };
                }
              ),
              ...Object.keys(permissions.global).map((k) => {
                return {
                  title: k,
                  key: `${permissions.moduleSpecific[key].name}.actions.${permissions.global[k]}`,
                };
              }),
            ],
          };
        });
        const assignedActions = selectedRole.actions;
        let actionSpecific: any[] = [];
        assignedActions.forEach((a: string) => {
          actions.forEach((aInner) => {
            if (aInner.key === a) {
              actionSpecific = [
                ...actionSpecific,
                ...aInner.children.map((c) => c.key),
              ];
            } else {
              aInner.children.forEach((c) => {
                if (c.key === a) {
                  actionSpecific.push(a);
                }
              });
            }
          });
        });
        setScopeValues({
          ...scope_values,
          ["actions"]: actionSpecific,
          ["actionSpecific"]: scope_values["actionSpecific"] || {},
          ["moduleSpecific"]: scope_values["moduleSpecific"] || {},
        });
      }
    }
  }, [selectedRole, permissions, roleId]);

  const columns = [
    {
      title: "S.No",
      key: "sNo",
      render: (a: any, value: any, index: number) => (
        <Text>{index + 1 + pagination.offset}</Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Role",
      dataIndex: ["role", "name"],
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Module Permissions/Actions",
      width: "40%",
      render: (a: any) => (
        <>
          <div className="accessGroup">
            <Tooltip
              placement="right"
              overlayStyle={{
                maxHeight: "300px",
                maxWidth: "500px",
                minWidth: "350px",
                overflowY: "scroll",
              }}
              title={
                <>
                  <Collapse>
                    <Panel header="Global Scope Values" key={1}>
                      <ul>
                        {allScopeValues?.length > 0 &&
                          allScopeValues
                            ?.filter((s: any) => s.data?.isGlobal)
                            ?.map(({ scope, values, data }: any) => {
                              return (
                                <li>
                                  <b>{data.name.toUpperCase()}: </b>{" "}
                                  {mainData &&
                                    mainData["scope_values"] &&
                                    mainData["scope_values"][data.name]?.map(
                                      (item: any) => {
                                        return values.map((bName: any) => {
                                          return bName.id === item
                                            ? bName.name + ","
                                            : "";
                                        });
                                      }
                                    )}
                                </li>
                              );
                            })}
                      </ul>
                    </Panel>
                    <Panel header="Module Specific Scope Values" key={2}>
                      <Collapse>
                        {modules?.length > 0 &&
                          modules?.map((item: any, i: any) => {
                            return (
                              <Panel header={item.key} key={i}>
                                <ul>
                                  {allScopeValues?.length > 0 &&
                                    allScopeValues?.map(
                                      (aitems: any, ii: any) => {
                                        return (
                                          <li>
                                            <b>
                                              {aitems.scope.toUpperCase()} :{" "}
                                            </b>
                                            {mainData?.scope_values
                                              ?.moduleSpecific &&
                                            item.key.toLowerCase() in
                                              mainData?.scope_values
                                                ?.moduleSpecific &&
                                            aitems.scope in
                                              mainData?.scope_values
                                                ?.moduleSpecific[
                                                item.key.toLowerCase()
                                              ]
                                              ? Array.isArray(
                                                  mainData?.scope_values
                                                    ?.moduleSpecific[
                                                    item.key.toLowerCase()
                                                  ][aitems.scope]
                                                )
                                                ? mainData?.scope_values?.moduleSpecific[
                                                    item.key.toLowerCase()
                                                  ][aitems.scope].map(
                                                    (itemss: any) => {
                                                      return (
                                                        allScopeValues?.length >
                                                          0 &&
                                                        allScopeValues?.map(
                                                          ({
                                                            scope,
                                                            values,
                                                            data,
                                                          }: any) => {
                                                            return (
                                                              values.length >
                                                                0 &&
                                                              Array.isArray(
                                                                values
                                                              ) &&
                                                              values.map(
                                                                (
                                                                  bName: any
                                                                ) => {
                                                                  if (
                                                                    (aitems.scope.toLowerCase() ===
                                                                      "user_type" ||
                                                                      aitems.scope.toLowerCase() ===
                                                                        "user_kind") &&
                                                                    (scope.toLowerCase() ===
                                                                      "user_type" ||
                                                                      scope.toLowerCase() ===
                                                                        "user_kind")
                                                                  ) {
                                                                    return (
                                                                      itemss +
                                                                      ","
                                                                    );
                                                                  } else {
                                                                    return bName.id ===
                                                                      itemss
                                                                      ? bName.name +
                                                                          ","
                                                                      : "";
                                                                    // && (bName.scope === aitems.scope)
                                                                  }
                                                                }
                                                              )
                                                            );
                                                          }
                                                        )
                                                      );
                                                    }
                                                  )
                                                : mainData?.scope_values
                                                    ?.moduleSpecific[
                                                    item.key.toLowerCase()
                                                  ][aitems.scope]
                                              : ""}
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </Panel>
                            );
                          })}
                      </Collapse>
                    </Panel>
                    <Panel header="Action Specific Scope Values" key={3}>
                      <Collapse>
                        {modules?.map((item: any, i: any) => {
                          return (
                            <Panel header={item.key} key={i}>
                              {mainData?.scope_values?.actions?.map(
                                (mainDataitem: any, ind: any) => {
                                  const modName = mainDataitem.split(".");
                                  return modName[0].toLowerCase() ===
                                    item.key.toLowerCase() ? (
                                    <ul>
                                      <li>{mainDataitem}</li>
                                    </ul>
                                  ) : (
                                    ""
                                  );
                                }
                              )}
                            </Panel>
                            // <Panel header={item.key} key={i}>
                            //     <ul>
                            //         {
                            //             accessGroups[0]?.role?.scopes?.map((aitems.scope: any, ii: any) => {
                            //                 return (
                            //                     <li>{aitems.scope}</li>
                            //                 )
                            //             })
                            //         }
                            //     </ul>
                            // </Panel>
                          );
                        })}
                      </Collapse>
                    </Panel>
                  </Collapse>
                </>
              }
            >
              <Button
                onMouseEnter={(e) => {
                  callPermission(a, e);
                }}
              >
                <InfoOutlined />
              </Button>
            </Tooltip>
          </div>
        </>
      ),
    },
    {
      fixed: "right" as "right",
      width: "150px",
      title: "Action",
      key: "action",
      render: (a: any) => {
        return (
          <Row gutter={10}>
            <PermissionComponent
              permission={ACCESS_GROUP_MODULE_ACTIONS_UPDATE}
            >
              <Button
                onClick={() =>
                  history.push(
                    ROUTE_ACCESS_GROUP_ACTION(account?.slug, a.id, "edit")
                  )
                }
                shape="circle"
                icon={<EditFilled />}
                size={"middle"}
              />
            </PermissionComponent>
          </Row>
        );
      },
    },
  ];
  useEffect(() => {
    getAccessGroupList();
  }, []);
  const onPageChange = (e: number) => {
    getAccessGroupList(e - 1);
  };
  const getAccessGroupList = (page: any = null) => {
    dispatch(
      fetchAccessGroups({
        limit: 10,
        offset: page !== null ? 10 * page : pagination.offset,
      })
    );
  };

  return (
    <div className={"col-md-12 main-container"}>
      <PermissionComponent permission={ACCESS_GROUP_MODULE_ACTIONS_READ}>
        <DesktopListLayout
          columns={columns}
          dataSource={accessGroups}
          enableAddRoute={ROUTE_ACCESS_GROUP_CREATE(account?.slug)}
          loading={loading}
          total={pagination.total}
          onPageChange={onPageChange}
          take={10}
          currentPage={pagination.offset / 10 + 1}
          title={"Access Groups"}
        />
      </PermissionComponent>
    </div>
  );
};

export default AccessGroupList;
