import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchAccessGroupById,
  updateAccessGroupById,
} from "../../../../redux/reducers/role.slice";
import { RootState } from "../../../../shared/constants";
import DesktopFormLayout from "../../../../shared/layouts/page-header.layout";
import AccessGroupForm from "./access-group-form.page";

const AccessGroupEdit: React.FunctionComponent<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const RouteParams: any = useParams();
  const accessGroup: any = useSelector(
    (state: RootState) => state.role.accessGroup
  );

  useEffect(() => {
    if (RouteParams["id"]) {
      dispatch(fetchAccessGroupById({id: RouteParams["id"], withDetails: true}));
    }
  }, []);
  console.log(accessGroup);

  return (
    <DesktopFormLayout
      title={"Access Group Edit"}
      subtitle={"Edit access group for .."}
    >
      <AccessGroupForm
        accessGroup={accessGroup}
        onSubmit={async (values: any) => {
          try {
            const response = await dispatch(
              updateAccessGroupById({
                id: accessGroup.id,
                data: {
                  ...values,
                },
              })
            );
            if (!response?.error) {
              history.goBack();
            }
          } catch (e) {}
        }}
      />
    </DesktopFormLayout>
  );
};

export default AccessGroupEdit;
