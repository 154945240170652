import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPatientsByNumber } from "../../../redux/reducers/patients.slice";
import PermissionComponent from "../../../shared/components/permission.component";
import {
  APPOINTMENT_MODULE_ACTIONS_CREATE,
  APPOINTMENT_MODULE_ACTIONS_READ,
  PATIENT_MODULE_ACTIONS_CREATE_PATIENT,
} from "../../../shared/components/permission.constants";
import { RootState } from "../../../shared/constants";
import TabsLayout from "../../../shared/layouts/tabs.layout";
import { ROUTE_BOOK_APPOINTMENTS } from "../../../shared/routes/admin.routes.constants";
import PatientTransactions from "../Patient/components/patient-transactions";
import PatientDetails from "../Patient/patient.page";
const { Title } = Typography;
const { Option } = Select;

const AppointmentList: React.FunctionComponent<any> = () => {
  const { searchValue } = useSelector((state: RootState) => state.master);
  const [patient, addPatient] = useState(false);
  const history = useHistory();
  const [intialStage, setIntialStage] = useState(true);
  const { Patients, loading } = useSelector(
    (state: RootState) => state.patient
  );
  const dispatch = useDispatch();
  const [selectedPatient, setSelectedPatient] = useState(null as any);
  useEffect(() => {
    GetDetails();
  }, [searchValue]);

  const GetDetails = () => {
    if (searchValue !== null) {
      setIntialStage(false);
      dispatch(fetchPatientsByNumber(searchValue));
    }
  };

  var appointmentTabs = new Array() || [];
  appointmentTabs = [
    {
      title: "Transaction",
      component: <PatientTransactions title={""} />,
    },
    {
      title: "3 Open Requests",
      component: "",
    },
    {
      title: "Promotional Communication",
      component: "",
    },
  ];

  const BookAppointment = () => {
    history.push({
      pathname: ROUTE_BOOK_APPOINTMENTS,
      state: Patients[0],
    });
  };

  const AddPatient = () => {
    addPatient(true);
    setIntialStage(false);
  };

  if (loading) {
    return <Skeleton></Skeleton>;
  } else {
    return (
      <Row>
        <PermissionComponent permission={APPOINTMENT_MODULE_ACTIONS_READ}>
          {intialStage === true ? (
            <Col span="24" className="full-height center-item">
              <p className="text-center mt100">
                <img
                  src={
                    require("../../../assets/icons/no result found.png").default
                  }
                ></img>
              </p>
              <p className="text-center mt20 primary-color-text bold">
                Search a patient to view their details
              </p>
            </Col>
          ) : (
            <Fragment>
              {patient === true ? (
                <Col span={24} className="mt20">
                  <PatientDetails
                    title={"Patient Details"}
                    data={[]}
                    mode={"edit"}
                  />
                </Col>
              ) : Patients.length === 0 ? (
                <Col span={24}>
                  <p className="text-center mt100">
                    <img
                      src={
                        require("../../../assets/icons/no result found.png")
                          .default
                      }
                    ></img>
                    <p className="text-center mt20 primary-color-text bold">
                      No Patient Exist in Max Records with Phone Number{" "}
                      <span className="sub-color-text">"{searchValue}"</span>
                    </p>
                  </p>
                  <p className="text-center">
                    <PermissionComponent
                      permission={PATIENT_MODULE_ACTIONS_CREATE_PATIENT}
                    >
                      <Button
                        onClick={() => AddPatient()}
                        className="text-center mt20"
                        type="primary"
                      >
                        Add Patient
                      </Button>
                    </PermissionComponent>
                  </p>
                </Col>
              ) : (
                <Fragment>
                  <Col span={24}>
                    <Title level={2}>
                      {Patients.length} Patient found for{" "}
                      <span className="sub-color-text">"{searchValue}"</span>
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Select
                      size="middle"
                      style={{ width: "30%" }}
                      placeholder="Choose Patient"
                      value="1"
                    >
                      {Patients &&
                        Patients.map((patient: any) => {
                          return (
                            <Option value={patient?.id}>
                              <Space
                                split={<Divider type="vertical"></Divider>}
                              >
                                <span>{patient.name} (28,M)</span>
                                <span>Cash</span>
                              </Space>
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                  <Col span={24} className="mt20">
                    <PermissionComponent
                      permission={APPOINTMENT_MODULE_ACTIONS_CREATE}
                    >
                      <Card>
                        <Row gutter={40}>
                          <Col span={20}>
                            <Select
                              size="middle"
                              style={{ width: "100%" }}
                              placeholder="Choose Patient"
                              value="1"
                            >
                              <Option value="1">Book Appointment</Option>
                            </Select>
                          </Col>
                          <Col span={4}>
                            <Button
                              type="primary"
                              block
                              onClick={() => BookAppointment()}
                            >
                              Book
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </PermissionComponent>
                  </Col>

                  <Col span={24} className="mt20">
                    {
                      <PatientDetails
                        title={"Patient Details"}
                        data={Patients && Patients[0]}
                        mode={"view"}
                      />
                    }
                  </Col>
                  <Col span={24} className="mt20">
                    <TabsLayout AllTabs={appointmentTabs}></TabsLayout>
                  </Col>
                </Fragment>
              )}
            </Fragment>
          )}
        </PermissionComponent>
      </Row>
    );
  }
};

export default AppointmentList;
