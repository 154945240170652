import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//import {Branch} from "../../shared/types/branch.type";
import {
  REQUEST_LOGS,
  REQUEST_LOG_BY_ID,
  TRIGGER_REQUEST_LOGS,
  _REQUEST_LOGS_USER_INFO
} from "../../shared/routes/route.constants";
import { Client } from "../../shared/Utils/api-client";

// Change the Branches API Accordingly and remove this one done

const config = {
  name: "requestLogs",
};
export const fetchRequestLogs: any = createAsyncThunk(
  `${config.name}/fetchRequestLogs`,
  async (params: any, { getState }: any) => {
    const { currentPage, limit } = getState().requestLogs;
    //console.log(currentPage,limit)
    return await Client.get(REQUEST_LOGS, {
      params: { ...params, limit, offset: (currentPage - 1) * limit },
    });
  }
);

export const fetchRequestLogById: any = createAsyncThunk(
  `${config.name}/fetchRequestLogById`,
  async (id: string) => {
    return await Client.get(REQUEST_LOG_BY_ID(id), {});
  }
);

export const fetchTriggerRequestLogs: any = createAsyncThunk(
  `${config.name}/fetchTriggerRequestLogs`,
  async (params: any) => {
    return await Client.get(TRIGGER_REQUEST_LOGS, { params });
  }
);

export const getLogsUserInfo: any = createAsyncThunk(
  `${config.name}/getLogsUserInfo`,
  async (params: any) => {
    return await Client.get(_REQUEST_LOGS_USER_INFO, { params });
  }
);

export const requestLogsSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    requestLog: null as any | null,
    error: "" as string,
    total: 0,
    limit: 20,
    offset: 0,
    currentPage: 1,
    requestLogs: [] as any[],
    data_source: "" as string,
    lastEvaluatedKey: null as any
  },
  reducers: {
    reset: (state) => {
      state.requestLog = false;
      state.loading = false;
      state.error = "";
      state.requestLogs = [];
    },
    setPage: (state, { payload }) => {
      //console.log(payload)
      state.currentPage = payload;
    },
  },
  extraReducers(builder) {
    builder
      // Branch List
      .addCase(fetchRequestLogs.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchRequestLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.requestLogs = action?.payload?.data?.data?.items;
        state.total = action?.payload?.data?.data?.total;
        state.offset = action?.payload?.data?.data?.offset;
        state.data_source = action?.payload?.data?.data?.data_source;
        state.lastEvaluatedKey = action?.payload?.data?.data?.lastEvaluatedKey;
      })
      .addCase(fetchRequestLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Branch By Id
      .addCase(fetchRequestLogById.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.requestLog = null;
      })
      .addCase(fetchRequestLogById.fulfilled, (state, action) => {
        state.loading = false;
        state.requestLog = action.payload?.data?.data;
      })
      .addCase(fetchRequestLogById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPage } = requestLogsSlice.actions;

export default requestLogsSlice.reducer;
