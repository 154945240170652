import { Button, Form, Input, Select, Skeleton } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBranches } from "../../../../redux/reducers/branch.slice";
import { fetchTeamTypes } from "../../../../redux/reducers/team.slice";
import { fetchUsers } from "../../../../redux/reducers/user.slice";
import { RootState } from "../../../../shared/constants";
import { Branch } from "../../../../shared/types/branch.type";
import { Team } from "../../../../shared/types/team.type";
import { User } from "../../../../shared/types/user.type";
import { getFullName } from "../../../../shared/Utils/utilities";

const { Option } = Select;
type Props = {
  onSubmit: (values: Team) => void;
  team?: Team;
};
const TeamForm: React.FunctionComponent<Props> = ({ team, onSubmit }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { savingTeam, loading } = useSelector((state: RootState) => state.team);
  const { branches } = useSelector((state: RootState) => state.branch);
  const { teamTypes } = useSelector((state: RootState) => state.team);
  const { users } = useSelector((state: RootState) => state.user);
  const RouteParams: any = useParams();
  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(fetchTeamTypes());
    dispatch(fetchUsers());
  }, []);
  if (loading || (RouteParams["id"] && !team)) {
    return <Skeleton />;
  }

  return (
    <Form
      name="basic"
      labelAlign={"left"}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 6 }}
      className="mt20"
      initialValues={team || {}}
      onFinish={(values: Team) => {
        onSubmit(values);
      }}
      onFinishFailed={() => console.log("")}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input Name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Branch" name="branch_id" rules={[]}>
        <Select>
          {branches.map((branch: Branch) => {
            return <Option value={branch.id}>{branch.name}</Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Team Type" name="type_id" rules={[]}>
        <Select>
          {teamTypes.map((branch: any) => {
            return <Option value={branch.id}>{branch.name}</Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Owner" name="owner_id" rules={[]}>
        <Select>
          {users.map((user: User) => {
            return <Option value={user.id}>{getFullName(user)}</Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
        <Button loading={savingTeam} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TeamForm;
