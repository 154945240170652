import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../shared/constants";
import { Col, Row, PageHeader, Modal, Button, Checkbox } from "antd";
import moment from 'moment';


const StartQCHeader: React.FunctionComponent<any> = () => {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { pharmacyDetails } = useSelector((state: RootState) => state.pharmacy);

    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const handlePatientTag = (e: any) => {
      console.log('checked', e.target.checked, e.target.value);
    }

    return (
      <div className={'desktop-form-layout'}>
        <div className="title">
          <Row>
            <Col>
              <PageHeader
                style={{ paddingLeft: "0px" }}
                className="primary-color-text"
                onBack={() =>  history.goBack()}
                title={pharmacyDetails?.patient?.pii?.first_name+ ' ' + pharmacyDetails?.patient?.pii?.last_name}
              >
              </PageHeader>
            </Col>
          </Row>
          <Row>
              <Col span={16}>
                <ul style={{listStyleType: 'none'}}>
                  <li> {pharmacyDetails?.patient?.pii?.mobile} | UHID: {pharmacyDetails?.patient?.uhid} 
                  {/* | &nbsp;<Tag> Chronic</Tag> <Tag>Sensitive</Tag> */}
                  </li>
                  {/* <li style={{color: 'blue', cursor:'pointer'}} onClick={showModal}> +Add Patient Tags </li> */}
                </ul>
              </Col>
              <Col span={8} style={{margin: '-45px'}}>
                {pharmacyDetails?.appointment ? 
                  <ul style={{listStyleType: 'none'}}>
                    <li> {pharmacyDetails?.appointment?.doctor?.name} {pharmacyDetails?.appointment?.doctor?.qualification ? (pharmacyDetails?.appointment?.doctor?.qualification) : ''}</li>
                    <li > Appt. Time: {moment(pharmacyDetails?.appointment?.appointment_date).format("DD MMM'YY hh:mm A")} | Max, Saket </li>
                    {/* <li > Prescription Time: 26 Sep'21,10:50 AM | Max, Saket </li> */}
                  </ul> : 
                  ""
                }
                
              </Col>
            </Row>
          <Modal title="Add Patient Tag" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
            footer={[
              <Button key="submit" type="primary">
                Submit
              </Button>
            ]}
          >
            <Checkbox value="chronic" onChange={handlePatientTag}>Chronic Patient</Checkbox>
            <Checkbox value="sensitive" onChange={handlePatientTag}>Sensitive Patient</Checkbox>
          </Modal>
        </div>
     </div>
    );
};

export default StartQCHeader;

