import React from "react";
import ValidationComponent from "./ValidationComponent";
class CompactTableCheckBoxComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  changeValue = (event) => {
    this.props.emitTextboxValue({
      name: event.target.name,
      value: event.target.value,
      type: "checkbox",
    });
  };

  checkIfValuePresentInValuesArray(value, valuesArray) {
    if (!value || !valuesArray) {
      return;
    }
    for (let index in valuesArray) {
      if (valuesArray[index].key === value) {
        return true;
      }
    }
    return false;
  }

  setErrorStatus = (errorStatus) => {
    this.props.emitErrorStatus(errorStatus);
  };

  render() {
    // console.log('-----------------------------------checkbox---------------------------------');
    const {
      type,
      name,
      classname,
      value,
      values,
      placeholder,
      label,
      labelSize,
      size,
      options,
      offset,
      rowType,
    } = this.props;
    return (
      <React.Fragment>
        {options &&
          options.map((indOption, index) => {
            return (
              <div className="form-check" key={index}>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={`${label}-${name}`}
                    id={name}
                    value={indOption.value || ""}
                    checked={this.checkIfValuePresentInValuesArray(
                      indOption.value,
                      indOption.values
                    )}
                    onChange={this.changeValue.bind(this)}
                  />{" "}
                  {indOption.value} <i className="input-helper"></i>
                  <i className="input-helper"></i>
                </label>
              </div>
            );
          })}
        {this.props.validation && (
          <ValidationComponent
            value={values}
            checkForm={this.props.checkForm}
            isDirty={this.props.isDirty}
            validation={this.props.validation}
            emitErrorStatus={this.setErrorStatus.bind(this)}
          />
        )}
        {offset && <div className={offset}> </div>}
      </React.Fragment>
    );
  }
}

export default CompactTableCheckBoxComponent;
