
const ReportUploadIcon =({height="38" , width="38"}) =>(
    <>
        
        <svg width={width} height={height} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.1" x="0.474609" y="0.678223" width="36.8303" height="36.8303" rx="18.4151" fill="#163B6D"/>
        <path d="M15.2806 16.5283C13.63 17.655 12.6367 19.523 12.6367 21.5244C12.6367 24.2622 14.4751 26.654 17.1189 27.3607" stroke="#163B6D" stroke-width="1.4" stroke-miterlimit="2.6131" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.0415 11.4904L14.0469 14.4851L20.2004 20.6386L23.195 17.6439L17.0415 11.4904Z" stroke="#163B6D" stroke-width="1.4" stroke-miterlimit="2.6131" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.4139 18.5026L21.0371 19.8794L22.2496 21.0919L23.6264 19.7151L22.4139 18.5026Z" stroke="#163B6D" stroke-width="1.4" stroke-miterlimit="2.6131" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.9264 10.8073L13.3574 12.3762L14.7552 13.774L16.3241 12.205L14.9264 10.8073Z" stroke="#163B6D" stroke-width="1.4" stroke-miterlimit="2.6131" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.3297 24.5442H20.6777" stroke="#163B6D" stroke-width="1.4" stroke-miterlimit="2.6131" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.5059 24.5442V27.3808" stroke="#163B6D" stroke-width="1.4" stroke-miterlimit="2.6131" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.6826 27.3806H12.0977" stroke="#163B6D" stroke-width="1.4" stroke-miterlimit="2.6131" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    </>
); 

export default ReportUploadIcon;


