import { Card, Col, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DiagnosticsTable from "./components/diagnostics.table";
const { Title } = Typography;
type Props = {};

function DiagnosticSummary(props: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <div className={"col-md-12 main-container padding15"}>
      <Title className="paddingcollapse" level={4}>
        Diagnostics
      </Title>
      <Col span={24}>
        <Card className="nopaddingcard crm-tabs">
          <DiagnosticsTable />
        </Card>
      </Col>
    </div>
  );
}

export default DiagnosticSummary;
