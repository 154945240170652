import { FileOutlined } from "@ant-design/icons";
import { Col, Image, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CLIENT_NAME, RootState } from "../../shared/constants";

const { Text } = Typography;

type Props = {
  prescriptionData?: any;
};

function PrescriptionComponent(props: Props) {
  const { Option } = Select;
  const [visible, setVisibleImage] = useState(false);
  const initialValues = props.prescriptionData;
  const [prescription, setPrescription] = useState(initialValues);
  const { pharmacyDetails } = useSelector((state: RootState) => state.pharmacy);

  useEffect(() => {
    if (props?.prescriptionData) {
      if (props?.prescriptionData[0]?.src) {
        setPrescription(props.prescriptionData);
      } else {
        setPrescription([]);
      }
    }
  }, [props?.prescriptionData]);

  return (
    <div className={"col-md-12 main-container"}>
      {pharmacyDetails?.document_details &&
      pharmacyDetails?.document_details.length > 0 ? (
        <>
          <Row>
            <Select placeholder="Select Prescription Source">
              <Option value="1">{CLIENT_NAME} Prescription</Option>
              <Option value="2">Non {CLIENT_NAME} Prescription</Option>
            </Select>
          </Row>
          {/* <Row className={'mt15'}>
          <iframe height="500" width="400" src='http://www.africau.edu/images/default/sample.pdf#toolbar=0' />
        </Row> */}
          <Row className={"mt15"}>
            <Image
              preview={{ visible: false }}
              width={400}
              height={500}
              src={prescription && prescription[0]?.src}
              onClick={() => setVisibleImage(true)}
            />
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible,
                  onVisibleChange: (vis) => setVisibleImage(vis),
                }}
              >
                {prescription.map((ind: any) => (
                  <Image src={ind.src} />
                ))}
              </Image.PreviewGroup>
            </div>
            <Text className="sub-heading mt10">
              1 of {prescription.length} Images
            </Text>
          </Row>
        </>
      ) : (
        <>
          <Row className="sub-title full-width mt50">
            <Col span={10}> </Col>
            <Col span={14}>
              {" "}
              <FileOutlined style={{ fontSize: "42px" }} />{" "}
            </Col>
          </Row>
          <Row className="sub-title full-width">
            <Col span={6}> </Col>
            <Col span={18}>
              {" "}
              <Text>No Prescription available.</Text>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default PrescriptionComponent;
