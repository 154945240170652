///////////// BOSS Routes
export const ROUTE_BOSS_INSTANT_VC = '/boss/video-call/instant'
export const ROUTE_BOSS_DASHBAORD = '/boss/dashboard'
export const ROUTE_BOSS_ANALYTICS = '/boss/analytics'
export const ROUTE_BOSS_INSTANT_VC_DOCTOR = '/boss/video/instant/doctor_summary'
export const ROUTE_BOSS_PHARMACY_FULFIL = '/boss/pharmacy/order-fulfilment'
export const ROUTE_BOSS_DOCTOR_CORDINATOR = '/boss/doctor/cordinator'
export const ROUTE_DIAGNOSTICS_ORDERS = '/boss/diagnostics/order-summary'
export const ROUTE_PAYMENT_SUMMARY = '/boss/payment/payment-summary'
export const ROUTE_PRESCRIPTION_TEMPLATES = "/boss/prescription-templates";
export const ROUTE_PRESCRIPTION_TEMPLATE_CREATE = '/boss/prescription-templates/create'
export const ROUTE_PRESCRIPTION_TEMPLATE_ACTION = (id: string, mode: string) => `/boss/prescription-templates/${id}/${mode}`
export const GET_QUICKSIGHT_DASHBOARD = (
    dashboard_id: string,
    account_slug: string
) =>
    "task/get-quicksight-dashboard?dashboard_id=" +
    dashboard_id +
    "&account_slug=" +
    account_slug;

// BOSS API routes














