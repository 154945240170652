import { RightOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Divider, Row, Space, Tag } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DoctorAppointmentBadge from "../../../../shared/layouts/doctor-badge.layout";
import { ROUTE_DOCTOR_APPOINTMENTS } from "../../../../shared/routes/doctor.routes.constants";
var _ = require("lodash");

type Props = {
  appointments?: Array<String>;
  current?: any;
};

const AppointmentCountCard: React.FunctionComponent<Props> = ({
  appointments,
  current,
}) => {
  const history = useHistory();
  const [, setCurrentDate] = useState(null as any);
  const showAppointments = (
    appointment: any,
    start?: any,
    end?: any,
    doctor_slot_config_id?: any,
    route?: any,
    practice?: any
  ) => {
    history.push({
      pathname: ROUTE_DOCTOR_APPOINTMENTS(route),
      state: {
        branch: appointment.branch_id,
        date: appointment.appointment_date,
        branch_name: appointment.branch_name,
        start: start,
        end: end,
        doctor_slot_config_id: doctor_slot_config_id,
        practice_status: practice,
      },
    });
  };

  useEffect(() => {
    setCurrentDate(moment(current).format("YYYY-MM-DD"));
  }, [current]);
  return (
    <Fragment>
      {appointments &&
        appointments.map((appointment: any) => {
          return (
            <Row>
              <Col
                span={
                  appointment?.appointments.length === 1
                    ? 8
                    : appointment?.appointments.length === 2
                    ? 16
                    : 22
                }
              >
                <Card
                  className="doctor-app-light-card noborders border-radius5 doctor-box-shadow capitalize"
                  title={
                    <span
                      className={
                        appointment?.branch_name ===
                        "Instant Video Consultation"
                          ? ""
                          : "cursor underline"
                      }
                      onClick={() =>
                        appointment?.branch_name !==
                        "Instant Video Consultation"
                          ? showAppointments(
                              appointment,
                              null,
                              null,
                              null,
                              "opd",
                              null
                            )
                          : ""
                      }
                    >
                      {appointment.branch_name} (
                      {_.sumBy(appointment.appointments, function (o: any) {
                        return appointment?.branch_name !==
                          "Instant Video Consultation" &&
                          o?.appointment_type !== "ipd"
                          ? parseInt(o.total_count)
                          : 0;
                      })}
                      ) <RightOutlined />
                    </span>
                  }
                  style={{ marginBottom: "20px" }}
                >
                  <Row gutter={30}>
                    {appointment.appointments.map(
                      (_appointment: any, i: any) => {
                        if (_appointment.appointment_type === "opd") {
                          return (
                            <Col
                              className={"mt10"}
                              span={
                                appointment?.appointments.length < 2
                                  ? 24
                                  : appointment?.appointments.length === 2
                                  ? 12
                                  : 8
                              }
                            >
                              <Row>
                                <Col
                                  span={6}
                                  className={
                                    _appointment.shift_completed
                                      ? "doctor-white-color-background border-radius5  text-center"
                                      : "doctor-primary-color-background border-radius5  text-center"
                                  }
                                  style={{ height: "73px", paddingTop: "25px" }}
                                >
                                  <p className={"white-text fw550"}>OPD</p>
                                </Col>

                                <Col span={17} style={{ paddingLeft: "15px" }}>
                                  <p
                                    className={
                                      _appointment.total_count === 0 ||
                                      _appointment.shift_completed
                                        ? "doctor-primary-color bold cursor capitalize "
                                        : "doctor-primary-color bold cursor capitalize mb-0"
                                    }
                                    onClick={() =>
                                      showAppointments(
                                        appointment,
                                        _appointment.slot_start_time,
                                        _appointment.slot_end_time,
                                        _appointment.doctor_slot_config_id,
                                        "opd",
                                        _appointment.practice_status
                                      )
                                    }
                                  >
                                    {(() => {
                                      if (_appointment.shift_completed) {
                                        return (
                                          <div className="">
                                            {" "}
                                            Shift Completed{" "}
                                          </div>
                                        );
                                      } else if (
                                        _appointment.total_count === 0
                                      ) {
                                        return (
                                          <div className="mt5">
                                            No Appointments Booked
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div className="">
                                            {_appointment.total_count +
                                              " Appointments"}
                                          </div>
                                        );
                                      }
                                    })()}

                                    {/* {_appointment.total_count === 0 ? "No Appointments Booked" : _appointment.total_count + " Appointments"}   <br></br> */}

                                    {(() => {
                                      if (_appointment.shift_completed) {
                                        if (_appointment.total_count === 0) {
                                          return (
                                            <div>
                                              {" "}
                                              {
                                                <>
                                                  <span
                                                    className={
                                                      _appointment.shift_completed
                                                        ? "max-subHead-color "
                                                        : ""
                                                    }
                                                  >
                                                    No Appointments Booked
                                                  </span>
                                                </>
                                              }{" "}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              {
                                                <>
                                                  <span
                                                    className={
                                                      _appointment.shift_completed
                                                        ? "max-subHead-color"
                                                        : ""
                                                    }
                                                  >
                                                    {_appointment.total_count +
                                                      " Appointments"}
                                                  </span>
                                                </>
                                              }
                                            </div>
                                          );
                                        }
                                      }
                                    })()}
                                    {_appointment.shift_completed ? (
                                      <>
                                        <span
                                          className="sub-text max-subHead-color font12"
                                          style={{
                                            fontWeight: "400",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {" "}
                                          {_appointment.slot_start_time} -{" "}
                                          {_appointment.slot_end_time}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          className="sub-text max-subHead-color font12"
                                          style={{
                                            fontWeight: "400",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {" "}
                                          {_appointment.slot_start_time} -{" "}
                                          {_appointment.slot_end_time}
                                        </span>{" "}
                                        {_appointment?.room_no && (
                                          <>
                                            <br></br>
                                            <span
                                              className="sub-text max-subHead-color font12"
                                              style={{
                                                fontWeight: "400",
                                              }}
                                            >
                                              (Room No: {_appointment?.room_no})
                                            </span>
                                          </>
                                        )}
                                        <br></br>
                                        {_appointment.procedures.map(
                                          (procedure: any) => {
                                            return (
                                              <Tag
                                                style={{
                                                  paddingRight: "0px",
                                                  alignContent: "center",
                                                  border: "1px solid white",
                                                  marginTop: "5px",
                                                }}
                                                className={"border-radius5"}
                                              >
                                                <DoctorAppointmentBadge
                                                  show={true}
                                                  width={15}
                                                  type={
                                                    procedure.procedure_name
                                                  }
                                                  count={
                                                    procedure.appointment_count
                                                  }
                                                ></DoctorAppointmentBadge>
                                              </Tag>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  </p>
                                </Col>
                                <Col
                                  span={1}
                                  style={{
                                    textAlign: "start",
                                    alignSelf: "center",
                                  }}
                                >
                                  <Space align={"center"} size={[0, 4]}>
                                    <RightOutlined
                                      className={"cursor"}
                                      onClick={() =>
                                        showAppointments(
                                          appointment,
                                          _appointment.slot_start_time,
                                          _appointment.slot_end_time,
                                          _appointment.doctor_slot_config_id,
                                          "opd",
                                          _appointment.practice_status
                                        )
                                      }
                                    />
                                    {appointment?.appointments.length > 0 &&
                                    appointment?.appointments.length > i + 1 &&
                                    i !== 2 ? (
                                      <Divider
                                        type="vertical"
                                        style={{
                                          height: "56px",
                                          borderLeft:
                                            "1px solid #8EAAC3 !important",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          );
                        }
                        if (_appointment.appointment_type === "instant") {
                          return (
                            <Col
                              className={"mt5"}
                              span={
                                appointment?.appointments.length < 2 ? 24 : 8
                              }
                            >
                              <Row>
                                <Col
                                  span={6}
                                  className={
                                    "doctor-secondary-color-background border-radius5  text-center"
                                  }
                                  style={{
                                    height: "73px",
                                    width: "60px",
                                    paddingTop: "25px",
                                  }}
                                >
                                  <p className={"white-text"}>VC</p>
                                </Col>

                                <Col span={17} style={{ paddingLeft: "15px" }}>
                                  <p
                                    className="doctor-primary-color bold mt10 mb-0 cursor capitalize"
                                    onClick={() =>
                                      showAppointments(
                                        appointment,
                                        _appointment.slot_start_time,
                                        _appointment.slot_end_time,
                                        _appointment.doctor_slot_config_id,
                                        "opd",
                                        _appointment.practice_status
                                      )
                                    }
                                  >
                                    {_appointment.total_count === 0
                                      ? "No"
                                      : _appointment.total_count}{" "}
                                    Appointments <br></br>{" "}
                                    <span className="sub-text light-text font12">
                                      {" "}
                                      {_appointment.slot_start_time} -{" "}
                                      {_appointment.slot_end_time}
                                    </span>{" "}
                                    <br></br>
                                    {_appointment.shift_completed && (
                                      <p className="sub-text opacity03">
                                        {" "}
                                        Shift Completed{" "}
                                      </p>
                                    )}
                                  </p>
                                </Col>
                                <Col
                                  span={1}
                                  style={{
                                    textAlign: "start",
                                    marginTop: "10px",
                                    alignSelf: "center",
                                  }}
                                >
                                  <Space align={"center"} size={[0, 0]}>
                                    <RightOutlined
                                      className={"cursor"}
                                      onClick={() =>
                                        showAppointments(
                                          appointment,
                                          _appointment.slot_start_time,
                                          _appointment.slot_end_time,
                                          _appointment.doctor_slot_config_id,
                                          "opd",
                                          _appointment.practice_status
                                        )
                                      }
                                    />
                                    {appointment?.appointments.length > 0 &&
                                    appointment?.appointments.length > i + 1 &&
                                    i !== 2 ? (
                                      <Divider
                                        type="vertical"
                                        style={{
                                          height: "55px",
                                          borderLeft:
                                            "1px solid  #8EAAC3 !important",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          );
                        } else {
                          return (
                            <Col
                              className={"mt10"}
                              span={
                                appointment?.appointments.length < 2
                                  ? 24
                                  : appointment?.appointments.length === 2
                                  ? 10
                                  : 8
                              }
                            >
                              <Row>
                                <Col
                                  span={6}
                                  className={
                                    "doctor-secondary-color-background border-radius5 text-center"
                                  }
                                  style={{
                                    height: "73px",
                                    width: "60px",
                                    paddingTop: "25px",
                                  }}
                                >
                                  <p className={"white-text fw550"}>IPD</p>
                                </Col>
                                <Col span={17} style={{ paddingLeft: "10px" }}>
                                  <p
                                    className="doctor-primary-color bold mt10 cursor mb-0 capitalize"
                                    onClick={() =>
                                      showAppointments(
                                        appointment,
                                        _appointment.slot_start_time,
                                        _appointment.slot_end_time,
                                        _appointment.doctor_slot_config_id,
                                        "ipd",
                                        _appointment.practice_status
                                      )
                                    }
                                  >
                                    {_appointment?.admit === undefined
                                      ? "0"
                                      : _appointment.admit}{" "}
                                    Admitted Patients{" "}
                                  </p>

                                  {
                                    <Tag
                                      style={{
                                        paddingRight: "10px",
                                        alignContent: "center",
                                        border: "1px solid white",
                                        marginTop: "5px",
                                      }}
                                      className={
                                        "border-radius5 doctor-primary-color"
                                      }
                                    >
                                      <Avatar
                                        style={{
                                          padding: "0px",
                                          lineHeight: "0px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        shape={"square"}
                                        src={
                                          <svg
                                            width="13"
                                            height="12"
                                            viewBox="0 0 13 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10.4654 4.95652L8.79581 4.01739V2.08696H9.57842V3.54783L10.8306 4.27826L10.4654 4.95652ZM12.0306 6.10435C12.2915 6.46956 12.448 6.88696 12.448 7.30435V12H11.4045V10.4348H2.0132V12H0.969727V4.17391H2.0132V8.86957H6.18712V5.53044C5.87407 4.95652 5.66538 4.33043 5.66538 3.65217C5.66538 1.61739 7.28277 0 9.31755 0C11.3523 0 12.9697 1.61739 12.9697 3.65217C12.9697 4.5913 12.6045 5.42609 12.0306 6.10435ZM6.70886 3.65217C6.70886 5.11304 7.85668 6.26087 9.31755 6.26087C10.7784 6.26087 11.9262 5.11304 11.9262 3.65217C11.9262 2.1913 10.7784 1.04348 9.31755 1.04348C7.85668 1.04348 6.70886 2.1913 6.70886 3.65217ZM4.10016 8.34783C4.98712 8.34783 5.66538 7.66957 5.66538 6.78261C5.66538 5.89565 4.98712 5.21739 4.10016 5.21739C3.2132 5.21739 2.53494 5.89565 2.53494 6.78261C2.53494 7.66957 3.2132 8.34783 4.10016 8.34783Z"
                                              fill="#23BCB5"
                                            />
                                          </svg>
                                        }
                                      ></Avatar>{" "}
                                      {_appointment.pre_admit === undefined
                                        ? "0"
                                        : _appointment.pre_admit}{" "}
                                      Pre Admission
                                    </Tag>
                                  }
                                </Col>
                                <Col
                                  span={1}
                                  style={{
                                    textAlign: "start",
                                    marginTop: "10px",
                                    alignSelf: "center",
                                  }}
                                >
                                  <Space align={"center"} size={[0, 0]}>
                                    <RightOutlined
                                      className={"cursor"}
                                      onClick={() =>
                                        showAppointments(
                                          appointment,
                                          _appointment.slot_start_time,
                                          _appointment.slot_end_time,
                                          _appointment.doctor_slot_config_id,
                                          "ipd",
                                          _appointment.practice_status
                                        )
                                      }
                                    />
                                    {appointment?.appointments.length > 0 &&
                                    appointment?.appointments.length > i + 1 &&
                                    i !== 2 ? (
                                      <Divider
                                        type="vertical"
                                        style={{
                                          height: "55px",
                                          borderLeft:
                                            "1px solid  #8EAAC3 !important",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          );
                        }
                      }
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
          );
        })}
    </Fragment>
  );
};

export default AppointmentCountCard;
