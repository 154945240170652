import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Col, Layout, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setSearchValue } from "../../redux/reducers/master.slice";
import AdminHeader from "./header-admin.layout";
import Sidebar from "./sidebar";
//import { getInitials } from '../Utils/utilities';

const { Header, Sider, Content } = Layout;

function AdminDesktopLayout({ children }: any) {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  //const { profile } = useSelector((state: RootState) => state.profile);
  //const [positions, setposition] = useState('absolute' as any)
  const searchValue = (values: any) => {
    dispatch(setSearchValue(values));
  };
  /*
  const logOut = () => {
    dispatch(logoutUser({ id: profile.id }));
  };
  
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => logOut()}>
        <span> Logout </span>
      </Menu.Item>
    </Menu>
  );
*/
  return (
    <Layout className={"parent-layout"}>
      <Sider
        trigger={null}
        theme="light"
        collapsible
        collapsed={collapsed}
        className={"doctor-app-sidebar noborder"}
      >
        <Row>
          <Col span={12}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
          </Col>

          <Col span={12}>
            <div className="logo">
              <img
                alt="Max Healthcare"
                style={{ width: "100%" }}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/6/65/Max-Healthcare-Logo.png"
                }
              />
            </div>
          </Col>
        </Row>
        <div className="mt20">
          <Sidebar type={"admin"} />
        </div>
      </Sider>
      <div
        className={`site-layout ${
          collapsed ? "collapsed-side-bar" : "expanded-side-bar"
        }`}
      >
        <Header className="site-header-background" style={{ padding: 15 }}>
          <Row>
            <Col span={24}>
              <AdminHeader onSearch={(values: any) => searchValue(values)} />
            </Col>
          </Row>
        </Header>
        <Content className="doctor-app admin-app">
          <Col span={24}>{children}</Col>
        </Content>
      </div>
    </Layout>
  );
}

export default AdminDesktopLayout;
