import { Col, Space, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAppointmentAuditTrail } from "../../../../redux/reducers/appointment.slice";
const { Text } = Typography;

const PatientJourney: React.FunctionComponent<any> = (Props) => {
  const [patientData, setpatientData] = useState();
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      className: "capitalize",
    },
    {
      title: "Action By",
      dataIndex: "action_by",
      className: "capitalize",
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      className: "capitalize",
      // render: (a: any) => { return (<>{formatDate(a)}</>) }
    },
    {
      title: "Source",
      dataIndex: "source",
      className: "capitalize",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      render: (a: any) => {
        return <>{moment(a).format("DD MMM'YY, h:m A")}</>;
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchAppointmentAuditTrail(Props?.appointmentId)).then(
      (val: any) => {
        setpatientData(val?.payload?.data?.data);
      }
    );
  }, []);

  useEffect(() => {
    if (Props?.isReset !== "reset" && Props?.isReset !== undefined) {
      dispatch(fetchAppointmentAuditTrail(Props?.appointmentId)).then(
        (val: any) => {
          setpatientData(val?.payload?.data?.data);
        }
      );
    }
  }, [Props.isReset]);

  return (
    <Col span={24}>
      <Table
        loading={false}
        locale={{
          emptyText: (
            <>
              <Space direction={"vertical"} size={10}>
                <Text className="max-subHead-color">No Records</Text>
              </Space>
            </>
          ),
        }}
        pagination={false}
        scroll={{ x: true }}
        dataSource={patientData}
        columns={columns}
      />
    </Col>
  );
};

export default PatientJourney;
